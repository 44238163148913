import { Component, ElementRef, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { LoggingService } from './services/logging-service';
import { CanDeactivateState } from './services/guards/can-deactivate.guard';
import { filter, tap } from 'rxjs/operators';
import { InjectorService } from './services/injector.service';
import { ScreenService } from './services/screen-service.service';
import { BaseComponent } from './models/base/base-component';
import { TypeService } from './services/type-service';
import { CompanyDomainModel } from './domainModels/company-domain-model';
// DO NOT DELETE BELOW - these must be imported to use these extensions across app
import './utils/behavior-subject.extensions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {

  public isMobile$ = this.screenService.isMobile$;

  constructor(
    private router: Router,
    private loggingService: LoggingService,
    private screenService: ScreenService,
    private injectorService: InjectorService, // Do not remove, or else injector service does not work.
    private typeService: TypeService, // Do not remove, or else type service does not work.
    private companyDomainModel: CompanyDomainModel, // initialize company domain model at startup
    private el: ElementRef
  ) {
    super();
    // Config for Firebase
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any } = {};
    userProperties.Platform = 'Dashboard';
    firebase.analytics().setUserProperties(userProperties);
    this.loggingService.SetFirebaseAppInstance(firebaseApp);
    // Config for Abandon changes on back button
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    ).subscribe();
  }

  override setupViews() {
    this.onResize(window?.innerWidth);
  }

  override setupBindings() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(windowInnerWidth?: number) {
    const width = windowInnerWidth || (this.el.nativeElement as HTMLElement).getBoundingClientRect().width;
    if (width > 0) {
      this.screenService.setWidth(width);
      this.screenService.setIsMobile(width < ScreenService.MOBILE_WIDTH);
    }
  }

}
