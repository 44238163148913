import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(
    public sanitizer: DomSanitizer
  ) {
  }

  transform(htmlString: string): string {
    return this.sanitizer?.sanitize(SecurityContext.HTML, htmlString);
  }

}
