import { Component, ViewChild } from '@angular/core';
import { EditBadgeViewModel } from './edit-badge-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageInterface } from '../../components/upload-asset/upload-image-interface';
import { UploadAssetComponent } from '../../components/upload-asset/upload-asset.component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { ReactiveFormDropDownComponent, ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-badge',
  templateUrl: './edit-badge.component.html',
  styleUrls: ['./edit-badge.component.scss'],
  providers: [ EditBadgeViewModel ],
})
export class EditBadgeComponent extends BaseModalComponent implements UploadImageInterface {

  @ViewChild('badgeName') badgeName: ReactiveFormTextComponent;
  @ViewChild('sectionDropDown') sectionDropDown: ReactiveFormDropDownComponent;
  @ViewChild('subSectionDropDown') subSectionDropDown: ReactiveFormDropDownComponent;
  @ViewChild('editBadgeUpload') uploadComponent: UploadAssetComponent;

  public canSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public badgeReplaced$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public canSubmit$ = combineLatest([
    this.canSubmitForm$,
    this.badgeReplaced$,
    this.viewModel.badgeAsset$,
    this.viewModel.imgToUpload$,
  ]).pipe(
    map(([canSubmitForm, badgeReplaced, badgeAsset, imgToUpload]) => {
      if (!this.viewModel.addingNewBadge) {
        return (canSubmitForm || badgeReplaced) && (!!badgeAsset || !!imgToUpload);
      } else {
        return (canSubmitForm || badgeReplaced) && ((!!badgeAsset || !!imgToUpload) && canSubmitForm);
      }
    })
  );

  constructor(
    public viewModel: EditBadgeViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  isDifferentName(): boolean {
    return this.badgeName.getMyValue() !== this.viewModel.originalBadgeName;
  }

  override cancel() {
    this.uploadComponent.clear();
    this.activeModal.close(null);
  }

  override setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) { this.cancel(); }
    });
    this.pushSub(s);
  }

  addNewBadge() {
    this.viewModel.addingNewBadge = true;
    this.viewModel.setBadge(new HydratedVariantBadge());
  }

  setBadge(b: HydratedVariantBadge) {
    this.viewModel.setBadge(b);
  }

  fileList(f: BudsenseFile[], id?: number) {
    this.viewModel.toggleReplaceImg();
    if (f.length > 0) {
      this.badgeReplaced$.next(true);
      this.viewModel.imgToUpload = f[0];
      this.viewModel.imgToUpload$.next(f[0]);
    } else {
      this.viewModel.imgToUpload = undefined;
      this.viewModel.imgToUpload$.next(undefined);
    }
  }

  listenToSectionChanges(sectionName: any) {
    this.viewModel.listenToSection(sectionName);
    if (!sectionName) {
      this.subSectionDropDown.clear();
    }
  }

}
