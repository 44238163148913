<lib-reactive-form-group #editVariantForm [bindTo]="bindTo" [mergeKey]="mergeKey">
  <hr />
  <lib-reactive-form-column-layout nColumns="2">
    <lib-reactive-form-switch
      class="switch-padding"
      [inputName]="'cultivatorVerified'"
      [label]="'Cultivator Verified'"
      [labelOnRight]="true"
      [bindingProperty]="'variant.cultivatorVerified'">
    </lib-reactive-form-switch>
    <lib-reactive-form-switch
      class="switch-padding"
      [inputName]="'delisted'"
      [label]="'Delisted Product'"
      [labelOnRight]="true"
      [bindingProperty]="'variant.delisted'">
    </lib-reactive-form-switch>
  </lib-reactive-form-column-layout>
  <hr />
  <div class="form-section-title">General Info</div>
  <lib-reactive-form-text
    [inputName]="'universalVariantName'"
    [label]="'Universal Variant Name'"
    [placeholder]="'Enter the Variant Name'"
    [required]="true"
    [bindingProperty]="'variant.name'">
  </lib-reactive-form-text>
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'brand'"
      [label]="'Brand'"
      [placeholder]="'Enter the brand'"
      [minLength]="1"
      [maxLength]="24"
      [required]="true"
      [bindingProperty]="'variant.brand'">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      [inputName]="'barcode'"
      [label]="'Barcode'"
      [placeholder]="'Enter the Barcode'"
      [disabled]="true"
      [bindingProperty]="'variant.barcode'">
    </lib-reactive-form-text>
    <lib-reactive-form-text
      [inputName]="'manufacturer'"
      [label]="'Manufacturer'"
      [placeholder]="'Enter the manufacturer'"
      [minLength]="1"
      [maxLength]="100"
      [bindingProperty]="'variant.manufacturer'">
    </lib-reactive-form-text>
    <lib-reactive-form-drop-down
      #productTypeDropdown
      [inputName]="'filterByProductType'"
      [bindingProperty]="'variant.productType'"
      [label]="'Product Type'"
      [required]="true"
      [placeholder]="'Choose a product type'"
      [dropdowns]="productTypeOptions$ | async"
      (valueChanged)="productTypeSelected($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      #variantTypeDropdown
      [inputName]="'variantType'"
      [bindingProperty]="'variant.variantType'"
      [label]="'Variant Type'"
      [required]="true"
      [disabled]="variantTypeDisabled$ | async"
      [placeholder]="'Choose a variant type'"
      [dropdowns]="variantTypeOptions$ | async">
    </lib-reactive-form-drop-down>
    <ng-container *ngIf="!(isAccessoryProduct$ | async)">
      <lib-reactive-form-drop-down
        [inputName]="'classification'"
        [bindingProperty]="'variant.classification'"
        [label]="'Strain Type'"
        [required]="true"
        [placeholder]="'Choose a strain type'"
        [dropdowns]="classificationTypeOptions$ | async">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'strain'"
        [label]="'Strain'"
        [placeholder]="'Enter the strain'"
        [minLength]="1"
        [maxLength]="100"
        [bindingProperty]="'variant.strain'">
      </lib-reactive-form-text>
    </ng-container>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-text-area
    [inputName]="'variantDescription'"
    [label]="'Description'"
    [placeholder]="'Add Description'"
    [required]="true"
    [bindingProperty]="'variant.description'">
  </lib-reactive-form-text-area>

  <lib-reactive-form-text-area
    [inputName]="'variantShortDescription'"
    [label]="'Short Description'"
    [placeholder]="'Add Short Description'"
    [required]="false"
    [bindingProperty]="'variant.shortDescription'">
  </lib-reactive-form-text-area>

  <ng-container *ngIf="!(isAccessoryProduct$ | async)">
    <hr />
    <div class="form-section-title">Packaging Info</div>
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-number
        [inputName]="'unitSize'"
        [label]="'Unit Size'"
        [required]="true"
        [placeholder]="'Enter the unit size'"
        [minValue]="0.01"
        [maxValue]="1000"
        [tooltip]="'This value is the total weight of the variant.'"
        [bindingProperty]="'variant.unitSize'">
      </lib-reactive-form-number>
      <lib-reactive-form-drop-down
        [inputName]="'unitOfMeasure'"
        [bindingProperty]="'variant.unitOfMeasure'"
        [label]="'Unit Of Measure'"
        [required]="true"
        [placeholder]="'Select product size unit of measure'"
        [dropdowns]="unitOfMeasureOptions$ | async">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-number
        [inputName]="'packagedQuantity'"
        [label]="'Packaged Quantity'"
        [required]="true"
        [placeholder]="'Enter the packaged quantity'"
        [integersOnly]="true"
        [minValue]="1"
        [maxValue]="1000"
        [tooltip]="'This value is the number of units that are packaged in the variant.'"
        [bindingProperty]="'variant.packagedQuantity'">
      </lib-reactive-form-number>
      <lib-reactive-form-drop-down
        [inputName]="'cannabisUnitOfMeasure'"
        [bindingProperty]="'variant.cannabisUnitOfMeasure'"
        [label]="'Cannabis Unit Of Measure'"
        [required]="true"
        [placeholder]="'Select product cannabis unit of measure'"
        [dropdowns]="cannabisUnitOfMeasureOptions$ | async">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-number
        [inputName]="'THC'"
        [label]="'Default THC'"
        [required]="true"
        [placeholder]="'Enter the default THC content'"
        [tooltip]="'This is the default THC value for the product.'"
        [maxValue]="10000"
        [bindingProperty]="'THC'">
      </lib-reactive-form-number>

      <lib-reactive-form-column-layout nColumns="2">
        <lib-reactive-form-number
          [inputName]="'minTHC'"
          [label]="'Default Min THC'"
          [required]="true"
          [placeholder]="'Enter the default min THC content'"
          [tooltip]="'This is the default min THC value for the product.'"
          [maxValue]="10000"
          [bindingProperty]="'minTHC'">
        </lib-reactive-form-number>
        <lib-reactive-form-number
          [inputName]="'maxTHC'"
          [label]="'Default Max THC'"
          [required]="true"
          [placeholder]="'Enter the default max THC content'"
          [tooltip]="'This is the default max THC value for the product.'"
          [maxValue]="10000"
          [bindingProperty]="'maxTHC'">
        </lib-reactive-form-number>
      </lib-reactive-form-column-layout>
      <lib-reactive-form-number
        [inputName]="'CBD'"
        [label]="'Default CBD'"
        [required]="true"
        [placeholder]="'Enter the default CBD content'"
        [tooltip]="'This is the default CBD value for the product.'"
        [maxValue]="10000"
        [bindingProperty]="'CBD'">
      </lib-reactive-form-number>
      <lib-reactive-form-column-layout nColumns="2">
        <lib-reactive-form-number
          [inputName]="'minCBD'"
          [label]="'Default Min CBD'"
          [required]="true"
          [placeholder]="'Enter the default min CBD content'"
          [tooltip]="'This is the default min CBD value for the product.'"
          [maxValue]="10000"
          [bindingProperty]="'minCBD'">
        </lib-reactive-form-number>
        <lib-reactive-form-number
          [inputName]="'maxCBD'"
          [label]="'Default Max CBD'"
          [required]="true"
          [placeholder]="'Enter the default max CBD content'"
          [tooltip]="'This is the default max CBD value for the product.'"
          [maxValue]="10000"
          [bindingProperty]="'maxCBD'">
        </lib-reactive-form-number>
      </lib-reactive-form-column-layout>
    </lib-reactive-form-column-layout>
  </ng-container>
  <hr />
  <div class="form-section-title">Terpenes</div>
  <div *ngIf="!(viewModel.terpenes$ | async)" class="no-terpenes-present">No terpenes present from Variant Lookup</div>
  <lib-reactive-form-column-layout nColumns="2" rowGap="0.875rem">
    <ng-container *ngFor="let terpene of viewModel.terpenes$ | async | keyvalue">
      <div class="terpene-wrapper">
        <div class="terpene-name">{{ terpene?.key }}</div>
        <div class="terpene-value">{{ terpene?.value | doubleDashIfEmpty }}</div>
      </div>
    </ng-container>
  </lib-reactive-form-column-layout>
  <hr />
</lib-reactive-form-group>
