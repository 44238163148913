import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SingleSelectionItem } from '../../../../models/shared/stylesheet/single-selection-item';

@Component({
  selector: 'app-single-selection-list',
  templateUrl: './single-selection-list.component.html',
  styleUrls: ['./single-selection-list.component.scss']
})
export class SingleSelectionListComponent {

  @Input() items: SingleSelectionItem[] = [];
  @Output() itemSelected: EventEmitter<SingleSelectionItem> = new EventEmitter<SingleSelectionItem>();

  public sid = Math.random().toString(24);

  constructor() {
  }

  handleSelection(item: SingleSelectionItem) {
    if (!item.disabled) {
      this.items.forEach(i => i.selected = false);
      item.selected = true;
      this.itemSelected.emit(item);
    }
  }

}
