import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { NavItem } from '../models/nav-item';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { RouteUtils } from '../../../../utils/route-utils';
import { exists } from '../../../../functions/exists';

@Injectable()
export class NavItemViewModel extends BaseViewModel {

  protected loadingOpts = new BehaviorSubject<LoadingOptions>(null);
  protected _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());
  protected navItem = new BehaviorSubject<NavItem>(null);
  public isNavItemActive$ = combineLatest([
    this.navItem,
    this.currentNavUrl$
  ]).pipe(
    map(([nav, url]) => {
      const isActive = url.indexOf(`${nav?.routerPath}`) === 0;
      const fragment = RouteUtils.getFragmentFromUrl(url);
      if (exists(nav) && isActive) {
        nav?.saveSubPosition(null, fragment);
      }
      return isActive;
    }),
    tap(isActive => {
      if (isActive) {
        this.navigateToFirstChild();
      }
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public multiLineNavItem$ = this.navItem.pipe(map(navItem => navItem?.name?.length > 12));

  constructor(
    protected router: Router
  ) {
    super();
  }

  connectToNavItem(item: NavItem) {
    this.navItem.next(item);
  }

  connectToCurrentNavUrl(url: string) {
    this._currentNavUrl.next(url);
  }

  connectToLoadingOpts(loadingOpts: LoadingOptions) {
    this.loadingOpts.next(loadingOpts);
  }

  navigateToFirstChild() {
    const currentNavItem = this.navItem.getValue();
    const currentUrl = this._currentNavUrl.getValue();
    const forwardSlashCount = currentUrl.split('/').length;
    // Tests if we are on a parent route, ie: /menus
    // This is an invalid route so we should reroute us to the first child nav route, ie: /menus/digital
    // first case catches /menus, second case catches /menus/
    const isParentRoute = forwardSlashCount - 1 === 1  || (forwardSlashCount - 1 === 2 && currentUrl.endsWith('/'));
    if (currentNavItem.hasSubItems() && isParentRoute)  {
      const firstChildNavItem = currentNavItem.subItems[0];
      this.router.navigate([`${currentNavItem?.routerPath}/${firstChildNavItem?.relativeRouterPath}`]).then(() => {});
    }
  }

}
