<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="modal-title mb-3">
    {{ viewModel.addingNewSectionOrSubSection ? 'Create' : 'Edit'
    }}{{ !viewModel.handlingSection ? ' Sub' : '' }} Section
  </div>
  <lib-reactive-form-group
    #sectionForm
    [bindTo]="viewModel?.formReq"
    (canSubmit$)="canSubmit$.next($event)"
    (formSubmitted$)="this.save()">
    <div *ngIf="viewModel.handlingSection; else handlingSubSection">
      <lib-reactive-form-text
        [inputName]="'sectionTitle'"
        [label]="'Section Name'"
        [placeholder]="'Enter the section name'"
        [bindingProperty]="'sectionTitle'"
        [required]="true"></lib-reactive-form-text>
    </div>

    <ng-template #handlingSubSection>
      <lib-reactive-form-drop-down
        [inputName]="'sectionTitle'"
        [label]="'Section'"
        [placeholder]="'Select section'"
        [bindingProperty]="'sectionTitle'"
        [dropdowns]="viewModel?.selectableBadgeSections"
        [required]="true"
        [disabled]="true"></lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'subSectionTitle'"
        [label]="'Sub-Section Name'"
        [placeholder]="'Enter the sub-section name'"
        [bindingProperty]="'subSectionTitle'"
        [required]="true"></lib-reactive-form-text>
    </ng-template>
  </lib-reactive-form-group>
  <lib-button-text
    *ngIf="!viewModel.addingNewSectionOrSubSection"
    (buttonClicked)="deleteSection()"
    [textColor]="'#FA5555'"
    class="mt-16px">
    Delete Section
  </lib-button-text>
  <div class="footer-button">
    <lib-button-neutral class="me-4" (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary (buttonClicked)="this.sectionForm.submitForm()" [disabled]="!(canSubmit$ | async)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
