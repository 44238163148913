import { Pipe, PipeTransform } from '@angular/core';
import { SyncJobResults } from '../../../../../../../../models/automation/sync-job-results';
import { SyncType } from '../../../../../../../../utils/sync-type-type';

@Pipe({
  name: 'getDataForSyncTypeResult'
})
export class GetDataForSyncTypeResultPipe implements PipeTransform {

  transform(results: SyncJobResults, type: SyncType): unknown {
    return results?.getResultsFor(type);
  }

}
