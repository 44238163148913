import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SelectableComponent } from '../../../../../../../shared/components/group-selection/selectable/selectable.component';
import { SyncTypeGrouping } from '../../../../../../../../utils/provider-utils';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { InventoryProvider } from '../../../../../../../../utils/inventory-provider-type';
import { SyncType } from '../../../../../../../../utils/sync-type-type';

@Component({
  selector: 'app-selectable-sync-type',
  templateUrl: './selectable-sync-type.component.html',
  styleUrls: ['./selectable-sync-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableSyncTypeComponent extends SelectableComponent implements OnChanges {

  @Input() override selection: SyncTypeGrouping;
  @Input() includeBottomBorder: boolean = true;
  @Input() disabled: boolean = false;
  @Input() provider: InventoryProvider;

  protected override _selection = new BehaviorSubject<SyncTypeGrouping>(null);
  public override selection$ = defer(() => this._selection) as Observable<SyncTypeGrouping>;
  public name$ = this.selection$.pipe(switchMap(selection => selection?.getHumanReadableParentName(this.provider)));

  public syncTypeTooltip$ = this.selection$.pipe(
    map(selection => {
      switch (selection?.parentSyncType) {
        case SyncType.ProductAudit:
          return 'Product Audit Sync may not be performed with other sync types.';
      }
      return null;
    })
  );

  public _provider = new BehaviorSubject<InventoryProvider>(null);
  public provider$ = this._provider as Observable<InventoryProvider>;

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.provider) this._provider.next(this.provider);
  }

}
