import { Component } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordRequest } from '../../../../../models/account/requests/reset-password-request';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    './reset-password.component.scss',
    '../auth.component.scss'
  ]
})
export class ResetPasswordComponent extends BaseComponent {

  // View
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public readonly req$ = combineLatest([
    this.viewModel.preFillEmail$,
    this.viewModel.preFillCode$
  ]).pipe(
    map(([email, code]) => new ResetPasswordRequest(email, code))
  );

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {
    super();
  }

  formSubmitted(req: ResetPasswordRequest) {
    const loadingMess = 'Resetting password.';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.resetPassword(req).subscribe({
        next: (u) => {
          if (u.session.validSession()) {
            this.toastService.publishSuccessMessage('Your password has been reset.', 'Success!');
          } else {
            this.toastService.publishSuccessMessage('Your password has been reset. Please sign in.', 'Success!');
          }
          this._loadingOpts.removeRequest(loadingMess);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(loadingMess);
          this.toastService.publishError(err);
          throwError(() => err);
        }
      });
    }
  }

}
