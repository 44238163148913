import { TypeDefinition } from './type-definition';

// These Variant Types are explicitly defined in the code base, but this is not an exhaustive list. The API returns all
// available Variant Type options.
export enum VariantType {
  // Accessories
  Accessories = 'Accessories',
  Battery = 'Battery',
  VapeAccessory = 'Vape Accessory',
  Grinder = 'Grinder',
  Paper = 'Paper',
  Lighter = 'Lighter',
  Pipe = 'Pipe',
  Bong = 'Bong',
  Cleaning = 'Cleaning',
  Book = 'Book',
  ConcentrateAccessory = 'Concentrate Accessory',
  Kitchenware = 'Kitchenware',
  Lifestyle = 'Lifestyle',
  Storage = 'Storage',
  Tray = 'Tray',
  // Beverage
  Beverage = 'Beverage',
  Distilled = 'Distilled',
  Dissolvable = 'Dissolvable',
  Tea = 'Tea',
  // Concentrate
  Concentrate = 'Concentrate',
  Wax = 'Wax',
  Kief = 'Kief',
  Hash = 'Hash',
  Resin = 'Resin',
  Rosin = 'Rosin',
  Distillate = 'Distillate',
  Shatter = 'Shatter',
  Extract = 'Extract',
  Crumble = 'Crumble',
  Diamond = 'Diamond',
  Caviar = 'Caviar',
  Badder = 'Badder',
  // Edible
  Edible = 'Edible',
  Gummy = 'Gummy',
  Chocolate = 'Chocolate',
  Baking = 'Baking',
  HardCandy = 'Hard Candy',
  Sublingual = 'Sublingual',
  // Flower
  Flower = 'Flower',
  BulkFlower = 'Bulk Flower',
  MilledFlower = 'Milled Flower',
  PreRoll = 'Pre-Roll',
  InfusedPreRoll = 'Infused Pre-Roll',
  Blunt = 'Blunt',
  // Oil
  Oil = 'Oil',
  Capsule = 'Capsule',
  Spray = 'Spray',
  THCOil = 'THC Oil',
  CBDOil = 'CBD Oil',
  BalancedOil = 'Balanced Oil',
  // Other
  Other = 'Other',
  GiftCard = 'Gift Card',
  Apparel = 'Apparel',
  Merch = 'Merch',
  // Seed
  Seed = 'Seed',
  Autoflower = 'Autoflower',
  Photoperiod = 'Photoperiod',
  // Vape
  Vape = 'Vape',
  DisposableVape = 'Disposable Vape',
  VapeKit = 'Vape Kit',
  VapeCartridge = 'Vape Cartridge',
  Cartridge510 = '510 Cartridge', // Enum on backend is 510Cartridge but cant start with a number
  CartridgePod = 'Pod Cartridge',
  // Wellness
  Wellness = 'Wellness',
  CreamLotion = 'Cream & Lotion',
  SkinCare = 'Skin Care',
  Lubricant = 'Lubricant',
  Bath = 'Bath',
  RollOn = 'Roll-On',
}

export class VariantTypeDefinition extends TypeDefinition {

  override value: VariantType;

}
