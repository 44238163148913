import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditBadgeSectionViewModel } from './edit-badge-section-view-model.service';
import { BehaviorSubject } from 'rxjs';
import { SelectableBadgeSection } from '../../../../../models/product/shared/selectable-badge-section';

@Component({
  selector: 'app-edit-badge-section',
  templateUrl: './edit-badge-section.component.html',
  styleUrls: ['./edit-badge-section.component.scss'],
  providers: [EditBadgeSectionViewModel],
})
export class EditBadgeSectionComponent {

  public canSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public viewModel: EditBadgeSectionViewModel,
    private activeModal: NgbActiveModal
  ) {
  }

  addSectionOrSubSection() {
    this.viewModel.addingNewSectionOrSubSection = true;
  }

  handleSection() {
    this.viewModel.handlingSection = true;
  }

  setSectionInformation(sectionAndSubSectionTitles: string[]) {
    this.viewModel.oldSectionTitle = sectionAndSubSectionTitles[0].toLowerCase();
    this.viewModel.oldSubSectionTitle = sectionAndSubSectionTitles[1].toLowerCase();
    this.viewModel.formReq.sectionTitle = sectionAndSubSectionTitles[0];
    this.viewModel.formReq.subSectionTitle = sectionAndSubSectionTitles[1];
  }

  setSelectableBadgeSections(selectableBadgeSections: SelectableBadgeSection[]) {
    this.viewModel.selectableBadgeSections = selectableBadgeSections;
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    if (this.viewModel.addingNewSectionOrSubSection) {
      if (this.viewModel.handlingSection) {
        this.activeModal.close(this.viewModel.formReq.sectionTitle);
      } else {
        this.activeModal.close(this.viewModel.formReq.subSectionTitle);
      }
    } else {
      this.viewModel.save();
    }
    this.activeModal.close();
  }

  deleteSection() {
    this.viewModel.deleteSection();
    this.activeModal.close();
  }

}
