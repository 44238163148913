import { Component, Input } from '@angular/core';
import { Theme } from '../../../../models/menu/dto/theme';
import { BaseComponent } from '../../../../models/base/base-component';
import { Orientation } from '../../../../utils/orientation-type';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent extends BaseComponent {

  @Input() theme: Theme;
  @Input() orientation: Orientation;
  @Input() isPrint: boolean;
  @Input() isPrintCard: boolean;
  @Input() isPrintLabel: boolean;

  constructor() {
    super();
  }

}
