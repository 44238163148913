import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectableComponent } from '../../../../../shared/components/group-selection/selectable/selectable.component';
import { SectionBlueprintCategory } from '../../../../../../models/menu/dto/section-blueprint-category';

@Component({
  selector: 'app-category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss']
})
export class CategorySelectionComponent extends SelectableComponent implements OnChanges {

  constructor() {
    super();
  }

  @Input() includeBottomBorder: boolean = true;
  @Input() maxNumOfSelectedBrands: number;

  protected override _selection = new BehaviorSubject<SectionBlueprintCategory>(null);
  public override selection$ = this._selection as Observable<SectionBlueprintCategory>;
  public override id$ = this.selection$.pipe(map(selection => selection?.getId()));
  public name$ = this.selection$.pipe(map(selection => selection?.name));
  protected override _selectedIds = new BehaviorSubject<string[]>([]);
  public override selectedIds$ = this._selectedIds as Observable<string[]>;
  public override selected$ = combineLatest([this.id$, this.selectedIds$]).pipe(
    map(([id, ids]) => ids?.contains(id.toString()))
  );
  protected override _indeterminate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public override indeterminate$ = this._indeterminate as Observable<boolean>;

  override setupBindings(): void {
    super.setupBindings();
    if (this.selection instanceof SectionBlueprintCategory) {
      this._selection.next(this.selection);
    }
    this._selectedIds.next(this.selectedIds);
  }

  override setupViews(): void {
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection && this.selection instanceof SectionBlueprintCategory) {
      this._selection.next(this.selection);
    }
    if (changes.selectedIds) { this._selectedIds.next(this.selectedIds); }
  }

}
