import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { LookAheadItem } from './look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { SearchUtils } from '../../../../utils/search-utils';
import { PopperUtils } from '../../../../utils/popper-utils';

@Injectable()
export class SearchWithLookAheadViewModel extends BaseViewModel {

  private _searchText = new BehaviorSubject<string>(null);
  public readonly searchText$ = this._searchText as Observable<string>;
  private _searchProperties = new BehaviorSubject<string[]>([]);
  private _nLookAheadItems = new BehaviorSubject<number>(5);
  private _searchItems = new BehaviorSubject<LookAheadItem[]>([]);
  private _outputAllItemsWhenSearchIsEmpty = new BehaviorSubject<boolean>(false);
  public searchTextAndFuzzyHits$: Observable<[string, LookAheadItem[]]> = combineLatest([
    this._searchItems,
    this._searchText,
    this._searchProperties,
    this._nLookAheadItems,
    this._outputAllItemsWhenSearchIsEmpty
  ]).pipe(
    debounceTime(50),
    map(([items, text, properties, returnNItems, outputAllWhenSearchEmpty]) => {
      return SearchUtils.lookAheadSearchElseEmptyList(items, text, properties, returnNItems, outputAllWhenSearchEmpty);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly fuzzyHits$ = this.searchTextAndFuzzyHits$.pipe(map(([, fuzzyHits]) => fuzzyHits));

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['bottom', 'top'])];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };

  public connectToSearchText(search: string) {
    this._searchText.next(search);
  }

  public connectToSearchProperties(properties: string[]) {
    this._searchProperties.next(properties);
  }

  public connectToNLookAheadItems(n: number) {
    this._nLookAheadItems.next(n);
  }

  public connectToSearchItems(items: LookAheadItem[]) {
    this._searchItems.next(items);
  }

  public connectToOutputAllItemsWhenSearchIsEmpty(outputAllItemsWhenSearchIsEmpty: boolean) {
    this._outputAllItemsWhenSearchIsEmpty.next(outputAllItemsWhenSearchIsEmpty);
  }

}
