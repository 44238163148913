import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFromMap'
})
export class GetFromMapPipe implements PipeTransform {

  transform<T, K>(value: Map<T, K>, key: T): K | null {
    return value?.get(key) ?? null;
  }

}
