import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { HiddenFormInputComponent } from '../hidden-form-input/hidden-form-input.component';
import { exists } from '../../../../functions/exists';
import { ValidatorFunctions } from '../validator-functions';

@Component({
  selector: 'app-hidden-array-form-input',
  templateUrl: '../hidden-form-input/hidden-form-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => HiddenArrayFormInputComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HiddenArrayFormInputComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HiddenArrayFormInputComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() inputArr: unknown[];

  override getValidators(): ValidatorFn[] {
    const required = this.required ? [Validators.required, ValidatorFunctions.nonEmptyArrayValidator] : [];
    return [...required, ...this.customValidators];
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.inputArr && exists(this.inputArr)) {
      this.inputArrChanged(this.inputArr);
    }
  }

  private inputArrChanged(inputArr: unknown[]): void {
    this.handleInputChange(inputArr);
    this.getSelfAsFormItem()?.patchValue(inputArr);
    this.markAsDirty();
  }

}
