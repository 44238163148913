import { TypeDefinition } from './type-definition';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum MenuType {
  DisplayMenu = 'DISPLAY_MENU',
  WebMenu = 'WEB_MENU',
  PrintMenu = 'PRINT_MENU',
  MarketingMenu = 'MARKETING_MENU',
  PrintCardMenu = 'PRINT_CARD_MENU',
  PrintLabelMenu = 'PRINT_LABEL_MENU',
  PrintReportMenu = 'PRINT_REPORT_MENU',
  PrintShelfTalkerMenu = 'PRINT_SHELF_TALKER_MENU'
}

export class MenuTypeDefinition extends TypeDefinition {

  override value: MenuType;

  static getThemeMenuCategoryTypes(): Observable<MenuTypeDefinition[]> {
    return window.types.menuTypes$.pipe(
      map(menuTypes => menuTypes?.filter(menuType => {
        return menuType.value === MenuType.DisplayMenu
          || menuType.value === MenuType.PrintMenu
          || menuType.value === MenuType.MarketingMenu
          || menuType.value === MenuType.PrintCardMenu
          || menuType.value === MenuType.PrintLabelMenu
          || menuType.value === MenuType.PrintReportMenu
          || menuType.value === MenuType.PrintShelfTalkerMenu;
      }))
    );
  }

  static containsStackedContent(menuType: MenuType): boolean {
    return menuType === MenuType.PrintCardMenu
      || menuType === MenuType.PrintShelfTalkerMenu
      || menuType === MenuType.PrintLabelMenu;
  }

}
