<div class="card bs-card smart-filter-category-preview-card" [ngClass]="cardClass" (click)="cardPressed.emit(card)">
  <div class="content-container">
    <div class="image-container">
      <app-asset [asset]="card.asset" [borderRadius]="'0.625rem'"> </app-asset>
    </div>
    <div class="text-container ms-3">
      <div class="category-name">{{ card.label }}</div>
    </div>
  </div>
</div>
