import { Deserializable } from '../../protocols/deserializable';

export class ChangePasswordRequest implements Deserializable {

  public accessToken: string = '';
  public oldPassword: string = '';
  public newPassword: string = '';

  constructor(accessToken?: string) {
    this.accessToken = accessToken;
  }

  onDeserialize() {
  }

}
