import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModelComponent } from '../base-smart-filter-form-view-model';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdvancedAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModelComponent {

  constructor(
    private productDomainModel: ProductDomainModel
  ) {
    super();
  }

  private allBadges$ = this.productDomainModel.badges$;

  public hydratedBadgesOnSmartFilter$ = combineLatest([
    this.smartFilter$,
    this.allBadges$
  ]).pipe(
    map(([sf, allBadges]) => {
      return allBadges?.filter(b => sf?.badgeIds.indexOf(b?.id) !== -1 );
    })
  );

}
