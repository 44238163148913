import { Pipe, PipeTransform } from '@angular/core';
import { NavSubItem } from '../../models/nav-sub-item';
import { DefaultNavSubItem } from '../models/default-nav-sub-item';

@Pipe({
  name: 'isDefaultNavSubItem'
})
export class IsDefaultNavSubItemPipe implements PipeTransform {

  transform(value: NavSubItem): boolean {
    return value instanceof DefaultNavSubItem;
  }

}
