import { Deserializable } from '../../protocols/deserializable';

export class VariantBadge implements Deserializable {

  public companyId: number;
  public id: string;
  public name: string;
  public category: string;
  public subCategory: string;
  public smartFilterIds: string[];
  public removeExistingOnSync: boolean;

  constructor(
    companyId?: number,
    badgeId?: string,
    badgeName?: string,
    smartFilterIds?: string[],
    removeExistingOnSync?: boolean
  ) {
    this.companyId = companyId;
    this.id = badgeId;
    this.name = badgeName;
    this.smartFilterIds = smartFilterIds;
    this.removeExistingOnSync = removeExistingOnSync;
  }

  public onDeserialize() {
  }

}
