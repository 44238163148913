import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SmartFiltersSectionViewModel } from './smart-filters-section-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartDataType } from '../../../../models/enum/shared/smart-data-type.enum';
import { ModalService } from '../../../../services/modal.service';
import { AddEditSmartFilterModalOpenedFrom } from 'src/app/models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-smart-filters-section',
  templateUrl: './smart-filters-section.component.html',
  styleUrls: ['./smart-filters-section.component.scss'],
  providers: [SmartFiltersSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFiltersSectionComponent extends BaseComponent implements OnChanges {

  @Input() smartDataType: SmartDataType = SmartDataType.Badges;
  @Input() smartFilterIds: string[] = [];
  @Output() smartFiltersAdded = new EventEmitter<string[]>();
  @Output() smartFilterRemoved = new EventEmitter<string>();

  constructor(
    public viewModel: SmartFiltersSectionViewModel,
    protected modalService: ModalService,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.smartFilterIds) {
      this.viewModel.setSmartFilterIds(this.smartFilterIds);
    }
  }

  openSmartFilterModal(): void {
    this.viewModel.smartFilterIds$.pipe(take(1)).subscribe(previouslySelectedIds => {
      this.modalService.openSelectSmartFilterModal(
        previouslySelectedIds,
        this.emitSmartFiltersAdded.bind(this),
        this.createSmartFilter.bind(this)
      );
    });
  }

  private emitSmartFiltersAdded(ids: string[]): void {
    if (exists(ids)) this.smartFiltersAdded.emit(ids);
  }

  public createSmartFilter(): void {
    let openingFrom = AddEditSmartFilterModalOpenedFrom.SmartBadge;
    if (this.smartDataType === SmartDataType.Labels) {
      openingFrom = AddEditSmartFilterModalOpenedFrom.SmartLabel;
    }
    this.modalService.openCreateSmartFilter(
      this.ngbModal,
      this.injector,
      openingFrom,
      (smartFilter) => this.emitSmartFiltersAdded([smartFilter.id])
    );
  }

}
