<div *ngIf="(viewModel?.labelText$ | async) !== ''">
  <div
    [style.background-color]="viewModel?.labelBackgroundColor$ | async"
    [style.color]="viewModel?.labelTextColor$ | async"
    [style.zoom]="zoom"
    class="product-label">
    <div class="product-label-text">{{ viewModel?.labelText$ | async }}</div>
    <app-label-remove-button
      *ngIf="viewModel.removable$ | async"
      [style.margin-left.rem]="0.25"
      [width]="'1rem'"
      [height]="'1rem'"
      [xScale]="0.6"
      appLabelThrottleClick
      [throttleInMilliSec]="1000"
      (throttleClick)="clearClicked()">
    </app-label-remove-button>
  </div>
</div>
