<div class="modal-body">
  <div class="modal-title mb-3">Edit Location Use Purpose</div>
  <lib-reactive-form-group
    #locationForm
    [bindTo]="location"
    (canSubmit$)="canSubmit$.next($event)"
    (formSubmitted$)="this.save()">
    <lib-reactive-form-drop-down
      [inputName]="'usePurpose'"
      [label]="'Use Purpose'"
      [required]="true"
      [bindingProperty]="'usePurpose'"
      [placeholder]="'Choose a use purpose'"
      [dropdowns]="usePurposeDropdowns$ | async"></lib-reactive-form-drop-down>
  </lib-reactive-form-group>

  <div class="footer-button">
    <lib-button-neutral class="me-4" (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary (buttonClicked)="this.locationForm.submitForm()" [disabled]="!(canSubmit$ | async)">
      Save Changes
    </lib-button-primary>
  </div>
</div>
