import { Observable } from 'rxjs';
import { TypeDefinition } from './type-definition';

export class DefaultDigitalSizeType extends TypeDefinition {

  private static readonly Digital720p = 'DefaultSize_720p';
  private static readonly Digital1080p = 'DefaultSize_1080p';
  private static readonly Digital4k = 'DefaultSize_4k';
  private static readonly Digital8k = 'DefaultSize_8k';

  constructor(value: string = null, name: string = null) {
    // Constructor should only be used when referencing 'dummy' instance
    super(value, name);
    this.value = value;
    this.name = name;
  }

  static getDefaultDigitalSizeOptions(): Observable<DefaultDigitalSizeType[]> {
    return window?.types?.digitalSizeTypes$;
  }

}
