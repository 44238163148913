<div
  [ngbTooltip]="viewModel.badgeName$ | async"
  [placement]="'top'"
  [container]="'body'"
  [style.cursor]="(viewModel.alreadyAdded$ | async) ? 'not-allowed' : 'pointer'">
  <app-asset
    matRipple
    appThrottleClick
    class="badge-img"
    [class.disabled]="viewModel.alreadyAdded$ | async"
    (throttleClick)="selected.emit(badge)"
    [asset]="badge?.image"
    [scaleFit]="true"
    [cachePolicy]="ClientTypes.CachePolicy.Service"
    [borderRadius]="'0.625rem'">
  </app-asset>
</div>
