import { Subject } from 'rxjs';
import { LoadingSpinnerSize } from '../enum/shared/loading-spinner-size.enum';
import { Deserializable } from '../protocols/deserializable';

export class LoadingOptions implements Deserializable {

  public isLoading: boolean = false;
  public fullscreen: boolean = false;
  public determinateLoading: boolean = false;
  public showLoadingText: boolean = false;
  public fontSize: string = '';
  public progress = 0;
  public loadingText: string;
  public backgroundColor: string;
  public spinnerColor: string;
  public color: string;
  public topMarginRem: number = 0;
  public cornerRadiusRem: number = 0;
  public spinnerSize: LoadingSpinnerSize = LoadingSpinnerSize.Default;
  public zIndex = 99;

  private awaitingRequests: string[] = [];

  static default(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgba(222, 222, 222, 0.85)';
    opt.spinnerColor = '#2C4058';
    opt.color = '#2C4058';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    return opt;
  }

  static defaultSmall(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = LoadingOptions.default(determinate, fullscreen);
    opt.spinnerSize = LoadingSpinnerSize.Small;
    opt.fontSize = '0.75rem';
    opt.loadingText = 'Deleting User';
    return opt;
  }

  static defaultInButton(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#FFF';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    return opt;
  }

  static defaultInTable(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#2c4058';
    opt.color = '#2c4058';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    return opt;
  }

  static defaultWhiteBackground(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const loadingOpts = LoadingOptions.default(determinate, fullscreen);
    loadingOpts.backgroundColor = 'white';
    return loadingOpts;
  }

  static defaultWhiteOpaque(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgb(255,255,255)';
    opt.spinnerColor = '#2C4058';
    opt.color = '#2C4058';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    return opt;
  }

  static getAssetLoadingOpts(): LoadingOptions {
    const opt = LoadingOptions.default();
    opt.backgroundColor = '#FFF';
    opt.zIndex = 50;
    return opt;
  }

  onDeserialize() {
  }

  containsRequest(mess: string): boolean {
    const currReqs = this.awaitingRequests?.shallowCopy() || [];
    const existingIndex = currReqs.indexOf(mess);
    return existingIndex > -1;
  }

  addRequest(mess: string, subject?: Subject<LoadingOptions>) {
    const currReqs = this.awaitingRequests?.shallowCopy() || [];
    const existingIndex = currReqs.indexOf(mess);
    if (existingIndex === -1) {
      currReqs.push(mess);
      this.setAwaitingRequests(currReqs);
      if (subject) {
        subject.next(window?.injector?.Deserialize?.instanceOf(LoadingOptions, this));
      }
    }
  }

  removeRequest(mess: string, subject?: Subject<LoadingOptions>) {
    const currReqs = this.awaitingRequests?.shallowCopy() || [];
    const removeIndex = currReqs.indexOf(mess);
    if (removeIndex > -1) {
      currReqs.splice(removeIndex, 1);
      this.setAwaitingRequests(currReqs);
      if (subject) {
        subject.next(window?.injector?.Deserialize?.instanceOf(LoadingOptions, this));
      }
    }

  }

  private setAwaitingRequests(reqs: string[]): void {
    this.awaitingRequests = reqs;
    this.isLoading = this.awaitingRequests?.length > 0;
    this.loadingText = this.awaitingRequests?.length ? this.awaitingRequests[this.awaitingRequests.length - 1] : '';
  }

  manuallyUpdateRequest(reqs: string[]) {
    this.setAwaitingRequests(reqs);
  }

}
