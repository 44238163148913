import { Deserializable } from '../../protocols/deserializable';

export class ForgotPasswordRequest implements Deserializable {

  constructor(public email: string = '') {}

  onDeserialize() {
  }

}
