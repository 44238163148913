<mat-accordion multi [displayMode]="'flat'">
  <hr [class.mt-0]="first" />
  <mat-expansion-panel #panel class="mat-elevation-z0 border-1">
    <mat-expansion-panel-header class="no-background ps-0 pe-1">
      <mat-panel-title class="bs-medium f14px">
        {{ viewModel.displayName$ | async }}
        <img
          *ngIf="viewModel.showExclamationCircle$ | push"
          src="assets/icons/red/solid/exclamation-circle.svg"
          class="ml-8px"
          [style.height.rem]="1"
          [style.width.rem]="1" />
        <div *ngIf="viewModel.isIgnored$ | push" class="bs-medium f12px is-ignored-label">Ignored</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-provider-specification-preview
      [companyConfiguration]="companyConfiguration"
      [inventoryProvider]="inventoryProvider"
      [specification]="viewModel.providerSpecification$ | push"
      [mergeKey]="mergeKey"
      (isIgnored)="viewModel.connectToIsIgnored($event)">
    </app-provider-specification-preview>
  </mat-expansion-panel>
</mat-accordion>
