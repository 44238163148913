import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { MaterialModule } from './material/material.module';
import { TestTabComponent } from './components/tab-bar/test-tab/test-tab.component';
import { TabContentComponent } from './components/tab-bar/tab-content/tab-content.component';
import { TabContentContainerDirective } from './components/tab-bar/tab-content/tab-content-container.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PillComponent } from './components/pills/pill/pill.component';
import { PillContainerComponent } from './components/pills/pill-container/pill-container.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxContainerComponent } from './components/checkboxes/checkbox-container/checkbox-container.component';
import { CheckboxComponent } from './components/checkboxes/checkbox/checkbox.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AbandonFormComponent } from './modals/abandon-form/abandon-form.component';
import { LocationPickerComponent } from './modals/location-picker/location-picker.component';
import { CardDeckComponent } from './components/card-deck/card-deck.component';
import { CardComponent } from './components/card-deck/card/card.component';
import { UploadItemComponent } from './components/upload-asset/upload-item/upload-item.component';
import { UploadAssetComponent } from './components/upload-asset/upload-asset.component';
import { DndDirective } from './directives/dnd-directive';
import { HeaderComponent } from './components/header/header.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IncompleteProductCardComponent } from './components/card-deck/incomplete-product-card/incomplete-product-card.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CompanyBadgeComponent } from './components/company-badge/company-badge.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { QuickActionCardComponent } from './components/card-deck/quick-action-card/quick-action-card.component';
import { ReorderModalComponent } from './components/reorder-modal/reorder-modal.component';
import { ReorderListComponent } from './components/reorder-list/reorder-list.component';
import { ActionListComponent } from './components/action-list/action-list.component';
import { ActionComponent } from './components/action-list/action/action.component';
import { ActiveComponent } from './components/active/active.component';
import { SingleSelectionListComponent } from './components/single-selection-list/single-selection-list.component';
import { AssetPreviewComponent } from './components/asset-preview/asset-preview.component';
import { SegmentedControlComponent } from './components/segmented-control/segmented-control.component';
import { ThemePreviewCardComponent } from './components/card-deck/theme-preview-card/theme-preview-card.component';
import { ThemeCarouselImageComponent } from './components/card-deck/theme-carousel-image/theme-carousel-image.component';
import { InformationModalComponent } from './modals/information-modal/information-modal.component';
import { AssetComponent } from './components/asset/asset.component';
import { TitleComponent } from './components/title/title.component';
import { CenterColumnComponent } from './components/center-column/center-column.component';
import { SubTitleComponent } from './components/sub-title/sub-title.component';
import { SectionDividerComponent } from './components/section-divider/section-divider.component';
import { RemoveButtonComponent } from './components/remove-button/remove-button.component';
import { EditBadgeComponent } from './modals/edit-badge/edit-badge.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DisplayOrientationPipe } from './pipes/display-orientation.pipe';
import { MenuTypePipe } from './pipes/menu-type.pipe';
import { CompanyListFilterPipe } from './pipes/company-list-filter.pipe';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { SearchWithLookAheadComponent } from './components/search-with-look-ahead/search-with-look-ahead.component';
import { LookAheadListComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-list.component';
import { LookAheadItemComponent } from './components/search-with-look-ahead/look-ahead-list/look-ahead-item/look-ahead-item.component';
import { CheckboxOnlyComponent } from './components/checkboxes/checkbox-only/checkbox-only.component';
import { InfoBannerComponent } from './components/banners/info-banner/info-banner.component';
import { WarningBannerComponent } from './components/banners/warning-banner/warning-banner.component';
import { MessageBannerComponent } from './components/banners/message-banner/message-banner.component';
import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { SmartMenuIndicatorComponent } from './components/smart-menu-indicator/smart-menu-indicator.component';
import { ThemeComponent } from './components/theme/theme.component';
import { PrivateThemeIndicatorComponent } from './components/private-theme-indicator/private-theme-indicator.component';
import { VariantFormattedSizingPipe } from './pipes/variant-formatted-sizing.pipe';
import { UnconfirmedProductCardComponent } from './components/card-deck/unconfirmed-product-card/unconfirmed-product-card.component';
import { CreateDisplayAttributeGroupModalComponent } from './modals/create-display-attribute-group-modal/create-display-attribute-group-modal.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { AddVariantToDisplayGroupModalComponent } from './modals/add-variant-to-display-group-modal/add-variant-to-display-group-modal.component';
import { GroupSelectionComponent } from './components/group-selection/group-selection.component';
import { SelectableComponent } from './components/group-selection/selectable/selectable.component';
import { AssetPreviewCardComponent } from './components/card-deck/asset-preview-card/asset-preview-card.component';
import { AssetPreviewModalComponent } from './modals/asset-preview-modal/asset-preview-modal.component';
import { EditAssetPreviewCardComponent } from './components/card-deck/edit-asset-preview-card/edit-asset-preview-card.component';
import { CreateAssetGroupModalComponent } from './modals/create-asset-group-modal/create-asset-group-modal.component';
import { AssignVariantToAssetGroupModalComponent } from './modals/assign-variant-to-asset-group-modal/assign-variant-to-asset-group-modal.component';
import { AssignToAssetGroupButtonComponent } from './modals/assign-variant-to-asset-group-modal/assign-to-asset-group-button/assign-to-asset-group-button.component';
import { AddAssetModalComponent } from './modals/add-asset-modal/add-asset-modal.component';
import { UpdateAssetLibraryTypesModalComponent } from './modals/update-asset-library-types-modal/update-asset-library-types-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CreateBrandModalComponent } from './modals/create-brand-modal/create-brand-modal.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { FilterByBrandModalComponent } from './modals/filter-by-brand-modal/filter-by-brand-modal.component';
import { BrandSelectionComponent } from './modals/filter-by-brand-modal/brand-selection/brand-selection.component';
import { SmartFilterCategoryPreviewCardComponent } from './components/card-deck/smart-filter-category-preview-card/smart-filter-category-preview-card.component';
import { BadgePickerForFormComponent } from './components/badge-picker-for-form/badge-picker-for-form.component';
import { InlinePickerActionButtonComponent } from './components/inline-picker-action-button/inline-picker-action-button.component';
import { BadgePickerComponent } from './components/badge-picker/badge-picker.component';
import { BadgeWithinPickerComponent } from './components/badge-picker/badge-within-picker/badge-within-picker.component';
import { VariantBadgeComponent } from './components/variant-badge/variant-badge.component';
import { SmartSyncLocationSelectModalComponent } from './modals/smart-sync-location-select-modal/smart-sync-location-select-modal.component';
import { SmartSyncLocationSelectionComponent } from './modals/smart-sync-location-select-modal/smart-sync-location-selection/smart-sync-location-selection.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { NoteTextComponent } from './components/note-text/note-text.component';
import { ErrorBannerComponent } from './components/banners/error-banner/error-banner.component';
import { StickyAutoPositionDirective } from './directives/sticky-auto-position.directive';
import { LoadingShimmerModule } from '@mobilefirstdev/loading-shimmer';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { PrivateCompanyThemeModalComponent } from './modals/private-company-theme-modal/private-company-theme-modal.component';
import { ListIncludesPipe } from './pipes/list-includes.pipe';
import { UnixToDateTimePipe } from './pipes/unix-to-date-time.pipe';
import { SectionBlueprintCategoryPreviewCardComponent } from './components/card-deck/section-blueprint-category-preview-card/section-blueprint-category-preview-card.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { BooleanToYesOrNoPipe } from './pipes/boolean-to-yes-or-no.pipe';
import { UnassignedAssetGroupCardComponent } from './components/card-deck/unassigned-asset-group-card/unassigned-asset-group-card.component';
import { ChangeMediaModalComponent } from './modals/change-media-modal/change-media-modal.component';
import { PageHeaderAndCrumbsComponent } from './components/page-header-and-crumbs/page-header-and-crumbs.component';
import { RxPush } from '@rx-angular/template/push';
import { IsDefaultSharedDisplayAttributeGroupPipe } from './pipes/is-default-shared-display-attribute-group.pipe';
import { GetVariantsForDisplayAttributeGroupDetailsPipe } from './pipes/get-variants-for-display-attribute-group-details.pipe';
import { GetVariantsForDisplayAttributeGroupDetailsFromBarcodesPipe } from './pipes/get-variants-for-display-attribute-group-details-from-barcodes.pipe';
import { GetSharedAttributeGroupDetailsPipe } from './pipes/get-shared-attribute-group-details.pipe';
import { NumberToStringPipe } from './pipes/number-to-string.pipe';
import { HiddenArrayFormInputComponent } from './forms/hidden-array-form-input/hidden-array-form-input.component';
import { ListItemCheckboxComponent } from './forms/list-item-checkbox/list-item-checkbox.component';
import { ListItemCheckboxesComponent } from './forms/list-item-checkboxes/list-item-checkboxes.component';
import { GetSelectableTitleFromValuePipe } from './pipes/get-selection-name-from-value.pipe';
import { GetSelectableTitlesFromValuesPipe } from './pipes/get-selectable-titles-from-values.pipe';
import { SnoozeAssetGroupModalComponent } from './modals/snooze-asset-group-modal/snooze-asset-group-modal.component';
import { SnoozedIconComponent } from './components/snoozed-icon/snoozed-icon.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CallMethodPipe } from './pipes/call-method.pipe';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { GetFromMapPipe } from './pipes/get-from-map.pipe';
import { FirstPortraitPreviewImagePipe } from './components/theme/first-portrait-preview-image.pipe';
import { FirstLandscapePreviewImagePipe } from './components/theme/first-landscape-preview-image.pipe';
import { IsPrivateThemePipe } from './components/theme/is-private-theme.pipe';
import { RandomStringPipe } from './pipes/random-string.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

/*
 * ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })
 * see https://github.com/angular/angular/issues/48350
 */
@NgModule({
  providers: [],
  declarations: [
    TabBarComponent,
    TestTabComponent,
    TabContentComponent,
    TabContentContainerDirective,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    LoadingComponent,
    ProgressBarComponent,
    CheckboxContainerComponent,
    CheckboxComponent,
    DropDownMenuComponent,
    AbandonFormComponent,
    LocationPickerComponent,
    CardDeckComponent,
    CardComponent,
    UploadItemComponent,
    UploadAssetComponent,
    DndDirective,
    HeaderComponent,
    IncompleteProductCardComponent,
    UnconfirmedProductCardComponent,
    UnassignedAssetGroupCardComponent,
    EmployeeComponent,
    BreadcrumbsComponent,
    CompanyBadgeComponent,
    ConfirmationModalComponent,
    QuickActionCardComponent,
    ReorderModalComponent,
    ReorderListComponent,
    ActionListComponent,
    ActionComponent,
    ReorderListComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    SectionBlueprintCategoryPreviewCardComponent,
    SmartFilterCategoryPreviewCardComponent,
    ThemePreviewCardComponent,
    ThemeCarouselImageComponent,
    AssetPreviewCardComponent,
    EditAssetPreviewCardComponent,
    InformationModalComponent,
    AssetComponent,
    AssetPreviewModalComponent,
    CreateAssetGroupModalComponent,
    AssignVariantToAssetGroupModalComponent,
    AssignToAssetGroupButtonComponent,
    AddAssetModalComponent,
    UpdateAssetLibraryTypesModalComponent,
    TitleComponent,
    CenterColumnComponent,
    SubTitleComponent,
    SectionDividerComponent,
    RemoveButtonComponent,
    EditBadgeComponent,
    DebounceClickDirective,
    DisplayOrientationPipe,
    MenuTypePipe,
    CompanyListFilterPipe,
    ToggleSwitchComponent,
    LookAheadListComponent,
    SearchWithLookAheadComponent,
    LookAheadItemComponent,
    CheckboxOnlyComponent,
    ErrorBannerComponent,
    InfoBannerComponent,
    WarningBannerComponent,
    MessageBannerComponent,
    ThrottleClickDirective,
    StickyAutoPositionDirective,
    SmartMenuIndicatorComponent,
    ThemeComponent,
    PrivateThemeIndicatorComponent,
    VariantFormattedSizingPipe,
    CreateDisplayAttributeGroupModalComponent,
    AddVariantToDisplayGroupModalComponent,
    CreateBrandModalComponent,
    GroupSelectionComponent,
    SelectableComponent,
    FilterButtonComponent,
    FilterByBrandModalComponent,
    BrandSelectionComponent,
    BadgePickerForFormComponent,
    InlinePickerActionButtonComponent,
    BadgePickerComponent,
    BadgeWithinPickerComponent,
    VariantBadgeComponent,
    SmartSyncLocationSelectModalComponent,
    SmartSyncLocationSelectionComponent,
    NoteTextComponent,
    PrivateCompanyThemeModalComponent,
    ChangeMediaModalComponent,
    ListIncludesPipe,
    UnixToDateTimePipe,
    ListItemComponent,
    BooleanToYesOrNoPipe,
    PageHeaderAndCrumbsComponent,
    IsDefaultSharedDisplayAttributeGroupPipe,
    GetVariantsForDisplayAttributeGroupDetailsPipe,
    GetVariantsForDisplayAttributeGroupDetailsFromBarcodesPipe,
    GetSharedAttributeGroupDetailsPipe,
    NumberToStringPipe,
    HiddenArrayFormInputComponent,
    ListItemCheckboxComponent,
    ListItemCheckboxesComponent,
    GetSelectableTitleFromValuePipe,
    GetSelectableTitlesFromValuesPipe,
    SnoozeAssetGroupModalComponent,
    SnoozedIconComponent,
    CallMethodPipe,
    GetFromMapPipe,
    FirstPortraitPreviewImagePipe,
    FirstLandscapePreviewImagePipe,
    IsPrivateThemePipe,
    RandomStringPipe,
    SanitizeHtmlPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    BsDropdownModule,
    NgbTooltipModule,
    FlexLayoutModule,
    NgxPopperjsModule,
    ReactiveFormModule,
    ImageCropperComponent,
    TooltipModule,
    ReactiveButtonsModule,
    LoadingShimmerModule,
    RxPush,
  ],
  exports: [
    // BudSense Shared Components
    TabBarComponent,
    MaterialModule,
    TestTabComponent,
    LoadingComponent,
    NgxSpinnerModule,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    ColorPickerModule,
    ProgressBarComponent,
    FormsModule,
    ReactiveFormsModule,
    CheckboxComponent,
    CheckboxContainerComponent,
    BsDropdownModule,
    DropDownMenuComponent,
    AbandonFormComponent,
    CardDeckComponent,
    CardComponent,
    UploadItemComponent,
    UploadAssetComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    EmployeeComponent,
    CompanyBadgeComponent,
    ConfirmationModalComponent,
    ReorderModalComponent,
    ActionListComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    AssetComponent,
    TitleComponent,
    CenterColumnComponent,
    SubTitleComponent,
    SectionDividerComponent,
    RemoveButtonComponent,
    EditBadgeComponent,
    DisplayOrientationPipe,
    MenuTypePipe,
    CompanyListFilterPipe,
    ToggleSwitchComponent,
    LookAheadListComponent,
    SearchWithLookAheadComponent,
    LookAheadItemComponent,
    CheckboxOnlyComponent,
    ErrorBannerComponent,
    InfoBannerComponent,
    WarningBannerComponent,
    MessageBannerComponent,
    ThrottleClickDirective,
    StickyAutoPositionDirective,
    ThemeComponent,
    VariantFormattedSizingPipe,
    GroupSelectionComponent,
    SelectableComponent,
    InformationModalComponent,
    FilterButtonComponent,
    BadgePickerForFormComponent,
    InlinePickerActionButtonComponent,
    NoteTextComponent,
    UnixToDateTimePipe,
    ListItemComponent,
    BooleanToYesOrNoPipe,
    UnconfirmedProductCardComponent,
    IncompleteProductCardComponent,
    PageHeaderAndCrumbsComponent,
    ListIncludesPipe,
    IsDefaultSharedDisplayAttributeGroupPipe,
    GetVariantsForDisplayAttributeGroupDetailsPipe,
    GetVariantsForDisplayAttributeGroupDetailsFromBarcodesPipe,
    GetSharedAttributeGroupDetailsPipe,
    NumberToStringPipe,
    HiddenArrayFormInputComponent,
    ListItemCheckboxComponent,
    ListItemCheckboxesComponent,
    GetSelectableTitleFromValuePipe,
    GetSelectableTitlesFromValuesPipe,
    SnoozedIconComponent,
    CallMethodPipe,
    GetFromMapPipe,
    RandomStringPipe,
    SanitizeHtmlPipe
  ],
})
export class SharedModule {
}
