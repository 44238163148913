import { Component } from '@angular/core';
import { ClientTypeUtils } from '../../utils/client-type-utils';
import { FoundationBaseComponent } from '@mobilefirstdev/base-angular';

@Component({ template: '' })
export abstract class BaseComponent extends FoundationBaseComponent {

  ClientTypes = ClientTypeUtils;

}
