import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { Observable, of } from 'rxjs';
import { DisplayLabelInterface } from '../label/display-label-interface';
import { LabelUtils } from '../utils/label-utils';

@Pipe({
  name: 'labelInterfaceForLabelPicker'
})
export class LabelInterfaceForLabelPickerPipe implements PipeTransform {

  transform(
    label: Label,
    isClearable: boolean,
  ): Observable<DisplayLabelInterface> {
    return of(LabelUtils.getDisplayLabelInterfaceForLabelPicker(
      label,
      isClearable
    ));
  }

}
