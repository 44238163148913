import { Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { CreateCompanyRequest } from '../models/company/requests/create-company-request';
import { Observable, throwError } from 'rxjs';
import { Company } from '../models/company/dto/company';
import { Endpoints } from './endpoints';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Location } from '../models/company/dto/location';
import { CompanyFeatures } from '../models/company/dto/company-features';
import { ApiPagination } from '../models/shared/api-pagination';
import { PopulateSandboxRequest } from '../models/account/requests/populate-sandbox-request';

export const COMPANIES_ALL_PAGINATION_COUNT = 25;

@Injectable({
  providedIn: 'root'
})
export class CompanyAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables
  serviceName = 'Company';

  // Company
  createCompany(createCompanyReq: CreateCompanyRequest): Observable<Company> {
    const url = Endpoints.CreateCompany();
    return this.apiClient.postObj(Company, url, createCompanyReq).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateCompany', err));
        return throwError(err);
      })
    );
  }

  // Location
  createLocation(location: Location): Observable<Company> {
    const url = Endpoints.CreateLocation();
    return this.apiClient.postObj(Company, url, location).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateLocation', err));
        return throwError(err);
      })
    );
  }

  public updateCompanyLocation(location: Location): Observable<Location> {
    const url = Endpoints.UpdateLocation();
    const additionalHeaders = { companyid: `${location?.companyId}` };
    return this.apiClient.postObj(Location, url, location, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCompanyLocation', err));
        return throwError(err);
      })
    );
  }

  // Admin
  adminGetCompanies(id?: string): Observable<Company[]> {
    const url = Endpoints.AdminGetCompanies(id);
    const pagination = new ApiPagination(COMPANIES_ALL_PAGINATION_COUNT);
    return this.apiClient.recursiveGet<Company>(Company, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminGetCompanies', err));
        return throwError(err);
      })
    );
  }

  updateCompanyFeatures(features: CompanyFeatures): Observable<CompanyFeatures> {
    const url = Endpoints.UpdateCompanyFeatures();
    return this.apiClient.postObj(CompanyFeatures, url, features).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCompanyFeatures', err));
        return throwError(err);
      })
    );
  }

  populateSandboxData(populateSandboxRequest: PopulateSandboxRequest): Observable<string> {
    const url =  Endpoints.PopulateSandboxData();
    return this.apiClient.postStr(url, populateSandboxRequest).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'PopulateSandboxData', err));
        return throwError(err);
      })
    );
  }

}
