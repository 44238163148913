import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { AuthComponent } from './components/auth/auth.component';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    data: {
      title: 'Account'
    },
  },
  {
    path: 'auth/sign-in',
    component: AuthComponent,
    data: {
      title: 'Sign In'
    },
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
    data: {
      title: 'Signing Out'
    },
  },
  {
    path: 'auth/forgot-password',
    component: AuthComponent,
    data: {
      title: 'Forgot Password'
    },
  },
  {
    path: 'auth/reset-password',
    component: AuthComponent,
    data: {
      title: 'Reset Password'
    },
  },
  {
    path: 'auth/new-password',
    component: AuthComponent,
    data: {
      title: 'Set New Password'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
}
