import { SmartFilterAppliedOnPillType } from '../models/enum/shared/smart-filter-applied-on-pill-type';
import type { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import type { Menu } from '../models/menu/dto/menu';
import type { Label } from '../models/shared/label';
import type { PillItem, PillItemInterface } from '../models/shared/stylesheet/pill-item';
import { MenuType } from './menu-type-definition';

export class SmartFilterUtils {

  static getAppliedOnPillIcon(type: SmartFilterAppliedOnPillType | MenuType): string {
    switch (type) {
      case MenuType.DisplayMenu:
        return 'assets/icons/dark/solid/desktop-computer-black.svg';
      case MenuType.PrintMenu:
        return 'assets/icons/dark/solid/printer.svg';
      case MenuType.WebMenu:
        return 'assets/icons/dark/outline/web.svg';
      case MenuType.MarketingMenu:
        return 'assets/icons/dark/solid/bookmark-alt.svg';
      case SmartFilterAppliedOnPillType.SmartBadge:
        return 'assets/icons/dark/solid/smart-badge.svg';
      case SmartFilterAppliedOnPillType.SmartLabel:
        return 'assets/icons/dark/solid/smart-label.svg';
    }
  }

  static generateAppliedOnPills(
    menus: Menu[],
    badges: HydratedVariantBadge[],
    labels: Label[],
    isClickable: boolean
  ): PillItem[] {
    const toBePilled: PillItemInterface[] = [...(menus || []), ...(badges || []), ...(labels || [])];
    return toBePilled?.map(item => item?.getAsPillItem(isClickable, false, false))?.filterNulls();
  }

}
