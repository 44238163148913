<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title-container">
        <div class="modal-title">
          {{ viewModel?.modalTitle$ | async }}
        </div>
        <div class="text-link" (click)="clearFiltersAndSearch()">Clear</div>
      </div>
    </div>
  </div>

  <div class="sticky-body-container brands-body">
    <div class="search-bar-container">
      <app-search-with-look-ahead
        #brandSearch
        [placeHolder]="'Search by name or brand keyword'"
        [nLookAheadItems]="10"
        (searchText)="viewModel?.setSearchText($event)">
      </app-search-with-look-ahead>
    </div>
    <ng-container *ngFor="let brand of $any(viewModel.brandsFilteredBySearchString$ | async); last as last">
      <app-brand-selection
        [selection]="brand"
        [selectedIds]="viewModel?.selectedFilterBrandIds$ | async"
        [maxNumOfSelectedBrands]="viewModel?.maxNumOfSelectedBrands$ | async"
        [includeBottomBorder]="!last"
        [selectingForFilter]="viewModel.selectingForFilter$ | async"
        (add)="viewModel?.addToFilterBrands($event)"
        (remove)="viewModel?.removeFromFilterBrands($event)"></app-brand-selection>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button (click)="viewModel?.finishedFiltering()" class="bs-button preferred-button">Done</button>
  </div>
</div>
