import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { Location } from './location';
import { CompanyConfiguration } from './company-configuration';
import { DateUtils } from '../../../utils/date-utils';
import { Cachable } from '../../protocols/cachable';
import { HydratedAdminUser } from '../../account/dto/hydrated-admin-user';
import { Selectable } from '../../protocols/selectable';
import { CompanyFeatures } from './company-features';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { InventoryProvider } from '../../../utils/inventory-provider-type';
import { Pagable } from '../../protocols/pagable';
import { MenuType } from '../../../utils/menu-type-definition';

export class Company implements Deserializable, Cachable, Selectable, UniquelyIdentifiable, Pagable {

  public id: number;
  public name: string;
  public logo: Asset;
  public altLogo: Asset;
  public locations: Location[];
  public configuration: CompanyConfiguration;
  public features: CompanyFeatures;
  public dateCreated: number;
  public employees: HydratedAdminUser[];
  public provider: InventoryProvider;
  public menuCountMap: Map<MenuType, number>;
  public displayCount: number = 0;

  // Cache
  public cachedTime: number;

  constructor(name?: string, id?: number) {
    this.name = name;
    this.id = id;
  }

  static buildCacheKey(id: string): string {
    return `Company-${id}`;
  }

  public onDeserialize() {
    this.logo = window.injector.Deserialize.instanceOf(Asset, this.logo);
    this.altLogo = window.injector.Deserialize.instanceOf(Asset, this.altLogo);
    this.locations = window.injector.Deserialize.arrayOf(Location, this.locations) || [];
    this.employees = window.injector.Deserialize.arrayOf(HydratedAdminUser, this.employees) || [];
    this.configuration = window.injector.Deserialize.instanceOf(CompanyConfiguration, this.configuration);
    this.features = window.injector.Deserialize.instanceOf(CompanyFeatures, this.features);
    this.provider = InventoryProvider[this.provider];
    this.menuCountMap = window.injector.Deserialize.genericMap(this.menuCountMap);
  }

  canCreateNewLocation(): boolean {
    // Update this list when a provider ( that can create a location ) is added.
    return this.provider === InventoryProvider.Treez ||
      this.provider === InventoryProvider.Dutchie ||
      this.provider?.includes(InventoryProvider.Dutchie) || // Catch DutchieMed/DutchieRec case
      this.provider === InventoryProvider.Blaze ||
      this.provider === InventoryProvider.BudSense;
  }

  cacheKey(): string {
    return Company.buildCacheKey(this.id.toString());
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.name;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.id}
      ${this.name}
      ${this.locations?.map(location => location.getUniqueIdentifier())?.join(',')}
      ${this.configuration?.getUniqueIdentifier()}
      ${this.dateCreated}
      ${this.features?.getUniqueIdentifier()}
      ${this.employees?.map(employee => employee.getUniqueIdentifier())?.join(',')}
      ${this.provider}
    `;
  }

  getStartKey(): string {
    return `${this.id}`;
  }

}
