import { Pipe, PipeTransform } from '@angular/core';
import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';

@Pipe({
  name: 'getSelectableTitlesFromValues'
})
export class GetSelectableTitlesFromValuesPipe implements PipeTransform {

  transform(values: any[], selectables: Selectable[] | null): string[] | null {
    return values?.map(value => {
      const selection = selectables?.find((s) => s?.getSelectionValue() === value);
      const isString = typeof value === 'string';
      return selection?.getSelectionTitle() || (isString ? value : null);
    });

  }

}
