<div *ngIf="(viewModel?.labelText$ | async) !== ''">
  <div
    class="product-label"
    [style.display]="'flex'"
    [style.flex-direction]="'row'"
    [style.white-space]="'nowrap'"
    [style.zoom]="zoom">
    <!-- colors included within style signals -->
    <div
      [style.border-top]="viewModel.flagTriangleHeight$ | async"
      [style.border-bottom]="viewModel.flagTriangleHeight$ | async"
      [style.border-left]="viewModel.flagTriangleWidth$ | async"
      class="triangles-for-flag"></div>

    <div
      #flagBody
      class="flag-body"
      [style.background-color]="viewModel?.labelBackgroundColor$ | async"
      [style.color]="viewModel?.labelTextColor$ | async">
      <div class="product-label-text">{{ viewModel?.labelText$ | async }}</div>
      <app-label-remove-button
        *ngIf="viewModel.removable$ | async"
        [style.margin-left.rem]="0.25"
        [width]="'1rem'"
        [height]="'1rem'"
        [xScale]="0.6"
        appLabelThrottleClick
        [throttleInMilliSec]="1000"
        (throttleClick)="clearClicked()">
      </app-label-remove-button>
    </div>
  </div>
</div>
