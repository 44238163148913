import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class CannabinoidsColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsModalViewModel
  ) {
    super();
  }

  public primaryCannabinoidColumnConfigsAndKeys$ = window.types.cannabinoidSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(keyType => keyType?.isPrimaryCannabinoidKey()));
    }),
    startWith([])
  );

  public secondaryCannabinoidColumnConfigsAndKeys$ = window.types.cannabinoidSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(
        keys?.filter(keyType => keyType?.isSecondaryCannabinoidKey())
      );
    }),
    startWith([])
  );

}
