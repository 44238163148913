import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { EditSmartFilterCategoryViewModel } from './edit-smart-filter-category-view-model';
import { UploadImageInterface } from '../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../models/shared/budsense-file';

@Component({
  selector: 'app-edit-smart-filter-category-modal',
  templateUrl: './edit-smart-filter-category-modal.component.html',
  styleUrls: ['./edit-smart-filter-category-modal.component.scss'],
  providers: [EditSmartFilterCategoryViewModel]
})
export class EditSmartFilterCategoryModalComponent extends BaseModalComponent implements UploadImageInterface {

  constructor(
    public viewModel: EditSmartFilterCategoryViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  fileList(f: BudsenseFile[], id?: number) {
    this.viewModel.replaceImg = false;
    if (f.length > 0) {
      this.viewModel.imgToUpload = f[0];
    } else {
      this.viewModel.imgToUpload = undefined;
    }
  }

}
