import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { CompanySmartSyncRequestType } from '../../../../models/company/enum/company-smart-sync-request-type.enum';
import { Company } from '../../../../models/company/dto/company';
import { debounceTime, distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { SearchUtils } from '../../../../utils/search-utils';
import { HasChildIds } from '../../../../models/protocols/has-child-ids';

@Injectable()
export class SmartSyncLocationSelectModalViewModel extends BaseModalViewModel {

  constructor(
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private _smartSyncRequestType = new BehaviorSubject<CompanySmartSyncRequestType>(null);
  public smartSyncRequestType$ = this._smartSyncRequestType as Observable<CompanySmartSyncRequestType>;
  setSmartSyncRequestType = (smartSyncRequestType: CompanySmartSyncRequestType) => {
    this._smartSyncRequestType.next(smartSyncRequestType);
  };

  private _company = new BehaviorSubject<Company>(null);
  public company$ = this._company as Observable<Company>;
  setCompany = (company: Company) => this._company.next(company);

  public locations$ = this.company$.pipe(
    map(company => company?.locations)
  );

  private _searchText = new BehaviorSubject<string>('');
  public searchText$ = this._searchText as Observable<string>;
  setSearchText = (searchText: string) => this._searchText.next(searchText);
  public clearSearchText = (): void => this._searchText.next('');

  public locationsFilteredBySearchText$ = combineLatest([
    this.locations$,
    this.searchText$.pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(100),
    map(([locations, searchText]) => {
      return searchText?.length > 1
        ? SearchUtils.searchLocations(locations, searchText)
        : locations;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public groupedLocationIds$ = this.locationsFilteredBySearchText$.pipe(
    map(locations => new class implements HasChildIds {

      getId = (): string => 'N/A';
      getChildIds = (): string[] => locations?.map(it => it?.id?.toString(10)) ?? [];

}()),
  );

  private _selectedLocationIds = new BehaviorSubject<string[]>([]);
  public selectedLocationIds$ = this._selectedLocationIds as Observable<string[]>;
  public nSelectedLocationIds$ = this.selectedLocationIds$.pipe(
    map(selectedLocationIds => selectedLocationIds?.length)
  );
  public canSubmit$ = this.selectedLocationIds$.pipe(map(locationIds => locationIds?.length > 0));

  public requestTypeDisplayText$ = this.smartSyncRequestType$.pipe(
    map(requestType => {
      switch (requestType) {
        case CompanySmartSyncRequestType.SmartFilter:
          return 'Smart Filter';
        case CompanySmartSyncRequestType.SmartDisplayAttributes:
          return 'Smart Display Attributes';
      }
    })
  );

  addLocationId(locationId: string) {
    this.selectedLocationIds$.pipe(take(1)).subscribe(selectedLocationIds => {
      selectedLocationIds.push(locationId);
      this._selectedLocationIds.next(selectedLocationIds.unique());
    });
  }

  bulkAddLocationIds(locationIds: string[]): void {
    locationIds?.forEach(id => this.addLocationId(id));
  }

  bulkRemoveLocationIds(locationIds: string[]): void {
    locationIds?.forEach(id => this.removeLocationId(id));
  }

  removeLocationId(locationId: string) {
    this.selectedLocationIds$.pipe(take(1)).subscribe(selectedLocationIds => {
      const update = selectedLocationIds?.shallowCopy() || [];
      const locationIdIndex = update.findIndex(id => id === locationId);
      if (locationIdIndex >= 0) {
        update.splice(locationIdIndex, 1);
        this._selectedLocationIds.next(update);
      }
    });
  }

  customLocationParser(locationIds: string[]) {
    let selectedLocationIds: string[] = [];
    if (locationIds instanceof Array) selectedLocationIds = locationIds?.filterNulls();
    return selectedLocationIds;
  }

  clearSelection() {
    this._selectedLocationIds.next([]);
  }

}
