import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BadgePickerViewModel } from './badge-picker-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuratedVariantBadgeSection } from '../../../../models/product/dto/curated-variant-badge-section';

@Component({
  selector: 'app-badge-picker',
  templateUrl: './badge-picker.component.html',
  styleUrls: ['./badge-picker.component.scss'],
  providers: [BadgePickerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgePickerComponent extends BaseComponent implements OnChanges {

  @Input() addedBadgeIds: string[];
  @Input() preselectedBadgeIds: string[];
  @Input() asListItems: boolean = false;
  @Input() maximumNumberOfBadges: number;
  @Input() curatedBadgesOnly: boolean = false;
  @Input() noSmartBadges = false;
  @Output() selected: EventEmitter<HydratedVariantBadge> = new EventEmitter<HydratedVariantBadge>();

  private _curatedBadgesOnly = new BehaviorSubject<boolean>(this.curatedBadgesOnly);
  private _noSmartBadges = new BehaviorSubject<boolean>(this.noSmartBadges);

  private badgeSections$ =  this.viewModel.curatedBadgeSections$;

  badgeSectionList$ = combineLatest([
    this.badgeSections$,
    this._noSmartBadges
  ]).pipe(
    map(([sections, noSmartBadges]) => {
      if (!noSmartBadges) return sections;
      const removeSmartFilterBadges = (section: CuratedVariantBadgeSection) => {
        section.badges = section.badges.filter((b) => !b?.smartFilterIds?.length);
      };
      sections?.forEach(removeSmartFilterBadges);
      return sections;
    }),
  );

  constructor(
    public viewModel: BadgePickerViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.curatedBadgesOnly) this._curatedBadgesOnly.next(this.curatedBadgesOnly);
    if (changes.noSmartBadges) this._noSmartBadges.next(this.noSmartBadges);
  }

}
