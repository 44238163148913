import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseSmartFilterFormViewModelComponent } from '../base-smart-filter-form-view-model';
import { exists } from '../../../../../functions/exists';
import { TypeDefinition } from '../../../../../utils/type-definition';

@Injectable()
export class BasicAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModelComponent {

  constructor() {
    super();
  }

  private selectedPrimarySortOption = new BehaviorSubject<string>(null);
  public disableSecondarySort$ = combineLatest([
    this.selectedPrimarySortOption,
    this.isViewOnly$
  ]).pipe(
    map(([selectedPrimarySort, viewOnly]) => {
      return !selectedPrimarySort || viewOnly;
    })
  );

  public primarySortDropdownOptions$ = window?.types?.groupedSectionSortOptions$;

  public secondarySortDropdownOptions$ = combineLatest([
    this.primarySortDropdownOptions$,
    this.selectedPrimarySortOption
  ]).pipe(
    map(([allDropdowns, primarySort]) => {
      // If primary sort is CBD_ASC, secondary cannot be either CBD_ASC or CBD_DESC
      if (!primarySort) {
        return null;
      }
      const primaryKey = primarySort?.substr(0, primarySort?.indexOf('_'));
      return allDropdowns.filter(dropdowns => {
        return !(dropdowns instanceof TypeDefinition) || !dropdowns.value?.includes(primaryKey);
      });
    })
  );

  // For UsePurposeType Picker (All, Medicinal, Recreational)
  // Leaving these two pipes in here in case we ever want to add the picker back
  public displayAllRecMedControl$ = of(false);
  public readonly usePurposeOptions$ = of(null);

  public hasSystemIdentifier$ = this.smartFilter$.pipe(
    map(smartFilter => exists(smartFilter?.systemIdentifier))
  );

  public primarySortDropdownChanged(sortOption: string) {
    this.selectedPrimarySortOption.next(sortOption);
  }

}
