import { Pipe, PipeTransform } from '@angular/core';
import { NavSubItem } from '../../models/nav-sub-item';
import { DisplayAttributesNavSubItem } from '../models/display-attributes-sub-nav-item';

@Pipe({
  name: 'isDisplayAttributeNavSubItem'
})
export class IsDisplayAttributeSubNavItemPipe implements PipeTransform {

  transform(value: NavSubItem): boolean {
    return value instanceof DisplayAttributesNavSubItem;
  }

}
