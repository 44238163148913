<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header no-border">
      <div class="modal-title">
        {{ viewModel.headerText$ | push }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <lib-reactive-form-group
      #categoryForm
      [bindTo]="viewModel.category$ | push"
      (canSubmit$)="viewModel.connectToCanSubmit($event)"
      (hasErrors$)="viewModel.connectToHasErrors($event)"
      (formSubmitted$)="viewModel.save()">
      <lib-reactive-form-drop-down
        [inputName]="'allowedSectionTypes'"
        [label]="'Section Types'"
        [placeholder]="'Select the type of sections that can be part of category.'"
        [bindingProperty]="'allowedSectionTypesString'"
        [dropdowns]="viewModel.categoryAllowedSectionTypesDropdowns$ | push"
        [disabled]="viewModel.isEditing$ | push"
        [required]="true"></lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'name'"
        [label]="'Category Name'"
        [placeholder]="'Enter the category name'"
        [inlineLabel]="false"
        [disabled]="false"
        [required]="true"
        [bindingProperty]="'name'">
      </lib-reactive-form-text>
    </lib-reactive-form-group>

    <div *ngIf="viewModel.showAssociatedBlueprints$ | push" class="associated-blueprints-container">
      <div class="bs-bold f14px py-3">Associated Blueprints</div>
      <ng-container *ngFor="let b of viewModel.associatedBlueprints$ | push; last as last">
        <div class="bs-medium f12px py-3 associated-blueprint-title">{{ b?.title }}</div>
      </ng-container>
    </div>
  </div>

  <div class="sticky-footer-container">
    <lib-button-text
      *ngIf="viewModel.isEditing$ | push"
      class="mt-16px"
      [textColor]="'#FA5555'"
      (buttonClicked)="viewModel.showDeleteBlueprintCategoryModal()">
      Delete Category
    </lib-button-text>
    <div class="footer-button">
      <lib-button-neutral class="me-4" (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        [disabled]="!(viewModel.canClickSaveButton$ | push)"
        (buttonClicked)="categoryForm.submitForm()">
        Save
      </lib-button-primary>
    </div>
  </div>
</div>
