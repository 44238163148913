export enum CardStyle {
  Default,
  IncompleteProduct,
  UnconfirmedProduct,
  Location,
  QuickAction,
  ThemePreview,
  ThemeCarouselImage,
  AssetPreview,
  EditAssetPreview,
  SmartFilterCategoryPreview,
  SectionBlueprintCategoryPreview,
  UnassignedAssetGroups
}
