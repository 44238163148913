import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Variant } from '../../../../models/product/dto/variant';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { DisplayAttributeGroup } from '../../../../models/product/dto/display-attribute-group';
import { SortUtils } from '../../../../utils/sort-utils';
import { BsError } from '../../../../models/shared/bs-error';
import { exists } from '../../../../functions/exists';

@Injectable()
export class AddVariantToDisplayGroupViewModel extends BaseModalViewModel {

  public searchableGdags$ = this.productDomainModel.displayAttributeGroups$.pipe(
    map(groups => [...(groups || [])].sort(SortUtils.displayAttributeGroupsLastUpdatedFirst))
  );

  private _ubvToAdd = new BehaviorSubject<Variant>(null);
  public ubvToAdd$ = this._ubvToAdd as Observable<Variant>;
  connectToUbvToAdd = (ubvToAdd: Variant) => this._ubvToAdd.next(ubvToAdd);

  private _idsToAdd = new BehaviorSubject<string[]>(null);
  public idsToAdd$ = this._idsToAdd as Observable<string[]>;
  connectToIdsToAdd = (idsToAdd: string[]) => this._idsToAdd.next(idsToAdd);

  private _searchString = new BehaviorSubject<string>('');
  public searchString$ = this._searchString.asObservable();
  private _searchHits = new BehaviorSubject<DisplayAttributeGroup[]>([]);
  public searchHits$ = this._searchHits;

  displayAttributesToShow$ = combineLatest([
    this.searchableGdags$,
    this.searchString$,
    this.searchHits$
  ]).pipe(
    debounceTime(250),
    map(([gdags, searchString, searchHits]) => ((searchString?.length >= 2) ? searchHits : gdags.slice(0, 10))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public displayedGdagCount$ = this.displayAttributesToShow$.notNull().pipe(map(gdag => gdag?.length ?? 0));
  public totalGdagCount$ = this.productDomainModel.displayAttributeGroupCount$;

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  groupClicked(g: DisplayAttributeGroup) {
    combineLatest(([
      this.ubvToAdd$,
      this.idsToAdd$
    ])).once(([ubvToAdd, idsToAdd]) => {
      this.updateExistingGroup(g, ubvToAdd, idsToAdd);
    });
  }

  updateExistingGroup(g: DisplayAttributeGroup, ubvToAdd: Variant, idsToAdd: string[]) {
    const lm = exists(ubvToAdd) ? 'Adding Variant to Group' : 'Adding Variant(s) to Group';
    const sm = exists(ubvToAdd) ? 'Variant added to group' : 'Variant(s) added to group';
    this._loadingOpts.addRequest(lm);
    this.productDomainModel.getHydratedDisplayAttributeGroups(g.id).pipe(
      switchMap((displayGroups) => {
        // Add new variant to existing group
        const groupToUpdate = displayGroups.firstOrNull();
        if (!groupToUpdate) {
          this.toastService.publishErrorMessage(`Could not find Display Group with id ${g.id}`, 'No Display Group!');
          return of(null);
        } else if (exists(ubvToAdd)) {
          // Add new variant to group to update
          groupToUpdate.variants.push(ubvToAdd);
        } else {
          // Add new variants to group to update
          idsToAdd?.forEach(id => {
            const newVariant = new Variant();
            newVariant.barcode = id;
            groupToUpdate.variants.push(newVariant);
          });
        }
        return this.productDomainModel.updateDisplayAttributeGroup(groupToUpdate);
      }),
      take(1)
    ).subscribe({
      next: (updatedDisplayGroup: DisplayAttributeGroup) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage(sm, 'Success!');
        this.activeModal.close(true);
        this.navigateToEditGroupPage(updatedDisplayGroup.id);
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  navigateToEditGroupPage(id: string) {
    this.router.navigate(['products/displayattributes/edit', id]).then();
  }

  createNewGroup() {
    combineLatest(([
      this.ubvToAdd$,
      this.idsToAdd$
    ])).once(([ubvToAdd, idsToAdd]) => {
      if (exists(ubvToAdd)) {
        this.activeModal.close([ubvToAdd?.barcode]);
      } else {
        this.activeModal.close(idsToAdd);
      }
    });
  }

  searchText = (s: string) => this._searchString.next(s);
  searchHits = (hits: DisplayAttributeGroup[]) => this._searchHits.next(hits);

}
