export class TypeCheckUtils {

  static isPrimitive(obj: any): boolean {
    return obj !== Object(obj);
  }

  static isBool(obj: any): boolean {
    return typeof obj === 'boolean';
  }

}
