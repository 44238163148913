import { EventEmitter, Injectable } from '@angular/core';
import { Company } from '../../../../models/company/dto/company';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { LabelGroupItem } from '../../../../models/shared/stylesheet/label-group-item';
import { ToastService } from '../../../../services/toast-service';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { Variant } from '../../../../models/product/dto/variant';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { EditVariantFormObject } from '../../forms/edit-variant-form/edit-variant-form-object';
import { delay, map, shareReplay, switchMap } from 'rxjs/operators';
import { BsError } from '../../../../models/shared/bs-error';
import { CannabisUnitOfMeasure } from '../../../../utils/cannabis-unit-of-measure-type';

@Injectable()
export class EditVariantModalViewModel extends BaseModalViewModel {

  constructor(
    private domainModel: ProductDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public variant: Variant;
  public variant$: BehaviorSubject<Variant> = new BehaviorSubject<Variant>(null);

  // Variant Usages
  private _variantUsages: BehaviorSubject<Variant[]> = new BehaviorSubject<Variant[]>([]);
  public variantUsages$ = this._variantUsages as Observable<Variant[]>;
  connectToVariantUsages = (variants: Variant[]) => this._variantUsages.next(variants);

  // Edit Form
  public variantFormObject: EditVariantFormObject;
  public incompleteVariantFlow: boolean;
  public showUnconfirmedInfoBanner: boolean;

  // Company
  public company: Company = null;

  // Usages
  public variantsFromBarcode$: Observable<Variant[]> = this.variant$.pipe(
    switchMap(v => !!v ? this.domainModel.getVariantsByBarcode(v.barcode) : of([])),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public showUsages$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public seeUsagesButtonEnabled$ = combineLatest([
    this.variantsFromBarcode$.notNull(),
    this.showUsages$.notNull(),
  ]).pipe(map(([variantsFromBarcode, showUsages]) => (variantsFromBarcode.length > 0) && !showUsages));

  private static mapBuild(countMap: Map<string, number>, key: string) {
    if (!!key) {
      const updateCount = (countMap?.get(key) ?? 0) + 1;
      countMap?.set(key, updateCount);
    }
  }

  private static createMeasurementRangeString(min, max, unit: string): string {
    return EditVariantModalViewModel.addUnitToMeasureOrRange(min + ' - ' + max, unit);
  }

  private static addUnitToMeasureOrRange(measureOrRange, unit: string): string {
    if (unit === CannabisUnitOfMeasure.NA) {
      return measureOrRange;
    } else if (unit === CannabisUnitOfMeasure.Percent) {
      return measureOrRange + unit;
    }
    return measureOrRange + ' ' + unit;
  }

  public nameCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.name));
      return countMap;
    })
  );
  public brandCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.brand));
      return countMap;
    })
  );
  public manufacturerCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.manufacturer));
      return countMap;
    })
  );
  public productTypeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.productType));
      return countMap;
    })
  );
  public variantTypeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.variantType));
      return countMap;
    })
  );
  public strainTypeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.classification));
      return countMap;
    })
  );
  public strainCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.strain));
      return countMap;
    })
  );
  public unitSizeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.unitSize.toString()));
      return countMap;
    })
  );
  public unitOfMeasureCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.unitOfMeasure));
      return countMap;
    })
  );
  public packagedQuantityCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => EditVariantModalViewModel.mapBuild(countMap, v.packagedQuantity.toString()));
      return countMap;
    })
  );
  public THCCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => {
        const val = EditVariantModalViewModel.addUnitToMeasureOrRange(v.THC.toString(), v.cannabisUnitOfMeasure);
        EditVariantModalViewModel.mapBuild(countMap, val);
      });
      return countMap;
    })
  );
  public THCRangeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => {
        const val = EditVariantModalViewModel.createMeasurementRangeString(
          v.minTHC.toString(),
          v.maxTHC.toString(),
          v.cannabisUnitOfMeasure
        );
        EditVariantModalViewModel.mapBuild(countMap, val);
      });
      return countMap;
    })
  );
  public CBDCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => {
        const val = EditVariantModalViewModel.addUnitToMeasureOrRange(v.CBD.toString(), v.cannabisUnitOfMeasure);
        EditVariantModalViewModel.mapBuild(countMap, val);
      });
      return countMap;
    })
  );
  public CBDRangeCountMap$ = this.variantUsages$.pipe(
    map(variants => {
      const countMap = new Map<string, number>();
      variants?.map(v => {
        const val = EditVariantModalViewModel.createMeasurementRangeString(
          v.minCBD.toString(),
          v.maxCBD.toString(),
          v.cannabisUnitOfMeasure
        );
        EditVariantModalViewModel.mapBuild(countMap, val);
      });
      return countMap;
    })
  );

  // Emitters
  public viewVariantClicked: EventEmitter<LabelGroupItem> = new EventEmitter<LabelGroupItem>();
  public override dismissModalSubject: Subject<boolean> = new Subject<boolean>();

  public setVariant(v: Variant, incompleteVariantFlow: boolean) {
    this.incompleteVariantFlow = incompleteVariantFlow;
    this.showUnconfirmedInfoBanner = incompleteVariantFlow && !v.isIncomplete() && v.isUnconfirmed();
    this.variant = window.injector.Deserialize.instanceOf(Variant, v);
    this.variantFormObject = new EditVariantFormObject(this.variant);
    this.variant$.next(this.variant);
  }

  public saveVariant(formObject: EditVariantFormObject) {
    const variantToSave = formObject.getVariant();
    const lm = 'Saving Variant';
    variantToSave.confirmed = true;
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.domainModel.updateUniversalVariant(variantToSave).pipe(delay(1000)).subscribe((_) => {
        this.toastService.publishSuccessMessage('Variant update successful.', 'Successful Update');
        this._loadingOpts.removeRequest(lm);
        this.dismissModalSubject.next(true);
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

}
