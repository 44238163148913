import { DefaultStackSizeType } from './default-stack-size-type';

export enum DefaultShelfTalkerCardSize {
  ShelfTalkerSize_PostCard = 'ShelfTalkerSize_PostCard',
  ShelfTalkerSize_Custom5x7 = 'ShelfTalkerSize_Custom5x7',
}

export class DefaultShelfTalkerCardSizeType extends DefaultStackSizeType {

  override value: DefaultShelfTalkerCardSize;

}
