import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-snoozed-icon',
  templateUrl: './snoozed-icon.component.html',
  styleUrls: ['./snoozed-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnoozedIconComponent {

  @Input() color: string = '#222222';
  @Input() icon: 'snoozed' | 'snoozedFromThePast';
  @Input() height: number = 24;
  @Input() width: number = 24;

}
