import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EmployeeAction } from '../../../../models/company/shared/employee-action';
import { AdminUser } from '../../../../models/account/dto/admin-user';
import { EmployeeViewModel } from './employee-view-model';
import { PopperUtils } from '../../../../utils/popper-utils';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  providers: [EmployeeViewModel]
})
export class EmployeeComponent extends BaseComponent implements OnChanges {

  @Input() employee: AdminUser;
  @Input() showOptions: boolean = true;
  @Input() showLastLogin: boolean = false;
  @Input() showCompanyRole: boolean = false;
  @Input() showProductOnlyAccess: boolean = false;
  @Input() showIfAdmin: boolean = false;
  @Output() selection: EventEmitter<EmployeeAction> = new EventEmitter();
  public popperModifier = [PopperUtils.flipModifier(['right', 'bottom', 'top'])];
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };
  public toolTipStyle = {
    'background-color': '#000000',
    color: '#FFFFFF'
  };

  constructor(
    public viewModel: EmployeeViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employee) this.viewModel.connectToEmployee(this.employee);
  }

  edit() {
    this.selection.emit(new EmployeeAction('edit', this.employee));
  }

  resendVerification() {
    this.selection.emit(new EmployeeAction('resend', this.employee));
  }

  delete() {
    this.selection.emit(new EmployeeAction('delete', this.employee));
  }

}
