import { Deserializable } from '../../protocols/deserializable';
import { Orientation } from '../../../utils/orientation-type';
import { DefaultStackSize } from '../../../utils/default-stack-size';

export class PrintConfig implements Deserializable {

  constructor() {
    this.paperSizes = [];
    this.printCardSizes = [];
    this.orientations = [];
    this.cardOrientationMap = new Map<DefaultStackSize, string>();
    this.previewCardMap = new Map<DefaultStackSize, string[]>();
    this.gridCountMap = new Map<DefaultStackSize, number>();
    this.visiblePropertyMap = new Map<DefaultStackSize, string[]>();
  }

  public marginBottom: number = 0;
  public marginLeft: number = 0;
  public marginRight: number = 0;
  public marginTop: number = 0;
  public paperSizes: string[];
  public printCardSizes: string[];
  public orientations: Orientation[];
  public cardOrientationMap: Map<DefaultStackSize, string>;
  public previewCardMap: Map<DefaultStackSize, string[]>;
  public gridCountMap: Map<DefaultStackSize, number>;
  public visiblePropertyMap: Map<DefaultStackSize, string[]>;

  onDeserialize() {
    this.paperSizes = Array.from(this.paperSizes || []);
    this.printCardSizes = Array.from(this.printCardSizes || []);
    this.orientations = Array.from(this.orientations || []);
    this.cardOrientationMap = !!this.cardOrientationMap
      ? window.injector.Deserialize.genericMap(this.cardOrientationMap)
      : new Map<DefaultStackSize, string>;
    this.previewCardMap = !!this.previewCardMap
      ? window.injector.Deserialize.genericArrayMap(this.previewCardMap)
      : new Map<DefaultStackSize, string[]>();
    this.gridCountMap = !!this.gridCountMap
      ? window.injector.Deserialize.genericMap(this.gridCountMap)
      : new Map<DefaultStackSize, number>();
    this.visiblePropertyMap = !!this.visiblePropertyMap
      ? window.injector.Deserialize.genericArrayMap(this.visiblePropertyMap)
      : new Map<DefaultStackSize, string[]>();
  }

}
