import { Component } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent extends BaseComponent {

  constructor() {
    super();
  }

}
