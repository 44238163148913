import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { CannabinoidsColumnOptionsFormViewModel } from './cannabinoids-column-options-form-view-model';

@Component({
  selector: 'app-cannabinoid-column-options-form',
  templateUrl: './cannabinoid-column-options-form.component.html',
  styleUrls: ['./cannabinoid-column-options-form.component.scss'],
  providers: [CannabinoidsColumnOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidColumnOptionsFormComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: CannabinoidsColumnOptionsFormViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
