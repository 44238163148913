import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { Variant } from '../../../../models/product/dto/variant';
import { CreateAssetGroupRequest } from '../../../../models/product/requests/create-asset-group-request';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { BsError } from '../../../../models/shared/bs-error';

@Injectable()
export class CreateAssetGroupModalViewModel extends BaseModalViewModel {

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public createAssetGroupRequest: CreateAssetGroupRequest = new CreateAssetGroupRequest(new AssetGroup());
  public displayAttributeGroupId: string;

  public canSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _unassignedVariants: BehaviorSubject<Variant[]> = new BehaviorSubject<Variant[]>([]);
  public unassignedVariants$ = this._unassignedVariants as Observable<Variant[]>;

  connectToUnassignedVariants = (variants: Variant[]) => this._unassignedVariants.next(variants);
  setSelectedVariants = (barcodes: string[]) => this.createAssetGroupRequest.universalVariantBarcodes.push(...barcodes);

  customVariantParser(variantBarcodes: string[]) {
    let selectedVariantBarcodes: string[] = [];
    if (variantBarcodes instanceof Array) { selectedVariantBarcodes = variantBarcodes.filterNulls(); }
    return selectedVariantBarcodes;
  }

  createAssetGroup() {
    const lm = 'Creating New Asset Group';
    this._loadingOpts.addRequest(lm);
    this.createAssetGroupRequest.displayAttributeGroupId = this.displayAttributeGroupId;
    this.productDomainModel.createAssetGroup(this.createAssetGroupRequest).subscribe(ags => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Your asset group was created', 'Success');
      this.activeModal.close(ags);
    }, (err: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(err);
    });
  }

}
