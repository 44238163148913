import { Pipe, PipeTransform } from '@angular/core';
import { DisplayAttributeGroupDetails } from '../../../models/product/dto/display-attribute-group-details';

@Pipe({
  name: 'isDefaultSharedDisplayAttributeGroup'
})
export class IsDefaultSharedDisplayAttributeGroupPipe implements PipeTransform {

  transform(value: DisplayAttributeGroupDetails): boolean {
    return value?.isDefaultSharedGroupForDisplayAttribute() || false;
  }

}
