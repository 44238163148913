import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '../../../../../../../../../../models/company/dto/location';

@Component({
  selector: 'app-location-selection-group',
  templateUrl: './location-selection-group.component.html',
  styleUrls: ['./location-selection-group.component.scss']
})
export class LocationSelectionGroupComponent {

  @Input() viewOnlyMode: boolean = false;
  @Input() showAddedOrRemovedStatus: boolean = false;
  @Input() expanded: boolean = false;
  @Input() province: string;
  @Input() locations: Location[];
  @Input() originalLocationIds: number[];
  @Input() updatedLocationIds: number[];
  @Input() selectedIds: string[] = [];
  @Output() addLocationIds = new EventEmitter<string[]>(true);
  @Output() removeLocationIds = new EventEmitter<string[]>(true);

}
