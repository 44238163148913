import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { SearchUtils } from '../../../../utils/search-utils';
import { AssetGroup } from '../../../../models/product/dto/asset-group';

@Injectable()
export class FilterByBrandModalViewModel extends BaseModalViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public brands$ = this.productDomainModel.brands$;
  protected _searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchText$ = this._searchText.pipe(debounceTime(100), distinctUntilChanged());

  private _selectedFilterBrands: BehaviorSubject<AssetGroup[]> = new BehaviorSubject<AssetGroup[]>(null);
  public selectedFilterBrands$ = this._selectedFilterBrands as Observable<AssetGroup[]>;
  public selectedFilterBrandIds$ = this.selectedFilterBrands$.pipe(
    map(brands => brands?.map(brand => String(brand?.id)))
  );
  public changingSelectedBrands$ = this.selectedFilterBrands$.pipe(
    map(selectedFilterBrands => selectedFilterBrands?.length > 1)
  );

  private _selectingForFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectingForFilter$ = this._selectingForFilter as Observable<boolean>;
  public maxNumOfSelectedBrands$ = this.selectingForFilter$.pipe(
    map(selectingForFilter => (selectingForFilter ? 10 : 1))
  );
  public modalTitle$ = combineLatest([
    this.selectedFilterBrands$,
    this.selectingForFilter$,
  ]).pipe(
    map(([selectedFilterBrands, selectingForFilter]) => {
      if (selectingForFilter) { return 'Filter by Brand'; }
      if (selectedFilterBrands?.length > 0) {
        return 'Change Brand Asset Group';
      } else {
        return 'Add Brand Asset Group';
      }
    })
  );

  // Display Attribute Groups filtered by search string
  public brandsFilteredBySearchString$ = combineLatest([
    this.brands$.pipe(
      map(brands => brands?.sort((a, b) => a?.groupName.localeCompare(b?.groupName)))
    ),
    this.searchText$.pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(100),
    map(([brands, searchText]) => {
      return searchText?.length > 1
        ? SearchUtils.searchBrand(brands, searchText)
        : brands;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public setSearchText = (searchText: string): void => this._searchText.next(searchText);
  public connectToSelectedFilterBrands = (filterBrands: AssetGroup[]) => this._selectedFilterBrands.next(filterBrands);
  public connectToSelectingForFilter = (selectingToFilter: boolean) => this._selectingForFilter.next(selectingToFilter);

  public clearFilters() {
    this._selectedFilterBrands.next([]);
    this._searchText.next('');
  }

  public addToFilterBrands(addedBrandId: string) {
    combineLatest([
      this.brands$,
      this.maxNumOfSelectedBrands$,
      this.selectingForFilter$
    ]).once(([brands, max, selectingForFilter]) => {
      const brandToAdd = brands?.find(brand => brand?.id === addedBrandId);
      const updatedBrandFilters = this._selectedFilterBrands.getValue()?.shallowCopy() || [];
      if (!selectingForFilter && !!brandToAdd) {
        this._selectedFilterBrands.next([brandToAdd]);
      } else if (!!brandToAdd && updatedBrandFilters?.length < max) {
        updatedBrandFilters.push(brandToAdd);
        this._selectedFilterBrands.next(updatedBrandFilters);
      }
    });
  }

  public removeFromFilterBrands(removedBrandId: string) {
    const updatedBrandFilters = this._selectedFilterBrands.getValue()?.shallowCopy() || [];
    const removedBrandIndex = updatedBrandFilters?.findIndex(brand => brand?.id === removedBrandId);
    if (removedBrandIndex > -1) {
      updatedBrandFilters.splice(removedBrandIndex, 1);
      this._selectedFilterBrands.next(updatedBrandFilters);
    }
  }

  public finishedFiltering() {
    this.selectedFilterBrands$.pipe(take(1)).subscribe(selectedFilterBrands => {
      this.activeModal.close(selectedFilterBrands);
    });
  }

}
