import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import { SectionColumnConfigDefaultState } from '../../../utils/section-column-config-default-state-type';
import { FontStyle } from '../../../utils/font-style-type';
import { SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigIndividualTerpeneKey, SectionColumnConfigKey, SectionColumnConfigPrimaryCannabinoidKey, SectionColumnConfigProductInfoKey, SectionColumnConfigSecondaryCannabinoidKey } from '../../enum/dto/section-column-config-key';
import { SectionColumnConfigDataValue } from '../../enum/dto/section-column-config-data-value';
import { SectionColumnConfigEmptyData } from '../../enum/dto/section-column-config-empty-data';
import { SectionColumnConfigSecondaryPricingData } from '../../enum/dto/section-column-config-secondary-pricing-data';

export class SectionColumnConfig implements UniquelyIdentifiable, Deserializable {

  public key: string = null;
  public defaultState: SectionColumnConfigDefaultState = SectionColumnConfigDefaultState.Unknown;
  public columnName: string = null;
  public columnWidth: number = null;
  // Currently, only used for Secondary Price
  public dataValue: SectionColumnConfigDataValue = SectionColumnConfigEmptyData.NotSet;
  public fontStyle: string = FontStyle.Normal;
  public fontColor: string = null;
  public columnColor: string = null;
  // ColumnOpacity is saved in the DB as a float 0.0-1.0 but is shown to the user as a % between 0-100
  public columnOpacity: number = null;
  // Not from API
  public hasCustomStyle: boolean = false;

  static getDefaultStateOptions(key: SectionColumnConfigKey): SectionColumnConfigDefaultState[] {
    switch (key) {
      case SectionColumnConfigProductInfoKey.Quantity:
      case SectionColumnConfigProductInfoKey.Size:
      case SectionColumnConfigProductInfoKey.Badges:
      case SectionColumnConfigPrimaryCannabinoidKey.THC:
      case SectionColumnConfigPrimaryCannabinoidKey.CBD:
        return [
          SectionColumnConfigDefaultState.On,
          SectionColumnConfigDefaultState.Off,
          SectionColumnConfigDefaultState.Auto,
          SectionColumnConfigDefaultState.Disabled,
        ];
      case SectionColumnConfigPrimaryCannabinoidKey.TAC:
      case SectionColumnConfigSecondaryCannabinoidKey.SecondaryCannabinoids:
      case SectionColumnConfigIndividualTerpeneKey.IndividualTerpenes:
        return [
          SectionColumnConfigDefaultState.Off
        ];
      default:
        return [
          SectionColumnConfigDefaultState.On,
          SectionColumnConfigDefaultState.Off,
          SectionColumnConfigDefaultState.Disabled,
        ];
    }
  }

  static getDataValueOptions(key: SectionColumnConfigKey): SectionColumnConfigDataValue[] {
    switch (key) {
      case SectionColumnConfigProductInfoKey.SecondaryPrice:
        return [
          SectionColumnConfigSecondaryPricingData.SecondaryPrice,
          SectionColumnConfigSecondaryPricingData.PricePerUOM,
          SectionColumnConfigSecondaryPricingData.OriginalPrice,
          SectionColumnConfigSecondaryPricingData.SaleOriginalPrice,
          SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice,
          SectionColumnConfigSecondaryPricingData.TaxesInPrice,
          SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice,
          SectionColumnConfigSecondaryPricingData.PreTaxPrice
        ];
    }
    return [];
  }

  static getGeneralColumnKeys(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigProductInfoKey.Asset,
      SectionColumnConfigProductInfoKey.Badges,
      SectionColumnConfigProductInfoKey.Brand,
      SectionColumnConfigProductInfoKey.StrainType,
      SectionColumnConfigProductInfoKey.Quantity,
      SectionColumnConfigProductInfoKey.Size,
      SectionColumnConfigProductInfoKey.QuantityAndSize,
      SectionColumnConfigProductInfoKey.VariantType,
      SectionColumnConfigProductInfoKey.Stock
    ];
  }

  static getCannabinoidColumnKeys(): SectionColumnConfigKey[] {
    return [
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey)
    ];
  }

  static getSecondaryCannabinoidColumnKeys(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigSecondaryCannabinoidKey.SecondaryCannabinoids,
    ];
  }

  static getTerpeneColumnKeys(): SectionColumnConfigKey[] {
    return [
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey)
    ];
  }

  static getIndividualTerpenesColumnKeys(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigIndividualTerpeneKey.IndividualTerpenes,
    ];
  }

  static getPricingColumnKeys(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigProductInfoKey.Price,
      SectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
  }

  getUniqueIdentifier(): string {
    const key = this.key;
    const defaultState = this.defaultState;
    const columnName = this.columnName ?? '';
    const columnWidth = this.columnWidth?.toString() ?? '';
    const dataValue = this.dataValue;
    const fontStyle = this.fontStyle;
    const fontColor = this.fontColor ?? '';
    const columnColor = this.columnColor ?? '';
    const columnOpacity = this.columnOpacity?.toString() ?? '';
    const hasCustomStyle = this.hasCustomStyle;
    return `${key}
      -${defaultState}
      -${columnName}
      -${columnWidth}
      -${dataValue}
      -${fontStyle}
      -${fontColor}
      -${columnColor}
      -${columnOpacity}
      -${hasCustomStyle}`;
  }

  onDeserialize() {
    this.defaultState = this.defaultState || SectionColumnConfigDefaultState.Unknown;
    if (this.columnWidth === 0) {
      this.columnWidth = null;
    }
    if (this.columnOpacity === 0) {
      this.columnOpacity = null;
    }
    this.dataValue = this.dataValue || SectionColumnConfigEmptyData.NotSet;
    this.hasCustomStyle = this.checkForCustomStyle();
  }

  private checkForCustomStyle(): boolean {
    return this.fontStyle !== FontStyle.Normal ||
      this.fontColor !== '' ||
      this.columnColor !== '' ||
      this.columnOpacity !== null;
  }

  // DTO === Data Transfer Object
  translateToDTO(): SectionColumnConfig | null {
    if (!this.defaultState || this.defaultState === SectionColumnConfigDefaultState.Disabled) {
      return null;
    }
    return this;
  }

}
