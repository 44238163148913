<div class="warning-banner" fxLayout="row">
  <img
    class="warning-icon"
    fxFlexAlign="center"
    [src]="'assets/icons/dark/solid/exclamation-circle.svg'"
    alt="exclamation-circle.svg" />
  <div class="warning-text" fxFlex fxFlexAlign="center">
    <ng-container *ngIf="!!message; else content">{{ message }}</ng-container>
    <ng-template #content><ng-content></ng-content></ng-template>
  </div>
</div>
