import { Pipe, PipeTransform } from '@angular/core';
import { DisplayAttributeGroupDetails } from '../../../models/product/dto/display-attribute-group-details';
import { DisplayAttributeGroup } from '../../../models/product/dto/display-attribute-group';

@Pipe({
  name: 'getSharedAttributeGroupDetails'
})
export class GetSharedAttributeGroupDetailsPipe implements PipeTransform {

  transform(
    groupDetails: DisplayAttributeGroupDetails,
    group: DisplayAttributeGroup
  ): DisplayAttributeGroupDetails | null {
    return groupDetails?.isDefaultSharedGroupForDisplayAttribute() ? null : group?.getDefaultSharedDetails();
  }

}
