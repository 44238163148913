import { Orderable } from '../protocols/orderable';
import { EditLabelPropertyKey } from '../enum/shared/edit-label-property-key';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { PolymorphicDeserializationKey } from '../enum/shared/polymorphic-deserialization-key.enum';
import { PillItem, PillItemInterface } from './stylesheet/pill-item';
import { SmartFilterAppliedOnPillType } from '../enum/shared/smart-filter-applied-on-pill-type';
import { SmartFilterUtils } from '../../utils/smart-filter-utils';
import { MenuLabel } from '../../utils/menu-label-type';
import { exists } from '../../functions/exists';

export const UnsetLabelPriority = -1;
export const LocationPriorityOffset = 1000;

export class Label implements Orderable, UniquelyIdentifiable, PillItemInterface {

  public locationId: number;
  // id can be GUID for custom label, systemLabelKey for system Label, or id from 3rd party (like POS)
  public id: string | MenuLabel;
  public color: string;
  public text: string = '';
  public textColor: string = '';
  public priority: number;
  // Label has an equivalent company version with the same id
  public isCompanyManaged: boolean;
  // Label is inheriting style from equivalent company version
  public isCompanyLinked: boolean;
  public isPOSManaged: boolean;
  public timeThreshold: number;
  public numericThreshold: number;
  public usedByLocationIds: string[];
  public isSystemLabel: boolean;
  public lastModified: number;
  public smartFilterIds: string[] = [];
  public removeExistingOnSync: boolean = false;
  // Not from API
  public applyToAllCompanyLocations: boolean = true;

  constructor(textColor?: string, labelColor?: string, text?: string, id?: string) {
    this.textColor = textColor;
    this.color = labelColor;
    this.text = text;
    this.id = id;
  }

  onDeserialize() {
    this.text = this.text?.toUpperCase();
  }

  getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Label;
  }

  isSaleLabel(): boolean {
    return this.id === MenuLabel.Sale;
  }

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableTitle(): string {
    return this.text;
  }

  getOrderableUniqueId(): string {
    return '';
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }

  propertyChanged(value: string | number, key: EditLabelPropertyKey): void {
    switch (key) {
      case EditLabelPropertyKey.LabelText:
        this.text = String(value);
        break;
      case EditLabelPropertyKey.LabelColor:
        this.color = String(value);
        break;
      case EditLabelPropertyKey.LabelTextColor:
        this.textColor = String(value);
        break;
      case EditLabelPropertyKey.LabelNumericThreshold:
        this.numericThreshold = Number(value);
        break;
      case EditLabelPropertyKey.LabelTimeThreshold:
        this.timeThreshold = Number(value);
        break;
    }
  }

  labelPriorityIsCompanyDefault(): boolean {
    return this.priority !== UnsetLabelPriority && this.priority < LocationPriorityOffset;
  }

  getLabelPickerTitle(): string {
    return this.text;
  }

  clearRemoveExistingOnSyncIfNecessary(): void {
    if (!this.smartFilterIds?.length) {
      this.removeExistingOnSync = false;
    }
  }

  public changesRequireSmartLabelSync(updatedLabel: Label): boolean {
    const currSmartLabelIds = this.smartFilterIds?.sort()?.join(',');
    const updatedSmartLabelIds = updatedLabel?.smartFilterIds?.sort()?.join(',');
    const hasOrHadSmartFilterIds = exists(currSmartLabelIds) || exists(updatedSmartLabelIds);
    // short circuit calculation - stops calling methods if previous method returns true
    return this.smartFilterIdsChangedSoSyncSmartLabels(hasOrHadSmartFilterIds, currSmartLabelIds, updatedSmartLabelIds)
        || this.removeExistingOnSyncChangedSoSyncSmartLabels(updatedSmartLabelIds, updatedLabel)
        || this.priorityChangedSoSyncSmartLabels(updatedSmartLabelIds, updatedLabel);
  }

  private smartFilterIdsChangedSoSyncSmartLabels(
    hasOrHadSmartFilterIds: boolean,
    currentSmartLabelIds: string,
    updatedSmartLabelIds: string
  ): boolean {
    return hasOrHadSmartFilterIds && (currentSmartLabelIds !== updatedSmartLabelIds);
  }

  private removeExistingOnSyncChangedSoSyncSmartLabels(updatedSmartLabelIds: string, updatedLabel: Label): boolean {
    return exists(updatedSmartLabelIds) && (this.removeExistingOnSync !== updatedLabel?.removeExistingOnSync);
  }

  private priorityChangedSoSyncSmartLabels(updatedSmartLabelIds: string, updatedLabel: Label): boolean {
    return exists(updatedSmartLabelIds) && (this.priority !== updatedLabel?.priority);
  }

  getAsPillItem(clickable: boolean, selected: boolean, disabled: boolean): PillItem {
    const text = this.isSystemLabel ? this.id : this.text;
    const imgSrc = SmartFilterUtils.getAppliedOnPillIcon(SmartFilterAppliedOnPillType.SmartLabel);
    return new PillItem(text, clickable, selected, disabled, imgSrc, this);
  }

  getUniqueIdentifier(): string {
    return `${this.locationId}
      -${this.id}
      -${this.color}
      -${this.text}
      -${this.textColor}
      -${this.priority}
      -${this.isCompanyManaged}
      -${this.isCompanyLinked}
      -${this.isPOSManaged}
      -${this.timeThreshold}
      -${this.numericThreshold}
      -${this.isSystemLabel}
      -${this.smartFilterIds?.sort()?.join(',')}
      -${this.removeExistingOnSync}`;
  }

}

