<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'createDisplayGroupForm'"
  (formsSubmitted$)="viewModel.createGroup()">
</lib-reactive-form-merge-groups>

<div class="sticky-header-container">
  <div class="modal-header no-bottom-border">
    <div class="modal-title">Create New Display Attribute Group</div>
  </div>
</div>

<div class="modal-body">
  <lib-reactive-form-group
    #createGDAGForm
    [bindTo]="viewModel.newDisplayAttributeGroup$ | async"
    [mergeKey]="'createDisplayGroupForm'">
    <lib-reactive-form-text
      [inputName]="'groupName'"
      [label]="'Group Name'"
      [placeholder]="'7ACRES - Pineapple Express'"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeVariantBrand$ | async"
      [inlineLabel]="false"
      [required]="true"
      [bindingProperty]="'groupName'">
    </lib-reactive-form-text>
    <lib-reactive-form-text
      [inputName]="'displayName'"
      [label]="'Display Name'"
      [placeholder]="'Pineapple Express'"
      [inlineLabel]="false"
      [required]="true"
      [bindingProperty]="'displayName'">
    </lib-reactive-form-text>
  </lib-reactive-form-group>
  <div class="modal-footer-flex-end pt-3">
    <app-checkbox
      [inline]="true"
      [checked]="viewModel?.openDisplayGroup?.checked"
      [checkbox]="viewModel?.openDisplayGroup"
      class="me-auto ms-0"
      style="margin: auto">
    </app-checkbox>

    <button (click)="close(null)" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="formCapture.submitForms()"
      [class.disabled]="!(createGDAGForm.canSubmit$ | async)"
      [disabled]="!(createGDAGForm.canSubmit$ | async)"
      class="bs-button preferred-button no-right-margin">
      Create Group
    </button>
  </div>
</div>
