import { Injectable, SecurityContext } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { map, switchMap } from 'rxjs/operators';
import { Asset } from '../../../../models/image/dto/asset';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaType } from '../../../../models/enum/dto/media-type.enum';
import { AssetLibraryType } from '../../../../utils/asset-library-type';
import { AspectRatio, DefaultAspectRatio } from '../../../../utils/aspect-ratio';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { BsError } from '../../../../models/shared/bs-error';
import { AssetGroupUtils } from '../../../../utils/asset-group-utils';
import { exists } from '../../../../functions/exists';

@Injectable()
export class AddAssetModalViewModel extends BaseModalViewModel {

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private readonly assetLibraryTypeDropdowns$ = window?.types.assetLibraryAssetGroupTypes$;
  private readonly brandAssetLibraryTypeDropdowns$ = window?.types.assetLibraryBrandAssetGroupTypes$;

  private _assetGroup: BehaviorSubject<AssetGroup> = new BehaviorSubject<AssetGroup>(null);
  public assetGroup$ = this._assetGroup as Observable<AssetGroup>;
  connectToAssetGroup = (as: AssetGroup) => this._assetGroup.next(as);
  public assetGroupName$ = this.assetGroup$.pipe(map(ag => ag?.groupName || 'Shared Group'));

  private _backgroundColor: BehaviorSubject<string> = new BehaviorSubject<string>('#FFFFFF');
  public backgroundColor$ = this._backgroundColor as Observable<string>;

  public libraryTypesDropdowns$ = this.assetGroup$.pipe(
    switchMap(ag => (ag?.isBrand ? this.brandAssetLibraryTypeDropdowns$ : this.assetLibraryTypeDropdowns$))
  );

  private _asset = new BehaviorSubject<Asset>(null);
  public asset$ = this._asset as Observable<Asset>;
  public imgToUpload: BudsenseFile;
  public imgToUpload$ = new BehaviorSubject<BudsenseFile>(null);
  public croppedImage: string = '';

  public _replaceImg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public replaceImg$ = this._replaceImg as Observable<boolean>;

  assetImgUrl$: Observable<string> = this.asset$.pipe(
    switchMap(a => a.sizePriorityUrl$),
    map(url => this.sanitizer.sanitize(SecurityContext.URL, url)),
  );

  public canSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public assetReplaced$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public assetTransformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public canSubmit$ = combineLatest([
    this.canSubmitForm$,
    this.assetReplaced$,
    this.assetTransformed$,
    this.asset$,
    this.imgToUpload$
  ]).pipe(
    map(([canSubmitForm, assetReplaced, assetTransformed, asset, imgToUpload]) => {
      return (canSubmitForm || assetReplaced || assetTransformed)
          && (exists(asset) || exists(imgToUpload))
          && canSubmitForm;
    })
  );

  /* Aspect Ratio Control */
  private _aspectRatio = new BehaviorSubject<DefaultAspectRatio>(DefaultAspectRatio.AspectRatio1x1);
  public aspectRatio$ = this._aspectRatio as Observable<DefaultAspectRatio>;
  public _aspectRatioOptions = new BehaviorSubject(AspectRatio.getAspectRatios(DefaultAspectRatio.AspectRatio1x1));
  public aspectRatioOptions$ = this._aspectRatioOptions as Observable<AspectRatio[]>;

  public aspectRatioSelected = (aspectRatioOptions: SegmentedControlOption[]): void => {
    this.aspectRatioInverted$.once(isInverted => {
      const updatedValue = aspectRatioOptions?.find(it => it?.selected)?.value;
      if (exists(updatedValue)) {
        this._aspectRatio.next(isInverted ? 1 / updatedValue : updatedValue);
      }
    });
  };

  public showImagePreview$ = combineLatest([
    this.asset$,
    this.imgToUpload$,
    this.replaceImg$
  ]).pipe(
    map(([asset, pendingAsset, replaceImg]) => (exists(asset) || exists(pendingAsset)) && !replaceImg)
  );

  public showAspectRatioControls$ = combineLatest([
    this.assetGroup$,
    this.showImagePreview$
  ]).pipe(
    map(([ag, showImagePreview]) => ag?.isBrand && showImagePreview)
  );

  public _aspectRatioInverted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private aspectRatioInverted$ = this._aspectRatioInverted as Observable<boolean>;

  public invertAspectRatio(): void {
    combineLatest([this.aspectRatio$, this.aspectRatioInverted$]).once(([aspectRatio, isInverted]) => {
      this._aspectRatio.next(1 / aspectRatio);
      this._aspectRatioInverted.next(!isInverted);
    });
  }

  toggleReplaceImg() {
    this.replaceImg$.once(replaceImg => {
      this.resetAspectRatio();
      this._replaceImg.next(!replaceImg);
    });
  }

  resetAspectRatio() {
    this._aspectRatio.next(DefaultAspectRatio.AspectRatio1x1);
    this._aspectRatioInverted.next(false);
    this._aspectRatioOptions.next(AspectRatio.getAspectRatios(DefaultAspectRatio.AspectRatio1x1));
  }

  generateImageFile(): BudsenseFile {
    const f = new BudsenseFile();
    f.name = this.imgToUpload.name;
    f.type = MediaType.PNG;
    f.url = this.croppedImage;
    return f;
  }

  addAsset(assetLibraryType: string) {
    const lm = 'Uploading Asset to Asset Group';
    this._loadingOpts.addRequest(lm);
    this.productDomainModel.uploadAsset(this._assetGroup.getValue(), this.generateImageFile()).once(ags => {
      const assetGroup = ags?.find(ag => ag.id === this._assetGroup.getValue()?.id);
      if (exists(assetGroup)) {
        const assetGroupCopy = window.injector.Deserialize.instanceOf(AssetGroup, assetGroup);

        const asset = assetGroupCopy?.assets?.find(a => a.fileName === this.imgToUpload.name);
        if (exists(asset)) {
          const assetUniqueId = AssetGroupUtils.createAssetUniqueId(asset);
          const assetLibraryTypeMapEntry = assetGroupCopy.assetTypeMap.get(assetLibraryType);
          if (exists(assetLibraryTypeMapEntry)) {
            assetLibraryTypeMapEntry.push(assetUniqueId);
          } else {
            assetGroupCopy.assetTypeMap.set(assetLibraryType, [assetUniqueId]);
          }

          this.productDomainModel.updateAssetGroup(assetGroupCopy).subscribe({
            next: updatedAgs => {
              this._loadingOpts.removeRequest(lm);
              this.toastService.publishSuccessMessage('Asset successfully uploaded to Asset Group', 'Success');
              this.activeModal.close(updatedAgs);
            },
            error: (err: BsError) => {
              this._loadingOpts.removeRequest(lm);
              this.toastService.publishError(err);
            }
          });
        }
      } else {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishErrorMessage(
          'Asset is a duplicate of an existing asset for this group',
          'Asset is Duplicate'
        );
      }
    });
  }

  changeBackgroundWithLibraryType(type: string) {
    switch (type) {
      case AssetLibraryType.AlternateBrand:
        this._backgroundColor.next('#000000');
        break;
      default:
        this._backgroundColor.next('#FFFFFF');
        break;
    }
  }

}
