import { Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterByBrandModalViewModel } from './filter-by-brand-modal-view-model';
import { SearchWithLookAheadComponent } from '../../components/search-with-look-ahead/search-with-look-ahead.component';
import { AssetGroup } from '../../../../models/product/dto/asset-group';

@Component({
  selector: 'app-filter-by-brand-modal',
  templateUrl: './filter-by-brand-modal.component.html',
  styleUrls: ['./filter-by-brand-modal.component.scss'],
  providers: [FilterByBrandModalViewModel]
})
export class FilterByBrandModalComponent extends BaseModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: FilterByBrandModalViewModel
  ) {
    super(activeModal);
  }

  @ViewChild('brandSearch') brandSearch: SearchWithLookAheadComponent;

  setFilterBrands(filterBrands: AssetGroup[]) {
    this.viewModel.connectToSelectedFilterBrands(filterBrands);
  }

  isSelectingForFilter(selectingForFilter: boolean) {
    this.viewModel.connectToSelectingForFilter(selectingForFilter);
  }

  clearFiltersAndSearch() {
    this.viewModel.clearFilters();
    this.brandSearch.clear();
  }

}
