<div class="d-flex flex-column">
  <lib-reactive-form-group [bindTo]="specification" [mergeKey]="mergeKey">
    <lib-reactive-form-text
      [inputName]="'specificationExamples'"
      [label]="'Example Values'"
      [bindingProperty]="'values'"
      [placeholder]="'--'"
      [disabled]="true">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #specificationVariantProperty
      [inputName]="'variantProperty'"
      [label]="'BudSense Variant Property'"
      [placeholder]="'--'"
      [bindingProperty]="'variantProperty'"
      [dropdowns]="variantPropertyDropdowns$ | async"
      [disabled]="specification.ignored">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-switch
      [inputName]="'isIgnored'"
      [label]="'Is Ignored'"
      [labelOnRight]="true"
      [bindingProperty]="'ignored'"
      (valueChanged)="isIgnoredChanged($event)">
    </lib-reactive-form-switch>
  </lib-reactive-form-group>
</div>
