import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { SingleColumnOptionsViewModel } from './single-column-options-view-model';

@Component({
  selector: 'app-single-column-option-form',
  templateUrl: './single-column-options-form.component.html',
  styleUrls: ['./single-column-options-form.component.scss'],
  providers: [SingleColumnOptionsViewModel]
})
export class SingleColumnOptionsFormComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: SingleColumnOptionsViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
