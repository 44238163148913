import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditVariantModalViewModel } from '../edit-variant-modal-view-model';
import { Variant } from '../../../../../models/product/dto/variant';

@Component({
  selector: 'app-variant-usages',
  templateUrl: './variant-usages.component.html',
  styleUrls: ['./variant-usages.component.scss'],
  providers: [EditVariantModalViewModel]
})
export class VariantUsagesComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() variants: Variant[];

  constructor(
    public viewModel: EditVariantModalViewModel,
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.setupBindings();
    this.setupViews();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variants) this.viewModel.connectToVariantUsages(this.variants);
  }

}
