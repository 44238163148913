import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopulateSandboxDataModalViewModel } from './populate-sandbox-data-modal-view-model';

@Component({
  selector: 'app-populate-sandbox-data',
  templateUrl: './populate-sandbox-data-modal.component.html',
  styleUrls: ['./populate-sandbox-data-modal.component.scss'],
  providers: [PopulateSandboxDataModalViewModel]
})
export class PopulateSandboxDataModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: PopulateSandboxDataModalViewModel,
    private activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  createPopulateSandboxRequest(companyId: number) {
    this.viewModel.createPopulateSandboxRequest(companyId);
  }

}
