import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'randomString' })
export class RandomStringPipe implements PipeTransform {

  transform(value: string): string {
    return value + Math.random().toString(36).replace('.', '');
  }

}
