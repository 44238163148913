<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <app-header [header]="viewModel.addingNewBadge ? 'Add New Badge' : 'Edit Badge'"></app-header>
  <p class="sub-title">Badge Info</p>
  <lib-reactive-form-group
    #editBadgeForm
    [bindTo]="viewModel?.badgeReq"
    (canSubmit$)="canSubmitForm$.next($event)"
    (formSubmitted$)="viewModel.updateBadge($event)">
    <lib-reactive-form-text
      #badgeName
      [inputName]="'badgeName'"
      [label]="'Badge Name'"
      [placeholder]="'Enter a Badge Name'"
      [bindingProperty]="'badgeName'"
      [required]="true"></lib-reactive-form-text>

    <div class="section-container">
      <lib-reactive-form-drop-down
        #sectionDropDown
        class="section-form-drop-down"
        [inputName]="'sectionTitle'"
        [label]="'Section Name'"
        [placeholder]="'Choose a Section'"
        [bindingProperty]="'sectionTitle'"
        [dropdowns]="viewModel?.selectableBadgeSections$ | async"
        [required]="true"
        [clearable]="true"
        (valueChanged)="listenToSectionChanges($event)"></lib-reactive-form-drop-down>

      <button type="button" class="bs-button text-button add-new-section-button" (click)="viewModel.addNewSection()">
        Create New
      </button>
    </div>

    <div class="section-container">
      <lib-reactive-form-drop-down
        #subSectionDropDown
        class="section-form-drop-down"
        [inputName]="'subSectionTitle'"
        [label]="'Sub-Section Name'"
        [placeholder]="'Choose a Sub-Section'"
        [bindingProperty]="'subSectionTitle'"
        [dropdowns]="viewModel?.selectableBadgeSubSections$ | async"
        [disabled]="viewModel?.disableSubSection$ | async"
        [clearable]="true"
        [required]="true"></lib-reactive-form-drop-down>

      <button
        type="button"
        class="bs-button text-button add-new-section-button"
        [ngClass]="{ disabled: viewModel?.disableSubSection$ | async }"
        [disabled]="viewModel?.disableSubSection$ | async"
        (click)="viewModel.addNewSubSection(sectionDropDown.getMyValue())">
        Create New
      </button>
    </div>
  </lib-reactive-form-group>
  <app-upload-asset
    #editBadgeUpload
    [allowTypes]="['image']"
    [displayList]="false"
    [isHidden]="!((!(viewModel.badgeAsset$ | async) && !viewModel.imgToUpload) || viewModel.replaceImg)"
    [maxAssets]="1"
    [parentHandler]="this">
  </app-upload-asset>
  <app-asset
    *ngIf="!viewModel.imgToUpload && !viewModel.replaceImg"
    class="badge-img"
    [asset]="viewModel.badgeAsset$ | async"></app-asset>
  <img
    *ngIf="!!viewModel.imgToUpload && !viewModel.replaceImg"
    [src]="viewModel.imgToUpload.url"
    alt="badge-image"
    class="badge-img" />
  <button
    *ngIf="(!!(viewModel.badgeAsset$ | async) || viewModel.imgToUpload) && !viewModel.replaceImg"
    (click)="this.viewModel.toggleReplaceImg()"
    class="bs-button neutral-button center mt-24px mb-16px">
    Replace Image
  </button>
  <hr class="mt-none" />
  <button
    (click)="viewModel.deleteBadge()"
    [hidden]="viewModel.addingNewBadge"
    class="bs-button text-button delete-button mt-20px mb-20px">
    Delete Badge
  </button>
  <button
    (click)="this.editBadgeForm.submitForm()"
    [disabled]="!(this.canSubmit$ | async)"
    [ngClass]="{ disabled: !(this.canSubmit$ | async) }"
    class="bs-button preferred-button float-right mt-20px mb-20px">
    {{ this.viewModel.addingNewBadge ? 'Save New Badge' : 'Save Changes' }}
  </button>
  <button (click)="this.viewModel.cancel()" class="bs-button neutral-button float-right mt-20px mb-20px">Cancel</button>
</div>
