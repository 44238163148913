import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../../../../../../../../utils/date-utils';

@Pipe({
  name: 'dateTimeStringIfUnixStringElseOriginal'
})
export class DateTimeStringIfUnixStringElseOriginalPipe implements PipeTransform {

  transform(val: string): string {
    const timestamp = parseInt(val, 10);
    const isValidUnixTimestamp = val?.length === 10 && !isNaN(timestamp);
    return isValidUnixTimestamp ? DateUtils.formatUnixToDateTime(timestamp) : val;
  }

}
