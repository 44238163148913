<div [ngClass]="cardClass" class="card bs-card bs-unconfirmed-product-card">
  <div class="bs-unconfirmed-product-card-wrapper">
    <div class="bs-card-icon">
      <img [src]="'assets/icons/dark/outline/flag.svg'" alt="" />
    </div>

    <div class="bs-card-label">
      <div class="bs-card-label-title">
        {{ card.label }}
      </div>
      <div class="bs-card-label-text">
        {{ card.text }}
      </div>
    </div>
  </div>
</div>
