<div class="badge-container mb-3" (click)="badgeClicked.emit(viewModel.badge)" fxLayout="column">
  <div class="badge-img">
    <app-asset
      [asset]="viewModel?.badge?.image"
      [scaleFit]="true"
      [cachePolicy]="ClientTypes.CachePolicy.Session"
      [borderRadius]="'0.625rem'">
    </app-asset>
    <div class="badge-text block mt-8px">{{ viewModel.badge.name }}</div>
  </div>
</div>
