import { TypeDefinition } from './type-definition';

export enum StrainType {
  Indica = 'Indica',
  IndicaDominant = 'IndicaDominant',
  Sativa = 'Sativa',
  SativaDominant = 'SativaDominant',
  Hybrid = 'Hybrid',
  Blend = 'Blend',
  CBD = 'CBD',
  UNKNOWN = ''
}

export class StrainTypeType extends TypeDefinition {

  override value: StrainType;

}
