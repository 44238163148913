<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<div class="row header-padding">
  <div class="col-12">
    <div class="bs-headline">Profile</div>
  </div>
</div>
<div class="row mt-48px">
  <div class="col-12 flex-align-end">
    <div class="align-self-center inline-block">
      <div class="bs-section-title lh-80p flex-wrap-content ml-16px">
        {{ viewModel.userFullName$ | push }}
      </div>
    </div>
  </div>
</div>

<hr class="mt-28px mb-24px" />

<div *ngIf="!(viewModel.userEmailConfirmed$ | push)">
  <div class="row">
    <div class="col-4">
      <p class="bs-semi-bold f16px">Email Confirmation</p>
      <p class="bs-regular f14px">
        A confirmation code has been sent to your email address.<br />
        Enter it and press 'Confirm Email'.
      </p>
    </div>
    <div class="col-8">
      <app-warning-banner *ngIf="!(viewModel.userEmailConfirmed$ | push)" class="block mb-10px">
        You are required to confirm your email address.
      </app-warning-banner>
      <!-- Form -->
      <lib-reactive-form-group
        #emailConfirmation
        [bindTo]="viewModel.emailConfirmationReq$ | push"
        (formChanged$)="validateConfirmationCode()"
        (formSubmitted$)="emailConfirmationSubmit($event)">
        <lib-reactive-form-text
          #confirmationCodeInput
          [inputName]="'confirmationCode'"
          [label]="'Confirmation Code'"
          [placeholder]="'Enter Code Here'"
          [bindingProperty]="'confirmationCode'"
          [programmaticallyChangeValue]="code"
          [required]="true"
          [customValidators]="viewModel.emailConfirmationCodeCustomValidator">
        </lib-reactive-form-text>
      </lib-reactive-form-group>
      <div class="mt-2">
        <lib-button-primary
          class="ml-0"
          (buttonClicked)="emailConfirmation.submitForm()"
          [disabled]="!(viewModel.confirmationCodeValid$ | push)">
          Confirm Email
        </lib-button-primary>
        <lib-button-neutral (buttonClicked)="resendCode()">Resend Code</lib-button-neutral>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-4">
    <p class="bs-semi-bold f16px">Account Information</p>
  </div>
  <div class="col-8">
    <lib-reactive-form-group
      #accountData
      [bindTo]="viewModel.accountInfoReq$ | push"
      (formSubmitted$)="accountInfoFormSubmitted($event)"
      (unsavedChanges$)="viewModel.unsavedChanges = $event">
      <lib-reactive-form-column-layout [nColumns]="2">
        <lib-reactive-form-text
          [inputName]="'firstName'"
          [label]="'First Name'"
          [bindingProperty]="'firstName'"
          [required]="true"
          [placeholder]="'Bob'">
        </lib-reactive-form-text>

        <lib-reactive-form-text
          [inputName]="'lastName'"
          [label]="'Last Name'"
          [bindingProperty]="'lastName'"
          [required]="true"
          [placeholder]="'Marley'">
        </lib-reactive-form-text>
      </lib-reactive-form-column-layout>

      <lib-reactive-form-column-layout [nColumns]="2">
        <lib-reactive-form-email
          [inputName]="'email'"
          [label]="'Email'"
          [bindingProperty]="'email'"
          [required]="true"
          [placeholder]="'Enter your email'">
        </lib-reactive-form-email>
      </lib-reactive-form-column-layout>
    </lib-reactive-form-group>

    <div class="mt-2 button-container">
      <lib-button-primary
        (buttonClicked)="accountData.submitForm()"
        [disabled]="this.accountData.disableSubmit$ | push">
        Save Changes
      </lib-button-primary>
      <lib-button-neutral (buttonClicked)="openChangePasswordModal()">Change Password</lib-button-neutral>
    </div>
  </div>
</div>
