import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { SnoozeAssetGroupModalViewModel } from './snooze-asset-group-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-snooze-asset-group-modal',
  templateUrl: './snooze-asset-group-modal.component.html',
  styleUrls: ['./snooze-asset-group-modal.component.scss'],
  providers: [SnoozeAssetGroupModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnoozeAssetGroupModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: SnoozeAssetGroupModalViewModel,
    activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
