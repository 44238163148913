import { Deserializable } from '../../protocols/deserializable';
import { Selectable } from '../../protocols/selectable';
import { Asset } from '../../image/dto/asset';
import { InventoryProvider } from '../../../utils/inventory-provider-type';
import { HasId } from '../../protocols/has-id';
import { UsePurpose } from '../../../utils/use-purpose-type';
import { SortUtils } from '../../../utils/sort-utils';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { exists } from '../../../functions/exists';

export class Location implements Deserializable, Selectable, LookAheadItem, HasId {

  public companyId: number;
  public id: number = null;
  public provider: InventoryProvider;
  public providerId: string;
  public archived: boolean;
  public name: string = null;
  public address: string = null;
  public city: string = null;
  public state: string;
  public stateCode: string = null;
  public country: string;
  public countryCode: string = null;
  public timezone: string;
  public lastModified: number;
  public logoId: string;
  public altLogoId: string;
  public logo: Asset;
  public altLogo: Asset;
  public recAPIKey: string;
  public medAPIKey: string;
  public username: string;
  public usePurpose: UsePurpose;
  public geoHashKey: number;
  public geoHash: number;
  public lat: number;
  public lng: number;

  static readonly incompleteLocationGroupName = 'Incomplete Locations';

  /**
   * Builds a JavaScript object of key value pairs.
   * The key being the province name.
   * The value being the locations in that province.
   * I used a JavaScript Object instead of a Map because one has to do key/value pairs
   * to decode the data while they iterate over the data.
   * Why? Because the keys are unknown to the programmer, and one always has to loop through the keys.
   * Therefore, no need for the overhead of a Map, because we'll never use the methods attached to the structure.
   */
  static groupLocationsByProvince(
    [idsToGroup, locations]: [number[], Location[]]
  ): { [provinceName: string]: Location[] } {
    const locationsGroupedByProvince = {};
    idsToGroup?.forEach(locationId => {
      const location = locations?.find(l => l?.id === locationId);
      const province = location?.state || Location.incompleteLocationGroupName;
      if (!locationsGroupedByProvince[province]) locationsGroupedByProvince[province] = [];
      locationsGroupedByProvince[province].push(location);
    });
    Object.keys(locationsGroupedByProvince)?.forEach(province => {
      locationsGroupedByProvince[province].sort(SortUtils.sortLocationByNameAsc);
    });
    return locationsGroupedByProvince;
  }

  static addedOrRemoved(location: Location, originalIds: number[], updatedIds: number[]): string {
    const originalHasLocation = originalIds?.includes(location?.id);
    const updatedHasLocation = updatedIds?.includes(location?.id);
    if (originalHasLocation && !updatedHasLocation) return 'Removed';
    if (!originalHasLocation && updatedHasLocation) return 'Added';
    return undefined;
  }

  public onDeserialize() {
    this.logo = window.injector.Deserialize.instanceOf(Asset, this.logo);
    this.altLogo = window.injector.Deserialize.instanceOf(Asset, this.altLogo);
  }

  getSelectionTitle(): any {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getFullAddress(): string {
    return `${this.address}, ${this.getCityProv()}`;
  }

  getCityProv(): string {
    const cityProv: string[] = [];
    if (!!this.city) {
      cityProv.push(this.city);
    }
    if (!!this.stateCode) {
      cityProv.push(this.stateCode);
    }
    return cityProv.join(', ');
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  isMissingMapInfo(): boolean {
    return !exists(this.lat) && !exists(this.lng);
  }

  isMissingAddressInfo(): boolean {
    return !exists(this.address)
      || !exists(this.city)
      || !exists(this.stateCode)
      || !exists(this.state);
  }

  isMissingLocationInfo(): boolean {
    return this.isMissingAddressInfo() || this.isMissingMapInfo();
  }

  getUniqueIdentifier(): string {
    return `
      ${this.companyId}
      ${this.id}
      ${this.provider}
      ${this.providerId}
      ${this.archived}
      ${this.name}
      ${this.address}
      ${this.city}
      ${this.state}
      ${this.stateCode}
      ${this.country}
      ${this.countryCode}
      ${this.timezone}
      ${this.logoId}
      ${this.altLogoId}
      ${this.logo}
      ${this.altLogo}
      ${this.recAPIKey}
      ${this.medAPIKey}
      ${this.username}
      ${this.usePurpose}
    `;
  }

  getId(): string {
    return this.id.toString();
  }

}
