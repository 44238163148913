import { Cachable } from '../protocols/cachable';
import { exists } from '../../functions/exists';

export class CacheVersion implements Cachable {

    public static readonly key: string = 'cache-version';

    cachedTime: number;
    version: string;

    constructor(version?: string) {
        if (exists(version)) {
            this.version = version;
        }
    }

    cacheExpirySeconds(): number {
        return 0;
    }

    cacheKey(): string {
        return CacheVersion.key;
    }

    isExpired(): boolean {
        return false;
    }

    onDeserialize() {
    }

}
