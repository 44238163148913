import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callMethod'
})
export class CallMethodPipe implements PipeTransform {

  transform<T>(value: T, methodName: string): any | null {
    if (!value || !methodName) return null;
    return value?.[methodName]?.();
  }

}
