import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { SearchUtils } from '../../../../utils/search-utils';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { SortUtils } from '../../../../utils/sort-utils';

@Injectable()
export class PrivateCompanyThemeModalViewModel extends BaseModalViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public companies$ = this.companyDomainModel.companies$;

  private _selectedCompanyIds: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public selectedCompanyIds$ = this._selectedCompanyIds as Observable<string[]>;
  public connectToSelectedCompanyIds = (companyIds: number[]) => {
    this._selectedCompanyIds.next(companyIds?.toStringArray());
  };

  private _companiesUsingTheme = new BehaviorSubject<string[]>(null);
  public companiesUsingTheme$ = this._companiesUsingTheme as Observable<string[]>;
  public connectToCompaniesUsingTheme = (companyIds: number[]) => {
    this._companiesUsingTheme.next(companyIds?.toStringArray());
  };

  protected _searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchText$ = this._searchText.pipe(debounceTime(50), distinctUntilChanged());
  public setSearchText = (searchText: string): void => this._searchText.next(searchText);

  /**
   * Will not affect the original companies$ data order since it's a shallow copy.
   */
  protected shallowCopyOfCompaniesSortedByName$ = this.companies$.pipe(
    map(companies => companies?.shallowCopy()?.sort(SortUtils.sortCompaniesByNameAsc))
  );
  public companiesFilteredBySearchString$ = combineLatest([
    this.shallowCopyOfCompaniesSortedByName$,
    this.searchText$.pipe(distinctUntilChanged())
  ]).pipe(
    debounceTime(100),
    map(([companies, searchText]) => {
      return searchText?.length > 1
        ? SearchUtils.searchCompanyAndLocations(companies, searchText)
        : companies;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public clearFilters() {
    this._selectedCompanyIds.next([]);
    this._searchText.next('');
  }

  public setSelectedValue(selected: boolean, cId: number) {
    this.selectedCompanyIds$.once(selectedCompanyIds => {
      const companyId = cId?.toString();
      const updateSelectedCompanyIds = selectedCompanyIds?.shallowCopy() || [];
      if (selected) {
        // Append the companyId and ensure list is unique
        updateSelectedCompanyIds.push(companyId);
        this._selectedCompanyIds.next(updateSelectedCompanyIds.unique());
      } else {
        // Remove companyId if exists in list
        const removeIndex = updateSelectedCompanyIds.indexOf(companyId);
        if (removeIndex !== -1) {
          updateSelectedCompanyIds.splice(removeIndex, 1);
          this._selectedCompanyIds.next(updateSelectedCompanyIds);
        }
      }
    });
  }

  public finishedSelection() {
    this.selectedCompanyIds$.once(selectedCompanyIdStrings => {
      const companyIds = selectedCompanyIdStrings?.map(companyIdString => parseInt(companyIdString, 10)) || [];
      this.activeModal.close(companyIds);
    });
  }

}
