import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { SelectableBadgeSection } from '../../../../../models/product/shared/selectable-badge-section';
import { InputFormReq } from '../../../../../models/shared/input-form-req';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';
import { ToastService } from '../../../../../services/toast-service';
import { HydratedVariantBadge } from '../../../../../models/product/dto/hydrated-variant-badge';
import { BsError } from '../../../../../models/shared/bs-error';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ConfirmationModalComponent } from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ModalUtils } from '../../../../../utils/modal-utils';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EditBadgeSectionViewModel extends BaseModalViewModel {

  public handlingSection: boolean = false;
  public addingNewSectionOrSubSection: boolean = false;
  protected override _loadingOpts = new BehaviorSubject(EditBadgeSectionViewModel.getLoadingSpinner());

  public oldSectionTitle: string = '';
  public oldSubSectionTitle: string = '';
  public formReq: InputFormReq = new InputFormReq();
  public selectableBadgeSections: SelectableBadgeSection[] = [];

  constructor(
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
    this.selectableBadgeSections = this.generateSectionDropdownOptions();
  }

  private static getLoadingSpinner(): LoadingOptions {
    const opts =  LoadingOptions.default();
    opts.loadingText = '';
    opts.isLoading = false;
    opts.fullscreen = false;
    return opts;
  }

  private generateSectionDropdownOptions(): SelectableBadgeSection[] {
    const array = [];
    this.productDomainModel.curatedBadgeSections$.pipe(map(sections => {
        sections.forEach(section => {
          const sec = new SelectableBadgeSection();
          sec.name = section.title.charAt(0).toUpperCase() + section.title.slice(1);
          array.push(sec);
        });
      })).subscribe();
    return array.sort((a, b) => a.name.localeCompare(b.name));
  }

  save() {
    const lm = this.handlingSection ? 'Updating Section' : 'Updating Sub Section';
    const badgesToUpdate = this.getSectionBadges();
    const updatedBadges = [];
    badgesToUpdate.forEach(badge => {
      if (this.handlingSection) {
        badge.category = this.formReq.sectionTitle.toLowerCase();
      } else {
        badge.subCategory = this.formReq.subSectionTitle.toLowerCase();
      }
      updatedBadges.push(badge.getVariantBadge());
    });
    this._loadingOpts.addRequest(lm);
    this.productDomainModel.updateCuratedBadges(updatedBadges, undefined).subscribe(_ => {
      this._loadingOpts.removeRequest(lm);
      if (this.handlingSection) {
        this.toastService.publishSuccessMessage('Section successfully updated.', 'Update Section');
      } else {
        this.toastService.publishSuccessMessage('Sub Section successfully updated.', 'Update Sub Section');
      }
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
    });
  }

  deleteSection() {
    // Note - unable to use modalService.openGenericConfirmationModal in this scenario because
    // it causes a circular dependency
    const opts = new ConfirmationOptions();
    if (this.handlingSection) {
      opts.title = `Delete Section and Badges?`;
      opts.bodyText = 'Are you sure you want to delete this sub-section?. All badges in this sub-section will ' + '' +
        'deleted, and removed from any existing menus. This action cannot be undone and is not recommended.';
      opts.continueText = 'Delete Sub Section';
    } else {
      opts.title = 'Delete Sub Section and Badges';
      opts.bodyText = 'Are you sure you want to delete this section?. All badges in this section will deleted, and ' +
        ' removed from any existing menus. This action cannot be undone and is not recommended.';
      opts.continueText = 'Delete Section';
    }
    opts.cancelText = 'Back';
    const lm = this.handlingSection ? 'Deleting Section' : 'Deleting Sub Section';
    this._loadingOpts.addRequest(lm);

    const modalRef = this.ngbModal.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) {
        this.productDomainModel.deleteCuratedBadges(this.getSectionBadges().map(b => b.id)).subscribe(_ => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Section successfully removed.', 'Delete Section');
        }, (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
        });
      }
    });
  }

  getSectionBadges(): HydratedVariantBadge[] {
    let sectionBadges;
    this.productDomainModel.curatedBadges$.pipe(
      map(badges => sectionBadges = badges.filter(b => {
        if (this.handlingSection) {
          return b.category === this.oldSectionTitle;
        }
        return b.category === this.oldSectionTitle && b.subCategory === this.oldSubSectionTitle;
      }))
    ).subscribe();
    return sectionBadges;
  }

}
