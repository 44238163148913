import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { AccountAPI } from '../api/account-api';
import { ImageAPI } from '../api/image-api';
import { CacheService } from '../services/cache-service';
import { ToastService } from '../services/toast-service';
import { BehaviorSubject, combineLatest, map, Observable, of, Subject, throwError } from 'rxjs';
import { HydratedAdminUser } from '../models/account/dto/hydrated-admin-user';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { exists } from '../functions/exists';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { SignOutRequest } from '../models/account/requests/sign-out-request';
import { catchError, concatMap, delay, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Session } from '../models/account/dto/session';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { ForgotPasswordRequest } from '../models/account/requests/forgot-password-request';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { RefreshSessionRequest } from '../models/account/requests/refresh-session-request';
import { NavigationService } from '../services/navigation.service';
import { AdminUser } from '../models/account/dto/admin-user';
import { CreateUserRequest } from '../models/account/requests/create-user-request';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';

@Injectable({ providedIn: 'root' })
export class UserDomainModel extends BaseDomainModel {

  constructor(
    private accountAPI: AccountAPI,
    private imageAPI: ImageAPI,
    private cacheService: CacheService,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
    super();
    this.setupBindings();
  }

  private _refreshingSessionUser = new BehaviorSubject<boolean>(false);
  public refreshingSessionUser$ = this._refreshingSessionUser as Observable<boolean>;
  connectToRefreshingSessionUser = (refreshing: boolean) => this._refreshingSessionUser.next(refreshing);

  public destroySession: Subject<boolean> = new Subject<boolean>();

  private _deadSession = new BehaviorSubject<boolean>(true);
  public deadSession$ = this._deadSession as Observable<boolean>;

  // Users
  private _adminUsers: BehaviorSubject<AdminUser[]> = new BehaviorSubject<AdminUser[]>(null);
  public adminUsers$ = this._adminUsers as Observable<AdminUser[]>;

  private _user = new BehaviorSubject<HydratedAdminUser | null>(null);
  public user$ = this._user as Observable<HydratedAdminUser | null>;
  public setUser(u: HydratedAdminUser, rememberMe?: boolean): void {
    this.user$.once(user => {
      u.rememberMe = (rememberMe !== undefined ? rememberMe : user?.rememberMe) ?? false;
      if (u.rememberMe) {
        this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u, true);
      }
      this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u);
      this._user.next(u);
    });
  }

  public userSession$ = this.user$.pipe(map(user => user?.session));
  public validSession$ = this.userSession$.pipe(map(session => session?.validSession()), distinctUntilChanged());
  public userId$ = this.user$.pipe(map(user => user?.userId), distinctUntilChanged());
  public userEmail$ = this.user$.pipe(map(user => user?.email), distinctUntilChanged());
  public authToken$ = this.user$.pipe(map(user => user?.session?.accessToken), distinctUntilChanged());
  public refreshToken$ = this.user$.pipe(map(user => user?.session?.refreshToken), distinctUntilChanged());
  public challengeToken$ = this.user$.pipe(map(user => user?.session?.challenge?.authSession), distinctUntilChanged());
  public isCompanyAdmin$ = this.user$.pipe(map(u => u?.isCompanyAdmin), distinctUntilChanged());

  public isAuthenticated(forceRefresh: boolean = false): Observable<Session | null> {
    return combineLatest([
      this.user$,
      this.refreshingSessionUser$,
    ]).pipe(
      filter(([, refreshingUser]) => !refreshingUser),
      concatMap(([user, refreshingUser]) => {
        if (!user) {
          user = this.getCachedUser();
        }
        if (user?.session?.validSession() && !refreshingUser && !forceRefresh) {
          return of(user.session);
        } else {
          return this.getRefreshSessionReq(user).pipe(
            take(1),
            switchMap(req => {
              if (!req) {
                // No refreshSession req in cache
                return of(null);
              }
              this.connectToRefreshingSessionUser(true);
              return this.accountAPI.RefreshSession(req).pipe(
                tap(apiReturnedUser => {
                  this.setUser(apiReturnedUser,  user.rememberMe);
                }),
                delay(1000),
                switchMap(u => {
                  this.connectToRefreshingSessionUser(false);
                  return of(u.session);
                }),
                catchError(() => {
                  this.destroySession.next(true);
                  this.connectToRefreshingSessionUser(false);
                  this.toastService.publishErrorMessage(
                    'For security reasons, your token has expired. Please log in again.',
                    'You have been signed out.'
                  );
                  return this.signOut().pipe(
                    map(() => null)
                  );
                })
              );
            })
          );
        }
      }),
      take(1),
      takeUntil(this.destroySession)
    );
  }

  public signOut(): Observable<any> {
    return this.getSignOutReq().pipe(
      take(1),
      switchMap(req => this.accountAPI.SignOut(req)),
      map((r) => {
        // Clear the user session from the persistent cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this.destroySession.next(true);
        return r;
      }),
      catchError(err => {
        // If sign out request fails, we want to kill all caches any destroy session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this.destroySession.next(true);
        return throwError(() => err);
      })
    );
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.SignIn(req).pipe(
      map((user) => {
        // Clear the existing user from the cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.setUser(user, req.rememberMe);
        return user;
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.accountAPI.GetForgotPasswordCode(req.email).pipe(
      map((deliveryDetails) => {
        this.setDeliveryDetails(deliveryDetails);
        return deliveryDetails;
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ResetForgottenPassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedAdminUser> {
    // Populate request from current session
    return combineLatest([this.userId$, this.challengeToken$]).pipe(
      take(1),
      switchMap(([userId, challengeToken]) => {
        req.userId = userId;
        req.session = challengeToken;
        return this.accountAPI.SignInNewPassword(req);
      }),
      tap((user) => this.setUser(user))
    );
  }

  private setupBindings() {
    this.destroySession.notNull().subscribeWhileAlive({
      owner: this,
      next: () => {
        this.connectToRefreshingSessionUser(false);
        this._deadSession.next(true);
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this._user.next(null);
        this.navigationService.signIn();
      }
    });
  }

  public changePassword(req: ChangePasswordRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ChangePassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public updateUser(req: HydratedAdminUser): Observable<HydratedAdminUser> {
    return this.accountAPI.UpdateUser(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public getRefreshSessionReq(user?: HydratedAdminUser): Observable<RefreshSessionRequest | null> {
    return combineLatest([this.userId$, this.refreshToken$]).pipe(
      take(1),
      map(([userId, refreshToken]) => {
        if (user) {
          userId = user?.userId;
          refreshToken = user?.session?.refreshToken;
        }
        const req = new RefreshSessionRequest(userId, refreshToken);
        return (req?.refreshToken && req?.userId) ? req : null;
      })
    );
  }

  public confirmEmail(req: ConfirmCodeRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.ConfirmCode(req).pipe(
      map((user) => {
        this.setUser(user, true);
        return user;
      })
    );
  }

  public resendEmailConfirmationCode(email: string): Observable<CodeDeliveryDetails> {
    return this.authToken$.pipe(
      take(1),
      switchMap((token) => this.accountAPI.ResendCode(email, token))
    );
  }

  public getSignOutReq(): Observable<SignOutRequest | null> {
    return combineLatest([this.userId$, this.authToken$]).pipe(
      take(1),
      map(([userId, authToken]) => {
        const req = new SignOutRequest(userId, authToken);
        return (req?.accessToken && req?.userId) ? req : null;
      })
    );
  }

  public getCachedUser(): HydratedAdminUser {
    const user = this.cacheService.getCachedObject(HydratedAdminUser, DefaultCacheKey.SessionUser)
      ?? this.cacheService.getCachedObject(HydratedAdminUser, DefaultCacheKey.SessionUser, true);
    this._deadSession.next(false);
    if (exists(user)) {
      this._user.next(user);
    }
    return user;
  }

  private setDeliveryDetails(deliveryDetails: CodeDeliveryDetails): void {
    const spoofedUser = new HydratedAdminUser();
    spoofedUser.codeDeliveryDetails = [deliveryDetails];
    this.setUser(spoofedUser);
  }

  // Admin Functions

  static getAdminCompanyId(): number {
    return -1;
  }

  public getUsers(): Observable<HydratedAdminUser[]> {
    return this.accountAPI.AdminGetUsers().pipe(
      map(users => {
        this._adminUsers.next(users);
        return users;
      })
    );
  }

  public createUser(req: CreateUserRequest): Observable<HydratedAdminUser> {
    return this.accountAPI.AdminCreateUser(req).pipe(
      tap(newUser => this.addUserToList(newUser))
    );
  }

  private addUserToList(user: AdminUser) {
    this.adminUsers$.once(users => {
      users.push(user);
      this._adminUsers.next(users);
    });
  }

  public deleteUser(user: AdminUser): Observable<string> {
    return this.accountAPI.AdminDeleteUser(user).pipe(
      tap(_ => this.removeUserFromList(user))
    );
  }

  private removeUserFromList(user: AdminUser) {
    this.adminUsers$.once(users => {
      const i = users.findIndex(u => u.userId === user.userId);
      if (i > -1) {
        users.splice(i, 1);
      }
      this._adminUsers.next(users);
    });
  }

  public updateAdminUser(user: AdminUser): Observable<AdminUser> {
    return this.accountAPI.AdminUpdateUser(user).pipe(
      tap(updatedUser => this.replaceUserInList(updatedUser))
    );
  }

  private replaceUserInList(user: AdminUser) {
    this.adminUsers$.once(users => {
      const i = users.findIndex(u => u.userId === user.userId);
      if (i > -1) {
        users[i] = user;
      }
      this._adminUsers.next(users);
    });
  }

  public resendConfirmationEmail(email: string): Observable<CodeDeliveryDetails> {
    return this.accountAPI.ResendCode(email);
  }

}
