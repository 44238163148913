import { DefaultStackSizeType } from './default-stack-size-type';

export enum DefaultPrintLabelSize {
  DefaultSize_CustomLabel2x4 = 'DefaultSize_CustomLabel2x4'
}

export class DefaultPrintLabelSizeType extends DefaultStackSizeType {

  override value : DefaultPrintLabelSize;

}
