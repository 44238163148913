import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Injectable, Type } from '@angular/core';
import { Deserializable } from '../models/protocols/deserializable';
import { APIRequestType } from '../models/enum/shared/api-request-type.enum';
import { StringifyUtils } from '../utils/stringify-utils';
import { expand, map, reduce } from 'rxjs/operators';
import { ApiPagination } from '../models/shared/api-pagination';
import { Pagable } from '../models/protocols/pagable';

export const DEFAULT_PAGINATION_COUNT = 1000;

@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getObj<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    additionalHeaders: any = null
  ): Observable<T> {
    return this.http.get<T>(url, {headers: additionalHeaders}).pipe(
      map(r => window.injector.Deserialize.instanceOf(respObjectType, r))
    );
  }

  public getUntypedObj(url: string, additionalHeaders: any = null): any {
    return this.http.get(url, {headers: additionalHeaders});
  }

  public getArr<T extends Deserializable>(
    respObjectType: new () => T,
    url: string,
    additionalHeaders: any = null
  ): Observable<T[]> {
    return this.http.get<T[]>(url, {headers: additionalHeaders}).pipe(
      map(r => r?.map(rr => window.injector.Deserialize.instanceOf(respObjectType, rr)) as T[])
    );
  }

  public recursiveGet<T extends Pagable>(
    respObjectType: new () => T,
    url: string,
    additionalHeaders: any = null,
    pagination?: ApiPagination
  ): Observable<T[]> {
    let paginationUrl: string;
    const existingParmas = url.includes('?');
    if (pagination) {
      pagination.count = pagination.count || DEFAULT_PAGINATION_COUNT;
      pagination.startKey = pagination.startKey || '';
      paginationUrl = `${url}${existingParmas ? '&' : '?'}Count=${pagination.count}&StartKey=${pagination.startKey}`;
    } else {
      pagination = new ApiPagination(DEFAULT_PAGINATION_COUNT);
      paginationUrl = `${url}${existingParmas ? '&' : '?'}Count=${pagination.count}`;
    }
    return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders).pipe(
      expand((res) => {
        if (res && res.length === pagination.count) {
          pagination.startKey = (res.last() as Pagable).getStartKey();
          const parameters = url.includes('?');
          paginationUrl = `${url}${parameters ? '&' : '?'}Count=${pagination.count}&StartKey=${pagination.startKey}`;
          return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders);
        } else {
          return EMPTY;
        }
      }),
      reduce((acc, val) => {
        acc.push(...val);
        return acc;
      })
    );
  }

  public getBlob<Blob>(url: string): Observable<Blob> {
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json'
    });
  }

  public postStr(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
    return this.http.request<string>(APIRequestType.POST, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public postObj<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window?.injector?.Deserialize?.instanceOf(ReturnType, r))
    );
  }

  public postObjGetArray<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    return this.http.post<T[]>(url, JSON.stringify(payload?.onSerialize?.() ?? payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ReturnType, rr)) as T[])
    );
  }

  public postArr<T extends Deserializable, U extends Deserializable>(
    ReturnType: Type<T>,
    url,
    payload: U[],
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    const serializedPayload = payload?.map(obj => obj?.onSerialize?.() ?? obj);
    return this.http.post<T[]>(url, JSON.stringify(serializedPayload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window?.injector?.Deserialize?.instanceOf(ReturnType, rr)) as T[])
    );
  }

  public postMapArr<T extends Deserializable>(
    respObjectType: new () => T,
    url,
    payload,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<Map<string, T[]>> {
    const serializedPayload = payload?.map(obj => obj?.onSerialize?.() ?? obj);
    return this.http.post<Map<string, T[]>>(url, JSON.stringify(serializedPayload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).pipe(
      map(r => window.injector.Deserialize.typedArrayMapOf(respObjectType, r))
    );
  }

  public deleteStr(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
    return this.http.request<string>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public deleteObj<T extends Deserializable>(
    respObjectType: new () => T,
    url,
    payload,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T> {
    return this.http.request<T>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(
      map(r => window.injector.Deserialize.instanceOf(respObjectType, r))
    );
  }

  public deleteArr<T extends Deserializable>(
    respObjectType: new () => T,
    url,
    payload,
    additionalHeaders: any = null,
    responseType: string = 'json'
  ): Observable<T[]> {
    return this.http.request<T[]>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).pipe(
      map(r => r.map(rr => window.injector.Deserialize.instanceOf(respObjectType, rr)) as T[])
    );
  }

}

