import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseModal } from '../../../../../../../../models/base/base-modal';
import { SyncDataJob } from '../../../../../../../../models/automation/sync-data-job';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSyncJobViewModel } from './review-sync-job-modal-view-model';

@Component({
  selector: 'app-review-sync-job',
  templateUrl: './review-sync-job-modal.component.html',
  styleUrls: ['./review-sync-job-modal.component.scss'],
  providers: [ReviewSyncJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewSyncJobModalComponent extends BaseModal {

  constructor(
    public viewModel: ReviewSyncJobViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  @Input() syncDataJob: SyncDataJob;

}
