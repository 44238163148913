import { Component, ViewChild } from '@angular/core';
import { AddAssetModalViewModel } from './add-asset-modal-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { UploadImageInterface } from '../../components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { UploadAssetComponent } from '../../components/upload-asset/upload-asset.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-add-asset-modal',
  templateUrl: './add-asset-modal.component.html',
  styleUrls: ['./add-asset-modal.component.scss'],
  providers: [AddAssetModalViewModel]
})
export class AddAssetModalComponent extends BaseModalComponent implements UploadImageInterface {

  constructor(
    public viewModel: AddAssetModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  @ViewChild('editAssetUpload') assetUpload: UploadAssetComponent;
  @ViewChild('assetLibraryTypeSelection') assetLibraryTypeDropdown: ReactiveFormDropDownComponent;
  public cropEvent: boolean = false;

  transform = {
    scale: 1,
    rotate: 0,
    flipH: false,
    flipV: false,
  };

  override cancel() {
    this.assetUpload.clear();
    this.activeModal.close(null);
  }

  override setupBindings(): void {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) { this.cancel(); }
    });
    this.pushSub(s);
  }

  setAssetGroup(as: AssetGroup) {
    this.viewModel?.connectToAssetGroup(as);
  }

  fileList(f: BudsenseFile[], id?: number) {
    this.viewModel.toggleReplaceImg();
    if (f.length > 0) {
      this.viewModel.assetReplaced$.next(true);
      this.viewModel.imgToUpload = f[0];
      this.viewModel.imgToUpload$.next(f[0]);
    } else {
      this.viewModel.imgToUpload = undefined;
      this.viewModel.imgToUpload$.next(undefined);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    // The image cropper library always fires an event on init, so we always want to ignore the first event
    if (!this.cropEvent) {
      this.cropEvent = true;
    } else {
      this.viewModel.assetTransformed$.next(true);
    }
    this.viewModel.croppedImage = event.base64;
  }

  scaleImageDown() {
    this.viewModel.assetTransformed$.next(true);
    let updatedScale = this.transform.scale - 0.1;
    if (updatedScale < 0.1) {
      updatedScale = 0.1;
    }
    this.transform = {
      scale: updatedScale,
      rotate: 0,
      flipH: false,
      flipV: false,
    };
  }

  scaleImageUp() {
    this.viewModel.assetTransformed$.next(true);
    let updatedScale = this.transform.scale + 0.1;
    if (updatedScale > 3) {
      updatedScale = 3;
    }
    this.transform = {
      scale: updatedScale,
      rotate: 0,
      flipH: false,
      flipV: false,
    };
  }

  addAsset() {
    const libraryType = this.assetLibraryTypeDropdown.getMyValue();
    this.viewModel.addAsset(libraryType);
  }

}
