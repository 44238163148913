import { Subject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Takes care of destroy lifecycle, so you don't need to add 'implements OnDestroy'.
 * If you need to override ngOnDestroy functionality, just override destroy() function.
 *
 * example of override inside parent class:
 *
 * destroy() {
 *     super.destroy();
 *     // Your override code goes below
 *     resizeObserver?.disconnect();
 * }
 */
@Injectable()
export class Subscribable implements OnDestroy {

  onDestroy = new Subject<boolean>();
  timerSubscriptions: Subscription[] = [];
  imageSubscriptions: Map<string, Subscription[]> = new Map<string, Subscription[]>();
  subscriptions: Subscription[] = [];

  pushSub(s: Subscription): void {
    this.subscriptions.push(s);
  }

  pushImageSub(key: string, s: Subscription): void {
    const existingSubs = this.imageSubscriptions.get(key);
    if (existingSubs) {
      existingSubs.push(s);
    } else {
      this.imageSubscriptions.set(key, [s]);
    }
  }

  pushTimerSub(s: Subscription): void {
    this.timerSubscriptions.push(s);
  }

  destroySubs(): void {
    this.subscriptions?.forEach(subscription => subscription?.unsubscribe());
    this.subscriptions = [];
  }

  destroyImageSubs(): void {
    this.imageSubscriptions.forEach((val) => {
      val.forEach(subscription => subscription?.unsubscribe());
    });
  }

  destroyImageSub(key: string): void {
    const sub = this.imageSubscriptions.get(key);
    if (!!sub) {
      sub.forEach(subscription => subscription?.unsubscribe());
      this.imageSubscriptions.delete(key);
    }
  }

  destroy(): void {
    this.onDestroy.next(true);
    this.destroySubs();
    this.destroyImageSubs();
    this.destroyAllTimerSubs();
  }

  destroyAllTimerSubs(): void {
    this.timerSubscriptions.forEach(t => t?.unsubscribe());
  }

  /**
   * Lifecycle hook called by angular framework when extended class dies.
   */
  ngOnDestroy(): void {
    this.destroy();
  }

}
