<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header no-border">
      <div class="modal-title">
        {{ viewModel.isEditing ? 'Edit Parent Group' : 'Create Parent Group' }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <lib-reactive-form-group
      #categoryForm
      (formSubmitted$)="viewModel.save()"
      [bindTo]="
        viewModel.isEditing ? (viewModel.existingSmartFilterCategory | async) : viewModel?.createNewSmartFilterCategory
      ">
      <lib-reactive-form-text
        [inputName]="'name'"
        [label]="'Name'"
        [placeholder]="'Parent Group Name'"
        [inlineLabel]="false"
        [disabled]="false"
        [required]="true"
        [bindingProperty]="'name'">
      </lib-reactive-form-text>
    </lib-reactive-form-group>
    <hr class="light-divider mb-2" />
    <div class="image-title-row">
      <div class="bs-bold f16px">Image</div>
      <button
        *ngIf="(!!(viewModel.categoryImgAsset$ | async) || viewModel.imgToUpload) && !viewModel.replaceImg"
        (click)="this.viewModel.removeImgClicked()"
        class="bs-button text-button no-padding red-text">
        Delete
      </button>
    </div>
    <app-upload-asset
      [allowTypes]="['image']"
      [displayList]="false"
      [isHidden]="!((!(viewModel.categoryImgAsset$ | async) && !viewModel.imgToUpload) || viewModel.replaceImg)"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
    <div class="img-container">
      <app-asset
        [scaleFit]="true"
        *ngIf="!viewModel.imgToUpload && (viewModel.categoryImgAsset$ | async) && !viewModel.replaceImg"
        class="category-img mb-5"
        [asset]="viewModel.categoryImgAsset$ | async"></app-asset>
      <img
        *ngIf="!!viewModel.imgToUpload && !viewModel.replaceImg"
        [src]="viewModel.imgToUpload.url"
        alt="image"
        class="category-img" />
    </div>
  </div>
  <div class="px-4" *ngIf="(viewModel.smartFiltersForCategory$ | async)?.length > 0">
    <div class="bs-bold f16px mb-4">Curated Smart Filters</div>
    <ng-container *ngFor="let filter of viewModel.smartFiltersForCategory$ | async">
      <div class="py-3">
        <div class="f12px bs-medium">
          {{ filter.name }}
        </div>
      </div>
      <hr class="light-divider" />
    </ng-container>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end w-100 pt-3">
    <button
      *ngIf="viewModel.isEditing"
      (click)="viewModel.showDeleteModal()"
      class="bs-button text-button no-padding red-text me-auto ms-0">
      Delete Parent Group
    </button>
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="categoryForm.submitForm()"
      [disabled]="categoryForm.hasErrors$ | async"
      [class.disabled]="categoryForm.hasErrors$ | async"
      class="bs-button preferred-button">
      Save
    </button>
  </div>
</div>
