import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditVariantFormComponent } from '../../forms/edit-variant-form/edit-variant-form.component';
import { BaseModal } from '../../../../models/base/base-modal';
import { EditVariantModalViewModel } from './edit-variant-modal-view-model';

@Component({
  selector: 'app-edit-variant-modal',
  templateUrl: './edit-variant-modal.component.html',
  styleUrls: ['./edit-variant-modal.component.scss'],
  providers: [EditVariantModalViewModel]
})
export class EditVariantModalComponent extends BaseModal implements AfterViewInit {

  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('editVariantFormComponent') editVariantFormComponent: EditVariantFormComponent;

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: EditVariantModalViewModel,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override setupBindings() {
    const s = this.viewModel.dismissModalSubject.subscribe((close) => {
      if (close) {
        this.close(close);
      }
    });
    this.pushSub(s);
  }

  saveChanges() {
    this.viewModel.saveVariant(this.editVariantFormComponent.getFormResult());
  }

  seeUsages() {
    this.viewModel.showUsages$.next(!this.viewModel.showUsages$.getValue());
  }

}
