import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, defer, Observable, of, Subject } from 'rxjs';
import { SmartFilter } from '../models/automation/smart-filter';
import { debounceTime, delay, map, mapTo, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { SelectableSmartFilter } from '../models/automation/protocols/selectable-smart-filter';
import { SmartFilterGrouping } from '../models/automation/smart-filter-grouping';
import { SortUtils } from '../utils/sort-utils';
import { AutomationAPI } from '../api/automation-api';
import { SmartFilterCategory } from '../models/automation/smart-filter-category';
import '../utils/subscription.extensions';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { BudsenseFile } from '../models/shared/budsense-file';
import { ImageAPI } from '../api/image-api';
import { StringUtils } from '../utils/string-utils';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { Asset } from '../models/image/dto/asset';
import { Label } from '../models/shared/label';

@Injectable({
  providedIn: 'root'
})
export class SmartFiltersDomainModel extends BaseDomainModel {

  // Curated
  private _curatedSmartFilters = new BehaviorSubject<HydratedSmartFilter[]>([]);
  public curatedSmartFilters$ = this._curatedSmartFilters.pipe(
    map(filters => filters?.sort(SortUtils.sortHydratedSmartFiltersByCategory))
  );
  public curatedSmartFilterIds$ = this.curatedSmartFilters$.pipe(map(filters => filters?.map(filter => filter?.id)));
  public curatedGroupedSmartFilters$ = this.curatedSmartFilters$.pipe(
    map(smartFilters => this.getGroupedSmartFilters(smartFilters))
  );

  public smartFilterCategories$ = new BehaviorSubject<SmartFilterCategory[]>([]);
  public refreshSmartFilterCategoriesSubject = new BehaviorSubject<void>(undefined);
  private fetchSmartFilterCategories = this.refreshSmartFilterCategoriesSubject
    .pipe(switchMapTo(this.automationAPI.getSmartFilterCategories()))
    .subscribeWhileAlive({
      owner: this,
      next: s => this.smartFilterCategories$.next(s?.sort((a, b) => a.name.localeCompare(b.name)))
    });

  private _fetchAllSmartFilters = new Subject<void>();
  private fetchSmartFilters = this._fetchAllSmartFilters
    .pipe(debounceTime(5000))
    .subscribeWhileAlive({
      owner: this,
      next: () => this.getCuratedSmartFilters()
    });

  // Labels

  public systemLabels$ = window?.types?.menuLabels$.pipe(
    map(labelKeys => {
      return labelKeys.map(key => new Label('white', 'black', key?.name, key?.value?.toLowerCase()));
    }),
    tap(labels => labels.sort(SortUtils.sortLabelsForLabelPicker))
  );

  constructor(
    private automationAPI: AutomationAPI,
    private imageAPI: ImageAPI,
  ) {
    super();
    this.setupBindings();
  }

  setupBindings() {
  }

  private getGroupedSmartFilters(filters: HydratedSmartFilter[]): SelectableSmartFilter[] {
    const groups: SmartFilterGrouping[] = [];
    const noGroup: SelectableSmartFilter[] = filters?.filter(filter => !filter?.getStandardizedCategoryName()) ?? [];
    const categories = filters
      ?.map(filter => filter?.getStandardizedCategoryName() ?? '')
      ?.unique()
      ?.filter(category => !!category);
    categories?.forEach(category => {
      const group = new SmartFilterGrouping(category);
      group.addToGroup(filters?.filter(filter => filter?.getStandardizedCategoryName() === category) ?? []);
      groups.push(group);
    });
    return [
      ...groups.sort(SortUtils.sortSelectableSmartFilterByName),
      ...noGroup.sort(SortUtils.sortSelectableSmartFilterByName)
    ];
  }

  getCuratedSmartFilters() {
    this.automationAPI.getCuratedSmartFilters().pipe(take(1)).subscribe(s => this._curatedSmartFilters.next(s));
  }

  createSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    return this.automationAPI.createSmartFilter(smartFilter).pipe(
      map(filter => {
        const updated = this._curatedSmartFilters?.getValue()?.shallowCopy();
        updated.push(filter);
        this._curatedSmartFilters.next(updated);
        return filter;
      })
    );
  }

  updateSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    return this.automationAPI.updateSmartFilter(smartFilter).pipe(
      map(filter => {
        const updated = this._curatedSmartFilters?.getValue()?.shallowCopy();
        const i = updated.indexOf(updated.find(v => v.id === filter.id));
        if (i > -1) {
          updated.splice(i, 1);
          updated.push(filter);
          this._curatedSmartFilters.next(updated);
        }
        return filter;
      })
    );
  }

  deleteSmartFilter(smartFilter: SmartFilter): Observable<string> {
    return this.automationAPI.deleteSmartFilter(smartFilter).pipe(
      map(s => {
        const updated = this._curatedSmartFilters?.getValue()?.shallowCopy();
        const i = updated.indexOf(updated.find(v => v.id === smartFilter.id));
        if (i > -1) {
          updated.splice(i, 1);
          this._curatedSmartFilters.next(updated);
        }
        return s;
      })
    );
  }

  saveSmartFilterCategory(
    smartFilterCategory: SmartFilterCategory,
    imgToUpload: BudsenseFile,
    deleteAsset: Asset
  ): Observable<SmartFilterCategory> {
    const saveObs$ = !!smartFilterCategory.id
      ? defer(() => this.updateSmartFilterCategory(smartFilterCategory))
      : defer(() => this.createSmartFilterCategory(smartFilterCategory));
    return saveObs$.pipe(
      switchMap(savedSmartFilterCategory => {
        if (imgToUpload) {
          return this.uploadSmartFilterCategoryImage(savedSmartFilterCategory, imgToUpload);
        } else if (!!deleteAsset) {
          return this.imageAPI.deleteAsset(deleteAsset.id, deleteAsset.md5Hash).pipe(mapTo(savedSmartFilterCategory));
        } else {
          return of(savedSmartFilterCategory);
        }
      }),
      tap(() => this.refreshSmartFilterCategoriesSubject.next())
    );
  }

  private createSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<SmartFilterCategory> {
    return this.automationAPI.createSmartFilterCategory(smartFilterCategory);
  }

  private updateSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<SmartFilterCategory> {
    return this.automationAPI.updateSmartFilterCategory(smartFilterCategory);
  }

  public updateSmartFilterCategoryPriorities(
    smartFilterCategories: SmartFilterCategory[]
  ): Observable<SmartFilterCategory[]> {
    return this.automationAPI.updateSmartFilterCategoryPriorities(smartFilterCategories).pipe(
      tap(sfCategories => this.smartFilterCategories$.next(sfCategories))
    );
  }

  deleteSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<string> {
    return this.automationAPI.deleteSmartFilterCategory(smartFilterCategory).pipe(
      map(s => {
        const existing = this.smartFilterCategories$.getValue();
        const i = existing.indexOf(existing.find(v => v.id === smartFilterCategory.id));
        if (i > -1) {
          existing.splice(i, 1);
          this.smartFilterCategories$.next(existing);
        }
        return s;
      })
    );
  }

  private uploadSmartFilterCategoryImage(
    smartFilterCategory: SmartFilterCategory,
    imgToUpload: BudsenseFile
  ): Observable<SmartFilterCategory> {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(imgToUpload.name);
    uploadReq.mediaClass = UploadFilePath.SmartFilterImagePath;
    uploadReq.mediaType = imgToUpload.getMediaType();
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('CompanyId', '-1');
    uploadReq.metadata.set('Id', smartFilterCategory.id);

    return this.imageAPI.GenerateUploadUrl(uploadReq).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, imgToUpload.url.toString(), uploadReq.fileName)
          .pipe(
            // provide delay based on file size
            delay(imgToUpload.getUploadDelay()),
            switchMap(() => of(smartFilterCategory)),
          );
        })
      );
  }

  fetchAllSmartFiltersAfterDelay(): void {
    this._fetchAllSmartFilters.next();
  }

}
