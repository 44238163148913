<div class="sticky-header-container">
  <div class="modal-header no-bottom-border location-select-header">
    <div class="modal-title">Select {{ viewModel?.requestTypeDisplayText$ | async }} Locations Sync</div>
    <div class="text-link clear-button" (click)="clearSelection()">Clear</div>
  </div>
  <div class="location-search">
    <app-search-with-look-ahead
      #searchBar
      [placeHolder]="'Search by location name'"
      (searchText)="viewModel.setSearchText($event)"></app-search-with-look-ahead>
  </div>
</div>

<div class="modal-body sticky-body-container location-selection-body">
  <div fxLayout="row" fxLayoutAlign="left" fxLayoutGap="1rem">
    <app-group-selection
      class="select-all-checkbox"
      [selection]="viewModel.groupedLocationIds$ | async"
      [selectedIds]="viewModel.selectedLocationIds$ | async"
      (bulkAdd)="viewModel.bulkAddLocationIds($event)"
      (bulkRemove)="viewModel.bulkRemoveLocationIds($event)"></app-group-selection>
    <div *ngIf="(viewModel.nSelectedLocationIds$ | async) > 0" class="select-all-text checked-count">
      ({{ viewModel.nSelectedLocationIds$ | async }})
    </div>
    <div class="select-all-text">Select all locations</div>
  </div>
  <hr class="no-margin" />

  <ng-container *ngFor="let l of viewModel.locationsFilteredBySearchText$ | async">
    <app-smart-sync-location-selection
      [selection]="l"
      [selectedIds]="viewModel.selectedLocationIds$ | async"
      (add)="viewModel.addLocationId($event)"
      (remove)="viewModel.removeLocationId($event)"></app-smart-sync-location-selection>
  </ng-container>
</div>

<div class="sticky-footer-container modal-footer-flex-end">
  <lib-button-neutral class="cancel-button" (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
  <lib-button-primary (buttonClicked)="syncLocations()" [disabled]="!(viewModel?.canSubmit$ | async)">
    Perform {{ viewModel?.requestTypeDisplayText$ | async }} Sync
  </lib-button-primary>
</div>
