import { Pipe, PipeTransform } from '@angular/core';
import { exists } from '../../../../../../../../functions/exists';

@Pipe({
  name: 'decodeSyncResultLineItem'
})
export class DecodeSyncResultLineItemPipe implements PipeTransform {

  transform(value: [string, number]): unknown {
    const [name, data] = value || [undefined, undefined];
    if (exists(name) && (data !== undefined)) {
      return `${name}: ${data}`;
    } else if (exists(name) && (data === undefined)) {
      return name;
    } else {
      return undefined;
    }
  }

}
