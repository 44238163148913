import { Pipe, PipeTransform } from '@angular/core';
import type { DisplayAttributeGroup } from '../../../models/product/dto/display-attribute-group';
import type { DisplayAttributeGroupDetails } from '../../../models/product/dto/display-attribute-group-details';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantsForDisplayAttributeGroupDetails'
})
export class GetVariantsForDisplayAttributeGroupDetailsPipe implements PipeTransform {

  transform(group: DisplayAttributeGroup, details: DisplayAttributeGroupDetails): Variant[] | null {
    return group?.getVariantsForDisplayAttributeGroupDetails(details) || null;
  }

}
