import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';

@Injectable()
export class BadgePickerViewModel extends BaseViewModel {

  public curatedBadgeSections$ = this.productDomainModel.curatedBadgeSections$.pipe(
    map((badgeSections) =>
      badgeSections?.sort((a, b) => {
      return a.title.localeCompare(b.title);
    }))
  );

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
    super();
  }

}
