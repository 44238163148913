<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Create New Section Blueprint</div>
    </div>
  </div>

  <div class="sticky-body-container">
    <lib-reactive-form-group
      #sectionCreationForm
      [bindTo]="viewModel.newSectionBlueprint$ | async"
      (canSubmit$)="viewModel?.connectToCanSubmit($event)"
      (formSubmitted$)="viewModel.createNewSectionBlueprint()">
      <lib-reactive-form-drop-down
        [inputName]="'sectionType'"
        [label]="'Section Type'"
        [placeholder]="'Select the type of section to create'"
        [bindingProperty]="'sectionType'"
        [dropdowns]="viewModel.sectionTypeBlueprintsDropdowns$ | async"
        [required]="true"></lib-reactive-form-drop-down>

      <lib-reactive-form-text
        [inputName]="'sectionTitle'"
        [label]="'Section Title'"
        [placeholder]="'Enter a title for this section'"
        [bindingProperty]="'title'"
        [required]="true"></lib-reactive-form-text>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral (buttonClicked)="cancel()" class="me-2"> Cancel </lib-button-neutral>
    <lib-button-primary
      (buttonClicked)="sectionCreationForm.submitForm()"
      [disabled]="!(viewModel.canSubmit$ | async)"
      class="ms-2">
      Add Section
    </lib-button-primary>
  </div>
</div>
