import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageInterface } from '../../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';

@Component({
  selector: 'app-upload-theme-image-modal',
  templateUrl: './upload-theme-image-modal.component.html',
  styleUrls: ['./upload-theme-image-modal.component.scss']
})
export class UploadThemeImageModalComponent extends BaseModalComponent implements UploadImageInterface {

  portrait: boolean;

  files: BudsenseFile[] = [];
  constructor(
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  submit() {
    this.activeModel.close(this.files);
  }

  fileList(f: BudsenseFile[], id?: number) {
    this.files = f;
  }

}
