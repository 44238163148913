<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ reorderOptions.title }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mb-24px">
    <!-- Modal Body -->
    <div class="reorder-subtitle" [hidden]="reorderOptions.subTitle === ''">
      {{ reorderOptions.subTitle }}
    </div>
    <div class="reorder-body">
      {{ reorderOptions.bodyText }}
    </div>
    <!--  Reorder list  -->
    <app-reorder-list [items]="items" [orderPrefix]="reorderOptions.orderPrefix"> </app-reorder-list>
    <!--  Modal Divider  -->
    <div class="modal-divider"></div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <button (click)="cancel()" [ngClass]="reorderOptions.cancelButtonClass" class="bs-button">
      {{ reorderOptions.cancelText }}
    </button>
    <button
      (click)="continue()"
      [disabled]="!orderHasChanged()"
      [ngClass]="reorderOptions.confirmButtonClass"
      [class.disabled]="!orderHasChanged()"
      class="bs-button">
      {{ reorderOptions.confirmText }}
    </button>
  </div>
</div>
