import { TypeDefinition } from './type-definition';
import { SegmentedControlOption } from '../models/shared/stylesheet/segmented-control-option';

export enum UsePurpose {
  ALL = 'All',
  MEDICAL = 'Medical',
  RECREATION = 'Recreation',
}

export class UsePurposeType extends TypeDefinition implements SegmentedControlOption {

  override value: UsePurpose;

  selected: boolean = false;
  disabled: boolean = false;
  title: string;
  tooltipText: string;

  override onDeserialize() {
    super.onDeserialize();
    this.title = this.getAbbreviatedName();
  }

  private getAbbreviatedName(): string {
    return this.name.slice(0, 3);
  }

}
