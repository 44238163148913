import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { switchMap, take } from 'rxjs/operators';
import { SearchUtils } from '../../../../utils/search-utils';
import { CreateAssetGroupRequest } from '../../../../models/product/requests/create-asset-group-request';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { BsError } from '../../../../models/shared/bs-error';

@Injectable()
export class CreateBrandModalViewModel extends BaseModalViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public brands$ = this.productDomainModel.brands$;

  private _newBrand: BehaviorSubject<AssetGroup> = new BehaviorSubject<AssetGroup>(new AssetGroup());
  public newBrand$ = this._newBrand as Observable<AssetGroup>;

  private _canSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;

  private _isPotentialDuplicate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPotentialDuplicate$ = this._isPotentialDuplicate as Observable<boolean>;

  private _duplicateBrands: BehaviorSubject<AssetGroup[]> = new BehaviorSubject<AssetGroup[]>([]);
  public duplicateBrands$ = this._duplicateBrands as Observable<AssetGroup[]>;
  private _createDuplicate: Subject<void>;

  connectToCanSubmit = (canSubmit: boolean) => this._canSubmit.next(canSubmit);

  checkIfBrandIsPotentialDuplicate(newBrand: AssetGroup) {
    const lm = 'Checking for duplicates';
    this.brands$.pipe(take(1)).subscribe(brands => {
      this._loadingOpts.addRequest(lm);
      const potentialDuplicates = SearchUtils.searchBrand(brands, newBrand.groupName);
      const potentialDuplicatesFound = potentialDuplicates.length > 0;
      this._loadingOpts.removeRequest(lm);
      if (potentialDuplicatesFound) {
        this._isPotentialDuplicate.next(potentialDuplicatesFound);
        this._duplicateBrands.next(potentialDuplicates);
      } else {
        this.createBrand();
      }
    });
  }

  goBackToCreate() {
    this._isPotentialDuplicate.next(false);
  }

  createBrand() {
    const lm = 'Creating New Brand';
    this._loadingOpts.addRequest(lm);
    this.newBrand$.pipe(
      switchMap(newBrand => {
        const createAssetGroupRequest = new CreateAssetGroupRequest(newBrand);
        createAssetGroupRequest.isBrand = true;
        return this.productDomainModel.createAssetGroup(createAssetGroupRequest);
      })
    ).subscribe(ags => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Your brand was created', 'Success');
      this.activeModal.close(ags);
    }, (err: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(err);
    });
  }

}
