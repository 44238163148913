import { Component, Input } from '@angular/core';
import { PillItem } from '../../../../../models/shared/stylesheet/pill-item';

@Component({
  selector: 'app-pill-container',
  templateUrl: './pill-container.component.html',
  styleUrls: ['./pill-container.component.scss']
})
export class PillContainerComponent {

  @Input() pills: PillItem[] = [];

}
