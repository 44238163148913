import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AddEditSmartFilterViewModel } from './add-edit-smart-filter-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';

@Component({
  selector: 'app-add-edit-smart-filter-modal',
  templateUrl: './add-edit-smart-filter-modal.component.html',
  styleUrls: ['./add-edit-smart-filter-modal.component.scss'],
  providers: [AddEditSmartFilterViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditSmartFilterModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: AddEditSmartFilterViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

}
