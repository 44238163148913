import { Deserializable } from '../../protocols/deserializable';

export class UpdateUserInfoRequest implements Deserializable {

  firstName: string = '';
  lastName: string = '';
  email: string = '';

  onDeserialize() {
  }

}
