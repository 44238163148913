<div #container fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1rem" class="location-selection-container">
  <app-checkbox-only
    class="location-checkbox"
    [checked]="selected$ | async"
    (valueChanged)="clicked($event)"></app-checkbox-only>
  <div fxFlex fxLayout="column" fxLayoutAlign="center">
    <div class="location-name">{{ name$ | async }}</div>
    <div class="location-address-info">{{ address$ | async }}</div>
    <div class="location-address-info">{{ cityProv$ | async }}</div>
  </div>
</div>
<hr class="no-margin" />
