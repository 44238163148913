import { Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Observable, throwError } from 'rxjs';
import { InventoryProviderSpecification } from '../models/company/dto/inventory-provider-specification';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';

@Injectable({
  providedIn: 'root'
})
export class ProviderSpecificationApi implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  serviceName = 'Provider Specifications';

  // Get Provider Specifications
  getProviderSpecifications(companyId: number): Observable<InventoryProviderSpecification[]> {
    const url = Endpoints.GetProviderSpecifications(companyId);
    return this.apiClient.getArr<InventoryProviderSpecification>(InventoryProviderSpecification, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetProviderSpecifications', err));
        return throwError(err);
      })
    );
  }

  // Update Provider Specifications
  updateProviderSpecifications(
    companyId: number,
    specifications: InventoryProviderSpecification[]
  ): Observable<InventoryProviderSpecification[]> {
    const url = Endpoints.UpdateProviderSpecifications(companyId);
    return this.apiClient.postArr(InventoryProviderSpecification, url, specifications).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateProviderSpecifications', err));
        return throwError(err);
      })
    );
  }

}
