<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header border-0">
      <div class="modal-title">Add Preview Image</div>
      <div class="modal-subtitle">
        {{ portrait ? 'Portrait' : 'Landscape' }}
      </div>
    </div>
  </div>

  <div class="sticky-body-container">
    <app-upload-asset [allowTypes]="['image']" [displayList]="true" [isHidden]="false" [parentHandler]="this">
    </app-upload-asset>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end w-100">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button [class.disabled]="files?.length === 0" (click)="submit()" class="bs-button preferred-button me-0">
      Save
    </button>
  </div>
</div>
