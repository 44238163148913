import { Deserializable } from '../../protocols/deserializable';
import { CodeDeliveryDetails } from './code-delivery-details';
import { Session } from './session';
import { AdminUser } from './admin-user';
import { Asset } from '../../image/dto/asset';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from 'src/app/utils/date-utils';
import { DefaultCacheKey } from '../../enum/shared/default-cache-key.enum';

export class HydratedAdminUser extends AdminUser implements Cachable, Deserializable {

  public profilePicture: Asset;
  public codeDeliveryDetails: CodeDeliveryDetails[];
  public session: Session;

  rememberMe: boolean = false;
  cachedTime: number;

  public override onDeserialize() {
    super.onDeserialize();
    this.profilePicture = window.injector.Deserialize.instanceOf(Asset, this.profilePicture);
    this.codeDeliveryDetails = window.injector.Deserialize.arrayOf(CodeDeliveryDetails, this.codeDeliveryDetails);
    this.session = window.injector.Deserialize.instanceOf(Session, this.session);
  }

  public user(): AdminUser {
    return window.injector.Deserialize.instanceOf(AdminUser, this);
  }

  public profilePictureExists(): boolean {
    return this?.hasProfilePicture && !this?.profilePicture.isEmpty();
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneWeek();
  }

  cacheKey(...params: any): string {
    return DefaultCacheKey.SessionUser;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

}
