import { LabelViewModel } from '../label/label-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FlagLabelViewModel extends LabelViewModel {

  private _flagHeight = new BehaviorSubject<number>(24.1895);
  public flagHeight$ = this._flagHeight as Observable<number>;
  public connectToFlagHeight = (height: number) => this._flagHeight.next(height);

  public flagTriangleHeight$ = combineLatest([
    this.flagHeight$,
    this.labelBackgroundColor$
  ]).pipe(
    map(([height, color]) => `${height / 2}px solid ${color}`),
  );

  public flagTriangleWidth$ = this.flagHeight$.pipe(
    map(height => `${0.3 * height}px solid transparent`),
  );

}
