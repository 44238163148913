import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeSyncResultLineItem'
})
export class DecodeSyncResultLineItemPipe implements PipeTransform {

  transform(value: [string, number]): unknown {
    const [name, data] = value || [undefined, undefined];
    if (!!name && (data !== undefined)) {
      return `${name}: ${data}`;
    } else if (!!name && (data === undefined)) {
      return name;
    } else {
      return undefined;
    }
  }

}
