import { defer, EMPTY, Observable, PartialObserver, Subscription } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LoadingOptions } from '../models/shared/loading-options';
import { Subscribable } from '../models/base/subscribable';

interface LivingObserver<T> {
  owner: Subscribable;
  closed?: boolean;
  next?: (value: T) => void;
  error?: (err: any) => void;
  complete?: () => void;
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    notNull(): Observable<T>;
    firstNotNull(): Observable<T>;
    once(run?: PartialObserver<T> | ((data: T) => void)): Subscription;
    subscribeWhileAlive(observer: LivingObserver<T>): Subscription;
  }
}

// noinspection SpellCheckingInspection
/**
 * Custom operator. Extends rxjs's iif operator.
 * Allows you to pass in observable pipes for all parameters.
 *
 * @param condition$ - The observable to emit true or false, which determines which observable to emit.
 * @param trueResult$ - The observable to emit if the condition$ observable emits true.
 * @param falseResult$ - The observable to emit if the condition$ observable emits false.
 *
 * Example
 * const condition$ = of(true);
 * const trueResult$ = of('positive');
 * const falseResult$ = of('negative');
 * const result$ = iiif(condition$, trueResult$, falseResult$);
 * result$.subscribe(); // 'positive'
 */
export function iiif<T = never, F = never>(
  condition$: Observable<boolean> = EMPTY,
  trueResult$: Observable<T> = EMPTY,
  falseResult$: Observable<F> = EMPTY
): Observable<T|F> {
  return defer(() => (condition$ || EMPTY).pipe(switchMap(condition => (condition ? trueResult$ : falseResult$))));
}

Observable.prototype.notNull = function(): Observable<any> {
  return this.pipe(filter(x => x !== null && x !== undefined));
};

Observable.prototype.firstNotNull = function(): Observable<any> {
  return this.pipe(filter(x => x !== null && x !== undefined), take(1));
};

Observable.prototype.subscribeWhileAlive = function<T>(observer: LivingObserver<T>): Subscription {
  const emptyObserver = !observer?.next && !observer?.error && !observer?.complete;
  const typedObserver = emptyObserver ? undefined : observer as PartialObserver<T>;
  return this.pipe(takeUntil(observer.owner.onDestroy)).subscribe(typedObserver);
};

Observable.prototype.once = function<T>(observerOrNext?: PartialObserver<T> | ((value: T) => void)): Subscription {
  return this.pipe(take(1)).subscribe(observerOrNext);
};

export function indicate<T>(loadingOpts: LoadingOptions, message: string): (source: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> => source$.pipe(
    prepare(() => loadingOpts.addRequest(message)),
    finalize(() => loadingOpts.removeRequest(message)),
  );
}

export function indicateOnNext<T>(
  loadingOpts: LoadingOptions,
  message: string
): (source: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> => source$.pipe(
    prepare(() => loadingOpts.addRequest(message)),
    tap(() => loadingOpts.removeRequest(message)),
    finalize(() => loadingOpts.removeRequest(message)),
  );
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source$;
  });
}
