import { BaseViewModel } from '../../../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { InventoryProviderSpecification } from '../../../../../../../../../models/company/dto/inventory-provider-specification';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../../../../../functions/exists';

@Injectable()
export class ProviderSpecificationsAccordionViewModel extends BaseViewModel {

  private _providerSpecification = new BehaviorSubject<InventoryProviderSpecification>(null);
  public providerSpecification$ = this._providerSpecification as Observable<InventoryProviderSpecification>;

  private _isIgnored = new BehaviorSubject<boolean>(false);
  public isIgnored$ = this._isIgnored as Observable<boolean>;

  connectToProviderSpecification = (providerSpecification: InventoryProviderSpecification) => {
    if (exists(providerSpecification)) {
      this._providerSpecification.next(providerSpecification);
      this._isIgnored.next(providerSpecification.ignored);
    }
  };
  connectToIsIgnored = (isIgnored: boolean) => {
    this._isIgnored.next(isIgnored);
  };

  public displayName$ = this.providerSpecification$.pipe(
    map(spec => spec?.displayName ?? ''),
  );

  public showExclamationCircle$ = combineLatest([
    this.providerSpecification$,
    this.isIgnored$
  ]).pipe(
    map(([spec, isIgnored]) => !spec.variantProperty && !isIgnored)
  );

}
