import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { SectionBlueprint } from '../../../../../models/menu/dto/section-blueprint';
import { BsError } from '../../../../../models/shared/bs-error';

@Injectable()
export class CreateSectionBlueprintModalViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private activeModal: NgbActiveModal,
    public toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _canSubmit = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;

  private _newSectionBlueprint = new BehaviorSubject<SectionBlueprint>(SectionBlueprint.newSectionBlueprint());
  public newSectionBlueprint$ = this._newSectionBlueprint as Observable<SectionBlueprint>;

  public sectionTypeBlueprintsDropdowns$ = window.types.sectionTypes$.pipe(
    map(sectionTypes => {
      return sectionTypes?.filter(sectionType => sectionType?.supportsBlueprintCreation());
    })
  );

  connectToCanSubmit = (canSubmit: boolean) => this._canSubmit.next(canSubmit);

  createNewSectionBlueprint() {
    const lm = 'Creating Section Blueprint';
    this.newSectionBlueprint$.pipe(
      switchMap(newSectionBlueprint => {
        this._loadingOpts.addRequest(lm);
        return this.menuDomainModel.createSectionBlueprint(newSectionBlueprint);
      }),
      take(1)
    ).subscribe(sectionBlueprints => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Created new section blueprint', 'Success');
      this.activeModal.close(sectionBlueprints);
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
    });
  }

}
