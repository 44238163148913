import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { CreateCompanyRequest } from '../models/company/requests/create-company-request';
import { CacheService } from '../services/cache-service';
import { CompanyAPI } from '../api/company-api';
import { BehaviorSubject, forkJoin, Observable, combineLatest } from 'rxjs';
import { Company } from '../models/company/dto/company';
import { Location } from '../models/company/dto/location';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ProductAPI } from '../api/product-api';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { ProviderSpecificationApi } from '../api/provider-specification-api';
import { InventoryProviderSpecification } from '../models/company/dto/inventory-provider-specification';
import { DistinctUtils } from '../utils/distinct-utils';
import { CompanyFeatures } from '../models/company/dto/company-features';
import { AutomationAPI } from '../api/automation-api';
import { PopulateSandboxRequest } from '../models/account/requests/populate-sandbox-request';
import { UserDomainModel } from './user-domain-model';
import { exists } from '../functions/exists';

@Injectable({
  providedIn: 'root'
})
export class CompanyDomainModel extends BaseDomainModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private companyAPI: CompanyAPI,
    private cacheService: CacheService,
    private productAPI: ProductAPI,
    private providerSpecificationAPI: ProviderSpecificationApi,
    private automationAPI: AutomationAPI,
  ) {
    super();
    this.setupBindings();
  }

  private _companies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>(null);
  public companies$ = this._companies as Observable<Company[]>;
  private _selectedCompanyId = new BehaviorSubject<number>(null);
  public selectedCompanyId$ = this._selectedCompanyId as Observable<number>;
  public selectedCompany$ = combineLatest([this.companies$.notNull(), this.selectedCompanyId$.notNull()]).pipe(
    map(([companies, selectedCompanyId]) => {
      return companies.find(c => c.id === selectedCompanyId);
    }),
  );

  private _selectedCompanyInventoryProviderSpecifications: BehaviorSubject<InventoryProviderSpecification[]>
    = new BehaviorSubject<InventoryProviderSpecification[]>(null);
  public selectedCompanyInventoryProviderSpecifications$ = this._selectedCompanyInventoryProviderSpecifications.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
  );
  private providerSpecificationMech = this.selectedCompanyId$.notNull().pipe(
    distinctUntilChanged(),
    switchMap(companyId => this.getProviderSpecifications(companyId)),
  ).subscribeWhileAlive({
    owner: this,
    next: ips => this._selectedCompanyInventoryProviderSpecifications.next(ips)
  });

  seededCompanyId: number = null;

  setupBindings() {
    const validSession$ = this.userDomainModel.user$.pipe(
      map(user => user?.session?.validSession()),
      distinctUntilChanged()
    );
    const companiesFetched$ = this.companies$.pipe(
      map(companies => exists(companies)),
      distinctUntilChanged()
    );
    combineLatest([validSession$, companiesFetched$]).pipe(
      map(([validSession, fetched]) => validSession && !fetched)
    ).subscribeWhileAlive({
      owner: this,
      next: (fetch) => fetch && this.adminGetCompanies().once()
    });
  }

  createCompany(company: CreateCompanyRequest): Observable<Company> {
    return this.companyAPI.createCompany(company).pipe(
      map(c => {
        const oldValues = this._companies.getValue() || [];
        oldValues.push(c);
        this._companies.next(oldValues);
        return c;
      })
    );
  }

  createLocation(locations: Location[]): Observable<Company> {
    if (locations.length === 1) {
      return this.companyAPI.createLocation(locations[0]).pipe(
        map((company) => {
          this.replaceExistingCompany(company);
          return company;
        })
      );
    } else {
      const created = locations.map(l => this.companyAPI.createLocation(l));
      return forkJoin(created).pipe(map((companies) => {
          let mostRecentCompany = null;
          if (companies.length > 0) {
            mostRecentCompany = companies.reduce((prev, current) => {
              return ((prev.locations.length > current.locations.length) ? prev : current);
            });
            this.replaceExistingCompany(mostRecentCompany);
          }
          return mostRecentCompany;
        }));
    }
  }

  public updateLocation(location: Location): Observable<Location> {
    return this.companyAPI.updateCompanyLocation(location).pipe(
      tap(updatedLocation => this.replaceExistingLocation(updatedLocation))
    );
  }

  replaceExistingCompany(newCompany: Company) {
    this.companies$.once(companies => {
      const companiesCopy = companies?.deepCopy() ?? [];
      const companyIndex = companiesCopy?.findIndex(c => c.id === newCompany.id);
      if (companyIndex > -1) {
        companiesCopy.splice(companyIndex, 1, newCompany);
        this._companies.next(companiesCopy);
      }
    });
  }

  replaceExistingLocation(updatedLocation: Location) {
    this.companies$.once(companies => {
      const companiesCopy = companies?.deepCopy() ?? [];
      const company = companiesCopy?.find(comp => comp?.id === updatedLocation?.companyId);
      if (!!company) {
        const locations = company?.locations;
        const locationIndex = locations?.findIndex(loc => loc?.id === updatedLocation?.id);
        if (locationIndex > -1) {
          locations.splice(locationIndex, 1);
          locations.push(updatedLocation);
          company.locations = locations;
          this.replaceExistingCompany(company);
        }
      }
    });
  }

  adminGetCompany(id: string): Observable<Company> {
    return this.companyAPI.adminGetCompanies(id).pipe(
      map(companies => companies?.firstOrNull())
    );
  }

  adminGetCompanies(): Observable<Company[]> {
    return this.companyAPI.adminGetCompanies().pipe(
      tap(companies => this.setCompanies(companies))
    );
  }

  updateCompanyFeatures(companyFeatures: CompanyFeatures): Observable<CompanyFeatures> {
    return this.companyAPI.updateCompanyFeatures(companyFeatures).pipe(
      map(features => {
        this.handleCompanyFeaturesUpdate(features);
        return features;
      })
    );
  }

  syncDisplayNames(id: number): Observable<CompanyConfiguration> {
    return this.productAPI.SyncDisplayNames(id.toString()).pipe(
      map(config => {
        this.updateCompanyConfig(config);
        return config;
      })
    );
  }

  syncCompanySmartFilters(companyId: number, locationIds: string[]): Observable<any> {
    return this.automationAPI.syncCompanySmartFilters(companyId, locationIds);
  }

  syncCompanySmartDisplayAttributes(companyId: number, locationIds: string[]): Observable<any> {
    return this.automationAPI.syncSmartDisplayAttributes(companyId, locationIds);
  }

  refreshCompany(companyId: number) {
    this.adminGetCompany(String(companyId)).subscribe(company => {
      const existing = this._companies.value?.shallowCopy() || [];
      const i = existing.findIndex(c => c?.id === company?.id);
      i > -1 ? existing.splice(i, 1, company) : existing.push(company);
      this._companies.next(existing);
    });
  }

  updateCompanyConfig(config: CompanyConfiguration) {
    this.companies$.once(companies => {
      const companiesCopy = companies?.deepCopy() ?? [];
      companiesCopy.find(company => company.id === config.companyId).configuration = config;
      this._companies.next(companiesCopy);
    });
  }

  private handleCompanyFeaturesUpdate(features: CompanyFeatures) {
    this.companies$.once(companies => {
      const companiesCopy = companies?.deepCopy() ?? [];
      companiesCopy.find(company => company.id === features.companyId).features = features;
      this._companies.next(companiesCopy);
    });
  }

  getProviderSpecifications(companyId: number): Observable<InventoryProviderSpecification[]> {
    return this.providerSpecificationAPI.getProviderSpecifications(companyId);
  }

  updateProviderSpecifications(
    companyId: number,
    providerSpecifications: InventoryProviderSpecification[]
  ): Observable<InventoryProviderSpecification[]> {
    return this.providerSpecificationAPI.updateProviderSpecifications(companyId, providerSpecifications);
  }

  populateSandboxData(populateSandboxRequest: PopulateSandboxRequest): Observable<string> {
    return this.companyAPI.populateSandboxData(populateSandboxRequest);
  }

  private setCompanies(companies: Company[]) {
    companies = companies?.sort((a, b) => a.name.localeCompare(b.name));
    this._companies.next(companies);
  }

  connectToSelectedCompanyId = (companyId: number) => this._selectedCompanyId.next(companyId);
  connectToSelectedCompanyInventoryProviderSpecifications = (ips: InventoryProviderSpecification[]) => {
    this._selectedCompanyInventoryProviderSpecifications.next(ips);
  };

}
