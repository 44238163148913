import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-edit-asset-preview-card',
  templateUrl: './edit-asset-preview-card.component.html',
  styleUrls: ['./edit-asset-preview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAssetPreviewCardComponent {

  @Input() card: Card;
  @Input() cardClass: string;
  @Output() cardPressed:  EventEmitter<Card> = new EventEmitter<Card>();

  public imgStyleOverrides = {
    position: 'relative',
    width: '9.5rem',
    height: '9.5rem',
    'z-index': '0'
  };

  handleCardPressed() {
    this.cardPressed.emit(this.card);
  }

}
