import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseReactiveFormComponent, ReactiveFormDropDownComponent, ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, Observable } from 'rxjs';
import { EditVariantFormObject } from './edit-variant-form-object';
import { debounceTime, startWith, take, tap } from 'rxjs/operators';
import { ProductType } from '../../../../utils/product-type-definition';
import { VariantTypeDefinition } from '../../../../utils/variant-type-definition';
import { EditVariantFormViewModel } from './edit-variant-form-view-model';

@Component({
  selector: 'app-edit-variant-form',
  templateUrl: './edit-variant-form.component.html',
  styleUrls: ['./edit-variant-form.component.scss'],
  providers: [EditVariantFormViewModel]
})
export class EditVariantFormComponent extends BaseReactiveFormComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('editVariantForm') editVariantForm!: ReactiveFormGroupComponent;
  @ViewChild('productTypeDropdown') productTypeDropdown!: ReactiveFormDropDownComponent;
  @ViewChild('variantTypeDropdown') variantTypeDropdown!: ReactiveFormDropDownComponent;

  @Input() override mergeKey: string;
  @Input() incompleteVariantFlow: boolean;
  @Input() override bindTo: EditVariantFormObject;

  public unitOfMeasureOptions$ = window.types.unitOfMeasures$;
  public productTypeOptions$ = window.types.productTypes$;
  private _variantTypeOptions = new BehaviorSubject<VariantTypeDefinition[]>([]);
  public variantTypeOptions$ = this._variantTypeOptions.pipe(debounceTime(1));
  private _variantTypeDisabled = new BehaviorSubject<boolean>(true);
  public variantTypeDisabled$ = this._variantTypeDisabled.pipe(debounceTime(1));
  public classificationTypeOptions$ = window.types.strainTypes$;
  public cannabisUnitOfMeasureOptions$ = window.types.cannabisUnitOfMeasures$;
  private _isAccessoryProduct = new BehaviorSubject<boolean>(true);
  public isAccessoryProduct$ = this._isAccessoryProduct.pipe(startWith(true), debounceTime(1));
  public isIncompleteProduct = new BehaviorSubject<boolean>(false);
  public isUnconfirmedProduct = new BehaviorSubject<boolean>(false);

  constructor(public viewModel: EditVariantFormViewModel) {
    super();
  }

  public getFormResult(): EditVariantFormObject {
    return this.editVariantForm.getPopulatedObject();
  }

  initializeWithVariant() {
    setTimeout(() => {
      this.editVariantForm.formItems.forEach(fi => {
        fi.handleInputChange(fi.getMyValue());
      });
    }, 100);
  }

  productTypeSelected(productType: ProductType, initialValue: boolean = false) {
    if (!initialValue) {
      this.editVariantForm.getPopulatedObject(); // if the form is reinitialized then we want to keep the updated values
    }
    this.productTypeChanged(productType).pipe(take(1)).subscribe((variantTypes) => {
      setTimeout(() => {
        if (variantTypes.length === 1) {
          this.variantTypeDropdown.getSelfAsFormItem().setValue(variantTypes[0]);
        } else {
          const variantTypeValues = variantTypes?.map(vt => vt?.value);
          if (!variantTypeValues.includes(this.variantTypeDropdown?.getMyValue())) {
            this.variantTypeDropdown.clear();
          }
        }
      });
    });
  }

  private productTypeChanged(productType: ProductType): Observable<VariantTypeDefinition[]> {
    return window?.types?.getVariantTypesForProductType(productType).pipe(
      tap((varTypes) => {
        this._variantTypeDisabled.next(!productType);
        this._isAccessoryProduct.next(productType === ProductType.Accessories);
        this._variantTypeOptions.next(varTypes);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) {
      this.initializeWithVariant();
    }
  }

  ngOnInit() {
    this.viewModel.connectToVariant(this.bindTo.variant);
  }

  ngAfterViewInit(): void {
    this.productTypeSelected(this.bindTo?.variant?.productType, true);
  }

}

