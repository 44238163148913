import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { map } from 'rxjs/operators';
import { Asset } from '../../../../../../models/image/dto/asset';
import { CardSizePreviews } from './card-size-previews';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { DefaultStackSize } from '../../../../../../utils/default-stack-size';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class UpdatePrintCardPreviewsModalViewModel extends BaseModalViewModel {

  constructor(
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _previews = new BehaviorSubject<Asset[]>(null);
  public previews$ = this._previews as Observable<Asset[]>;
  connectToPreviews = (previews: Asset[]) => this._previews.next(previews);

  private _uploads = new BehaviorSubject<BudsenseFile[]>(null);
  public uploads$ = this._uploads as Observable<BudsenseFile[]>;
  connectToUploads = (uploads: BudsenseFile[]) => this._uploads.next(uploads);

  private _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme as Observable<Theme>;
  connectToTheme = (theme: Theme) => this._theme.next(theme);

  private _uploadCardSizeMap = new BehaviorSubject<Map<string, DefaultStackSize>>(null);
  public uploadCardSizeMap$ = this._uploadCardSizeMap as Observable<Map<string, DefaultStackSize>>;
  connectToUploadCardSizeMap = (uploadCardSizeMap: Map<string, DefaultStackSize>) => {
    this._uploadCardSizeMap.next(uploadCardSizeMap);
  };

  private _cardSizes = new BehaviorSubject<string[]>([]);
  public cardSizes$ = this._cardSizes as Observable<string[]>;
  connectToCardSizes = (cardSizes: string[]) => this._cardSizes.next(cardSizes);

  private _canSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;
  setCanSubmit = (canSubmit: boolean) => this._canSubmit.next(canSubmit);

  public dropdown$ = combineLatest([
    this.cardSizes$,
    window.types.printStackSizeTypes$
  ]).pipe(
    map(([printCardSizes, printCardSizeTypes]) => {
      return printCardSizeTypes.filter(printCardSizeType => {
        return printCardSizes.includes(printCardSizeType.getSelectionValue());
      });
    })
  );

  public themeName$ = this.theme$.pipe(
    map(theme => theme?.name)
  );

  public cardSizePreviews$ = combineLatest([
    this.previews$,
    this.uploads$,
    this.theme$,
    this.uploadCardSizeMap$
  ]).pipe(
    map(([previews, uploads, theme, uploadCardSizeMap]) => {
      const existingCardSizePreview = previews?.map(p => new CardSizePreviews(p, null) || []) || [];
      const uploadedCardSizePreviews = uploads?.map(u => new CardSizePreviews(null, u)) || [];
      const cardSizePreviews = uploadedCardSizePreviews.concat(...existingCardSizePreview);

      uploadCardSizeMap?.forEach((cardSize, fileName) => {
        const cardSizePreviewIndex = cardSizePreviews?.findIndex(p => p.uploadedImage?.name === fileName);
        if (cardSizePreviewIndex >= 0) cardSizePreviews[cardSizePreviewIndex].previewCardSize = cardSize;
      });

      theme?.printConfig?.previewCardMap.forEach((previewHashes, cardSize) => {
        previewHashes?.forEach(hash => {
          const cardSizePreviewIndex = cardSizePreviews?.findIndex(p => p.existingAsset?.md5Hash === hash);
          if (cardSizePreviewIndex >= 0) cardSizePreviews[cardSizePreviewIndex].previewCardSize = cardSize;
        });
      });
      return cardSizePreviews;
    })
  );

  updateCardSizePreviews(cardSizePreviews: CardSizePreviews[]) {
    const newCardSizePreviewMap = new Map<string, string[]>;
    const uploadCardSizeMap = new Map<string, string>;

    cardSizePreviews.forEach(csp => {
      if (exists(csp?.existingAsset)) {
        let cardSizePreviewHashes = newCardSizePreviewMap?.get(csp?.previewCardSize);
        if (!cardSizePreviewHashes) cardSizePreviewHashes = [];
        cardSizePreviewHashes.push(csp?.existingAsset?.md5Hash);
        newCardSizePreviewMap.set(csp?.previewCardSize, cardSizePreviewHashes);
      }
      if (exists(csp?.uploadedImage)) {
        uploadCardSizeMap.set(csp?.uploadedImage?.name, csp?.previewCardSize);
      }
    });

    this.activeModal.close([newCardSizePreviewMap, uploadCardSizeMap]);
  }

}
