import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { UploadAssetViewModel } from './upload-asset-view-model';
import { UploadImageInterface } from './upload-image-interface';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Animatable } from '../../../../models/protocols/animatable';
import { MediaUtils } from '../../../../utils/media-utils';

export type UploadType = 'video' | 'image' | 'csv';
/**
 * This component allows you to drag and drop files into it, and notify a parent component when files
 * have changes.
 *
 * @param parentHandler: pass your parent component in that implements this interface, so that it
 * knows when the file list has changed.
 * @param maxImages: x <= 0 means infinite, else x
 * @param allowVideo: self explainatory
 * @param allowImage: self explanatory
 */
@Component({
  selector: 'app-upload-asset',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss'],
  providers: [UploadAssetViewModel],
})
export class UploadAssetComponent extends BaseComponent
  implements OnInit, OnChanges, OnDestroy, Animatable {

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
  @Input() parentHandler: UploadImageInterface;
  @Input() allowTypes: UploadType[] = ['image', 'video'];
  @Input() maxAssets: number = -1;
  @Input() maxFileSize: number = MediaUtils.DEFAULT_MAX_FILE_SIZE;
  @Input() displayList: boolean = true;
  @Input() isHidden: boolean = false;
  @Input() loadingOpts: LoadingOptions = LoadingOptions.default();
  @Input() id: number = 0;
  public animating: boolean = false;

  constructor(
    public viewModel: UploadAssetViewModel,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxImages) { this.viewModel.initMaxAssets(this.maxAssets); }
    if (changes.parentHandler) { this.viewModel.initParentHandler(this.parentHandler); }
    if (changes.allowTypes) { this.viewModel.initAcceptType(this.allowTypes); }
    if (changes.id) { this.viewModel.id = this.id; }
    if (changes.maxFileSize) { this.viewModel.maxFileSize = this.maxFileSize; }
  }

  override ngOnInit(): void {
    this.viewModel.id = this.id;
    this.viewModel.maxFileSize = this.maxFileSize;
    this.viewModel.initMaxAssets(this.maxAssets);
    this.viewModel.initParentHandler(this.parentHandler);
    this.viewModel.initAcceptType(this.allowTypes);
  }

  override setupBindings() {
    const s = this.viewModel.resetInputState.subscribe(() => {
      this.fileDropEl.nativeElement.value = '';
    });
    this.pushSub(s);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.viewModel.destroy();
  }

  onFileDropped($event) {
    this.viewModel.handleUploadedFiles($event);
  }

  clear() {
    this.viewModel.clear();
  }

  // Animating
  animate(duration: number) {
    this.animating = true;
    setTimeout(function() {
        this.animating = false;
      }.bind(this), duration);
  }

}
