import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class NavigationService {

  constructor(
    private router: Router,
    private ngbModal: NgbModal
  ) {
  }

  newPassword(extras?: NavigationExtras): void {
    this.router.navigate(['auth/new-password'], extras).then();
  }

  resetPassword(extras?: NavigationExtras): void {
    this.router.navigate(['auth/reset-password'], extras).then();
  }

  forgotPassword(extras?: NavigationExtras): void {
    this.router.navigate(['auth/forgot-password'], extras).then();
  }

  signIn(extras?: NavigationExtras): void {
    this.router.navigate(['/auth/sign-in'], extras).then();
  }

}
