import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../services/modal.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { SmartFilterGrouping } from '../../../../models/automation/smart-filter-grouping';
import { Variant } from '../../../../models/product/dto/variant';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { exists } from '../../../../functions/exists';
import { DisplayAttributeGroupDetails } from '../../../../models/product/dto/display-attribute-group-details';

@Injectable({ providedIn: 'root' })
export class SmartFilterRowViewModel extends BaseViewModel {

  constructor(
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private productDomainModel: ProductDomainModel
  ) {
    super();
    const lm = '';
    this._loadingOpts.addRequest(lm);
    this.curatedSmartFilterGroups$.notNull().pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: groups => {
        if (groups !== null) {
          this._loadingOpts.removeRequest(lm);
        }
      }
    });
    this.productDomainModel.tellSmartFiltersRowViewModelAboutVariantUpdate$.subscribeWhileAlive({
      owner: this,
      next: updatedVariants => this.checkForActiveSmartFilterChanges(updatedVariants)
    });
  }

  public curatedSmartFilterGroups$ = this.smartFiltersDomainModel.curatedGroupedSmartFilters$.pipe(
    // Only including category cards that do not contain advanced filters
    map(selectableSmartFilters => selectableSmartFilters.filter(
      smartFilterGrouping => smartFilterGrouping.getGroupedFilters().some(filters => !filters.advancedFilter)
    ))
  );
  public allSmartFilters$: Observable<HydratedSmartFilter[]> = this.smartFiltersDomainModel.curatedSmartFilters$;
  private _appliedSmartFilters = new BehaviorSubject<HydratedSmartFilter[]>([]);
  public appliedSmartFilterIds$ = this._appliedSmartFilters.pipe(map(filters => filters?.map(filter => filter.id)));
  public appliedSmartFilters$: Observable<HydratedSmartFilter[]> = combineLatest([
    this.appliedSmartFilterIds$,
    this.allSmartFilters$
  ]).pipe(
    map(([appliedSmartFilterIds, allSmartFilters]) => {
      return allSmartFilters?.filter(smartFilter => appliedSmartFilterIds?.includes(smartFilter.id));
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public hasAppliedFilters$ = this.appliedSmartFilters$.pipe(
    map(appliedSmartFilters => appliedSmartFilters?.length > 0)
  );

  public allProductsGrouping = new SmartFilterGrouping('All Products');

  public openAccordion: boolean = false;

  private listenToLengthOfAppliedFilters = this.appliedSmartFilters$.subscribeWhileAlive({
    owner: this,
    next: appliedSmartFilters => {
      if (appliedSmartFilters?.length === 0) {
        this.openAccordion = false;
      }
    }
  });

  private appliedSmartFilterVariantsMech = combineLatest([
    this.appliedSmartFilters$,
    this.productDomainModel.universalVariants$,
    this.productDomainModel.displayAttributeGroups$
  ]).pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([appliedFilters, variants, gdags]) => {
        if (appliedFilters?.length > 0) {
          const uniqueVariants = variants?.filter(v => {
            return appliedFilters?.some(f => f.matchesVariant(v));
          }).unique();
          const uniqueVariantsFilteredByGroupDetails = uniqueVariants?.filter(v => {
            const gdag = gdags?.find(g => g?.id === v?.displayAttributeGroupId);
            let gdagDetails: DisplayAttributeGroupDetails = null;
            if (exists(gdag)) {
              gdagDetails = gdag?.groupDetails?.find(gd => gd?.variantBarcodes?.includes(v?.barcode));
              if (!exists(gdagDetails)) {
                gdagDetails = gdag?.groupDetails?.find(gd => gd?.isDefaultSharedGroupForDisplayAttribute());
              }
            } else {
              gdagDetails = DisplayAttributeGroupDetails.getEmptyGroupDetails();
            }
            return appliedFilters?.some(f => {
              return f?.matchesDisplayAttributeGroupDetails(gdagDetails);
            });
          }).unique();
          this.productDomainModel.setSmartFilterDataTableVariants(uniqueVariantsFilteredByGroupDetails);
        } else {
          this.productDomainModel.setSmartFilterDataTableVariants(null);
        }
      }
    });

  openSelectSmartFilterModal() {
    this.appliedSmartFilters$.pipe(
      map(filters => filters?.map(sf => sf.id))
    ).once(smartFilterIds => {
      this.modalService.openSelectSmartFilterModal(
        smartFilterIds,
        this.applySmartFiltersFromModal.bind(this),
        this.createSmartFilter.bind(this)
      );
    });
  }

  applySmartFilterCategory(smartFilters: HydratedSmartFilter[], keepExisting: boolean = false) {
    const existingFilters = this._appliedSmartFilters.getValue();
    const existingIds = existingFilters.map(sf => sf.id);
    const newIds = smartFilters.map(sf => sf.id);
    const newValue = existingFilters.concat(smartFilters);
    if (existingIds.equals(newIds)) {
      this._appliedSmartFilters.next([]);
    } else {
      if (keepExisting) {
        this._appliedSmartFilters.next([...new Set(newValue)]);
      } else {
        this._appliedSmartFilters.next([...new Set(smartFilters)]);
      }
    }
  }

  applySmartFiltersFromModal(ids: string[]) {
    this.allSmartFilters$.once(allSmartFilters => {
      const smartFiltersFromId = allSmartFilters.filter(asf => (ids?.indexOf(asf.id) ?? -1) !== -1);
      this.applySmartFilterCategory(smartFiltersFromId, true);
      this.openAccordion = true;
    });
  }

  createSmartFilter() {
  }

  createSmartFilterHelper(smartFilter?: HydratedSmartFilter) {
    const smartFilterId = smartFilter?.id;
    if (!!smartFilterId) { this.applySmartFiltersFromModal([smartFilterId]); }
  }

  removeSmartFilter(id: string) {
    const existing = this._appliedSmartFilters.getValue();
    const i = existing.findIndex(sf => sf.id === id);
    if (i > -1) {
      existing.splice(i, 1);
    }
    this._appliedSmartFilters.next(existing);
  }

  clearAllSmartFilters() {
    this._appliedSmartFilters.next([]);
  }

  toggleAccordion() {
    this.openAccordion = !this.openAccordion;
  }

  checkForActiveSmartFilterChanges(updatedVariants: Variant[]): void {
    this.appliedSmartFilters$.once(appliedSmartFilters => {
      const hits = appliedSmartFilters?.filter(smartFilter => updatedVariants?.map(variant => {
          const vId = variant.barcode;
          return smartFilter?.appliedVariantIds?.contains(vId) && !smartFilter?.ignoredVariantIds?.contains(vId);
        })?.contains(true));
      if (hits?.length > 0) {
        this.smartFiltersDomainModel?.fetchAllSmartFiltersAfterDelay();
      }
    });
  }

}
