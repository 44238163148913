import { Pipe, PipeTransform } from '@angular/core';
import type { DisplayAttributeGroup } from '../../../models/product/dto/display-attribute-group';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantsForDisplayAttributeGroupDetailsFromBarcodes'
})
export class GetVariantsForDisplayAttributeGroupDetailsFromBarcodesPipe implements PipeTransform {

  transform(group: DisplayAttributeGroup, barcodes: string[]): Variant[] | null {
    return group?.getVariantsForDisplayAttributeGroupDetailsFromBarcodes(barcodes) || null;
  }

}
