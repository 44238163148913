import { NavSubItemViewModel } from '../nav-sub-item-view-model';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';

@Injectable()
export class DisplayAttributesSubNavItemViewModel extends NavSubItemViewModel {

  public showNavItemAlertIndicator$ = this.productDomainModel.unassignedVariantCount$.pipe(
    map(uv => uv > 0)
  );

  constructor(
    protected productDomainModel: ProductDomainModel,
    router: Router,
  ) {
    super(router);
  }

}
