import { MediaType } from '../../enum/dto/media-type.enum';
import { UploadFilePath } from '../../enum/dto/upload-file.path';
import { Deserializable } from '../../protocols/deserializable';

export class GenerateUploadUrlRequest implements Deserializable {

  public mediaType: MediaType;
  public mediaId: string;
  public fileName: string;
  public mediaClass: UploadFilePath;
  public metadata: Map<string, string>;

  onDeserialize() {
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-image-service/blob/dev/models/GenerateImageUrlRequest.go
  onSerialize() {
    const dto = Object.create(GenerateUploadUrlRequest.prototype);
    dto.mediaType = this.mediaType;
    dto.mediaId = this.mediaId;
    dto.fileName = this.fileName;
    dto.mediaClass = this.mediaClass;
    dto.metadata = this.metadata;
    return dto;
  }

}
