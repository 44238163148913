<div class="inline-picker-container" [ngClass]="{ disabled: disabled }">
  <span [style.display]="'contents'" [style.pointer-events]="disabled ? 'none' : ''">
    <app-simple-label-preview
      *ngIf="(labelIds$ | async)?.length > 0"
      [labelIds]="labelIds$ | async"
      (updatedLabelIds)="picker.handleLabelCleared($event)">
    </app-simple-label-preview>
    <div *ngIf="(labelIds$ | async)?.length === 0" class="f12px bs-medium">Add Labels</div>
    <div
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPreventOverflow]="false"
      [popperPositionFixed]="true"
      [popperApplyClass]="'label-picker-popper'"
      [popperStyles]="popperStyles"
      [popperPlacement]="popperPlacement"
      [popperDisabled]="disabled">
      <div class="ms-2">
        <app-inline-picker-action-button
          *ngIf="(labelIds$ | async)?.length < maxSelectedLabels"
          [disabled]="disabled"
          [canEdit]="!!(labelIds$ | async) && (labelIds$ | async)?.length > 0">
        </app-inline-picker-action-button>
      </div>
    </div>
    <popper-content #popperContent [style.position]="'fixed'" [style.z-index]="1500">
      <app-labels-picker
        #picker
        class="label-picker"
        [bindTo]="bindTo"
        [mergeKey]="mergeKey"
        [maxSelectedLabels]="maxSelectedLabels"
        (selectedIds)="setUpdatedLabelIds($event)">
      </app-labels-picker>
    </popper-content>
  </span>
</div>
