import { VariantBadge } from './variant-badge';
import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { Asset } from '../../image/dto/asset';
import { PillItem, PillItemInterface } from '../../shared/stylesheet/pill-item';
import { SmartFilterAppliedOnPillType } from '../../enum/shared/smart-filter-applied-on-pill-type';
import { SmartFilterUtils } from '../../../utils/smart-filter-utils';

export class HydratedVariantBadge extends VariantBadge implements Cachable, Deserializable, PillItemInterface {

  public image: Asset;
  public cachedTime: number;

  static buildArrayCacheKey(companyId: number): string {
    return `VariantBadges-${companyId}`;
  }

  static buildCacheKey(companyId: number, badgeId: string): string {
    return `VariantBadge-${companyId}-${badgeId}`;
  }

  public override onDeserialize() {
    super.onDeserialize();
    this.image = window.injector.Deserialize.instanceOf(Asset, this.image);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(companyId: number): string {
    return HydratedVariantBadge.buildCacheKey(companyId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  getVariantBadge(): VariantBadge {
    const vBadge = new VariantBadge();
    vBadge.category = this.category;
    vBadge.subCategory = this.subCategory;
    vBadge.id = this.id;
    vBadge.companyId = this.companyId;
    vBadge.name = this.name;
    return vBadge;
  }

  getAsPillItem(clickable: boolean, selected: boolean, disabled: boolean): PillItem {
    const imgSrc = SmartFilterUtils.getAppliedOnPillIcon(SmartFilterAppliedOnPillType.SmartBadge);
    return new PillItem(this.name, clickable, selected, disabled, imgSrc, this);
  }

}
