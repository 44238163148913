<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'smart-filter-form'"
  [triggerManualObjectHydration]="viewModel.hydrateObject"
  (canSubmit$)="viewModel.setCanSubmit($event)"
  (formsSubmitted$)="viewModel.saveChanges()"
  (unsavedChanges$)="viewModel?.formChanges($event)"
  (manualHydrationResult$)="viewModel.setFormObject($event)">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel.isEditing ? 'Edit Curated Smart Filter' : 'Create Curated Smart Filter' }}
      </div>
    </div>
    <app-warning-banner *ngIf="viewModel.isEditing" [style.margin-top.rem]="1">
      Any changes made to a Curated Smart Filter will affect all customers that are currently using it.
    </app-warning-banner>
  </div>

  <div #modalBody class="sticky-body-container">
    <div>
      <app-basic-add-edit-smart-filter-form
        [bindTo]="viewModel.isEditing ? (viewModel.existingSmartFilter | push) : viewModel?.smartFilterReq"
        [mergeKey]="'smart-filter-form'">
      </app-basic-add-edit-smart-filter-form>

      <mat-accordion multi [displayMode]="'flat'">
        <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openProductAccordion$ | push">
          <mat-expansion-panel-header class="no-background">
            <mat-panel-title class="accordion-title"> Product Info </mat-panel-title>
          </mat-expansion-panel-header>
          <app-product-add-edit-smart-filter-form
            [bindTo]="viewModel.isEditing ? (viewModel.existingSmartFilter | push) : viewModel?.smartFilterReq"
            [mergeKey]="'smart-filter-form'">
          </app-product-add-edit-smart-filter-form>
        </mat-expansion-panel>
      </mat-accordion>
      <hr />

      <mat-accordion multi [displayMode]="'flat'">
        <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openCannabinoidAccordion$ | push">
          <mat-expansion-panel-header class="no-background">
            <mat-panel-title class="accordion-title"> Cannabinoid Info </mat-panel-title>
          </mat-expansion-panel-header>
          <app-cannabinoid-add-edit-smart-filter-form
            [bindTo]="viewModel.isEditing ? (viewModel.existingSmartFilter | push) : viewModel?.smartFilterReq"
            [mergeKey]="'smart-filter-form'">
          </app-cannabinoid-add-edit-smart-filter-form>
        </mat-expansion-panel>
      </mat-accordion>
      <hr />

      <mat-accordion multi [displayMode]="'flat'">
        <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openTerpeneAccordion$ | push">
          <mat-expansion-panel-header class="no-background">
            <mat-panel-title class="accordion-title"> Terpene Info </mat-panel-title>
          </mat-expansion-panel-header>
          <app-terpene-add-edit-smart-filter-form
            [bindTo]="viewModel.isEditing ? (viewModel.existingSmartFilter | push) : viewModel?.smartFilterReq"
            [mergeKey]="'smart-filter-form'">
          </app-terpene-add-edit-smart-filter-form>
        </mat-expansion-panel>
      </mat-accordion>
      <hr />

      <mat-accordion multi [displayMode]="'flat'">
        <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openAdvancedAccordion$ | push">
          <mat-expansion-panel-header class="no-background">
            <mat-panel-title class="accordion-title"> Advanced Filters </mat-panel-title>
          </mat-expansion-panel-header>
          <app-advanced-add-edit-smart-filter-form
            [bindTo]="viewModel.isEditing ? (viewModel.existingSmartFilter | push) : viewModel?.smartFilterReq"
            [mergeKey]="'smart-filter-form'">
          </app-advanced-add-edit-smart-filter-form>
        </mat-expansion-panel>
      </mat-accordion>
      <hr />
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end w-100">
    <div *ngIf="viewModel.isEditing && !(viewModel?.hasSystemIdentifier$ | push)" class="edit-button-container me-auto">
      <button class="bs-button text-button ms-0 px-0" (click)="viewModel.showDeleteModal()">Delete Smart Filter</button>
    </div>
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      (click)="formCapture.submitForms()"
      [class.disabled]="!(viewModel.canSubmitForm | push)"
      [disabled]="!(viewModel.canSubmitForm | push)"
      class="bs-button preferred-button me-0">
      {{ viewModel.isEditing ? 'Save Changes' : 'Create Smart Filter' }}
    </button>
  </div>
</div>
