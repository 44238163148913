import { Injectable } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../../views/shared/modals/abandon-form/abandon-form.component';
import { ModalUtils } from '../../utils/modal-utils';
import { firstValueFrom, Observable } from 'rxjs';
import type { ComponentCanDeactivate } from '../../models/protocols/component-can-deactivate';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

export type Deactivation = Observable<boolean> | Promise<boolean> | boolean;

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard  {

  constructor(
    private modalService: NgbModal
  ) {
  }

  private deactivateModel: NgbModalRef = null;

  canDeactivate(component: ComponentCanDeactivate): Deactivation {
    let deactivation$ = component.canDeactivate();
    const handleDeactivation = (deactivate: boolean) => {
      if (deactivate) return true;
      return !this.deactivateModel ? this.openAbandonModal() : false;
    };
    if (deactivation$ instanceof Observable) {
      deactivation$ = firstValueFrom(deactivation$);
    }
    return deactivation$ instanceof Promise
      ? deactivation$.then(handleDeactivation)
      : handleDeactivation(deactivation$);
  }

  private openAbandonModal(): Promise<boolean> {
    this.deactivateModel = this.modalService.open(AbandonFormComponent, ModalUtils.defaultModalOptions());
    return this.deactivateModel.result.then(result => {
      this.deactivateModel = null;
      return result;
    }).catch((_) => {
      this.deactivateModel = null;
      return false;
    }) as Promise<boolean>;
  }

}
