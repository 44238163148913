import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationSourceInterceptor implements HttpInterceptor {

  private static createHeaders(headers: HttpHeaders): HttpHeaders {
    if (!headers.get('Source')) {
      headers = headers.append('Source', 'Admin');
    }
    return headers;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({headers: ApplicationSourceInterceptor.createHeaders(req.headers)}));
  }

}
