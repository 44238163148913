import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';
import { NavigationExtras, Router } from '@angular/router';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Input() breadcrumbs: Breadcrumb[];
  @Input() margin: string = '0 0 1.5rem 0';
  @Input() unorderedListMargin: string;

  constructor(
    private router: Router
  ) {
  }

  breadcrumbClicked(bc: Breadcrumb) {
    let extras: NavigationExtras = { replaceUrl: true };
    if (exists(bc?.fragment)) extras = { fragment: bc.fragment, replaceUrl: true };
    if (!bc.active && !bc.disabled) {
      this.router.navigate([bc.targetUrl], extras).then();
    }
  }

}
