import { Component } from '@angular/core';
import { AuthFlow } from '../../../../models/account/enum/auth-flow.enum';
import { AuthViewModel } from '../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../services/toast-service';
import { BaseComponent } from '../../../../models/base/base-component';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent {

  // View Properties
  public authFlows = AuthFlow;
  private logoClickCount: number = 0;

  constructor(
    public viewModel: AuthViewModel,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  parseAuthFlow() {
    const urlSub = this.route.url.subscribe((url) => {
      switch (url[url.length - 1].path) {
        case 'sign-in': {
          this.viewModel.authFlow = AuthFlow.SignIn;
          break;
        }
        case 'forgot-password': {
          this.viewModel.authFlow = AuthFlow.ForgotPassword;
          break;
        }
        case 'reset-password': {
          this.viewModel.authFlow = AuthFlow.ResetPassword;
          break;
        }
        case 'new-password': {
          this.viewModel.authFlow = AuthFlow.SetNewPassword;
          break;
        }
      }
    });
    this.pushSub(urlSub);
  }

  override setupBindings() {
    this.parseAuthFlow();
    // Navigate to the account page on successful auth
    const authSuccessSubject = this.viewModel.authSuccess.notNull().subscribe((_) => {
      this.router.navigate(['metrics']).then(() => {
      });
    });
    this.pushSub(authSuccessSubject);
    // Navigate to the reset password page
    const authFlowSubject = this.viewModel.nextAuthFlow.notNull().subscribe((nextAuthFlow) => {
      switch (nextAuthFlow) {
        case AuthFlow.SignIn:
          this.navigationService.signIn();
          break;
        case AuthFlow.ResetPassword:
          this.navigationService.resetPassword();
          break;
        case AuthFlow.SetNewPassword:
          this.navigationService.newPassword();
          break;
        case AuthFlow.ForgotPassword:
          this.navigationService.forgotPassword();
          break;
      }
    });
    this.pushSub(authFlowSubject);
  }

  logoClicked() {
    // If the user clicks the logo 7 times quickly, it will reset the caches
    this.logoClickCount++;
    if (this.logoClickCount > 6) {
      this.viewModel.clearCaches();
      this.logoClickCount = 0;
      this.toastService.publishInfoMessage('All caches have been cleared.', 'Caches cleared');
    }
    setTimeout(() => {
      this.logoClickCount = 0;
    }, 5000);
  }

}
