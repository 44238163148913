import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditSectionBlueprintCategoryModalViewModel } from './edit-section-blueprint-category-modal-view-model';
import { SectionBlueprintCategory } from '../../../../../models/menu/dto/section-blueprint-category';

@Component({
  selector: 'app-edit-section-blueprint-category-modal',
  templateUrl: './edit-section-blueprint-category-modal.component.html',
  styleUrls: ['./edit-section-blueprint-category-modal.component.scss'],
  providers: [EditSectionBlueprintCategoryModalViewModel]
})
export class EditSectionBlueprintCategoryModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: EditSectionBlueprintCategoryModalViewModel,
    activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  setCategoryToEdit(category: SectionBlueprintCategory) {
    this.viewModel.connectToCategory(category);
  }

}
