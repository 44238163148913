export {};
declare global {
  export interface Number {
    mod(n: number): number;
  }
}

// The % operator in JavaScript is the remainder operator, not the modulo operator
// if I do -2 % 5, I'd expect 3, but in javascript I get -2
Number.prototype.mod = function(n) {
  return ((this % n) + n) % n;
};

export class NumberUtils {

  static isEven(i: number): boolean {
    return (i % 2) === 0;
  }

  static isOdd(i: number): boolean {
    return (i % 2) !== 0;
  }

  static secondsToMilli(n: number): number {
    return n * 1000;
  }

  static getIntegerSum(total: number, num: number) {
    return total + Math.round(num);
  }

  /** returns reduced version of input numerator and denominator: [reducedNumerator, reducedDenominator] */
  static reduceFraction(numerator, denominator) {
    const gcd = function greatestCommonDivisor(n, d): number {
      return d ? greatestCommonDivisor(d, n % d) : n;
    };
    const newDenominator = gcd(numerator, denominator);
    const reducedNumerator = numerator / newDenominator;
    const reducedDenominator = denominator / newDenominator;
    return [reducedNumerator, reducedDenominator];
  }

  static parseFloatOrNull(s: string) {
    const p = parseFloat(s);
    return isNaN(p) ? null : p;
  }

}
