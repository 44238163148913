import { Component } from '@angular/core';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';
import { SignOutNavItemViewModel } from './sign-out-nav-item-view-model';

@Component({
  selector: 'app-sign-out-nav-item',
  templateUrl: './sign-out-nav-item.component.html',
  styleUrls: [
    './sign-out-nav-item.component.scss',
    '../nav-item.component.scss'
  ],
  providers: [SignOutNavItemViewModel],
})
export class SignOutNavItemComponent extends NavItemComponent {

  constructor(
    public override viewModel: SignOutNavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

  override navItemSelected() {
    this.viewModel.signOut();
  }

}
