import { TypeDefinition } from './type-definition';
import { SegmentedControlOption } from '../models/shared/stylesheet/segmented-control-option';

export enum DefaultAspectRatio {
  AspectRatio1x1 = 1,
  AspectRatio4x3 = 1.3333333333333333,
  AspectRatio3x2 = 1.5,
  AspectRatio16x9 = 1.7777777777777777,
  AspectRatio21x9 = 2.3333333333333335,
}

export class AspectRatio extends TypeDefinition implements SegmentedControlOption {

  override value: DefaultAspectRatio;

  selected: boolean = false;
  disabled: boolean = false;
  title: string;
  tooltipText: string;

  static getAspectRatios(selected: DefaultAspectRatio): AspectRatio[] {
    // Use deserializer so setTitle() is called and can easily by plugged in via API if necessary
    const aspectRatios = [
      window.injector.Deserialize.instanceOf(AspectRatio, new AspectRatio(DefaultAspectRatio.AspectRatio1x1, '1:1')),
      window.injector.Deserialize.instanceOf(AspectRatio, new AspectRatio(DefaultAspectRatio.AspectRatio4x3, '4:3')),
      window.injector.Deserialize.instanceOf(AspectRatio, new AspectRatio(DefaultAspectRatio.AspectRatio3x2, '3:2')),
      window.injector.Deserialize.instanceOf(AspectRatio, new AspectRatio(DefaultAspectRatio.AspectRatio16x9, '16:9')),
      window.injector.Deserialize.instanceOf(AspectRatio, new AspectRatio(DefaultAspectRatio.AspectRatio21x9, '21:9')),
    ];
    aspectRatios.forEach(ar => ar.selected = ar.value === selected);
    return aspectRatios;
  }

  override onDeserialize() {
    super.onDeserialize();
    this.setTitle();
  }

  private setTitle() {
    switch (this.value) {
      case DefaultAspectRatio.AspectRatio1x1:
        this.title = '1:1';
        return;
      case DefaultAspectRatio.AspectRatio4x3:
        this.title = '4:3';
        return;
      case DefaultAspectRatio.AspectRatio3x2:
        this.title = '3:2';
        return;
      case DefaultAspectRatio.AspectRatio16x9:
        this.title = '16:9';
        return;
      case DefaultAspectRatio.AspectRatio21x9:
        this.title = '21:9';
        return;
    }
  }

}
