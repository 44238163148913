import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseDirective } from '../../../models/base/base-directive';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appThrottleClick]'
})
export class ThrottleClickDirective extends BaseDirective {

  @Input() throttleInMilliSec = 500;
  @Output() throttleClick = new EventEmitter();
  private clicks = new Subject();

  constructor() {
    super();
  }

  override setupBindings() {
    const s = this.clicks.pipe(throttleTime(this.throttleInMilliSec)).subscribe(e => this.throttleClick.emit(e));
    this.pushSub(s);
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
