import { BaseModalViewModel } from 'src/app/models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { SyncDomainModel } from '../../../../../../../../domainModels/sync-domain-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReviewSyncJobViewModel extends BaseModalViewModel {

  constructor(
    private syncDomainModel: SyncDomainModel,
    private companyDomainModel: CompanyDomainModel,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public inventoryProvider$ = combineLatest([
    this.companyDomainModel.companies$,
    this.syncDomainModel.companyId$,
  ]).pipe(
    map(([companies, companyId]) => {
      return companies?.find(c => c.id === companyId)?.provider;
    })
  );

}
