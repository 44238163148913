import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-segmented-control',
  templateUrl: './segmented-control.component.html',
  styleUrls: ['./segmented-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SegmentedControlComponent {

  @Input() options: SegmentedControlOption[] = [];
  @Input() allowMultipleSelection: boolean = false;
  @Input() verticalLayout: boolean = false;
  @Output() optionSelected: EventEmitter<SegmentedControlOption[]> = new EventEmitter<SegmentedControlOption[]>();

  public controlName = Math.random().toString(24);

  constructor() {
  }

  controlSelected(e: MatButtonToggleChange) {
    if (this.allowMultipleSelection) {
      const selVals = e.value as Array<any>;
      const selOpts = this.options.filter(o => selVals.contains(o.value));
      this.optionSelected.emit(selOpts.map(o => {
        o.selected = true;
        return o;
      }));
    } else {
      const selVal = this.options.find(o => o.value === e.value);
      selVal.selected = true;
      this.optionSelected.emit([selVal]);
    }
  }

}
