import { Component } from '@angular/core';

@Component({
  selector: 'app-center-column',
  templateUrl: './center-column.component.html',
  styleUrls: ['./center-column.component.scss']
})
export class CenterColumnComponent {

  constructor() {
  }

}
