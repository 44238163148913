import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { AssetGroup } from '../../../../../models/product/dto/asset-group';

@Component({
  selector: 'app-assign-to-asset-group-button',
  templateUrl: './assign-to-asset-group-button.component.html',
  styleUrls: ['./assign-to-asset-group-button.component.scss']
})
export class AssignToAssetGroupButtonComponent extends BaseComponent {

  @Input() assetGroup: AssetGroup;
  @Input() selectedId: string;
  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  assetGroupClicked() {
    if (this.assetGroup.id === this.selectedId) {
      this.selectionChanged.emit('');
    } else {
      this.selectionChanged.emit(this.assetGroup?.id);
    }
  }

}
