import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetPreviewModalViewModel } from './asset-preview-modal-view-model';
import { Asset } from '../../../../models/image/dto/asset';

@Component({
  selector: 'app-asset-preview-modal',
  templateUrl: './asset-preview-modal.component.html',
  styleUrls: ['./asset-preview-modal.component.scss'],
  providers: [AssetPreviewModalViewModel]
})
export class AssetPreviewModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: AssetPreviewModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setAssetPreviewInfo(
    displayGroupId: string,
    libraryTypeName: string,
    asset: Asset,
    canDelete: boolean,
    isDisabled: boolean,
    isLinkedAsset: boolean,
    isBrandAssetGroup: boolean,
  ) {
    this.viewModel.displayGroupId = displayGroupId;
    this.viewModel.libraryTypeName = libraryTypeName;
    this.viewModel.asset = asset;
    this.viewModel.canDelete = canDelete && !isLinkedAsset; // Linked assets can not be deleted
    this.viewModel.isDisabled = isDisabled;
    this.viewModel.isBrandAssetGroup = isBrandAssetGroup;
  }

  deleteAsset() {
    this.viewModel.deleteCurrentAsset().subscribe((updatedAssetGroups) => {
      this.activeModal.close(updatedAssetGroups);
    });
  }

}
