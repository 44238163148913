import { TypeDefinition } from './type-definition';

export enum OverflowState {
  NONE = 'NONE',
  SCROLL = 'SCROLL',
  STEADY_SCROLL = 'STEADY_SCROLL',
  PAGING = 'PAGING'
}

export class OverflowStateType extends TypeDefinition {

  override value: OverflowState;

}
