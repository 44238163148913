<mat-accordion multi [displayMode]="'flat'">
  <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="expanded">
    <mat-expansion-panel-header [collapsedHeight]="'3.5rem'" [expandedHeight]="'3.5rem'">
      <div fxLayout="row" fxLayoutAlign="start center" [style.gap.rem]="0.5">
        <div *ngIf="!viewOnlyMode" fxLayout="row" fxLayoutAlign="start center">
          <app-group-selection
            #groupSelectionComponent
            [selection]="locations | getLocationsAsSelection"
            [selectedIds]="selectedIds"
            (bulkAdd)="addLocationIds.emit($event)"
            (bulkRemove)="removeLocationIds.emit($event)">
          </app-group-selection>
          <div class="n-selected">
            <span *ngIf="selectedIds | getLocationGroupSelectedCount : locations as nSelected" class="checked-count">
              ({{ nSelected }})
            </span>
          </div>
        </div>
        <div class="province-name">{{ province }}</div>
      </div>
    </mat-expansion-panel-header>
    <app-location-selection
      *ngFor="let child of locations"
      [viewOnlyMode]="viewOnlyMode"
      [showAddedOrRemovedStatus]="showAddedOrRemovedStatus"
      [isChild]="true"
      [originalLocationIds]="originalLocationIds"
      [updatedLocationIds]="updatedLocationIds"
      [selection]="child"
      [selectedIds]="selectedIds"
      (add)="addLocationIds.emit([$event])"
      (remove)="removeLocationIds.emit([$event])">
    </app-location-selection>
  </mat-expansion-panel>
</mat-accordion>
