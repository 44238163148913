<div
  #container
  fxLayout="row"
  fxLayoutAlign=" center"
  fxLayoutGap="1rem"
  class="smart-filter-selection-container"
  [ngClass]="{ disabled: previouslyAdded$ | async, child: isChild }">
  <app-checkbox-only
    [checked]="selected$ | async"
    [disabled]="previouslyAdded$ | async"
    [indeterminate]="indeterminate$ | async"
    (valueChanged)="clicked($event)"></app-checkbox-only>
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="smart-filter-name">{{ name$ | async | titlecase }}</div>
  </div>
</div>
<hr *ngIf="includeBottomBorder" class="no-margin" />
