<div
  #subList
  class="nav-sub-list"
  [ngClass]="{
    'sub-list-open': viewModel.isNavListActive$ | async
  }">
  <ng-container *ngFor="let subItem of navItem?.subItems">
    <app-nav-sub-item
      *ngIf="subItem | isDefaultNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-nav-sub-item>
    <app-universal-variants-sub-nav-item
      *ngIf="subItem | isUniversalVariantsNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-universal-variants-sub-nav-item>
    <app-display-attributes-sub-nav-item
      *ngIf="subItem | isDisplayAttributeNavSubItem"
      [navItem]="navItem"
      [subItem]="subItem"
      [currentNavUrl]="currentNavUrl"></app-display-attributes-sub-nav-item>
  </ng-container>
</div>
