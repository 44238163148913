import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SmartFilterLineItemViewModel } from './smart-filter-line-item-view-model';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-smart-filter-line-item',
  templateUrl: './smart-filter-line-item.component.html',
  styleUrls: ['./smart-filter-line-item.component.scss'],
  providers: [SmartFilterLineItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterLineItemComponent extends BaseComponent {

  @Input() smartFilterId: string;
  @Output() smartFilterRemoved = new EventEmitter();

  constructor(
    public viewModel: SmartFilterLineItemViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected modalService: ModalService
  ) {
    super();
  }

  override setupViews(): void {
    this.viewModel.setSmartFilterId(this.smartFilterId);
  }

  removeSmartFilter(): void {
    this.smartFilterRemoved.emit(this.smartFilterId);
  }

  editSmartFilter(): void {
    this.viewModel.smartFilter$.pipe(take(1)).subscribe((smartFilter) => {
      this.modalService.openEditSmartFilterModal(smartFilter);
    });
  }

}
