export class SandboxMenuStyling {

  public menuTitleTextColor: string = '';
  public menuBodyTextColor: string = '';
  public sectionHeaderBackgroundColor: string = '';
  public sectionHeaderTextColor: string = '';

  constructor() {
  }

}
