import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ValidatorFunctions {

  static nonEmptyArrayValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (Array.isArray(value) && value.length > 0) {
      return null;
    }
    return { 'nonEmptyArray': {value} };
  }

}
