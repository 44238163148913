import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoadingOptions } from '../models/shared/loading-options';

declare module 'rxjs/internal/BehaviorSubject' {
  interface BehaviorSubject<T> {
    bind(obvs$: Observable<any>): Subscription;
    consumeFlag(consume: (n: any) => void): Subscription;
    addRequest(msg: string): void;
    removeRequest(msg: string): void;
    containsRequest(msg: string): boolean;
  }
}

BehaviorSubject.prototype.bind = function(obvs$: Observable<any>): Subscription {
  return obvs$.subscribe((n) => {
    this.next(n);
  });
};

BehaviorSubject.prototype.consumeFlag = function(consume: (n: any) => void): Subscription {
  return this.subscribe((n) => {
    if (n) {
      consume(n);
      this.next(null);
    }
  });
};

BehaviorSubject.prototype.addRequest = function(msg: string) {
  const val = this.getValue();
  if (val instanceof LoadingOptions) {
    val.addRequest(msg, this);
  }
};

BehaviorSubject.prototype.removeRequest = function(msg: string) {
  const val = this.getValue();
  if (val instanceof LoadingOptions) {
    val.removeRequest(msg, this);
  }
};

BehaviorSubject.prototype.containsRequest = function(msg: string) {
  const val = this.getValue();
  if (val instanceof LoadingOptions) {
    return val.containsRequest(msg);
  }
  return false;
};
