import { Deserializable } from '../../protocols/deserializable';
import { Variant } from './variant';
import { Asset } from '../../image/dto/asset';
import { DateUtils } from '../../../utils/date-utils';
import { Cachable } from '../../protocols/cachable';
import { HasId } from '../../protocols/has-id';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { Pagable } from '../../protocols/pagable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { exists } from '../../../functions/exists';
import * as moment from 'moment/moment';

export class AssetGroup implements HasId, Deserializable, Cachable, Pagable, LookAheadItem, UniquelyIdentifiable {

  displayAttributeGroupId: string;
  id: string;
  groupName: string;
  assetTypeMap: Map<string, string[]>;
  disabledAssets: string[];
  isBrand: boolean;
  brandGroupId: string;
  linkedAssetGroupIds: string[];
  brand: AssetGroup;
  variants: Variant[];
  assets: Asset[];
  dateCreated: number;
  lastModified: number;
  snoozeUntil: number; // unix timestamp in MILLISECONDS
  public cachedTime: number;

  static buildCacheKey(id: string): string {
    return `AssetGroups-${id}`;
  }

  getId(): string {
    return this.id;
  }

  onDeserialize() {
    this.assetTypeMap = window.injector.Deserialize.genericArrayMap(this.assetTypeMap);
    this.disabledAssets = Array.from(this.disabledAssets || []);
    this.brand = window.injector.Deserialize.instanceOf(AssetGroup, this.brand);
    this.linkedAssetGroupIds = Array.from(this.linkedAssetGroupIds || []);
    this.variants = window.injector.Deserialize.arrayOf(Variant, this.variants);
    this.assets = window.injector.Deserialize.arrayOf(Asset, this.assets);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(): string {
    return AssetGroup.buildCacheKey(this.displayAttributeGroupId);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  getStartKey(): string {
    return this.isBrand ? `${this.displayAttributeGroupId},${this.id}` : this.id;
  }

  getAssetTypeMapString(): string {
    const assetTypeMapStrings = [];
    this.assetTypeMap?.forEach((value, key) => {
      assetTypeMapStrings.push(`${key}:${value.sort().join(',')}`);
    });
    return assetTypeMapStrings.sort().join(',');
  }

  isDefaultSharedAssetGroup(): boolean {
    return this.id === this.displayAttributeGroupId;
  }

  isBeingSnoozed(): boolean {
    return exists(this.snoozeUntil) && moment(this.snoozeUntil).isAfter(moment());
  }

  snoozeIsFromThePast(): boolean {
    return exists(this.snoozeUntil) && moment(this.snoozeUntil).isBefore(moment());
  }

  getUniqueIdentifier(...opts: any): string {
    const uniqueVariants = this.variants?.map(v => v.getUniqueIdentifier()).sort().join(',');
    const uniqueBrand = this.brand?.getUniqueIdentifier();
    const uniqueAssets = this.assets?.map(a => a?.getUniqueIdentifier())?.sort().join(',');
    return `
      ${this.id}-
      ${this.displayAttributeGroupId}-
      ${this.groupName}-
      ${this.isBrand}-
      ${this.brandGroupId}-
      ${uniqueBrand}-
      ${this.disabledAssets.sort().join(',')}-
      ${this.linkedAssetGroupIds.sort().join(',')}-
      ${this.getAssetTypeMapString()}-
      ${uniqueVariants}-
      ${uniqueAssets}-
      ${this.snoozeUntil}
    `;
  }

}
