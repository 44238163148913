<div class="mt-4">
  <app-column-general-options-form
    [formColumnConfig]="viewModel.formColumnConfig$ | push"
    [columnSectionKey]="viewModel.columnSectionKey$ | push"
    [formMergeKey]="formMergeKey"
    [inputDisabled]="viewModel.inputDisabled$ | push"
    [sectionLayoutType]="viewModel.sectionLayoutType$ | push"
    [defaultColumnName]="defaultColumnName"
    (defaultStateChanged)="viewModel.connectToChangedDefaultState($event)"
    (changes)="viewModel.connectToChanges($event)">
  </app-column-general-options-form>

  <app-column-style-options-form
    [sectionColumnConfig]="viewModel?.formColumnConfig$ | async"
    [formMergeKey]="formMergeKey"
    [columnOptionName]="viewModel?.columnSectionKey$ | async"
    [enabled]="!(viewModel?.inputDisabled$ | async)"
    [hideTextColor]="viewModel?.hideTextOptions$ | async"
    [hideTextOptions]="viewModel?.hideTextOptions$ | async">
  </app-column-style-options-form>
</div>
