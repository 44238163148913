import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class AdminUser implements Deserializable, UniquelyIdentifiable {

  public userId: string;
  public dateCreated: number;
  public cognitoId: string;
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public hasProfilePicture: boolean;
  public emailConfirmed: boolean;
  public accountConfirmed: boolean;
  public lastSession: number;
  public isCompanyAdmin: boolean;
  public companyRole: string;
  public productOnlyAccess: boolean;

  public onDeserialize() {

  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }

  setFromOtherUser(u: AdminUser) {
    this.dateCreated = u.dateCreated;
    this.cognitoId = u.cognitoId;
    this.email = u.email;
    this.firstName = u.firstName;
    this.lastName = u.lastName;
    this.hasProfilePicture = u.hasProfilePicture;
    this.emailConfirmed = u.emailConfirmed;
    this.accountConfirmed = u.accountConfirmed;
    this.lastSession = u.lastSession;
    this.companyRole = u.companyRole;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.userId}
      ${this.dateCreated}
      ${this.cognitoId}
      ${this.email}
      ${this.firstName}
      ${this.lastName}
      ${this.hasProfilePicture}
      ${this.emailConfirmed}
      ${this.accountConfirmed}
      ${this.lastSession}
      ${this.isCompanyAdmin}
      ${this.companyRole}
    `;
  }

}
