declare global {
  interface String {
    spaceOutCamelString(): string;
    stripWhiteSpace(): string;
    stripWhiteSpaceAndLowerCase(): string;
    pluralizer(): Pluralizer;
  }
}

String.prototype.spaceOutCamelString = function() {
  return this.replace(/([A-Z])/g, ' $1').trim();
};

String.prototype.stripWhiteSpace = function() {
  return this.trim().replace(/\s/g, '');
};

String.prototype.stripWhiteSpaceAndLowerCase = function() {
  return this.trim().replace(/\s/g, '').toLowerCase();
};

type PluralizerRule = { isPlural?: boolean; listConnection: any[]; word: string; useApostrophe: boolean };
type Pluralizer = { addRule: (rule: PluralizerRule) => Pluralizer; pluralize: () => string };
String.prototype.pluralizer = function(): Pluralizer {
  const rules: PluralizerRule[] = [];
  let pluralized: string = this;
  const addRule = (rule: PluralizerRule) => {
    rules.push(rule);
    return { addRule, pluralize };
  };
  const pluralize = (): string => {
    for (const rule of rules) {
      if (rule?.isPlural || (rule?.listConnection?.length !== 1)) {
        pluralized = pluralized.replace(rule.word, rule.word + (rule.useApostrophe ? '\'' : '') + 's');
      }
    }
    return pluralized;
  };
  return { addRule, pluralize };
};

export class StringUtils {

  static lowercaseFirstCharacter(s: string): string {
    return (s.length > 1) ? s.charAt(0).toLowerCase() + s.slice(1) : s;
  }

  static uppercaseFirstCharacter(s: string): string {
    return (s.length > 1) ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }

  static normalizeCharacters(s: string): string {
    // First replace all non-ASCII characters, then replace all duplicate spaces with a single space
    return s.normalize('NFD')
      .replace(/[^a-zA-Z0-9\-_().\s]/g, '')
      .replace(/\s\s+/g, ' ');
  }

  static camelize(str) {
    return str.replace(
      /^\w|[A-Z]|\b\w/g,
      (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())
    ).replace(/\s+/g, '');
  }

  /**
   * Pascal case, or PascalCase, is a variable naming convention in programming in which the first letter of each word
   * is uppercase and the remaining letters are lowercase, with no spaces or other separators between words.
   */
  static toPascalCase(s: string, separator: string = ' ') {
    return s?.split(separator)?.map(word => StringUtils.uppercaseFirstCharacter(word))?.join('');
  }

  static lowerCaseAndStripWhiteSpace(str: string): string {
    return str.toLowerCase().replace(/\s/g, '');
  }

  static getStringMode(items: string[]): string {
    const modeCounts = {};
    items?.forEach(item => modeCounts[item] = (modeCounts[item] || 0) + 1);
    const sortedModeCounts = Object.keys(modeCounts)?.sort((a, b) => modeCounts[b] - modeCounts[a]);
    return sortedModeCounts?.firstOrNull();
  }

  static splitPascalCaseIntoSentence(s: string) {
    return s?.split(/(?=[A-Z])/)?.join(' ');
  }

  static kebabCaseToTitleCase(s: string) {
    return s?.split('-')?.map(StringUtils.uppercaseFirstCharacter)?.join(' ');
  }

}
