import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class BannerMessage implements Deserializable, UniquelyIdentifiable {

  message: string;
  clearAfter: number;
  id: string; // not from API

  onDeserialize() {}

  getUniqueIdentifier(): string {
    return `
      -${this.message}
      -${this.clearAfter}
    `;
  }

}
