import { Asset } from '../../../../../../models/image/dto/asset';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';

export class CardSizePreviews {

  public existingAsset: Asset;
  public uploadedImage: BudsenseFile;
  public previewCardSize: string;

  constructor(existingAsset: Asset, uploadedFile: BudsenseFile) {
    this.existingAsset = existingAsset;
    this.uploadedImage = uploadedFile;
    this.previewCardSize = '';
  }

}
