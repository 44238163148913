import { TypeDefinition } from './type-definition';

export enum PrintCardProperty {
  Name = 'Name',
  Brand = 'Brand',
  Manufacturer = 'Manufacturer',
  SKU = 'SKU',
  Barcode = 'Barcode',
  Description = 'Description',
  StrainType = 'StrainType',
  UnitSize = 'UnitSize',
  PackageQuantity = 'PackagedQuantity',
  ProductTypeVariantType = 'ProductTypeVariantType',
  Strain = 'Strain',
  IsMedical = 'IsMedical',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
  Label = 'Label',
  Badges = 'Badges',
  CompanyLogo = 'CompanyLogo',
  THC = 'THC',
  CBD = 'CBD',
  TAC = 'TAC',
  EnabledSecondaryCannabinoids = 'EnabledSecondaryCannabinoids',
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
  PresentTerpenes = 'PresentTerpenes',
  EnabledTerpenes = 'EnabledTerpenes'
}

export class PrintCardPropertyDefinition extends TypeDefinition {

  override value: PrintCardProperty;

  public static getProductPropertyList = [
    PrintCardProperty.Name,
    PrintCardProperty.Brand,
    PrintCardProperty.Manufacturer,
    PrintCardProperty.SKU,
    PrintCardProperty.Barcode,
    PrintCardProperty.Description,
    PrintCardProperty.StrainType,
    PrintCardProperty.UnitSize,
    PrintCardProperty.PackageQuantity,
    PrintCardProperty.ProductTypeVariantType,
    PrintCardProperty.Strain,
    PrintCardProperty.IsMedical,
    PrintCardProperty.Price,
    PrintCardProperty.SecondaryPrice,
    PrintCardProperty.Label,
    PrintCardProperty.Badges,
    PrintCardProperty.CompanyLogo
  ];

  public static getCannabinoidPropertyList = [
    PrintCardProperty.THC,
    PrintCardProperty.CBD,
    PrintCardProperty.TAC,
    PrintCardProperty.EnabledSecondaryCannabinoids
  ];

  public static getTerpenePropertyList = [
    PrintCardProperty.TopTerpene,
    PrintCardProperty.TotalTerpene,
    PrintCardProperty.PresentTerpenes,
    PrintCardProperty.EnabledTerpenes
  ];

  public isProductProperty(): boolean {
    return PrintCardPropertyDefinition.getProductPropertyList.includes(this.value);
  }

  public isCannabinoidProperty(): boolean {
    return PrintCardPropertyDefinition.getCannabinoidPropertyList.includes(this.value);
  }

  public isPrimaryCannabinoidProperty(): boolean {
    return this.value === PrintCardProperty.CBD || this.value === PrintCardProperty.THC;
  }

  public isTerpeneProperty(): boolean {
    return PrintCardPropertyDefinition.getTerpenePropertyList.includes(this.value);
  }

  public isCollectiveTerpeneProperty(): boolean {
    return this.value === PrintCardProperty.TopTerpene || this.value === PrintCardProperty.TotalTerpene;
  }

}
