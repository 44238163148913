import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../../../../models/base/base-modal-view-model';
import { DisplayAttributeGroupDetails } from '../../../../../../models/product/dto/display-attribute-group-details';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import type { BsError } from '../../../../../../models/shared/bs-error';
import type { Variant } from '../../../../../../models/product/dto/variant';

@Injectable()
export class CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalViewModel extends BaseModalViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private readonly _modalMode = new BehaviorSubject<'create'|'update'>('create');
  public readonly modalMode$ = this._modalMode as Observable<'create'|'update'>;
  connectToModalMode = (x: 'create'|'update') => this._modalMode.next(x);

  public readonly createMode$ = this.modalMode$.pipe(map(x => x === 'create'));
  public readonly updateMode$ = this.modalMode$.pipe(map(x => x === 'update'));

  private readonly _editThisDetailsId = new BehaviorSubject<string|null>(null);
  public readonly editThisDetailsId$ = this._editThisDetailsId as Observable<string|null>;
  connectToEditThisDetailsId = (id: string) => this._editThisDetailsId.next(id);

  private readonly _displayAttributeGroupId = new BehaviorSubject<string|null>(null);
  public readonly displayAttributeGroupId$ = this._displayAttributeGroupId as Observable<string|null>;
  connectToDisplayAttributeGroupId = (id: string) => this._displayAttributeGroupId.next(id);

  private readonly _groupDetailsName = new BehaviorSubject<string|null>(null);
  public readonly groupDetailsName$ = this._groupDetailsName as Observable<string|null>;
  connectToGroupDetailsName = (name: string) => this._groupDetailsName.next(name);

  private readonly _liveVariantBarcodes = new BehaviorSubject<Map<string, string[]>>(null);
  public readonly liveVariantBarcodes$ = this._liveVariantBarcodes as Observable<Map<string, string[]>>;
  connectToLiveVariantBarcodes = (liveMap: Map<string, string[]>) => this._liveVariantBarcodes.next(liveMap);

  private readonly _currentVariantBarcodes = new BehaviorSubject<string[]|null>(null);
  public readonly currentVariantBarcodes$ = this._currentVariantBarcodes as Observable<string[]|null>;
  connectToCurrentVariantBarcodes = (codes: string[]) => this._currentVariantBarcodes.next(codes);

  private readonly _universalBudSenseVariants = new BehaviorSubject<Variant[]>([]);
  public readonly universalBudSenseVariants$ = this._universalBudSenseVariants as Observable<Variant[]>;
  connectToUniversalBudSenseVariants = (x: Variant[]) => this._universalBudSenseVariants.next(x);

  public readonly disabledVariantBarcodes$ = combineLatest([
    this.editThisDetailsId$,
    this.liveVariantBarcodes$,
  ]).pipe(
    map(([detailsId, liveMap]) => {
      const disabledIds = [];
      liveMap?.forEach((value, key) => {
        if (key !== detailsId) disabledIds.push(...value);
      });
      return disabledIds;
    })
  );

  public readonly request$ = combineLatest([
    this.displayAttributeGroupId$,
    this.currentVariantBarcodes$
  ]).pipe(
    map(([displayAttributeGroupId, barcodes]) => {
      const request = new DisplayAttributeGroupDetails();
      request.displayAttributeGroupId = displayAttributeGroupId;
      request.variantBarcodes = barcodes?.shallowCopy();
      return request;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  variantBarcodeParser(variantBarcodes: string[]) {
    return variantBarcodes instanceof Array
      ? variantBarcodes?.filterNulls()
      : [];
  }

  formSubmitted(): void {
    this.modalMode$.once(mode => {
      switch (mode) {
        case 'create':
          this.createDisplayAttributeGroupDetails();
          break;
        case 'update':
          this.updateDisplayAttributeGroup();
          break;
      }
    });

  }

  createDisplayAttributeGroupDetails(): void {
    this.request$.once(request => {
      const lm = 'Creating Display Attribute Group Details';
      this._loadingOpts.addRequest(lm);
      this.productDomainModel.createDisplayAttributeGroupDetails(request).subscribe({
        next: (created) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Display Attribute Group Details Created', 'Success');
          this.activeModal.close(created);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
        }
      });
    });
  }

  updateDisplayAttributeGroup() {
    this.request$.once(request => this.activeModal.close(request?.variantBarcodes));
  }

}
