import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartSyncLocationSelectModalViewModel } from './smart-sync-location-select-modal-view-model';
import { Company } from '../../../../models/company/dto/company';
import { CompanySmartSyncRequestType } from '../../../../models/company/enum/company-smart-sync-request-type.enum';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-smart-sync-location-select-modal',
  templateUrl: './smart-sync-location-select-modal.component.html',
  styleUrls: ['./smart-sync-location-select-modal.component.scss'],
  providers: [SmartSyncLocationSelectModalViewModel]
})
export class SmartSyncLocationSelectModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: SmartSyncLocationSelectModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setCompanyAndRequestType(company: Company, requestType: CompanySmartSyncRequestType) {
    this.viewModel.setCompany(company);
    this.viewModel.setSmartSyncRequestType(requestType);
  }

  clearSelection() {
    this.viewModel.clearSelection();
  }

  syncLocations() {
    this.viewModel.selectedLocationIds$.pipe(take(1)).subscribe(selectedLocationIds => {
      this.activeModal.close(selectedLocationIds);
    });
  }

}
