import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { ProductType } from '../../../../../utils/product-type-definition';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../functions/exists';

@Injectable()
export class ProductAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor() {
    super();
  }

  private _selectedProductType = new BehaviorSubject<ProductType>(null);
  public selectedProductType$ = this._selectedProductType as Observable<ProductType>;
  public enableVariantType$ = combineLatest([
    this.isViewOnly$,
    this.selectedProductType$
  ]).pipe(
    map(([isViewOnly, selectedProductType ]) => {
      return !isViewOnly && exists(selectedProductType);
    })
  );

  public connectToSelectedProductType = (pt: ProductType) => this._selectedProductType.next(pt);

}
