import { Pipe, PipeTransform } from '@angular/core';
import { SyncType, SyncTypeType } from '../../../../../../../../utils/sync-type-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getSyncTypeDefinition'
})
export class GetSyncTypeDefinitionPipe implements PipeTransform {

  transform(value: SyncType): Observable<SyncTypeType> {
    return window.types.syncTypes$.pipe(
      map(syncTypes => syncTypes?.find(syncType => syncType?.value === value))
    );
  }

}
