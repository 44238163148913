import { TypeDefinition } from './type-definition';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  ReversePortrait = 'reverseportrait',
  NA = ''
}

export class OrientationType extends TypeDefinition {

  override value: Orientation;

  static getOrientationsWithoutReversePortrait(): Observable<OrientationType[]> {
    return window.types.orientations$.pipe(
      map(orientations => orientations?.filter(o => o?.value !== Orientation.ReversePortrait))
    );
  }

}
