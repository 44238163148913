import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { exists } from '../../functions/exists';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig || route.routeConfig.loadChildren || route.queryParamMap.keys.length > 0) {
      return false;
    }
    return exists(route.data.cacheRoute);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[this.getKey(route)] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return exists(route.routeConfig) && exists(this.handlers[this.getKey(route)]);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren || route.queryParamMap.keys.length > 0) {
      return null;
    }
    return this.handlers[this.getKey(route)];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return curr.routeConfig === future.routeConfig;
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    const params = route.queryParamMap.keys.length > 0 ? JSON.stringify(route.queryParams) : '';
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/') + params;
  }

  private getKey(route: ActivatedRouteSnapshot) {
    return this.getResolvedUrl(route);
  }

}
