<div class="variant-usages-section-title">{{ sectionTitle }}</div>

<ng-container *ngIf="usageCountMap.size > 0; else NAUsages">
  <div *ngFor="let usageCount of usageCountMap | keyvalue" class="usages-data-row">
    <div class="usages-data-key">
      {{ usageCount.key }}
      <img class="usages-location-img" [src]="'assets/icons/dark/outline/office-building.svg'" />
    </div>
    <div class="usages-data-count">{{ usageCount.value }}x</div>
  </div>
</ng-container>

<ng-template #NAUsages>
  <div class="usages-data-row">
    <div class="usages-data-key">N/A</div>
  </div>
</ng-template>

<hr />
