import { Selectable } from '../../protocols/selectable';

export class SelectableBadgeSection implements Selectable {

  public name: string = '';

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.name;
  }

  getSelectionValue(): any {
    return this.name;
  }

}
