import { Deserializable } from '../../protocols/deserializable';

export class RefreshSessionRequest implements Deserializable {

  public userId: string = '';
  public refreshToken: string = '';

  constructor(userId?: string, refreshToken?: string) {
    this.userId = userId;
    this.refreshToken = refreshToken;
  }

  onDeserialize() {
  }

}
