import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';

@Component({ template: '' })
export abstract class BaseSmartFilterFormComponent extends BaseComponent {

  @Input() mergeKey: string;
  @Input() bindTo: HydratedSmartFilter;
  @Input() isViewOnly: boolean = false;
  @Input() isEditing: boolean = false;

}
