import { Deserializable } from '../models/protocols/deserializable';
import { Selectable } from '../models/protocols/selectable';
import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';

export class TypeDefinition implements Deserializable, Selectable, UniquelyIdentifiable {

  public value: any;
  public name: string;
  public description: string;
  public metadata: any;

  constructor(value: any = null, name: string = null) {
    this.value = value;
    this.name = name;
  }

  onDeserialize() {
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.value;
  }

  getSelectionValue(): any {
    return this.value;
  }

  getUniqueIdentifier(): string {
    return `${this.value}
          -${this.name}`;
  }

  getSelectionDescription(): string {
    return this.description;
  }

}

