<div *ngIf="reactiveFormGroup$ | async as formGroup" [formGroup]="reactiveFormGroupComponent?.getReactiveFormGroup()">
  <div class="inline-picker-container" [ngClass]="{ disabled: disabled }">
    <span [style.display]="'contents'" [style.pointer-events]="disabled ? 'none' : ''">
      <div *ngIf="(badges$ | async)?.length === 0 || !(badges$ | async)" class="f12px bs-medium">{{ addText }}</div>
      <div *ngIf="(badges$ | async)?.length > 0 && !!(badges$ | async)" class="badge-list">
        <ng-container *ngFor="let badge of badges$ | async">
          <div class="badge-img me-2">
            <app-asset [asset]="badge?.image" [scaleFit]="true"> </app-asset>
            <app-remove-button
              appThrottleClick
              class="remove-button"
              [width]="'1rem'"
              [height]="'1rem'"
              [throttleInMilliSec]="1000"
              (throttleClick)="updateBadges(badge)">
            </app-remove-button>
          </div>
        </ng-container>
      </div>

      <app-inline-picker-action-button
        *ngIf="(badges$ | async)?.length < maxBadgesAllowed"
        [disabled]="disabled"
        [canEdit]="!!(badges$ | async) && (badges$ | async)?.length > 0"
        [popper]="popperContent"
        [popperShowOnStart]="false"
        [popperTrigger]="ClientTypeUtils.NgxPopperjsTriggers.click"
        [popperModifiers]="popperModifier"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="false"
        [popperPreventOverflow]="false"
        [popperPositionFixed]="true"
        [popperApplyClass]="'badge-picker-popper'"
        [popperStyles]="popperStyles"
        [popperPlacement]="popperPlacement">
      </app-inline-picker-action-button>
      <popper-content #popperContent [style.position]="'fixed'" [style.z-index]="1050">
        <app-badge-picker
          class="badge-picker"
          [preselectedBadgeIds]="badgeIds$ | async"
          [maximumNumberOfBadges]="maxBadgesAllowed"
          (selected)="updateBadges($event)">
        </app-badge-picker>
      </popper-content>
    </span>
  </div>
</div>
