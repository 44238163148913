import { Deserializable } from '../../protocols/deserializable';

export class SessionChallenge implements Deserializable {

  public challengeName: string;
  public challengeParameters: Map<string, string>;
  public authSession: string;

  public onDeserialize() {
    if (!(this.challengeParameters instanceof Map)) {
      this.challengeParameters = window.injector.Deserialize.genericMap(this.challengeParameters);
    }
  }

}
