import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-divider',
  templateUrl: './section-divider.component.html',
  styleUrls: ['./section-divider.component.scss']
})
export class SectionDividerComponent {

  @Input() topMargin: string = '1.5rem';
  @Input() bottomMargin: string = '1.5rem';
  @Input() color: string = '#F2F2F2';
  constructor() {}

}
