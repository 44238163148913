import { Deserializable } from '../../protocols/deserializable';
import { Selectable } from '../../protocols/selectable';
import { ThemeFeatures } from './theme-features';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { Asset } from '../../image/dto/asset';
import { MenuColumnCountConfig } from './menu-column-count-config';
import { PrintConfig } from './print-config';
import { ThemeSectionColumnConfig } from './theme-section-column-config';
import { MenuType, MenuTypeDefinition } from '../../../utils/menu-type-definition';
import { MenuSubtype } from '../../../utils/menu-subtype-definition';
import { DefaultPrintCardSize } from '../../../utils/default-print-card-size-type';
import { DefaultPrintLabelSize } from '../../../utils/default-print-label-size-type';
import { SectionColumnConfigKeyType } from '../../../utils/section-column-config-key-type';

export class Theme implements Deserializable, Selectable, Cachable {

  public id: string = null;
  public name: string = null;
  public description: string = null;
  public portraitPreviewImages: Asset[];
  public landscapePreviewImages: Asset[];
  public supportedMenuTypes: MenuType[] = [];
  public menuSubType: MenuSubtype = null;
  public themeFeatures: ThemeFeatures;
  public companyIds: number[] = [];
  public usedByCompanyIds: number[];
  public menuColumnCountConfig: MenuColumnCountConfig;
  public printConfig: PrintConfig;
  public sectionColumnConfig: Map<string, ThemeSectionColumnConfig>;
  // Cache
  public cachedTime: number;

  constructor() {
    if (!this.sectionColumnConfig) {
      this.sectionColumnConfig = new Map<string, ThemeSectionColumnConfig>();
      SectionColumnConfigKeyType.getAllKeys()?.forEach((configKeyVal) => {
          this.sectionColumnConfig.set(configKeyVal, new ThemeSectionColumnConfig());
        });
      this.appendKeyToColumnConfig();
    }
  }

  static buildArrayCacheKey(companyId: number): string {
    return `Themes-${companyId}`;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  // Cache

  static buildCacheKey(id: string): string {
    return `Theme-${id}`;
  }

  public onDeserialize() {
    this.portraitPreviewImages = window.injector.Deserialize.arrayOf(Asset, this.portraitPreviewImages);
    this.landscapePreviewImages = window.injector.Deserialize.arrayOf(Asset, this.landscapePreviewImages);
    this.themeFeatures = window.injector.Deserialize.instanceOf(ThemeFeatures, this.themeFeatures);
    this.menuColumnCountConfig =
      window.injector.Deserialize.instanceOf(MenuColumnCountConfig, this.menuColumnCountConfig);
    if (!!this.printConfig) {
      this.printConfig = window.injector.Deserialize.instanceOf(PrintConfig, this.printConfig);
    } else {
      this.printConfig = new PrintConfig();
    }
    if (!this.sectionColumnConfig) {
      this.sectionColumnConfig = new Map<string, ThemeSectionColumnConfig>();
    }
    if (!(this.sectionColumnConfig instanceof Map)) {
      this.sectionColumnConfig =
        window.injector.Deserialize.mapOf(ThemeSectionColumnConfig, this.sectionColumnConfig);
    }
    this.appendKeyToColumnConfig();
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneWeek();
  }

  cacheKey(...params: any): string {
    return Theme.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  firstPortraitPreviewImage() {
    if (this.supportedMenuTypes.some(menuType => MenuTypeDefinition.containsStackedContent(menuType))) {
      const previewImage = this.getStackPreviewImage();
      if (!!previewImage) return previewImage;
    }
    return this.portraitPreviewImages?.find(i => !!i);
  }

  firstLandscapePreviewImage() {
    if (this.supportedMenuTypes.some(menuType => MenuTypeDefinition.containsStackedContent(menuType))) {
      const previewImage = this.getStackPreviewImage();
      if (!!previewImage) return previewImage;
    }
    return this.landscapePreviewImages?.find(i => !!i);
  }

  private getStackPreviewImage(): Asset {
    const sizes = [
      ...Object.values(DefaultPrintCardSize),
      ...Object.values(DefaultPrintLabelSize)
    ];
    let previewHashes: string[];
    // find the first asset hash on the card size order above
    sizes.some(cs => {
      previewHashes = this.printConfig?.previewCardMap?.get(cs);
      return !!previewHashes && previewHashes?.length > 0;
    });
    // always use landscape previews
    return this.landscapePreviewImages?.find(i => previewHashes?.includes(i?.md5Hash));
  }

  public isPrivateTheme(): boolean {
    return this.companyIds.length > 0;
  }

  appendKeyToColumnConfig() {
    // Must save reference to the key in the config for purposes of from group
    this.sectionColumnConfig.forEach((val, key) => {
      val.key = key;
    });
  }

  validPrivateThemeCriteria(): boolean {
    const privatelyUsedByTheseCompanyIds = this.usedByCompanyIds?.intersection(this.companyIds);
    const themeIsPrivate = !!this.companyIds?.length;
    const themeInUse = !!this.usedByCompanyIds?.length;
    const usageIsEqualToPrivateUsage = this.usedByCompanyIds?.length === privatelyUsedByTheseCompanyIds?.length;
    return !themeIsPrivate || (themeIsPrivate && (!themeInUse || usageIsEqualToPrivateUsage));
  }

  containsStackedContent(): boolean {
    return this.supportedMenuTypes.some(menuType => MenuTypeDefinition.containsStackedContent(menuType));
  }

  isPrint(): boolean {
    return this.supportedMenuTypes.includes(MenuType.PrintMenu);
  }

  isPrintCard(): boolean {
    return this.supportedMenuTypes.includes(MenuType.PrintCardMenu);
  }

  isPrintLabel(): boolean {
    return this.supportedMenuTypes.includes(MenuType.PrintLabelMenu);
  }

  isPrintReport(): boolean {
    return this.supportedMenuTypes.includes(MenuType.PrintReportMenu);
  }

  isDigital(): boolean {
    return this.supportedMenuTypes.includes(MenuType.DisplayMenu)
      || this.menuSubType.includes(MenuType.WebMenu)
      || this.menuSubType.includes(MenuType.MarketingMenu);
  }

}
