<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky max-out" fxLayout="column">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign=" center">
      <div fxFlex class="modal-title">Select Smart Filters</div>
      <div (click)="viewModel.clearSelections()" class="text-link">Clear</div>
    </div>
    <app-search-with-look-ahead
      [disablePopper]="true"
      [placeHolder]="'Search by name'"
      [nLookAheadItems]="10"
      [lookAheadItems]="viewModel.searchableSmartFilters$ | async"
      [searchTheseProperties]="['name', 'category']"
      (searchText)="viewModel.searchText($event)"
      (lookAheadHits)="viewModel.searchHits($event)">
    </app-search-with-look-ahead>
  </div>

  <div class="sticky-body-container position-relative" fxFlex>
    <div [hidden]="viewModel.loadingSmartFilters$ | async">
      <ng-container *ngFor="let selectable of viewModel?.groupedHits$ | async">
        <app-smart-filter-selection-group
          *ngIf="selectable | isSmartFilterGrouping"
          [grouping]="selectable | castAsSmartFilterGrouping"
          [previouslyAddedIds]="viewModel.previouslyAddedSmartFilterIds$ | async"
          [selectedIds]="viewModel.selectedSmartFilterIds$ | async"
          (add)="viewModel.addSelectedId($event)"
          (remove)="viewModel.removeSelectedId($event)"
          (bulkAdd)="viewModel.addSelectedIds($event)"
          (bulkRemove)="viewModel.removeSelectedIds($event)">
        </app-smart-filter-selection-group>
        <app-smart-filter-selection
          *ngIf="selectable | isHydratedSmartFilter"
          [selection]="selectable | castAsHydratedSmartFilter"
          [previouslyAddedIds]="viewModel.previouslyAddedSmartFilterIds$ | async"
          [selectedIds]="viewModel.selectedSmartFilterIds$ | async"
          (add)="viewModel.addSelectedId($event)"
          (remove)="viewModel.removeSelectedId($event)">
        </app-smart-filter-selection>
      </ng-container>
    </div>
    <div
      *ngIf="viewModel.showNoResults$ | async"
      class="no-search-results-container"
      fxLayout="column"
      fxLayoutGap="0.5rem">
      <div class="bs-section-list-title">No Results</div>
      <div class="bs-edit-section-description">We couldn't find any smart filters matching your search criteria.</div>
    </div>
  </div>

  <div class="sticky-footer-container mt-3" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="0.5rem">
    <button (click)="cancel()" class="bs-button neutral-button no-margin">Cancel</button>
    <button (click)="viewModel.spitOutNewlySelectedIds()" class="bs-button preferred-button no-margin">Add</button>
  </div>
</div>
