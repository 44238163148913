<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"></app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Add Asset</div>
      <div class="modal-subtitle">{{ viewModel.assetGroupName$ | async }}</div>
    </div>
  </div>

  <div class="sticky-body-container add-asset-form-container">
    <app-upload-asset
      #editAssetUpload
      [allowTypes]="['image']"
      [displayList]="false"
      [isHidden]="viewModel?.showImagePreview$ | async"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
    <div class="mt-3 pt-1 pb-3" *ngIf="viewModel?.showImagePreview$ | async">
      <image-cropper
        class="image-cropper"
        format="png"
        [ngStyle]="{ 'background-color': viewModel?.backgroundColor$ | async }"
        [imageURL]="$any((viewModel.imgToUpload$ | async)?.url) || (viewModel.assetImgUrl$ | async)"
        [maintainAspectRatio]="true"
        [aspectRatio]="viewModel?.aspectRatio$ | async"
        (imageCropped)="imageCropped($event)"
        [containWithinAspectRatio]="true"
        [alignImage]="'center'"
        [hideResizeSquares]="false"
        [transform]="transform"
        [output]="'base64'">
      </image-cropper>
      <div class="scale-image-controls">
        <ng-container i18n>Scale:</ng-container>
        <img [src]="'assets/icons/dark/outline/minus-circle.svg'" alt="" (click)="scaleImageDown()" />
        <img [src]="'assets/icons/dark/outline/plus-circle.svg'" alt="" (click)="scaleImageUp()" />
      </div>
    </div>

    <div class="image-upload-controls">
      <div
        class="aspect-ratio-controls"
        fxLayout="row"
        fxLayoutAlign="center"
        fxLayoutGap="2rem"
        *ngIf="viewModel?.showAspectRatioControls$ | async">
        <app-segmented-control
          [options]="viewModel.aspectRatioOptions$ | async"
          (optionSelected)="viewModel?.aspectRatioSelected($event)">
        </app-segmented-control>

        <div class="rotate-aspect-ratio" (click)="viewModel.invertAspectRatio()"></div>
      </div>

      <lib-button-neutral
        *ngIf="(!!(viewModel.asset$ | async) || (viewModel.imgToUpload$ | async)) && !(viewModel.replaceImg$ | async)"
        (buttonClicked)="this.viewModel.toggleReplaceImg()"
        class="center">
        Replace Image
      </lib-button-neutral>
    </div>

    <lib-reactive-form-group
      #assetType
      class="mt-2"
      (canSubmit$)="viewModel?.canSubmitForm$.next($event)"
      (formSubmitted$)="addAsset()">
      <lib-reactive-form-drop-down
        #assetLibraryTypeSelection
        [inputName]="'assetLibraryType'"
        [label]="'Asset Library Type'"
        [placeholder]="'Select Asset Library Type'"
        [dropdowns]="viewModel?.libraryTypesDropdowns$ | async"
        [required]="true"
        (valueChanged)="viewModel?.changeBackgroundWithLibraryType($event)"></lib-reactive-form-drop-down>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral class="mx-3" (buttonClicked)="cancel()">Cancel</lib-button-neutral>
    <lib-button-primary (buttonClicked)="assetType.submitForm()" [disabled]="!(viewModel?.canSubmit$ | async)">
      Done
    </lib-button-primary>
  </div>
</div>
