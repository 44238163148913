/**
 * All logic around Provider specific functionality should be contained in one place.
 * This will ensure new POS providers are accounted for in all scenarios of the software.
 */
import { InventoryProvider } from './inventory-provider-type';
import { SyncType, SyncTypeType } from './sync-type-type';
import { Deserializable } from '../models/protocols/deserializable';
import { HasId } from '../models/protocols/has-id';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export class ProviderUtils {

  static getInventoryProviderLogoPath(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Blaze:
        return '/assets/img/company/blaze.png';
      case InventoryProvider.BudSense:
        return '/assets/img/company/budsense.png';
      case InventoryProvider.Cova:
        return '/assets/img/company/cova.png';
      case InventoryProvider.Dutchie:
        return '/assets/img/company/dutchie.png';
      case InventoryProvider.FlowHub:
        return '/assets/img/company/flowhub.png';
      case InventoryProvider.Greenline:
        return '/assets/img/company/greenline.png';
      case InventoryProvider.GrowFlow:
        return '/assets/img/company/growflow.png';
      case InventoryProvider.IndicaOnline:
        return '/assets/img/company/indicaonline.png';
      case InventoryProvider.TechPOS:
        return '/assets/img/company/techpos.png';
      case InventoryProvider.TendyPOS:
        return '/assets/img/company/tendypos.png';
      case InventoryProvider.Treez:
        return '/assets/img/company/treez.png';
    }
    return '';
  }

  static supportsPromotions(inventoryProvider: InventoryProvider): boolean {
    // Blaze & Treez support promotions, but not implemented yet
    // TODO support GrowFlow when access granted to API
    return inventoryProvider === InventoryProvider.Cova ||
      inventoryProvider === InventoryProvider.Greenline ||
      inventoryProvider === InventoryProvider.Dutchie;
  }

  static supportsPOSLotInfo(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Blaze
      || inventoryProvider === InventoryProvider.Cova
      || inventoryProvider === InventoryProvider.GrowFlow
      || inventoryProvider === InventoryProvider.Dutchie
      || inventoryProvider === InventoryProvider.TendyPOS
      || inventoryProvider === InventoryProvider.Treez;
  }

  static supportsLocationCreation(ip: InventoryProvider): boolean {
    return ip === InventoryProvider.Dutchie ||
      ip === InventoryProvider.Treez ||
      ip === InventoryProvider.Blaze ||
      ip === InventoryProvider.BudSense;
  }

  static supportsSettingProviderSpecifications(ip: InventoryProvider): boolean {
    return ip === InventoryProvider.Cova;
  }

  static supportsPOSLocationSync(ip: InventoryProvider): boolean {
    return ip === InventoryProvider.Cova ||
      ip === InventoryProvider.FlowHub ||
      ip === InventoryProvider.Greenline ||
      ip === InventoryProvider.GrowFlow ||
      ip === InventoryProvider.IndicaOnline ||
      ip === InventoryProvider.Dutchie ||
      ip === InventoryProvider.TechPOS ||
      ip === InventoryProvider.TendyPOS ||
      ip === InventoryProvider.Treez ||
      ip === InventoryProvider.Blaze;
  }

  // Location Creation Properties

  static requireNewLocationDetails(ip: InventoryProvider): boolean {
    return ProviderUtils.supportsLocationCreation(ip) &&
      (ip === InventoryProvider.Treez ||
        ip === InventoryProvider.BudSense);
  }

  static supportsLocationPrimaryAPIKey(ip: InventoryProvider): boolean {
    return ProviderUtils.supportsLocationCreation(ip) &&
      (ip === InventoryProvider.Dutchie ||
        ip === InventoryProvider.Treez ||
        ip === InventoryProvider.Blaze);
  }

  static supportsLocationSecondaryAPIKey(ip: InventoryProvider): boolean {
    return ProviderUtils.supportsLocationCreation(ip) &&
      ip === InventoryProvider.Dutchie;
  }

  static isPOSProvider(ip: InventoryProvider): boolean {
    return ip !== InventoryProvider.BudSense;
  }

  static primaryLocationAPIKeyName(ip: InventoryProvider): string {
    switch (ip) {
      case InventoryProvider.Dutchie:
        return 'Rec API Key';
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
        return 'API Key';
      default:
        return null;
    }
  }

  static secondaryLocationAPIKeyName(ip: InventoryProvider): string {
    switch (ip) {
      case InventoryProvider.Dutchie:
        return 'Med API Key';
      default:
        return null;
    }
  }

  static supportsLocationSpecificPOSSyncing(ip: InventoryProvider): boolean {
    return ip === InventoryProvider.Cova
      || ip === InventoryProvider.FlowHub
      || ip === InventoryProvider.Greenline
      || ip === InventoryProvider.GrowFlow;
  }

  static supportsSyncSinceLastUpdated(ip: InventoryProvider): boolean {
    return ip === InventoryProvider.Cova
      || ip === InventoryProvider.Greenline
      || ip === InventoryProvider.IndicaOnline
      || ip === InventoryProvider.GrowFlow;
  }

  static supportsLocationUsername(ip: InventoryProvider): boolean {
    return ProviderUtils.supportsLocationCreation(ip) &&
      ip === InventoryProvider.Treez;
  }

  static locationUsernameName(ip: InventoryProvider): string {
    switch (ip) {
      case InventoryProvider.Treez:
        return 'Dispensary Name';
      default:
        return null;
    }
  }

  static locationUsernameTooltip(ip: InventoryProvider): string {
    switch (ip) {
      case InventoryProvider.Treez:
        return 'https://api.treez.io/v2.0/dispensary/{DispensaryName}/';
      default:
        return null;
    }
  }

  static supportsUsePurposeOnLocationCreate(ip: InventoryProvider): boolean {
    if (!ProviderUtils.supportsLocationCreation(ip)) {
      return false;
    } else {
      // Provider must support manual location creation and have a way to denote med/rec products in the POS
      switch (ip) {
        case InventoryProvider.Treez:
          return true;
        default:
          return false;
      }
    }
  }

  static supportsUsePurposeManualEditing(ip: InventoryProvider): boolean {
    switch (ip) {
      case InventoryProvider.Treez:
      case InventoryProvider.Cova:
        // Only providers that can handle UsePurpose and do not set it via API can be manually edited
        return true;
      default:
        return false;
    }
  }

  static getSyncFullProductInfoSyncTypes(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider
  ): SyncType[] {
    switch (inventoryProvider) {
      case InventoryProvider.TechPOS:
        return [SyncType.Product, SyncType.Inventory, SyncType.Pricing];
      case InventoryProvider.Greenline:
      case InventoryProvider.GrowFlow:
        return [SyncType.Product, SyncType.Pricing];
      case InventoryProvider.Cova:
      case InventoryProvider.TendyPOS:
      case InventoryProvider.FlowHub:
        return [SyncType.Inventory, SyncType.Pricing];
      case InventoryProvider.Treez:
        return (companyConfig?.syncPOSCannabinoid || companyConfig?.syncPOSTerpene)
          ? [SyncType.Product, SyncType.Inventory, SyncType.Pricing, SyncType.LotInfo]
          : [SyncType.Product, SyncType.Inventory, SyncType.Pricing];
      case InventoryProvider.Blaze:
        const syncTypes = [SyncType.Product, SyncType.Inventory, SyncType.Pricing];
        if (companyConfig?.syncPOSCannabinoid || companyConfig?.syncPOSTerpene) {
          syncTypes.push(SyncType.LotInfo);
        }
        if (companyConfig?.syncPOSLabels) {
          syncTypes.push(SyncType.Labels);
        }
        return syncTypes;
      case InventoryProvider.IndicaOnline:
        return companyConfig?.syncPOSLabels ?
          [SyncType.Product, SyncType.Pricing, SyncType.Labels] : [SyncType.Product, SyncType.Pricing];
      default:
        return [];
    }
  }

  static getUserAllowableManualSyncTypes(inventoryProvider: InventoryProvider): SyncType[] {
    const allowedSyncTypes = [SyncType.FullProductInfo, SyncType.Product, SyncType.Inventory, SyncType.Pricing];
    if (ProviderUtils.supportsPOSLocationSync(inventoryProvider)) {
      allowedSyncTypes.push(SyncType.Location);
    }
    if (ProviderUtils.supportsSyncSinceLastUpdated(inventoryProvider)) {
      allowedSyncTypes.splice(1, 0, SyncType.ProductAudit);
    }
    return allowedSyncTypes;
  }

  static getManualSyncTypeGroupings(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
  ): SyncTypeGrouping[] {
    const groupings = ProviderUtils.getUserAllowableManualSyncTypes(inventoryProvider)
      ?.map(primarySyncType => new SyncTypeGrouping(companyConfig, inventoryProvider, primarySyncType))
      ?.filter(grouping => !grouping?.isEmpty()) ?? [];
    const duplicatesRemoved = groupings.shallowCopy();
    groupings.forEach(grouping => {
      const findDuplicates = duplicatesRemoved
        ?.filter(g => g?.childSyncTypes?.intersection(grouping?.childSyncTypes)?.length > 0)
        ?.sort((a, b) => a?.childSyncTypes?.length - b?.childSyncTypes?.length);
      if (findDuplicates?.length > 1) {
        duplicatesRemoved?.remove(findDuplicates?.firstOrNull());
      }
    });
    return duplicatesRemoved;
  }

  static getPromotionsTabName(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Dutchie:
      case InventoryProvider.Greenline:
        return 'Discounts';
      default:
        return 'Promotions';
    }
  }

  static supportsInventoryRooms(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Blaze
      || inventoryProvider === InventoryProvider.Cova
      || inventoryProvider === InventoryProvider.Dutchie
      || inventoryProvider === InventoryProvider.Treez;
  }

}

// Needs to be in this file to avoid circular import
export class SyncTypeGrouping implements Deserializable, HasId {

  constructor(
    compConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
    public parentSyncType: SyncType,
  ) {
    const fullProductInfoSyncTypes = ProviderUtils.getSyncFullProductInfoSyncTypes(compConfig, inventoryProvider);
    if (parentSyncType === SyncType.FullProductInfo) {
      const getSubtypeGrouping = (type) => SyncTypeGrouping.getSubtypeGrouping(compConfig, inventoryProvider, type);
      const groupings = fullProductInfoSyncTypes?.length > 0
        ? fullProductInfoSyncTypes?.map(getSubtypeGrouping)
        : [];
      const duplicatedRemoved = groupings.shallowCopy();
      groupings.forEach(grouping => {
        const findDuplicates = duplicatedRemoved
          ?.filter(g => g?.intersection(grouping)?.length > 0)
          ?.sort((a, b) => a?.length - b?.length);
        if (findDuplicates?.length > 1) {
          duplicatedRemoved?.remove(findDuplicates?.firstOrNull());
        }
      });
      this.childSyncTypes = duplicatedRemoved;
      return;
    }
    if (!fullProductInfoSyncTypes?.includes(parentSyncType)) {
      this.childSyncTypes = [SyncTypeGrouping.getSubtypeGrouping(compConfig, inventoryProvider, parentSyncType)];
    }
  }

  public childSyncTypes: SyncType[][];

  static getSubtypeGrouping(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
    primarySyncType: SyncType
  ): SyncType[] {
    switch (primarySyncType) {
      case SyncType.Product: {
        return companyConfig?.syncPOSLabels
          ? [SyncType.Product, SyncType.Labels]
          : [SyncType.Product];
      }
      case SyncType.Inventory: {
        const supportsLotInfo = ProviderUtils.supportsPOSLotInfo(inventoryProvider);
        const syncPOSLotInfo = companyConfig?.syncPOSCannabinoid || companyConfig?.syncPOSTerpene;
        return (supportsLotInfo && syncPOSLotInfo)
          ? [SyncType.Inventory, SyncType.LotInfo]
          : [SyncType.Inventory];
      }
      case SyncType.Pricing: {
        return ProviderUtils.supportsPromotions(inventoryProvider)
          ? [SyncType.Pricing, SyncType.Promotions]
          : [SyncType.Pricing];
      }
    }
    return [primarySyncType];
  }

  static buildFromId(id: string): SyncTypeGrouping {
    return window.injector.Deserialize.instanceOf(SyncTypeGrouping, JSON.parse(id));
  }

  onDeserialize() {
  }

  getId(): string {
    return JSON.stringify(this);
  }

  getHumanReadableParentName(provider: InventoryProvider): Observable<string> {
    return window.types.syncTypes$.pipe(
      switchMap(syncTypes => {
        const child = this.childSyncTypes?.firstOrNull();
        const isSingleLineMultiSync = (this.childSyncTypes?.length === 1) && (child?.length > 1);
        return isSingleLineMultiSync
          ? this.getChildSyncTypeNames(provider).pipe(map(childNames => childNames?.firstOrNull()))
          : this.getParentSyncTypeName(syncTypes, provider);
      }),
      take(1)
    );
  }

  getParentSyncTypeName(syncTypes: SyncTypeType[], provider: InventoryProvider): Observable<string> {
    const syncType = syncTypes?.find(t => t?.value === this.parentSyncType);
    switch (true) {
      case syncType?.value === SyncType.Location && ProviderUtils.supportsInventoryRooms(provider):
        return of(`${syncType?.name} / Inventory Rooms`);
      default:
        return of(syncType?.name);
    }
  }

  private getHumanReadableChildSyncTypeNames = (syncTypes: SyncTypeType[], provider: InventoryProvider): string[] => {
    return this.childSyncTypes?.map(types => {
      return types
        ?.map(subType => {
          return subType === SyncType.Promotions
            ? ProviderUtils.getPromotionsTabName(provider)
            : syncTypes?.find(t => t?.value === subType)?.name;
        })
        ?.filterNulls()
        ?.join(' / ');
    });
  };

  getChildSyncTypeNames(provider: InventoryProvider): Observable<string[]> {
    return window.types.syncTypes$.pipe(
      map(types => this.getHumanReadableChildSyncTypeNames(types, provider)),
      take(1)
    );
  }

  hasDisplayableChildren(): boolean {
    return this.childSyncTypes?.length > 1;
  }

  isEmpty(): boolean {
    return !this.childSyncTypes?.length;
  }

}
