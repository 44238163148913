import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReorderOptions } from '../../../../models/shared/stylesheet/reorder-options';
import { Orderable } from '../../../../models/protocols/orderable';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Observable } from 'rxjs';
import { ToastService } from '../../../../services/toast-service';
import { BsError } from '../../../../models/shared/bs-error';

@Component({
  selector: 'app-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['./reorder-modal.component.scss']
})
export class ReorderModalComponent extends BaseModalComponent implements AfterViewInit {

  public loadingOpts: LoadingOptions = LoadingOptions.default();
  public reorderOptions: ReorderOptions;
  public items: Orderable[];
  public reorderOperation: (items: Orderable[]) => Observable<any>;
  private initialOrderIds: string[] = [];

  @ViewChild('modalBody') modalBody: HTMLDivElement;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super(activeModal);
  }

  setReorderOptions(opts: ReorderOptions) {
    this.reorderOptions = opts;
  }

  setReorderItems<T extends Orderable>(items: Orderable[], objectVal: new () => T) {
    // Create new instance of objects as to not affect the original memory address
    this.items = window.injector.Deserialize.arrayOf(objectVal, items);
    this.initialOrderIds = items.map(i => i.getOrderableUniqueId());
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override cancel() {
    this.activeModal.close(false);
  }

  continue() {
    const lm = this.reorderOptions.loadingMess;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.reorderOperation(this.items).subscribe((success) => {
        if (success) {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(this.reorderOptions.successMess, this.reorderOptions.successTitle);
          this.activeModal.close(this.items);
        } else {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishErrorMessage(this.reorderOptions.failureMess, this.reorderOptions.failureTitle);
        }
      }, (error: BsError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  orderHasChanged(): boolean {
    return !this.initialOrderIds.equals(this.items.map(i => i.getOrderableUniqueId()));
  }

}
