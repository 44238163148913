import { Pipe, PipeTransform } from '@angular/core';
import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';

@Pipe({
  name: 'getSelectableTitleFromValue'
})
export class GetSelectableTitleFromValuePipe implements PipeTransform {

  transform(value: any, selectables: Selectable[] | null): string | null {
    const selection = selectables?.find((s) => s?.getSelectionValue() === value);
    const isString = typeof value === 'string';
    return selection?.getSelectionTitle() || (isString ? value : null);
  }

}
