import { ApiClient } from './api-client';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Theme } from '../models/menu/dto/theme';

@Injectable({
  providedIn: 'root'
})

export class ThemeApi implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Theme';

  // Themes

  public getThemes(): Observable<Theme[]> {
    const url = Endpoints.getAllThemes();
    return this.apiClient.getArr<Theme>(Theme, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetThemes', err));
        return throwError(err);
      })
    );
  }

  public createTheme(theme: Theme): Observable<Theme> {
    const url = Endpoints.createTheme();
    return this.apiClient.postObj(Theme, url, theme).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createTheme', err));
        return throwError(err);
      })
    );
  }

  public updateTheme(theme: Theme): Observable<Theme> {
    const url = Endpoints.updateTheme();
    const payload = Object.assign({}, theme);
    payload.landscapePreviewImages = undefined;
    payload.portraitPreviewImages = undefined;
    return this.apiClient.postObj(Theme, url, payload).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateTheme', err));
        return throwError(err);
      })
    );
  }

}
