<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<div class="modal-container">
  <div class="modal-body sticky edit-variant-modal-body">
    <div class="sticky-header-container">
      <div class="modal-header">
        <div class="modal-title">
          {{ viewModel.incompleteVariantFlow ? 'Complete Variant' : 'Edit Variant' }}
          <button
            (click)="seeUsages()"
            [disabled]="!(viewModel?.seeUsagesButtonEnabled$ | async)"
            [ngClass]="{ disabled: !(viewModel?.seeUsagesButtonEnabled$ | async) }"
            class="bs-button neutral-button footer-button">
            See Usages <img [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="" />
          </button>
        </div>
        <div class="modal-subtitle-container">
          <div class="modal-subtitle inline-subtitle">
            {{ viewModel?.variant?.name }}
            <img *ngIf="viewModel?.variant?.cultivatorVerified" src="assets/icons/dark/solid/badge-check.svg" alt="" />
          </div>
        </div>
        <div class="mt-3" *ngIf="viewModel?.showUnconfirmedInfoBanner">
          <app-message-banner
            message="All UBVs require manual confirmation that all of the information is complete. If everything you see appears correct, just press the ‘Done’ button to mark this variant as confirmed.">
          </app-message-banner>
        </div>
      </div>
    </div>

    <div #modalBody class="sticky-body-container">
      <app-edit-variant-form
        #editVariantFormComponent
        [incompleteVariantFlow]="viewModel.incompleteVariantFlow"
        [mergeKey]="'edit-variant-form'"
        [bindTo]="viewModel.variantFormObject">
      </app-edit-variant-form>
    </div>

    <div class="sticky-footer-container modal-footer-flex-end">
      <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
      <button (click)="saveChanges()" class="bs-button preferred-button">
        {{ viewModel.incompleteVariantFlow ? 'Done' : 'Save' }}
      </button>
    </div>
  </div>

  <div *ngIf="viewModel?.showUsages$ | async" class="usages-modal">
    <div class="sticky-header-container">
      <div class="modal-header">
        <div class="modal-title">
          Usages
          <button (click)="seeUsages()" class="close-usage-button footer-button">
            <img [src]="'assets/icons/dark/outline/x.svg'" />
          </button>
        </div>
      </div>

      <app-variant-usages [variants]="viewModel?.variantsFromBarcode$ | async"></app-variant-usages>
    </div>
  </div>
</div>
