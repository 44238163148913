import { Component, Input } from '@angular/core';
import { SyncTypeType } from '../../../../../../../../utils/sync-type-type';
import { SyncJobResults } from '../../../../../../../../models/automation/sync-job-results';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { InventoryProvider } from '../../../../../../../../utils/inventory-provider-type';

@Component({
  selector: 'app-review-sync-list-item',
  templateUrl: './review-sync-list-item.component.html',
  styleUrls: ['./review-sync-list-item.component.scss']
})
export class ReviewSyncListItemComponent extends BaseComponent {

  @Input() syncType: SyncTypeType;
  @Input() syncJobResults: SyncJobResults;
  @Input() provider: InventoryProvider;

}
