import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChange, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { StickyAutoPositionService } from '../../../services/sticky-auto-position.service';
import { ResizeObserver } from '@juggle/resize-observer';
import { exists } from '../../../functions/exists';

@Directive({
  selector: '[appStickyAutoPosition]'
})
export class StickyAutoPositionDirective implements AfterViewInit, OnChanges, OnDestroy {

  constructor(
    private stickyAutoPositionService: StickyAutoPositionService,
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) { }

  @Input() stickyBackgroundColor: string;
  private _backgroundColor = new BehaviorSubject<string>(null);
  public backgroundColor$ = this._backgroundColor.pipe(distinctUntilChanged());

  @Input() stickyPositionEnabled: boolean = true;
  private _stickyPositionEnabled = new BehaviorSubject(true);
  public stickyPositionEnabled$ = this._stickyPositionEnabled.pipe(distinctUntilChanged());

  @Input() stickyCollectionKey: string;
  private _stickyCollectionKey = new BehaviorSubject<string>(null);
  public stickyCollectionKey$ = this._stickyCollectionKey.pipe(distinctUntilChanged());

  @Input() stickChildClassInstead: string;
  private _stickChildClassInstead = new BehaviorSubject<string>(null);
  public stickChildClassInstead$ = this._stickChildClassInstead.pipe(distinctUntilChanged());

  @Input() stickyZ: number;
  private _stickyZ: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public stickyZ$ = this._stickyZ.pipe(distinctUntilChanged());

  @Input() stickyOrder: number;
  private _stickyOrder: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  private elementSub: Subscription;
  private element$ = this.stickChildClassInstead$.pipe(
    map(childClass => {
      return exists(childClass)
        ? this.elementRef.nativeElement.getElementsByClassName(childClass)?.item(0)
        : this.elementRef.nativeElement;
    })
  );

  private myStickyCollection$ = combineLatest([
    this.stickyAutoPositionService.stickyCollections$,
    this.stickyCollectionKey$
  ]).pipe(
    map(([collections, key]) => collections.get(key) || [])
  );

  private resizeObserver: ResizeObserver;
  private _myHeight = new BehaviorSubject<number>(0);
  public myHeight$ = this._myHeight.pipe(distinctUntilChanged());

  private runningHeightSub: Subscription;

  public positionRunningHeight$ = this.myStickyCollection$.pipe(
    switchMap((collection) => {
      const asc = (a: StickyAutoPositionDirective, b: StickyAutoPositionDirective) => a?.stickyOrder - b?.stickyOrder;
      const sortedCollection = collection?.sort(asc);
      const myListIndex = sortedCollection?.findIndex(item => item === this);
      if (myListIndex > -1) {
        const runningList: StickyAutoPositionDirective[] = sortedCollection?.slice(0, myListIndex) || [];
        if (runningList?.length) {
          return combineLatest(runningList?.map(d => d.myHeight$)).pipe(
            map(heights => heights?.reduce((a, b) => a + b))
          );
        }
      }
      return of(0);
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stickyCollectionKey) this.handleKeyChange(changes.stickyCollectionKey);
    if (changes.stickyBackgroundColor) this._backgroundColor.next(this.stickyBackgroundColor);
    if (changes.stickyPositionEnabled) this._stickyPositionEnabled.next(this.stickyPositionEnabled);
    if (changes.stickChildClassInstead) this._stickChildClassInstead.next(this.stickChildClassInstead);
    if (changes.stickyZ) this._stickyZ.next(this.stickyZ);
    if (changes.stickyOrder) {
      this._stickyOrder.next(this.stickyOrder);
      this.stickyAutoPositionService.positionUpdated(this);
    }
  }

  private handleKeyChange(stickyCollectionKey: SimpleChange): void {
    this._stickyCollectionKey.next(this.stickyCollectionKey);
    const prevKey = stickyCollectionKey.previousValue;
    const currKey = stickyCollectionKey.currentValue;
    if (!prevKey && exists(currKey)) {
      this.stickyAutoPositionService.addToCollection(this);
    } else if (exists(prevKey) && !currKey) {
      this.stickyAutoPositionService.removeFromCollection(this, prevKey);
    } else if (exists(prevKey) && exists(currKey)) {
      this.stickyAutoPositionService.removeFromCollection(this, prevKey);
      this.stickyAutoPositionService.addToCollection(this);
    }
  }

  ngAfterViewInit(): void {
    this.elementSub = this.element$.subscribe(element => {
      this.observePosition(element);
    });

    this.runningHeightSub = combineLatest([
      this.element$,
      this.backgroundColor$,
      this.positionRunningHeight$,
      this.stickyZ$,
      this.stickyAutoPositionService.stickyCollections$,
      this.stickyPositionEnabled$
    ]).subscribe(([element, backgroundColor,  positionRunningHeight, z, collection, enabled]) => {
      if (!element) return;
      if (enabled) {
        const topPosition = (collection?.size > 0 && collection[0] === this) ? '0' : `${positionRunningHeight}px`;
        this.renderer2?.setStyle(element, 'background-color', backgroundColor);
        this.renderer2?.setStyle(element, 'position', 'sticky');
        this.renderer2?.setStyle(element, 'top', topPosition);
        this.renderer2?.setStyle(element, 'z-index', z);
      } else {
        this.renderer2?.removeStyle(element, 'background-color');
        this.renderer2?.removeStyle(element, 'position');
        this.renderer2?.removeStyle(element, 'top');
        this.renderer2?.removeStyle(element, 'z-index');
      }
    });
  }

  private observePosition(element: any): void {
    this.resizeObserver?.disconnect();
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this._myHeight.next(Math.floor(entry?.contentRect?.bottom));
      }
    });
    if (exists(element)) this.resizeObserver?.observe(element);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.runningHeightSub?.unsubscribe();
    this.elementSub?.unsubscribe();
    this.stickyAutoPositionService.removeFromCollection(this);
  }

}
