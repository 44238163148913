import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../../../models/company/dto/company';

@Pipe({
  name: 'companyListFilter',
  pure: false
})

export class CompanyListFilterPipe implements PipeTransform {

  transform(items: Company[], filterVal: string): any {
    if (!items || filterVal.length < 3) {
      return items;
    } else {
      return items.filter(item => item.name.toLowerCase().includes(filterVal.toLowerCase())
        || item.locations.some(l => l.name.toLowerCase().includes(filterVal.toLowerCase())));
    }
  }

}
