<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-group #createForm [bindTo]="viewModel.request$ | async" (formSubmitted$)="viewModel.formSubmitted()">
  <div class="modal-body">
    <div appStickyAutoPosition [stickyZ]="2" [stickyCollectionKey]="'create-additional-details-group'">
      <div class="modal-header no-bottom-border">
        <div class="modal-title-container">
          <ng-container *ngIf="viewModel.createMode$ | async; else updateMode">
            <div class="modal-title">Create Additional Details Group</div>
          </ng-container>
          <ng-template #updateMode>
            <div class="modal-title">Update Variants in Group Details</div>
            <div class="modal-subtitle">{{ viewModel.groupDetailsName$ | async }}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div
      appStickyAutoPosition
      [stickyCollectionKey]="'create-additional-details-group'"
      [stickyZ]="2"
      [stickChildClassInstead]="'text-input'">
      <lib-reactive-form-text
        *ngIf="viewModel.createMode$ | async"
        appStickyAutoPosition
        class="text-input"
        [inputName]="'detailsGroupName'"
        [label]="'Details Group Name'"
        [placeholder]="'Enter details group name'"
        [required]="true"
        [bindingProperty]="'groupName'">
      </lib-reactive-form-text>
      <lib-reactive-form-checkboxes
        [customValueParser]="viewModel.variantBarcodeParser"
        [inputName]="'selectUnassignedUBVs'"
        [label]="'Select Applicable Universal BudSense Variants'"
        [bindingProperty]="'variantBarcodes'"
        [hideBulkCheckbox]="true"
        [requireMinimumSelection]="1">
        <lib-reactive-form-label
          [inputName]="'selectUnassignedUBVs'"
          [showRequiredAstrix]="true"
          [label]="'Select Applicable Universal BudSense Variants'">
        </lib-reactive-form-label>
        <lib-reactive-form-column-layout nColumns="1" rowGap="0.5rem">
          <ng-container *ngFor="let v of viewModel.universalBudSenseVariants$ | async">
            <lib-reactive-form-checkbox
              [inputName]="'UBV-' | randomString"
              [label]="v.name + ' (' + v?.packagedQuantity + ' x ' + v?.unitSize + v?.unitOfMeasure + ')'"
              [disabled]="viewModel.disabledVariantBarcodes$ | async | listIncludes : v?.barcode"
              [disabledTooltip]="'Already assigned to another group'"
              [selectedValue]="v?.barcode"
              [notSelectedValue]="null">
            </lib-reactive-form-checkbox>
          </ng-container>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-checkboxes>
    </div>

    <div class="sticky-footer-container modal-footer-flex-end">
      <lib-button-neutral (buttonClicked)="cancel()" class="me-2">Cancel</lib-button-neutral>
      <lib-button-primary
        class="ms-2"
        [disabled]="createForm.disableSubmit$ | async"
        (buttonClicked)="createForm?.submitForm()">
        {{ (viewModel.createMode$ | async) ? 'Create' : 'Update' }}
      </lib-button-primary>
    </div>
  </div>
</lib-reactive-form-group>
