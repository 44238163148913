<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">
        {{ viewModel?.asset?.fileName }}
      </div>
    </div>
  </div>

  <div
    class="sticky-body-container asset-preview-container"
    [ngClass]="{ 'alternate-brand-asset': viewModel?.libraryTypeName === 'Alternate Brand' }">
    <app-asset
      class="asset-preview-image"
      [asset]="viewModel?.asset"
      [styleOverrides]="viewModel?.imgStyleOverrides"
      [size]="Types.AssetSize.Large"
      [scaleFit]="true"></app-asset>

    <div *ngIf="!!viewModel?.libraryTypeName" class="asset-library-type">{{ viewModel?.libraryTypeName }}</div>
    <div *ngIf="!viewModel?.libraryTypeName" class="asset-library-type">
      {{ viewModel?.isDisabled ? 'Disabled' : '--' }}
    </div>
  </div>

  <div class="modal-footer-flex-end pt-3 mt-3 me-3 mb-3">
    <button class="bs-button destructive-button" *ngIf="viewModel?.canDelete" (click)="deleteAsset()">
      Delete Asset
    </button>
    <button (click)="close(null)" class="bs-button neutral-button">Done</button>
  </div>
</div>
