<div
  matRipple
  fxLayout="row"
  fxLayoutAlign="center center"
  class="remove-button-container"
  [style.borderRadius]="borderRadius"
  [style.height]="height"
  [style.width]="width"
  [style.zIndex]="zIndex"
  [style.backgroundColor]="backgroundColor">
  <img class="x-icon" [src]="'assets/icons/dark/solid/x.svg'" alt="x icon" />
</div>
