import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { MenuType } from '../../../utils/menu-type-definition';

@Pipe({
  name: 'menuType',
  pure: false
})

export class MenuTypePipe implements PipeTransform {

  transform(items: Menu[], type: string): any {
    if (!items && !type) {
      return items;
    }
    if (type === 'display') {
      return items.filter(item => item.type === MenuType.DisplayMenu);
    } else if (type === 'web') {
      return items.filter(item =>
        (item.type === MenuType.WebMenu));
    } else if (type === 'marketing') {
      return items.filter(item =>
        (item.type === MenuType.MarketingMenu));
    } else if (type === 'print') {
      return items.filter(item =>
        (item.type === MenuType.PrintMenu));
    }
  }

}
