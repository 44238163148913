import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InventoryProviderSpecification } from '../../../../../../../../../models/company/dto/inventory-provider-specification';
import { CompanyConfiguration } from '../../../../../../../../../models/company/dto/company-configuration';
import { InventoryProvider } from '../../../../../../../../../utils/inventory-provider-type';

@Component({
  selector: 'app-provider-specifications-accordion',
  templateUrl: './provider-specifications-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderSpecificationsAccordionComponent {

  @Input() companyConfiguration: CompanyConfiguration;
  @Input() inventoryProvider: InventoryProvider;
  @Input() mergeKey: string;
  @Input() providerSpecifications: InventoryProviderSpecification[];

}
