import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModelComponent } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';

@Injectable()
export class CannabinoidAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModelComponent {

  public primaryCannabinoids$ = of(Object.values(PrimaryCannabinoid));
  public secondaryCannabinoids$: Observable<string[]> = window.types.secondaryCannabinoids$.pipe(
    map(secondaryCannabinoidTypes => secondaryCannabinoidTypes?.map(sct => sct?.getSelectionValue()))
  );

}
