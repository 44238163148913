import { Deserializable } from '../../protocols/deserializable';

export class SignInNewPasswordRequest implements Deserializable {

  public userId: string = '';
  public password: string = '';
  public session: string = '';

  onDeserialize() {
  }

}
