import { Component } from '@angular/core';
import { AssignVariantToAssetGroupViewModel } from './assign-variant-to-asset-group-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../../../../models/product/dto/variant';
import { AssetGroup } from '../../../../models/product/dto/asset-group';

@Component({
  selector: 'app-assign-variant-to-asset-group-modal',
  templateUrl: './assign-variant-to-asset-group-modal.component.html',
  styleUrls: ['./assign-variant-to-asset-group-modal.component.scss'],
  providers: [AssignVariantToAssetGroupViewModel]
})
export class AssignVariantToAssetGroupModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: AssignVariantToAssetGroupViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setVariantToAssign(variant: Variant) {
    this.viewModel?.connectToVariantToAssign(variant);
  }

  setAssetGroups(assetGroups: AssetGroup[]) {
    this.viewModel?.connectToAssetGroups(assetGroups);
  }

}
