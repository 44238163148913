<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <div class="cannabinoid-title">
      <span class="text"> Primary Cannabinoids </span>
    </div>
    <lib-reactive-form-column-layout nColumns="2">
      <ng-container *rxFor="let primaryCannabinoid of viewModel.primaryCannabinoids$ | push">
        <lib-reactive-form-number
          [inputName]="'min' | randomString"
          [label]="'Minimum ' + primaryCannabinoid"
          [placeholder]="'Minimum ' + primaryCannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' +
            primaryCannabinoid +
            ' values greater than or equal to this value.'
          "
          [bindingProperty]="'min' + primaryCannabinoid">
        </lib-reactive-form-number>

        <lib-reactive-form-number
          [inputName]="'max' | randomString"
          [label]="'Maximum ' + primaryCannabinoid"
          [placeholder]="'Maximum ' + primaryCannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' +
            primaryCannabinoid +
            ' values less than or equal to this value.'
          "
          [bindingProperty]="'max' + primaryCannabinoid">
        </lib-reactive-form-number>
      </ng-container>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-pill-drop-down
      [inputName]="'presentSecondaryCannabinoids'"
      [label]="'Present Secondary Cannabinoids'"
      [emptyPlaceholder]="'Enter present secondary cannabinoids'"
      [placeholder]="'Enter present secondary cannabinoids'"
      [dropdowns]="viewModel.secondaryCannabinoidDropdowns$ | push"
      [bindingProperty]="'presentSecondaryCannabinoids'"></lib-reactive-form-pill-drop-down>

    <div class="cannabinoid-title" [style.margin-top.rem]="2">
      <span class="text"> Secondary Cannabinoids </span>
    </div>
    <lib-reactive-form-column-layout nColumns="2">
      <ng-container *rxFor="let cannabinoid of viewModel.secondaryCannabinoids$ | push">
        <lib-reactive-form-number
          [inputName]="'min' | randomString"
          [label]="'Minimum ' + cannabinoid"
          [placeholder]="'Minimum ' + cannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' +
            cannabinoid +
            ' values greater than or equal to this value.'
          "
          [bindingProperty]="'min' + cannabinoid">
        </lib-reactive-form-number>

        <lib-reactive-form-number
          [inputName]="'max' | randomString"
          [label]="'Maximum ' + cannabinoid"
          [placeholder]="'Maximum ' + cannabinoid + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' + cannabinoid + ' values less than or equal to this value.'
          "
          [bindingProperty]="'max' + cannabinoid">
        </lib-reactive-form-number>
      </ng-container>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</div>
