import { Deserializable } from '../../protocols/deserializable';
import { BannerMessage } from './banner-message';
import * as uuid from 'uuid';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class CustomerMessage implements UniquelyIdentifiable, Deserializable {

  constructor() {
    this.customerMessages = [];
    this.systemMessages = [];
  }

  customerMessages?: BannerMessage[];
  systemMessages?: BannerMessage[];

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.customerMessages = Deserialize?.arrayOf(BannerMessage, this.customerMessages);
    this.systemMessages = Deserialize?.arrayOf(BannerMessage, this.systemMessages);
  }

  addIdsSoWeCanMatchMessages(): void {
    this.customerMessages?.forEach(message => message.id = uuid?.v4());
    this.systemMessages?.forEach(message => message.id = uuid?.v4());
  }

  hasMessages(): boolean {
    return (this.customerMessages?.length > 0) || (this.systemMessages?.length > 0);
  }

  getAllMessagesWithCustomerMessagesFirst(): BannerMessage[] {
    return [...(this.customerMessages || []), ...(this.systemMessages || [])];
  }

  getUniqueIdentifier(): string {
    return `
      -${this.customerMessages?.map(msg => msg.getUniqueIdentifier())?.join(',')}
      -${this.systemMessages?.map(msg => msg.getUniqueIdentifier())?.join(',')}
    `;
  }

}
