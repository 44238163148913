import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectableComponent } from '../../../../../../../../../../shared/components/group-selection/selectable/selectable.component';
import { Location } from '../../../../../../../../../../../models/company/dto/location';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSelectionComponent extends SelectableComponent implements OnChanges {

  @Input() viewOnlyMode: boolean = false;
  @Input() showAddedOrRemovedStatus: boolean = false;
  @Input() originalLocationIds: number[];
  @Input() updatedLocationIds: number[];
  @Input() override selection: Location;
  @Input() isChild: boolean = true;
  @Input() disabled: boolean = false;

  protected override _selection = new BehaviorSubject<Location>(null);
  public override selection$ = super.selection$ as Observable<Location>;

}
