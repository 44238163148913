import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectableComponent } from '../../../components/group-selection/selectable/selectable.component';
import { AssetGroup } from '../../../../../models/product/dto/asset-group';

@Component({
  selector: 'app-brand-selection',
  templateUrl: './brand-selection.component.html',
  styleUrls: ['./brand-selection.component.scss']
})
export class BrandSelectionComponent extends SelectableComponent implements OnChanges {

  constructor() {
    super();
  }

  @Input() includeBottomBorder: boolean = true;
  @Input() maxNumOfSelectedBrands: number;
  @Input() selectingForFilter: boolean = true;

  protected override _selection: BehaviorSubject<AssetGroup> = new BehaviorSubject<AssetGroup>(null);
  public override selection$ = this._selection as Observable<AssetGroup>;
  public override id$ = this.selection$.pipe(map(selection => selection?.id));
  public name$ = this.selection$.pipe(map(selection => selection?.groupName));
  protected override _selectedIds = new BehaviorSubject<string[]>([]);
  public override selectedIds$ = this._selectedIds as Observable<string[]>;
  public override selected$ = combineLatest([this.id$, this.selectedIds$]).pipe(
    map(([id, ids]) => ids?.contains(id.toString()))
  );
  public selectionDisabled$ = combineLatest([
    this.selectedIds$,
    this.selected$
  ]).pipe(
    map(([selectedIds, selected]) => {
      return !selected && selectedIds?.length >= this.maxNumOfSelectedBrands && this.selectingForFilter;
    })
  );
  protected override _indeterminate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public override indeterminate$ = this._indeterminate as Observable<boolean>;

  override setupBindings(): void {
    if (this.selection instanceof AssetGroup) {
      this._selection.next(this.selection);
    }
    this._selectedIds.next(this.selectedIds);
  }

  override setupViews(): void {
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection && this.selection instanceof AssetGroup) { this._selection.next(this.selection); }
    if (changes.selectedIds) { this._selectedIds.next(this.selectedIds); }
  }

}
