import { Pipe, PipeTransform } from '@angular/core';
import { SyncTypeGrouping } from '../../../../../../../../utils/provider-utils';

@Pipe({
  name: 'hasDisplayableChildren'
})
export class HasDisplayableChildrenPipe implements PipeTransform {

  transform(value: SyncTypeGrouping): boolean {
    return value?.hasDisplayableChildren() || false;
  }

}
