import { TypeDefinition } from './type-definition';
import { ProvinceCode } from './province-type';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum VariantLookupType {
  AGLC = 'AGLC',
  BCCW = 'BCCW',
  MBLL = 'MBLL',
  OCS = 'OCS',
  OCS_Delisted = 'OCSDelisted',
}

export class VariantLookupTypeDefinition extends TypeDefinition {

  constructor(value: VariantLookupType = null, name: string = null) {
    super(value, name);
    this.value = value;
    this.name = name;
  }

  override value: VariantLookupType;

  static getVariantLookupTypesForProvince(
    variantLookupTypes$: Observable<VariantLookupTypeDefinition[]>,
    provinceCode: ProvinceCode
  ): Observable<VariantLookupTypeDefinition[]> {
    const variantLookupTypesForProvince$ = variantLookupTypes$?.pipe(
      map(variantLookupTypes => {
        const variantLookupTypesFor = (): VariantLookupType[] => {
          switch (provinceCode) {
            case ProvinceCode.AB: return [VariantLookupType.AGLC];
            case ProvinceCode.MB: return [VariantLookupType.MBLL];
            case ProvinceCode.BC: return [VariantLookupType.BCCW];
            case ProvinceCode.ON: return [VariantLookupType.OCS, VariantLookupType.OCS_Delisted];
            default:              return [];
          }
        };
        const byProvinceCode = (vlt: VariantLookupTypeDefinition) => variantLookupTypesFor().includes(vlt?.value);
        return variantLookupTypes?.filter(byProvinceCode);
      })
    );
    return variantLookupTypesForProvince$ ?? of<VariantLookupTypeDefinition[]>([]);
  }

}
