<div class="employee-container" fxLayout="row" fxLayoutAlign=" center">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
  <div class="employee-text" fxFlex>
    <div class="employee-name">
      {{ employee.firstName }} {{ employee.lastName }}
      <app-pill [clickable]="false" *ngIf="showIfAdmin && employee.isCompanyAdmin">Admin</app-pill>
    </div>
    <div class="employee-email-container" fxLayout="row">
      <img
        *ngIf="employee.accountConfirmed"
        [popper]="popperTooltip"
        [popperShowOnStart]="false"
        [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
        [popperModifiers]="popperModifier"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="true"
        [popperPreventOverflow]="false"
        [popperApplyClass]="'custom-popper-content'"
        [popperStyles]="toolTipStyle"
        [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMSTART"
        class="employee-info-icon"
        src="assets/icons/green/solid/check-circle.svg"
        alt="" />
      <img
        *ngIf="!employee.accountConfirmed"
        [popper]="popperTooltipErr"
        [popperShowOnStart]="false"
        [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
        [popperModifiers]="popperModifier"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="true"
        [popperPreventOverflow]="false"
        [popperApplyClass]="'custom-popper-content'"
        [popperStyles]="toolTipStyle"
        [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMSTART"
        class="employee-info-icon"
        src="assets/icons/red/solid/exclamation-circle.svg"
        alt="" />
      <popper-content #popperTooltip [style.z-index]="1">Confirmed</popper-content>
      <popper-content #popperTooltipErr [style.z-index]="1">Unconfirmed</popper-content>
      <div class="employee-email" fxFlex>{{ employee?.email }}</div>
    </div>
    <div *ngIf="showCompanyRole" class="employee-role-container mt-2px" fxLayout="row">
      <img class="employee-info-icon" src="assets/icons/dark/solid/user.svg" alt="" />
      <div class="employee-info-text" fxFlex>
        {{ viewModel?.employeeRole$ | async }}
      </div>
    </div>
    <div
      *ngIf="showProductOnlyAccess && (viewModel?.productOnlyEmployee$ | async)"
      class="employee-product-only-container mt-2px"
      fxLayout="row">
      <img class="employee-info-icon" src="assets/icons/dark/solid/view-grid.svg" alt="" />
      <div class="employee-info-text" fxFlex>Product Only Access</div>
    </div>
    <div *ngIf="showLastLogin" class="employee-last-login-container">
      <div class="employee-last-login" fxFlex>
        {{ viewModel.generateLastLoginText(employee?.lastSession) }}
      </div>
    </div>
  </div>
  <div *ngIf="showOptions" class="options-button">
    <div
      class="employee-item-action"
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="true"
      [popperPreventOverflow]="true"
      [popperPositionFixed]="true"
      [popperApplyArrowClass]="'d-none'"
      [popperApplyClass]="'custom-popper-content'"
      [popperStyles]="popperStyles"
      [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
      <img [src]="'assets/icons/dark/solid/dots-horizontal.svg'" alt="options" />
    </div>
    <popper-content #popperContent [style.z-index]="1">
      <div role="menuitem">
        <button matRipple class="bs-dropdown-menu-item" (click)="edit(); popperContent.hide()">Edit</button>
      </div>
      <div *ngIf="!this.employee.accountConfirmed" role="menuitem">
        <button matRipple class="bs-dropdown-menu-item" (click)="resendVerification(); popperContent.hide()">
          Resend Verification
        </button>
      </div>
      <div role="menuitem">
        <button matRipple class="bs-dropdown-menu-item red-text" (click)="delete(); popperContent.hide()">
          Delete
        </button>
      </div>
    </popper-content>
  </div>
</div>
<app-section-divider [bottomMargin]="'0'" [topMargin]="'0'"></app-section-divider>
