import { Deserializable } from '../../protocols/deserializable';
import { ProvinceCode } from '../../../utils/province-type';
import { VariantLookupType } from '../../../utils/variant-lookup-type-definition';

export class VariantLookupLog implements Deserializable {

  public id: string = null;
  public province: ProvinceCode = null;
  public lookupType: VariantLookupType = null;
  public dateCreated: number = null;
  public success: boolean = null;
  public note: string = null;
  public variantsAdded: number = null;
  public variantsUpdated: number = null;
  public bucket: string = null;
  public key: string = null;
  public fileName: string = null;

  public onDeserialize() {
  }

}
