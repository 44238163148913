import { Observable } from 'rxjs';
import { TypeDefinition } from './type-definition';
import { map } from 'rxjs/operators';

export class DefaultPrintSizeType extends TypeDefinition {

  // NA Sizes
  private static readonly PaperJuniorLegal = 'DefaultSize_JuniorLegal';
  private static readonly PaperGovernmentLetter = 'DefaultSize_GovernmentLetter';
  private static readonly PaperLetter = 'DefaultSize_Letter';
  private static readonly PaperLegal = 'DefaultSize_Legal';
  private static readonly PaperTabloid = 'DefaultSize_Tabloid';
  private static readonly PaperLedger = 'DefaultSize_Ledger';

  // International Sizes
  private static readonly PaperA0 = 'DefaultSize_A0';
  private static readonly PaperA1 = 'DefaultSize_A1';
  private static readonly PaperA2 = 'DefaultSize_A2';
  private static readonly PaperA3 = 'DefaultSize_A3';
  private static readonly PaperA4 = 'DefaultSize_A4';
  private static readonly PaperA5 = 'DefaultSize_A5';
  private static readonly PaperA6 = 'DefaultSize_A6';

  // Custom Sizes
  private static readonly CustomLargeFormat24x60 = 'DefaultSize_CustomLargeFormat24x60';

  constructor(value: string = null, name: string = null) {
    // Constructor should only be used when referencing 'dummy' instance
    super(value, name);
    this.value = value;
    this.name = name;
  }

  static getDefaultPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$;
  }

  static getNAPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$.pipe(map(t => this.NAPrintSizes(t)));
  }

  static getInternationalPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
    return window?.types?.printSizeTypes$.pipe(map(t => this.internationalPrintSizes(t)));
  }

  static getCustomPrintSizeOptions(): Observable<DefaultPrintSizeType[]> {
  return window?.types?.printSizeTypes$.pipe(map(t => this.customPrintSizes(t)));
}

  private static NAPrintSizes(t: DefaultPrintSizeType[]): DefaultPrintSizeType[] {
    const naSizes =  [
      this.PaperJuniorLegal,
      this.PaperGovernmentLetter,
      this.PaperLetter,
      this.PaperLegal,
      this.PaperTabloid,
      this.PaperLedger,
    ];
    return t.filter(types => naSizes.includes(types.value));
  }

  private static internationalPrintSizes(t: DefaultPrintSizeType[]): DefaultPrintSizeType[] {
    const intSizes =  [
      this.PaperA0,
      this.PaperA1,
      this.PaperA2,
      this.PaperA3,
      this.PaperA4,
      this.PaperA5,
      this.PaperA6
    ];
    return t.filter(types => intSizes.includes(types.value));
  }

  private static customPrintSizes(t: DefaultPrintSizeType[]): DefaultPrintSizeType[] {
    const customSizes = [
      this.CustomLargeFormat24x60
    ];
    return t.filter(types => customSizes.includes(types.value));
  }

}
