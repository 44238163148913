import { BaseModal } from '../../../../../../../../models/base/base-modal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderSpecificationsViewModel } from './provider-specifications-view-model';

@Component({
  selector: 'app-provider-specifications-modal',
  templateUrl: './provider-specifications-modal.component.html',
  styleUrls: ['./provider-specifications-modal.component.scss'],
  providers: [ProviderSpecificationsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderSpecificationsModalComponent extends BaseModal  {

  constructor(public viewModel: ProviderSpecificationsViewModel, activeModal: NgbActiveModal) {
    super(activeModal);
  }

  override setupViews(): void {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: close => { if (close) this.cancel(); }
    });
  }

}
