import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-theme-carousel-image',
  templateUrl: './theme-carousel-image.component.html',
  styleUrls: ['./theme-carousel-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeCarouselImageComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  @Output() cardButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  getCardClasses() {
    if (this.card?.customClass) {
      return `${this.cardClass} ${this.card.customClass}`;
    } else {
      return this.cardClass;
    }
  }

}
