import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';
import { InventoryProviderSpecification } from '../../../../../../../../../models/company/dto/inventory-provider-specification';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { CompanyConfiguration } from '../../../../../../../../../models/company/dto/company-configuration';
import { InventoryProvider } from '../../../../../../../../../utils/inventory-provider-type';
import { exists } from '../../../../../../../../../functions/exists';

@Component({
  selector: 'app-provider-specification-preview',
  templateUrl: './provider-specification-preview.component.html',
  styleUrls: ['./provider-specification-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderSpecificationPreviewComponent extends BaseComponent {

  @Input() companyConfiguration: CompanyConfiguration;
  @Input() inventoryProvider: InventoryProvider;
  @Input() specification: InventoryProviderSpecification;
  @Input() mergeKey: string;
  @ViewChild('specificationVariantProperty') specificationVariantProperty: ReactiveFormDropDownComponent;
  @Output() isIgnored = new EventEmitter<boolean>();

  variantPropertyDropdowns$ = window.types.variantProperties$;

  isIgnoredChanged(isIgnored: boolean) {
    if (isIgnored) {
      this.specificationVariantProperty.disabled = true;
      this.specificationVariantProperty.getSelfAsFormItem().setValue(undefined);
    } else {
      this.specificationVariantProperty.disabled = false;
      if (exists(this.specification.variantProperty)) {
        this.specificationVariantProperty.getSelfAsFormItem().setValue(this.specification.variantProperty);
      }
    }
    this.isIgnored.emit(isIgnored);
  }

}
