<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ options.modalTitle }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mt-24px">
    <app-upload-asset
      #changeMedia
      [id]="options.inputId"
      [allowTypes]="options.allowTypes$ | async"
      [maxAssets]="options.maxAssets"
      [maxFileSize]="options.maxFileSize"
      [parentHandler]="this">
    </app-upload-asset>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0">
    <lib-button-neutral (buttonClicked)="cancel()" class="mx-2">Cancel</lib-button-neutral>
    <lib-button-primary (buttonClicked)="handleChangeMedia()" [disabled]="assets.length === 0">
      Done
    </lib-button-primary>
  </div>
</div>
