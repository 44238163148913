<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-drop-down
        [inputName]="'productType'"
        [label]="'Product Type'"
        [placeholder]="'Select a product type'"
        [inlineLabel]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [required]="false"
        [dropdowns]="dropdownProductTypes$ | push"
        [tooltip]="'The smart filter will contain all products that match this product type.'"
        [bindingProperty]="'productType'"
        [clearable]="!(viewModel.isViewOnly$ | push)"
        [useClearableButton]="false"
        (valueChanged)="listenToProductType($event)">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-drop-down
        #variantType
        [inputName]="'variantType'"
        [label]="'Variant Type'"
        [placeholder]="'Select a variant type'"
        [inlineLabel]="false"
        [disabled]="!(viewModel.enableVariantType$ | push)"
        [required]="false"
        [clearable]="viewModel.enableVariantType$ | push"
        [useClearableButton]="false"
        [tooltip]="'The smart filter will contain all products that match this variant type.'"
        [dropdowns]="dropdownVariantTypes | push"
        [bindingProperty]="'variantType'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>

    <lib-reactive-form-drop-down
      [inputName]="'classification'"
      [label]="'Strain Type'"
      [placeholder]="'Select a strain type'"
      [inlineLabel]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [required]="false"
      [clearable]="!(viewModel.isViewOnly$ | push)"
      [useClearableButton]="false"
      [tooltip]="'The smart filter will contain all products that match this strain.'"
      [dropdowns]="dropdownClassificationTypes$ | push"
      [bindingProperty]="'classification'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-number
        [inputName]="'minPrice'"
        [label]="'Minimum Price'"
        [placeholder]="'Minimum price of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with a price greater than or equal to this value.'"
        [bindingProperty]="'minPrice'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'maxPrice'"
        [label]="'Maximum Price'"
        [placeholder]="'Maximum price of products'"
        [inlineLabel]="false"
        [required]="false"
        [integersOnly]="false"
        [step]="0.01"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="'The smart filter will include products with a price less than or equal to this value.'"
        [bindingProperty]="'maxPrice'">
      </lib-reactive-form-number>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</div>
