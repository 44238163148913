<mat-accordion multi [displayMode]="'flat'">
  <ng-container *ngFor="let spec of providerSpecifications; let first = first">
    <hr [class.mt-0]="first" />
    <mat-expansion-panel #panel class="mat-elevation-z0 border-1">
      <mat-expansion-panel-header class="no-background ps-0 pe-1">
        <mat-panel-title class="bs-medium f14px">
          {{ spec.displayName }}
          <img
            *ngIf="!spec.variantProperty && !spec.ignored"
            src="assets/icons/red/solid/exclamation-circle.svg"
            class="ml-8px"
            [style.height.rem]="1"
            [style.width.rem]="1" />
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-provider-specification-preview
        [companyConfiguration]="companyConfiguration"
        [inventoryProvider]="inventoryProvider"
        [specification]="spec"
        [mergeKey]="mergeKey">
      </app-provider-specification-preview>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
