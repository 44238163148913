import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { switchMap } from 'rxjs';

@Injectable()
export class TerpeneColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsModalViewModel
  ) {
    super();
  }

  public collectiveTerpeneColumnConfigsAndKeys$ = window.types.terpeneSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(k => k?.isCollectiveTerpeneKey()));
    })
  );

  public individualTerpeneColumnConfigsAndKeys$ = window.types.terpeneSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(k => k?.isIndividualTerpeneKey()));
    })
  );

}
