import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../models/base/base-modal.component';
import { Asset } from '../../../../../../models/image/dto/asset';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-theme-preview-modal',
  templateUrl: './theme-preview-modal.component.html',
  styleUrls: ['./theme-preview-modal.component.scss']
})
export class ThemePreviewModalComponent extends BaseModalComponent {

  existingAsset: Asset;
  uploadFile: BudsenseFile;

  constructor(
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

}
