<div class="modal-body">
  <div class="modal-title mb-3">Choose a location</div>

  <app-drop-down (selection)="selectionChanged($event)" [currentSelection]="activeLocation" [dropdowns]="locations">
  </app-drop-down>

  <div class="remember-me-container">
    <app-checkbox (clickEvent)="checkRemember($event)" [checked]="remember" [label]="'Set as default location'">
    </app-checkbox>
  </div>

  <div class="footer-button">
    <button (click)="cancel()" class="bs-button outlined-button">Cancel</button>
    <button (click)="save()" class="bs-button preferred-button">Save</button>
  </div>
</div>
