import { TypeDefinition } from './type-definition';

export enum SmartFilterSystemIdentifiersType {
  NewProducts = 'SF_NEW_PRODUCTS',
  RestockedProducts = 'SF_RESTOCKED_PRODUCTS'
}

export class SmartFilterSystemIdentifiersTypeDefinition extends TypeDefinition {

  override value: SmartFilterSystemIdentifiersType;

}
