import { ApiClient } from './api-client';
import { defer, Observable, of, throwError, timer } from 'rxjs';
import { Endpoints } from './endpoints';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { SignedUploadUrl } from '../models/shared/signed-upload-url';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError, concatMap, delayWhen, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaUtils } from '../utils/media-utils';
import { AssetUrl } from '../models/image/dto/asset-url';
import { Asset } from '../models/image/dto/asset';
import * as buffer from 'buffer';
import { exists } from '../functions/exists';

@Injectable({
  providedIn: 'root'
})

export class ImageAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient,
  ) {
  }

  // Variables

  public serviceName = 'Image';

  // Image

  public deleteAsset(id, md5: string): Observable<string> {
    const url = Endpoints.DeleteAsset(id, md5);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteAsset', err));
        return throwError(err);
      })
    );
  }

  public GenerateUploadUrl(req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    const url = Endpoints.GenerateUploadUrl();
    return this.apiClient.postObj(SignedUploadUrl, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GenerateUploadUrl', err));
        return throwError(() => err);
      })
    );
  }

  public GetPreview(
    locationId: number,
    displayId: string,
    menuId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> {
    const url = Endpoints.GetPreview(locationId, displayId, menuId, returnLastSaved, forceUpdate, previewOnly);
    const notForcedUrl = Endpoints.GetPreview(locationId, displayId, menuId, returnLastSaved, false, previewOnly);
    let retryCount = 0;
    // Want to return untyped obj so deserializeToInstance is ignored until after delay
    // Defer says: don't create my observable until I am subscribed to. Once subscribed, use the observable
    // factory to create my source. Everytime I'm subscribed to, redo the above. Retry logic always resubscribes
    // to the source observable, therefore, defer allows me to have a conditional source observable.
    return defer(() => this.apiClient.getUntypedObj(retryCount > 0 ? notForcedUrl : url)).pipe(
      retryWhen(errors$ => errors$.pipe(
          concatMap((err) => {
            if (err.status === 503) {
              retryCount++;
              return of(err.status);
            } else {
              const e = new BsError(err, this.serviceName);
              this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPreview', e));
              return throwError(e);
            }
          }),
          delayWhen(_ => timer(15000)),
          take(5)
        ))
    );
  }

  public PutImageUploadUrl(url: string, file: string, fileName: string): Observable<any> {
    const adjustedfileName = fileName.replace(' ', '').toLowerCase();
    const type = MediaUtils.getMediaType(adjustedfileName.split('.').pop());
    const newFileContents = MediaUtils.stripFileContents(file)
      .replace(/^data:text\/\w+;base64,/, '');

    const buff = buffer.Buffer.from(newFileContents, 'base64');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', type);
    headers = headers.append('Content-Encoding', 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.http.put<any>(url, blob, {headers}).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'PutImageUploadUrl', err));
        return throwError(() => err);
      })
    );
  }

  public GetAsset(id, md5Hash: string): Observable<Asset> {
    const url = Endpoints.GetAsset(id, md5Hash);
    return this.apiClient.getObj<Asset>(Asset, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAsset', err));
        return throwError(err);
      })
    );
  }

  public GetBlobFromUrl(assetUrl: AssetUrl): Observable<Blob|null|undefined> {
    if (!assetUrl.url || assetUrl.urlExpired()) {
      return this.GetAsset(assetUrl.assetId, assetUrl.md5Hash).pipe(
        map(fetchedAsset => fetchedAsset.urls?.find(url => url.size === assetUrl.size)),
        map(updatedAssetUrl => assetUrl.updateDataFrom(updatedAssetUrl)),
        switchMap(updatedAssetUrl => {
          return exists(updatedAssetUrl?.url) ? this.fetchBlobFromApi(updatedAssetUrl.url) : of<Blob>();
        })
      );
    } else {
      return this.fetchBlobFromApi(assetUrl.url);
    }
  }

  private fetchBlobFromApi(url: string): Observable<Blob|null|undefined> {
    return this.apiClient.getBlob<Blob>(url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBlobFromUrl', err));
        return throwError(err);
      })
    );
  }

}
