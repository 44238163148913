import { AfterViewInit, Component, Injector, ViewChild } from '@angular/core';
import { AccountViewModel } from '../../viewModels/account-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUtils } from '../../../../utils/modal-utils';
import { ChangePasswordComponent } from '../modals/change-password/change-password.component';
import { BaseComponent } from '../../../../models/base/base-component';
import { UpdateUserInfoRequest } from '../../../../models/account/requests/update-user-info-request';
import { ConfirmCodeRequest } from '../../../../models/account/requests/confirm-code-request';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';
import { ActivatedRoute } from '@angular/router';
import { ReactiveFormTextComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends BaseComponent implements AfterViewInit, ComponentCanDeactivate {

  constructor(
    public viewModel: AccountViewModel,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private injector: Injector
  ) {
    super();
  }

  @ViewChild('confirmationCodeInput') confirmationCodeInput: ReactiveFormTextComponent;
  public companyRole$ = window.types.companyRoles$;
  public code: string;
  private getActivationCodeFromParams = this.activatedRoute.queryParams
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        if (!!params?.Code) {
          this.viewModel.connectToEmailVerificationCode(params.Code);
        }
      }
    });

  override setupViews(): void {
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit(); // This will call setupBindings()
  }

  override setupBindings(): void {
  }

  validateConfirmationCode() {
    this.viewModel.confirmationCodeValid$.next(this.confirmationCodeInput.getSelfAsFormItem().valid);
  }

  accountInfoFormSubmitted(hydratedFormObject: UpdateUserInfoRequest): void {
    this.viewModel?.accountInformationSubmitted(hydratedFormObject);
  }

  emailConfirmationSubmit(hydratedFormObject: ConfirmCodeRequest): void {
    this.viewModel?.emailConfirmationSubmitted(hydratedFormObject);
  }

  openChangePasswordModal(): void {
    const modalRef = this.modalService.open(
      ChangePasswordComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then((_) => {});
  }

  resendCode(): void {
    this.viewModel.resendCode();
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

}
