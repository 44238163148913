import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { HydratedAdminUser } from '../../models/account/dto/hydrated-admin-user';

type Activation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {

  constructor(
    private userDomainModel: UserDomainModel,
    public router: Router
  ) {
  }

  public findTerm = (href: string, term: string) => {
    if (href.includes(term)) {
      return href;
    }
  };

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Activation {
    return this.userDomainModel.user$.firstNotNull().pipe(
      map((user) => {
        return this.routeIsPermitted(state, user) || this.router.parseUrl('');
      }),
    );
  }

  routeIsPermitted(state: RouterStateSnapshot, user: HydratedAdminUser): boolean {
    let isPermitted = true;
    if (user) {
      const isCompanyAdmin = user.isCompanyAdmin;
      switch (state.url) {
        // Example
        case this.findTerm(state.url, '/products'):
        case this.findTerm(state.url, '/account'):
        case this.findTerm(state.url, '/sign-out'):
          // Handle case so that all other routes are blocked if productOnlyAccess.
          // The above routes are accessible for all users
          break;
        default:
          if (user.productOnlyAccess) {
            isPermitted = false;
          }
      }
    }
    if (!isPermitted) {
      if (user.productOnlyAccess) {
        this.router.navigate(['products/universalvariants']).then(() => {
        });
      } else {
        this.router.navigate(['']).then(() => {
        });
      }
    }
    return isPermitted;
  }

}
