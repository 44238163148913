import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Label } from '../../../models/shared/label';
import { SortUtils } from '../../../utils/sort-utils';
import { SmartFiltersDomainModel } from '../../../domainModels/smart-filters-domain-model';

@Injectable()
export class SimpleLabelPreviewViewModel extends BaseViewModel {

  constructor(
    private smartFiltersDomainModel: SmartFiltersDomainModel
  ) {
    super();
  }

  private _labelIds = new BehaviorSubject<string[]>(null);
  public labelIds$ = this._labelIds as Observable<string[]>;

  private _labelsToDisplay = new BehaviorSubject<Label[]>(null);
  public labelsToDisplay$ = this._labelsToDisplay as Observable<Label[]>;

  private listenToLabels = combineLatest([
    this.labelIds$.notNull(),
    this.smartFiltersDomainModel.systemLabels$
  ]).subscribeWhileAlive({
      owner: this,
      next: ([labelIds, systemLabels]) => {
        const labelsToDisplay = [];
        labelIds?.forEach(id => {
          const locationIndex = systemLabels?.findIndex(ll => ll?.id?.toLowerCase() === id?.toLowerCase());
          if (locationIndex > -1) {
            labelsToDisplay.push(systemLabels[locationIndex]);
          }
        });
        this._labelsToDisplay.next(labelsToDisplay?.sort(SortUtils.sortLabelsAlphabetically));
      }
    });

  connectToLabelIds = (ids: string[]) => this._labelIds.next(ids);

}
