import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { exists } from '../../../../functions/exists';

@Injectable()
export class SmartFilterSelectionModalViewModel extends BaseModalViewModel {

  // already added smart filter ids (these will be in a disabled state)
  private _previouslyAddedSmartFilterIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedSmartFilterIds$ = this._previouslyAddedSmartFilterIds.asObservable();

  // searching for smart filters
  public searchableSmartFilters$ = this.smartFilterDomainModel.curatedSmartFilters$;
  private _searchString = new BehaviorSubject<string>('');
  public searchString$ = this._searchString.asObservable();
  private _searchHits = new BehaviorSubject<HydratedSmartFilter[]>([]);
  public searchHits$ = this._searchHits;

  // smart filter output pipes for which smart filter to display
  private _loadingSmartFilters = new BehaviorSubject<boolean>(true);
  public loadingSmartFilters$ = this._loadingSmartFilters.asObservable();
  public groupedSearchableSmartFilters$ = this.smartFilterDomainModel.curatedGroupedSmartFilters$.pipe(
    tap(c => {
      if (exists(c)) {
        this._loadingSmartFilters.next(false);
      }
    })
  );

  public groupedHits$ = combineLatest([
    this.searchString$,
    this.searchHits$,
    this.groupedSearchableSmartFilters$
  ]).pipe(
    debounceTime(100),
    map(([searched, hits, groups]) => (exists(searched) ? groups?.filter(group => group.some(hits)) : groups))
  );

  public showNoResults$ = combineLatest([
    this.loadingSmartFilters$,
    this.groupedHits$
  ]).pipe(
    map(([loading, hits]) => {
      const noHits = hits?.length < 1;
      return !loading && noHits;
    })
  );

  // selected smart filters
  private _selectedSmartFilterIds = new BehaviorSubject<string[]>([]);
  public selectedSmartFilterIds$ = this._selectedSmartFilterIds.asObservable();

  // add to selection
  private _addSmartFilterId = new Subject<string>();
  private listenToAddAnId = this._addSmartFilterId.pipe(
    map(id => this._selectedSmartFilterIds.getValue()?.concat([id])?.unique())
  ).subscribeWhileAlive({
    owner: this,
    next: (ids) => this._selectedSmartFilterIds.next(ids)
  });

  // remove from selection
  private _removeSmartFilterId = new Subject<string>();
  private listenToRemoveAnId = this._removeSmartFilterId.pipe(
    map(id => this._selectedSmartFilterIds.getValue()?.filter(currId => currId !== id))
  ).subscribeWhileAlive({
    owner: this,
    next: (ids) => this._selectedSmartFilterIds.next(ids)
  });

  constructor(
    public smartFilterDomainModel: SmartFiltersDomainModel,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  searchText = (s: string) => this._searchString.next(s);
  searchHits = (hits: HydratedSmartFilter[]) => this._searchHits.next(hits);
  previouslyAddedSmartFilterIds = (ids: string[]) => this._previouslyAddedSmartFilterIds.next(ids);
  clearSelections = () => this._selectedSmartFilterIds.next([]);
  addSelectedId = (id: string) => this._addSmartFilterId.next(id);
  removeSelectedId = (id: string) => this._removeSmartFilterId.next(id);
  addSelectedIds = (ids: string[]) => ids?.forEach(id => this.addSelectedId(id));
  removeSelectedIds = (ids: string[]) => ids?.forEach(id => this.removeSelectedId(id));
  spitOutNewlySelectedIds = () => this.selectedSmartFilterIds$.pipe(take(1)).subscribe(this.dismissModalSubject);

}
