import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseModalComponent implements AfterViewInit {

  public confirmationOptions: ConfirmationOptions;

  @ViewChild('modalBody') modalBody: HTMLDivElement;

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super(activeModal);
  }

  override setupBindings() {
    this.bindToBackNav();
  }

  setConfirmationOptions(opts: ConfirmationOptions) {
    this.confirmationOptions = opts;
  }

  override ngAfterViewInit() {
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override cancel() {
    this.activeModal.close(false);
  }

  continue() {
    this.activeModal.close(true);
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

}
