import { AssetGroup } from '../dto/asset-group';

export class CreateAssetGroupRequest extends AssetGroup {

  public universalVariantBarcodes: string[];

  constructor(assetGroup: AssetGroup) {
    super();
    this.displayAttributeGroupId = assetGroup.displayAttributeGroupId ?? '';
    this.id = assetGroup.id ?? '';
    this.groupName = assetGroup.groupName ?? '';
    this.assetTypeMap = new Map<string, string[]>(assetGroup.assetTypeMap);
    this.isBrand = assetGroup?.isBrand ?? false;
    this.brandGroupId = assetGroup?.brandGroupId ?? '';
    this.universalVariantBarcodes = [];
  }

}
