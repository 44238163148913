<div
  #card
  class="smart-filter-category-card"
  (click)="emitCardClicked()"
  [style.background-color]="(isSelected$ | async) ? '#2c4058' : '#F2F2F2'">
  <div class="content-container">
    <ng-container
      *ngIf="smartFilterGrouping?.getSelectionName() !== ClientTypes.SmartFilterCategoryAllProducts.AllProducts">
      <div class="image-container">
        <app-asset
          class="smart-filter-category-icon"
          [asset]="smartFilterGrouping?.getGroupingImage()"
          [borderRadius]="'0.625rem 0rem 0rem 0.625rem'">
        </app-asset>
      </div>
    </ng-container>
    <div class="text-container ms-3">
      <div class="category-name" [style.color]="(isSelected$ | async) ? '#FFFFFF' : '#222222'">
        {{ smartFilterGrouping?.getSelectionName() }}
      </div>
    </div>
  </div>
</div>
