import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UniversalVariantsSubNavItemViewModel } from './universal-variants-sub-nav-item-view-model';
import { NavSubItemComponent } from '../nav-sub-item.component';

@Component({
  selector: 'app-universal-variants-sub-nav-item',
  templateUrl: './universal-variants-sub-nav-item.component.html',
  styleUrls: ['./universal-variants-sub-nav-item.component.scss',
    '../nav-sub-item.component.scss'],
  providers: [UniversalVariantsSubNavItemViewModel]
})
export class UniversalVariantsSubNavItemComponent extends NavSubItemComponent {

  constructor(
    public override viewModel: UniversalVariantsSubNavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

}
