import { CardStyle } from './card-style.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Asset } from '../../image/dto/asset';

export class Card implements UniquelyIdentifiable {

  public id: string;
  public label: string;
  public text: string;
  public subtext: string;
  public asset: Asset;
  public imageSrc: string;
  public filterValue: any[];
  public cardStyle: CardStyle = CardStyle.Default;
  public data: any;
  public customClass: string;
  public selected: boolean = false;

  constructor(label, text: string) {
    this.label = label;
    this.text = text;
  }

  getBackgroundUrl(): string {
    if (this.imageSrc) {
      return `url(${this.imageSrc})`;
    } else {
      return 'url(assets/icons/light/outline/photograph-grey.svg)';
    }
  }

  getUniqueIdentifier(...opts: any): string {
    return `${this.id}-
    ${this.label}-
    ${this.text}-
    ${this.subtext}-
    ${this.imageSrc}-
    ${this.filterValue}-
    ${this.cardStyle}-
    ${this.data}-
    ${this.customClass}-
    ${this.selected}-`;
  }

}
