import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InventoryProviderSpecification } from '../../../../../../../../../models/company/dto/inventory-provider-specification';
import { CompanyConfiguration } from '../../../../../../../../../models/company/dto/company-configuration';
import { InventoryProvider } from '../../../../../../../../../utils/inventory-provider-type';
import { ProviderSpecificationsAccordionViewModel } from './provider-specifications-accordion-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-provider-specifications-accordion',
  templateUrl: './provider-specifications-accordion.component.html',
  styleUrls: ['./provider-specifications-accordion.component.scss'],
  providers: [ProviderSpecificationsAccordionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderSpecificationsAccordionComponent {

  constructor(
    public viewModel: ProviderSpecificationsAccordionViewModel,
  ) {
  }

  @Input() companyConfiguration: CompanyConfiguration;
  @Input() inventoryProvider: InventoryProvider;
  @Input() mergeKey: string;
  @Input() first: boolean = false;
  @Input() @ViewModelConnector('connectToProviderSpecification') providerSpecification: InventoryProviderSpecification;

}
