import { AuthFlow, AuthFlowSubtext, AuthFlowTitle } from '../../../models/account/enum/auth-flow.enum';
import { SignInRequest } from '../../../models/account/requests/sign-in-request';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { Injectable } from '@angular/core';
import { ForgotPasswordRequest } from '../../../models/account/requests/forgot-password-request';
import { CodeDeliveryDetails } from '../../../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../../../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../../../models/account/requests/sign-in-new-password-request';
import { CacheService } from '../../../services/cache-service';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { switchMap, take, tap } from 'rxjs/operators';
import { UserDomainModel } from 'src/app/domainModels/user-domain-model';
import { ActivatedRoute, Router } from '@angular/router';
import { exists } from '../../../functions/exists';

@Injectable({ providedIn: 'root' })
export class AuthViewModel extends BaseViewModel {

  // Observables
  public nextAuthFlow: Subject<AuthFlow> = new Subject<AuthFlow>();
  public authSuccess: Subject<any> = new Subject<any>();

  private _user: BehaviorSubject<HydratedAdminUser|null> = new BehaviorSubject<HydratedAdminUser|null>(null);
  public user$ = this._user as Observable<HydratedAdminUser|null>;

  private _preFillEmail = new BehaviorSubject<string>('');
  public readonly preFillEmail$ = this._preFillEmail as Observable<string>;

  private _preFillCode = new BehaviorSubject<string>('');
  public readonly preFillCode$ = this._preFillCode as Observable<string>;

  private _preFillPassword = new BehaviorSubject<string>('');
  public readonly preFillPassword$ = this._preFillPassword as Observable<string>;

  private listenToQueryParams = this.route.queryParams.subscribeWhileAlive({
    owner: this,
    next: params => {
      const email = decodeURIComponent(decodeURIComponent(params?.Email || params?.email || ''));
      const code = decodeURIComponent(decodeURIComponent(params?.Code || params?.code || ''));
      const password = decodeURIComponent(decodeURIComponent(params?.Password || params?.password || ''));
      if (exists(email)) this._preFillEmail.next(email);
      if (exists(code)) this._preFillCode.next(code);
      if (exists(password)) this._preFillPassword.next(password);
      if (exists(email) || exists(code) || exists(password)) {
        this.router.navigate([], {queryParams: {}, replaceUrl: true, relativeTo: this.route}).then();
      }
    }
  });

  // Misc
  // @ts-ignore
  public versionString = require('package.json')?.version;

  constructor(
    private userDomainModel: UserDomainModel,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.setupBindings();
  }

  // Properties
  public authFlow: AuthFlow = AuthFlow.SignIn;

  setupBindings() {
    // Bind to SessionContainer to navigate to necessary Auth flow
    const sessUserSub = this.userDomainModel.user$.notNull().subscribe((user) => {
      if (user?.session?.challenge) {
        // Navigate the user to the new password page
        this.nextAuthFlow.next(AuthFlow.SetNewPassword);
      } else if (user?.session?.validSession()) {
        // Navigate the user to the account page
        this.authSuccess.next(true);
      } else if (user?.codeDeliveryDetails?.length > 0) {
        // Navigate the user to the reset password page
        this.nextAuthFlow.next(AuthFlow.ResetPassword);
      } else {
        // Navigate to the Sign in flow. If reset flow succeeds but the user has explicitly signed out,
        // then no refresh token exists on server, so force a new log in
        this.nextAuthFlow.next(AuthFlow.SignIn);
      }
    });
    this.pushSub(sessUserSub);
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedAdminUser> {
    return this.tapIntoUser(this.userDomainModel.signIn(req));
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.userDomainModel.forgotPassword(req);
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedAdminUser> {
    return this.tapIntoUser(this.userDomainModel.resetPassword(req));
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedAdminUser> {
    return this.user$.pipe(take(1), switchMap(user => {
      req.userId = user?.userId;
      req.session = user?.session?.challenge?.authSession;
      return this.tapIntoUser(this.userDomainModel.signInNewPassword(req));
    }));
  }

  private tapIntoUser(user$: Observable<HydratedAdminUser|null>): Observable<HydratedAdminUser|null> {
    return user$.pipe(
      tap({
        next: user => this._user.next(user),
        error: _ => this._user.next(null),
        complete: () => {
          this._preFillEmail.next('');
          this._preFillCode.next('');
          this._preFillPassword.next('');
        }
      }),
    );
  }

  // Auth Flow

  getAuthFlowTitle(): string {
    return AuthFlowTitle(this.authFlow);
  }

  getAuthFlowSubtext(): string {
    return AuthFlowSubtext(this.authFlow);
  }

  clearCaches() {
    this.cacheService.clearAllCaches();
  }

}
