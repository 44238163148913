import { Deserializable } from '../../protocols/deserializable';

export class ConfirmCodeRequest implements Deserializable {

  public accessToken: string = '';
  public companyId: number = 0;
  public confirmationCode: string = '';
  public flow: string = '';
  public userId: string = '';

  onDeserialize() {
  }

}
