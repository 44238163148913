import { SandboxMenuStyling } from '../dto/sandbox-menu-styling';
import { Deserializable } from '../../protocols/deserializable';

export class PopulateSandboxRequest implements Deserializable {

  public companyId: number;
  public populateSandboxProducts: boolean = false;
  public populateSandboxMenus: boolean = false;
  public populateSandboxDisplays: boolean = false;
  public menuStyling: SandboxMenuStyling;

  constructor(companyId?: number) {
    this.companyId = companyId;
    this.menuStyling = new SandboxMenuStyling();
  }

  onDeserialize() {
  }

}
