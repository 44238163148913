import { MediaType } from '../models/enum/dto/media-type.enum';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import type { AssetGroup } from '../models/product/dto/asset-group';
import type { BudsenseFile } from '../models/shared/budsense-file';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import type { ImageAPI } from '../api/image-api';
import { DateUtils } from './date-utils';

export class MediaUtils {

  static ASSET_RETRY_COUNT = 10;
  static ASSET_RETRY_DELAY = 10;
  static ASSET_DELETE_DELAY = 10;

  static ASSET_TIMESTAMP_LEADER = '__bs__';

  static DEFAULT_MAX_FILE_SIZE = 10485760; // 10 MB

  static getMediaType(name: string): MediaType {
    switch (name.split('.').pop().toLowerCase()) {
      case 'jpg':
        return MediaType.JPG;
      case 'jpeg':
        return MediaType.JPEG;
      case 'png':
        return MediaType.PNG;
      case 'gif':
        return MediaType.GIF;
      case 'webp':
        return MediaType.WEBP;
      case 'mp4':
        return MediaType.MP4;
      case 'webm':
        return MediaType.WEBM;
      case 'ogg':
        return MediaType.OGV;
      case 'avi':
        return MediaType.AVI;
      case 'mpeg':
        return MediaType.MPEG;
      case 'mov':
        return MediaType.MOV;
      case 'pdf':
        return MediaType.PDF;
      case 'csv':
        return MediaType.CSV;
      default:
        return null;
    }
  }

  static isImage(mediaType: MediaType): boolean {
    const imageTypes: MediaType[] = [MediaType.JPG, MediaType.JPEG, MediaType.PNG, MediaType.GIF];
    return imageTypes.includes(mediaType);
  }

  static isVideo(mediaType: MediaType): boolean {
    const videoTypes: MediaType[] = [
      MediaType.MP4,
      MediaType.WEBM,
      MediaType.OGV,
      MediaType.AVI,
      MediaType.MPEG,
      MediaType.MOV
    ];
    return videoTypes.includes(mediaType);
  }

  static getRefreshAssetLoadingMessage(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Asset.';
      case 2:
        return 'Reloading Asset.';
      case 3:
        return 'Reloading Asset.';
      case 4:
        return 'Resizing Asset.';
      case 5:
        return 'Resizing Asset.';
      case 6:
        return 'Resizing Asset.';
      case 7:
        return 'Compressing Asset.';
      case 8:
        return 'Compressing Asset.';
      case 9:
        return 'Compressing Asset.';
      case 10:
        return 'Compressing Asset.';
      default:
        return `Loading Asset (${remainingRetries})`;
    }
  }

  static getRefreshMenuMediaAsset(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Media.';
      case 2:
        return 'Reloading Media.';
      case 3:
        return 'Reloading Media.';
      case 4:
        return 'Resizing Media.';
      case 5:
        return 'Resizing Media.';
      case 6:
        return 'Resizing Media.';
      default:
        return `Compressing Media.`;
    }
  }

  static stripFileContents(file: string): string {
    const newFileContents = file.replace(/^data:image\/\w+;base64,/, '');
    return newFileContents.replace(/^data:video\/\w+;base64,/, '');
  }

  static getSHA256FromBlob(blob: Blob): Observable<string> {
    return from(blob.arrayBuffer()).pipe(
      switchMap(arrayBuffer => from(crypto.subtle.digest('SHA-256', arrayBuffer)).pipe(
        map(hash => new TextDecoder().decode(new Uint8Array(hash)))
      )),
      take(1),
    );
  }

  static newAssetIsUnique(imageAPI: ImageAPI, assetGroup: AssetGroup, f: BudsenseFile): Observable<boolean> {
    const assets = assetGroup?.assets;
    const isAssetUnique = (): Observable<boolean> => {
      const calcSHA256$ = (blob: Blob) => MediaUtils.getSHA256FromBlob(blob);
      const getSHA256sFromUrls$ = assets
        ?.map(asset => asset?.getAssetUrl(AssetSize.Original))
        ?.map(assetUrl => imageAPI.GetBlobFromUrl(assetUrl).pipe(switchMap(calcSHA256$))) || [];
      const flattenStrings = (strings: string[]) => strings?.flatten<string[]>() || [];
      const assetHashes$ = forkJoin(getSHA256sFromUrls$).pipe(map(flattenStrings));
      const checkForUniqueAsset$ = combineLatest([f.getSHA256Hash(), assetHashes$]).pipe(take(1));
      return checkForUniqueAsset$.pipe(map(([newHash, hashes]) => hashes?.every(hash => hash !== newHash)));
    };
    return assets?.length > 0 ? isAssetUnique() : of(true);
  }

  static appendTimestampToFileName(fileName: string): string {
    const extension = fileName?.substring(fileName.lastIndexOf('.'));
    const timestamp = DateUtils.currentTimestampInSeconds();
    return `${fileName.substring(0, fileName.lastIndexOf('.'))}`
      + `${MediaUtils.ASSET_TIMESTAMP_LEADER}${timestamp}${extension}`;
  }

}
