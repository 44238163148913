<div
  #container
  fxLayout="row"
  fxLayoutAlign="start center"
  [style.gap.rem]="1"
  class="location-selection-container"
  [class.child]="isChild"
  [class.disabled]="disabled">
  <app-checkbox-only
    *ngIf="!viewOnlyMode"
    [checked]="selected$ | async"
    [disabled]="disabled"
    (valueChanged)="clicked($event)">
  </app-checkbox-only>
  <app-location-info-text *ngIf="isChild" [locationIds]="updatedLocationIds" [location]="selection">
  </app-location-info-text>
  <div *ngIf="showAddedOrRemovedStatus" class="status">
    {{ selection | getAddedOrRemovedStatus : originalLocationIds : updatedLocationIds }}
  </div>
</div>
