import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Variant } from '../../../../models/product/dto/variant';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { BsError } from '../../../../models/shared/bs-error';
import { exists } from '../../../../functions/exists';

@Injectable()
export class AssignVariantToAssetGroupViewModel extends BaseModalViewModel {

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private _variantToAssign: BehaviorSubject<Variant> = new BehaviorSubject<Variant>(null);
  public variantToAssign$ = this._variantToAssign as Observable<Variant>;
  public variantName$ = this.variantToAssign$.pipe(map(v => v?.name));

  private _assetGroups: BehaviorSubject<AssetGroup[]> = new BehaviorSubject<AssetGroup[]>(null);
  public assetGroups$ = this._assetGroups as Observable<AssetGroup[]>;

  private _selectedAssetGroupId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedAssetGroupId$ = this._selectedAssetGroupId as Observable<string>;

  public canSubmit$ = this.selectedAssetGroupId$.pipe(map(id => exists(id)));

  connectToVariantToAssign = (variant: Variant) => this._variantToAssign.next(variant);
  connectToAssetGroups = (assetGroups: AssetGroup[]) => this._assetGroups.next(assetGroups);

  changeSelectedAssetGroup = (id: string) => this._selectedAssetGroupId.next(id);

  updateAssetGroup() {
    const lm = 'Adding UBV to Asset Group';
    this._loadingOpts.addRequest(lm);

    const variant = this._variantToAssign.getValue();
    const assetGroups = this._assetGroups.getValue();
    const selectedId = this._selectedAssetGroupId.getValue();

    const assetGroupToUpdate = assetGroups.find(ag => ag.id === selectedId);
    const updatedAssetGroupCopy = window.injector.Deserialize.instanceOf(AssetGroup, assetGroupToUpdate);
    if (!updatedAssetGroupCopy?.variants) {
      updatedAssetGroupCopy.variants = [];
    }
    const variantCopy = window.injector.Deserialize.instanceOf(Variant, variant);
    updatedAssetGroupCopy?.variants.push(variantCopy);

    this.productDomainModel.updateAssetGroup(updatedAssetGroupCopy).subscribe(ags => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('UBV successfully added to Asset Group', 'Success');
      this.activeModal.close(ags);
    }, (err: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(err);
    });
  }

  openCreateAssetGroupModal() {
    const variant = this._variantToAssign.getValue();
    this.activeModal.dismiss(variant);
  }

}
