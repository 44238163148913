import { Deserializable } from '../../protocols/deserializable';
import { SessionChallenge } from './session-challenge';
import { DateUtils } from '../../../utils/date-utils';

export class Session implements Deserializable {

  public accessToken: string;
  public expiresIn: number;
  public idToken: string;
  public refreshToken: string;
  public tokenType: string;
  public challenge: SessionChallenge;
  public expiresAt: number;

  public onDeserialize() {
    this.challenge = window.injector.Deserialize.instanceOf(SessionChallenge, this.challenge);
  }

  public validSession(): boolean {
    return this.accessToken && this.expiresAt > DateUtils.currentTimestampInSeconds();
  }

}
