import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';

@Component({
  selector: 'app-page-header-and-crumbs',
  templateUrl: './page-header-and-crumbs.component.html',
  styleUrls: ['./page-header-and-crumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderAndCrumbsComponent {

  @Input() pageTitle: string;
  @Input() crumbs: Breadcrumb[] = [];
  @Input() showDivider: boolean = true;

}
