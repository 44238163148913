<div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="1rem" class="category-selection-container">
  <app-checkbox-only
    [checked]="selected$ | async"
    [indeterminate]="indeterminate$ | async"
    (valueChanged)="clicked($event)"></app-checkbox-only>
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="category-name">{{ name$ | async }}</div>
  </div>
</div>
<hr *ngIf="includeBottomBorder" class="no-margin" />
