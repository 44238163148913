import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HydratedSmartFilter } from '../../../models/automation/hydrated-smart-filter';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { PopperUtils } from '../../../utils/popper-utils';
import { BaseComponent } from '../../../models/base/base-component';

@Component({
  selector: 'app-inline-labels-picker',
  templateUrl: './inline-labels-picker.component.html',
  styleUrls: ['./inline-labels-picker.component.scss']
})
export class InlineLabelsPickerComponent extends BaseComponent implements OnChanges {

  @Input() disabled: boolean = false;
  @Input() bindTo: HydratedSmartFilter;
  @Input() mergeKey: string;
  @Input() maxSelectedLabels: number = 5;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.TOP;

  @Output() updatedLabelIds = new EventEmitter<string[]>(true);

  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;

  private _labelIds = new BehaviorSubject<string[]>([]);
  public labelIds$ = this._labelIds as Observable<string[]>;

  constructor() {
    super();
  }

  override setupViews() {
  }

  override setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bindTo) this._labelIds.next(this.bindTo?.labelIds);
  }

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['left'])];
  public popperStyles = { 'background-color': '#FFFFFF' };

  public setUpdatedLabelIds(ids: string[]) {
    const copy = ids.deepCopy();
    this.updatedLabelIds.emit(copy);
    this._labelIds.next(copy);
  }

}
