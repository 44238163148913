import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { VariantBadgeViewModel } from './variant-badge-view-model';

@Component({
  selector: 'app-variant-badge',
  templateUrl: './variant-badge.component.html',
  styleUrls: ['./variant-badge.component.scss'],
  providers: [VariantBadgeViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantBadgeComponent extends BaseComponent implements OnChanges {

  constructor(public viewModel: VariantBadgeViewModel) {
    super();
  }

  @Input() addedBadgeIds: string[];
  @Input() badge: HydratedVariantBadge;
  @Input() showPlusSign: boolean = false;
  @Input() editable: boolean = true;
  @Input() badgeInfo: string;
  @Input() includeTopDivider: boolean = true;
  @Input() includeBottomDivider: boolean = true;
  @Output() removeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  override setupViews() {
    this.viewModel.connectToAddedBadgeIds(this.addedBadgeIds);
    this.viewModel.connectToBadge(this.badge);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addedBadgeIds) this.viewModel.connectToAddedBadgeIds(this.addedBadgeIds);
    if (changes.badge) this.viewModel.connectToBadge(this.badge);
  }

}
