import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../../../../../../models/company/dto/location';

@Pipe({
  name: 'getLocationGroupSelectedCount'
})
export class GetLocationGroupSelectedCountPipe implements PipeTransform {

  transform(selectedIds: string[], locationsInList: Location[]): number {
    return locationsInList?.filter(location => selectedIds?.includes(location?.id?.toString(10)))?.length;
  }

}
