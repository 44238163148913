import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { SyncType } from '../../utils/sync-type-type';

export class SyncJobResults implements Deserializable, UniquelyIdentifiable {

  // Products
  productsCreated: number;
  productsUpdated: number;
  productsDeleted: number;
  // Variants
  variantsCreated: number;
  variantsUpdated: number;
  variantsDeleted: number;
  // Inventory
  inventoryCreated: number;
  inventoryUpdated: number;
  inventoryDeleted: number;
  lotInfoUpdated: number;
  // Pricing
  pricingCreated: number;
  pricingUpdated: number;
  pricingDeleted: number;
  // Promotions
  promotionsCreated: number;
  promotionsUpdated: number;
  promotionsDeleted: number;
  // Labels
  labelsCreated: number;
  labelsUpdated: number;
  labelsDeleted: number;

  onDeserialize() {
  }

  getResultsFor(type: SyncType): [string, number][] {
    switch (type) {
      case SyncType.Product:
        return [
          ['Products Added', this.productsCreated],
          ['Products Updated', this.productsUpdated],
          ['Products Removed', this.productsDeleted],
          ['Variants Added', this.variantsCreated],
          ['Variants Updated', this.variantsUpdated],
          ['Variants Removed', this.variantsDeleted]
        ];
      case SyncType.ProductAudit:
        return [
          ['Products Removed', this.productsDeleted],
          ['Variants Removed', this.variantsDeleted]
        ];
      case SyncType.Inventory:
        return [
          ['Added', this.inventoryCreated],
          ['Updated', this.inventoryUpdated],
          ['Removed', this.inventoryDeleted]
        ];
      case SyncType.LotInfo:
        return [
          ['Updated', this.lotInfoUpdated]
        ];
      case SyncType.Pricing:
        return [
          ['Added', this.pricingCreated],
          ['Updated', this.pricingUpdated],
          ['Removed', this.pricingDeleted]
        ];
      case SyncType.Promotions:
        return [
          ['Added', this.promotionsCreated],
          ['Updated', this.promotionsUpdated],
          ['Removed', this.promotionsDeleted]
        ];
      case SyncType.Labels:
        return [
          ['Added', this.labelsCreated],
          ['Updated', this.labelsUpdated],
          ['Removed', this.labelsDeleted]
        ];
      case SyncType.Location:
        return [
          ['Synced', undefined]
        ];
    }
  }

  getUniqueIdentifier(): string {
    return `${this.productsCreated}
      -${this.productsUpdated}
      -${this.productsDeleted}
      -${this.variantsCreated}
      -${this.variantsUpdated}
      -${this.variantsDeleted}
      -${this.inventoryCreated}
      -${this.inventoryUpdated}
      -${this.inventoryDeleted}
      -${this.lotInfoUpdated}
      -${this.pricingCreated}
      -${this.pricingUpdated}
      -${this.pricingDeleted}
      -${this.promotionsCreated}
      -${this.promotionsUpdated}
      -${this.promotionsDeleted}
      -${this.labelsCreated}
      -${this.labelsUpdated}
      -${this.labelsDeleted}`;
  }

}
