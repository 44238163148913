<div #container fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="1rem" class="sync-job-results-container">
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="sync-type-name">{{ syncType?.name | getSyncTypeNameForProvider : provider }}</div>
  </div>
  <div class="sync-results-data">
    <div
      *ngFor="let result of syncJobResults | getDataForSyncTypeResult : syncType?.value"
      class="sync-job-result-info">
      {{ result | decodeSyncResultLineItem }}
    </div>
  </div>
</div>
<hr class="no-margin" />
