import { Component, } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayAttributesSubNavItemViewModel } from './display-attributes-sub-nav-item-view-model';
import { NavSubItemComponent } from '../nav-sub-item.component';

@Component({
  selector: 'app-display-attributes-sub-nav-item',
  templateUrl: './display-attributes-sub-nav-item.component.html',
  styleUrls: ['./display-attributes-sub-nav-item.component.scss',
    '../nav-sub-item.component.scss'],
  providers: [DisplayAttributesSubNavItemViewModel]
})
export class DisplayAttributesSubNavItemComponent extends NavSubItemComponent {

  constructor(
    public override viewModel: DisplayAttributesSubNavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

}
