import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { DateUtils } from '../../../../utils/date-utils';
import { AdminUser } from '../../../../models/account/dto/admin-user';
import { map } from 'rxjs/operators';

@Injectable()
export class EmployeeViewModel extends BaseViewModel {

  // Loading
  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultSmall());

  private _employee: BehaviorSubject<AdminUser> = new BehaviorSubject<AdminUser>(null);
  public employee$ = this._employee.asObservable();
  public connectToEmployee = (e: AdminUser) => this._employee.next(e);

  public employeeRole$ = this.employee$.pipe(map(e => e?.companyRole));
  public productOnlyEmployee$ = this.employee$.pipe(map(e => e?.productOnlyAccess));

  constructor() {
    super();
  }

  generateLastLoginText(timestamp: number): string {
    if (timestamp === 0) {
      return 'Employee has not logged in';
    } else {
      return 'Last Login: ' + DateUtils.formatUnixForDateAtTime(timestamp);
    }
  }

}
