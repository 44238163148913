import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModal } from '../../../../../../../../models/base/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewSyncJobViewModel } from './new-sync-job-view-model';
import { Checkbox } from '../../../../../../../../models/shared/stylesheet/checkbox';

@Component({
  selector: 'app-new-sync-job-modal',
  templateUrl: './new-sync-job-modal.component.html',
  styleUrls: ['./new-sync-job-modal.component.scss'],
  providers: [NewSyncJobViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSyncJobModalComponent extends BaseModal {

  constructor(
    public viewModel: NewSyncJobViewModel,
    activeModel: NgbActiveModal
  ) {
    super(activeModel);
  }

  public forceSyncAllTime: Checkbox = new Checkbox('Sync All Time');

  override setupBindings(): void {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: (close) => close && this.cancel()
    });

    this.viewModel.forceSyncAllTime$.subscribeWhileAlive({
      owner: this,
      next: (forceSyncAllTime) => {
        if (this.forceSyncAllTime.checked !== forceSyncAllTime) {
          this.forceSyncAllTime.checked = forceSyncAllTime;
        }
      }
    });
  }

}
