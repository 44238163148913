import { Variant } from '../../../../models/product/dto/variant';
import { NumberUtils } from '../../../../utils/number-utils';
import { ProductType } from '../../../../utils/product-type-definition';

export class EditVariantFormObject {

  public variant: Variant;
  public THC: number;
  public minTHC: number;
  public maxTHC: number;
  public CBD: number;
  public minCBD: number;
  public maxCBD: number;

  constructor(variant: Variant) {
    this.variant = variant;
    this.THC = NumberUtils.parseFloatOrNull(variant.THC);
    this.minTHC = NumberUtils.parseFloatOrNull(variant.minTHC);
    this.maxTHC = NumberUtils.parseFloatOrNull(variant.maxTHC);
    this.CBD = NumberUtils.parseFloatOrNull(variant.CBD);
    this.minCBD = NumberUtils.parseFloatOrNull(variant.minCBD);
    this.maxCBD = NumberUtils.parseFloatOrNull(variant.maxCBD);
  }

  getVariant(): Variant {
    if (this.variant.productType === ProductType.Accessories) {
      this.variant.THC = null;
      this.variant.minTHC = null;
      this.variant.maxTHC = null;
      this.variant.CBD = null;
      this.variant.minCBD = null;
      this.variant.maxCBD = null;
      this.variant.strain = null;
      this.variant.classification = null;
    } else {
      this.variant.THC = `${this.THC}`;
      this.variant.minTHC = `${this.minTHC}`;
      this.variant.maxTHC = `${this.maxTHC}`;
      this.variant.CBD = `${this.CBD}`;
      this.variant.minCBD = `${this.minCBD}`;
      this.variant.maxCBD = `${this.maxCBD}`;
    }
    return this.variant;
  }

}
