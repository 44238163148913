import { PrimaryCannabinoid } from '../shared/primary-cannabinoid.enum';
import { EnumUtils } from '../../../utils/enum-utils';

/** The order of these keys determines the sort order of the columns */
export enum SectionColumnConfigProductInfoKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  VariantType = 'VariantType',
  Stock = 'Stock',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
}

export const SectionColumnConfigPrimaryCannabinoidKey = EnumUtils.createEnum(Object.values(PrimaryCannabinoid));
export enum SectionColumnConfigSecondaryCannabinoidKey {
  SecondaryCannabinoids = 'SecondaryCannabinoids',
}

export type SectionColumnConfigCannabinoidKey = keyof typeof SectionColumnConfigPrimaryCannabinoidKey
  | keyof typeof SectionColumnConfigSecondaryCannabinoidKey;

export enum SectionColumnConfigCollectiveTerpeneKey {
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
}

export enum SectionColumnConfigIndividualTerpeneKey {
  IndividualTerpenes = 'IndividualTerpenes',
}

export type SectionColumnConfigTerpeneKey = SectionColumnConfigCollectiveTerpeneKey
  | keyof typeof SectionColumnConfigIndividualTerpeneKey;

export type SectionColumnConfigKey = SectionColumnConfigProductInfoKey
  | SectionColumnConfigCannabinoidKey
  | SectionColumnConfigTerpeneKey;
