import { Observable, of } from 'rxjs';
import { UploadType } from '../../../views/shared/components/upload-asset/upload-asset.component';
import { MediaUtils } from '../../../utils/media-utils';

export class ChangeMediaOptions {

  // Media
  public modalTitle: string = 'Change Media';
  public allowTypes$: Observable<UploadType[]> = of(['image', 'video']);
  public maxAssets: number = 1;
  public maxFileSize: number = MediaUtils.DEFAULT_MAX_FILE_SIZE;
  public inputId: number = 99;
  // Loading
  public loadingMess: string;
  public successTitle: string;
  public successMess: string;
  public failureTitle: string;
  public failureMess: string;

}
