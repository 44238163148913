<div class="auth-wrapper">
  <div class="auth-brand-container">
    <div class="brand-wrapper">
      <div class="brand-background"></div>
      <div class="brand-logo-container">
        <img
          (click)="logoClicked()"
          alt="bs"
          class="brand-logo"
          src="../../../../../assets/logo/light/logo-filled.svg" />
      </div>
    </div>
  </div>
  <div class="auth-action-container">
    <!--  Sign In Flow  -->
    <app-sign-in *ngIf="viewModel.authFlow === authFlows.SignIn"> </app-sign-in>
    <!--  Forgot Password Flow  -->
    <app-forgot-password *ngIf="viewModel.authFlow === authFlows.ForgotPassword"> </app-forgot-password>
    <!--  Reset Password Flow  -->
    <app-reset-password *ngIf="viewModel.authFlow === authFlows.ResetPassword"> </app-reset-password>
    <!--  New Password Flow  -->
    <app-new-password *ngIf="viewModel.authFlow === authFlows.SetNewPassword"> </app-new-password>
  </div>
</div>
