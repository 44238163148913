import * as moment from 'moment';

export class DateUtils {

  // Formatters

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToTimeAndSeconds(d: number): string {
    const utcMoment = moment.unix(d);
    // 8:30:00 PM
    return moment(utcMoment).local().format('LTS');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  static formatUnixForDateAtTime(d: number): string {
    if (d === 0) {
      return '--';
    } else {
      const utcMoment = moment.unix(d);
      return moment(utcMoment).local().format('MMMM Do YYYY') + ' at ' + moment(utcMoment).local().format('h:mm a');
    }
  }

  static formatUnixForShortDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM DD YYYY');
  }

  // Helpers for Seconds

  static currentTimestampInSeconds(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static secondsInOneMonth(): number {
    return DateUtils.secondsInOneDay() * 30;
  }

  static secondsInOneWeek(): number {
    return DateUtils.secondsInOneDay() * 7;
  }

  static secondsInOneDay(): number {
    return DateUtils.secondsInOneHour() * 24;
  }

  static secondsInOneHour(): number {
    return DateUtils.secondsInOneMinute() * 60;
  }

  static secondsInOneMinute(): number {
    return 60;
  }

}
