import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionColumnConfigDefaultState } from '../../../../../../../utils/section-column-config-default-state-type';
import { SectionColumnConfigProductInfoKey } from '../../../../../../../models/enum/dto/section-column-config-key';

@Injectable()
export class SingleColumnOptionsViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsModalViewModel
  ) {
    super();
  }

  public showDisabledInGridModeMessage$ = combineLatest([
    this.container.layoutType$,
    this.container.columnSectionKey$
  ]).pipe(
    map(([layoutType, columnSectionKey]) => {
      return ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, layoutType) ||
        (columnSectionKey === SectionColumnConfigProductInfoKey.Price && layoutType?.isGrid());
    })
  );

  public disabledInGridModeMessage$ = combineLatest([
    this.container.layoutType$,
    this.container.columnSectionKey$,
    this.container.columnSectionName$
  ]).pipe(
    map(([layoutType, columnSectionKey, columnName]) => {
      if (ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, layoutType)) {
        return `The ${columnName} column is disabled when the section is in Grid Mode.`;
      }
      return 'This section has Grid Mode enabled, and there may be multiple instance of this column in the section. ' +
        'Each column will be the width that is defined below.';
    })
  );

  public formColumnConfig$ = combineLatest([
    this.container.columnConfigMap$,
    this.container.layoutType$,
    this.container.columnSectionKey$
  ]).pipe(
    map(([columnConfigMap, layoutType, columnSectionKey]) => {
      const columnConfig = columnConfigMap?.get(columnSectionKey);
      if (ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, layoutType)) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.Disabled;
      } else if (layoutType?.isGrid() && columnSectionKey === SectionColumnConfigProductInfoKey.Price) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.On;
      }
      if (!!columnConfig?.columnOpacity && columnConfig?.columnOpacity <= 1) {
        columnConfig.columnOpacity = Math.round(columnConfig.columnOpacity * 100);
      }
      return columnConfig;
    })
  );

}
