<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"></app-loading>

<div class="modal-body sticky">
  <div
    #modalHeader
    appStickyAutoPosition
    [stickyCollectionKey]="'pos-sync'"
    [stickyZ]="2"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border pb-none">
      <div class="titles">
        <div>
          <div *ngIf="viewModel.modalTitle$ | async as title" class="modal-title" ngbAutofocus>{{ title }}</div>
          <div *ngIf="viewModel.modalSubtitle$ | async as subtitle" class="modal-subtitle">{{ subtitle }}</div>
        </div>
        <div
          *ngIf="viewModel.showClearSelectionsButton$ | async"
          appThrottleClick
          class="text-link"
          [class.disabled]="!(viewModel.nSelectedLocationIds$ | async)"
          (throttleClick)="viewModel.connectToSelectedLocationIds([])">
          Clear
        </div>
      </div>
    </div>
  </div>

  <app-data-to-sync-from-pos
    [hidden]="(viewModel.locationsSpecificPOSSyncingStep$ | async) !== ClientTypes.SyncJobStep.SelectSyncTypes">
  </app-data-to-sync-from-pos>

  <app-select-sync-locations
    [hidden]="(viewModel.locationsSpecificPOSSyncingStep$ | async) !== ClientTypes.SyncJobStep.SelectLocations">
  </app-select-sync-locations>

  <div
    #footerContainer
    class="sticky-footer-container modal-footer-flex-end"
    [style.justify-content]="(viewModel?.supportsSyncSinceLastUpdated$ | async) ? 'space-between' : null">
    <app-checkbox
      class="force-sync-all-time-checkbox"
      *ngIf="viewModel?.supportsSyncSinceLastUpdated$ | async"
      [inline]="true"
      [disabled]="viewModel?.syncAllTimeInputDisabled$ | async"
      [checked]="forceSyncAllTime?.checked"
      [checkbox]="forceSyncAllTime"
      [ngbTooltip]="'When selected the Last Updated timestamp is ignored and all data is synced from the POS.'"
      (clickEvent)="viewModel?.connectToForceSyncAllTime($event)">
    </app-checkbox>
    <div class="modal-footer-flex-end" [style.gap.rem]="1">
      <lib-button-neutral (buttonClicked)="viewModel.neutralButtonAction()">
        {{ viewModel.neutralButtonText$ | async }}
      </lib-button-neutral>
      <lib-button-primary
        [disabled]="viewModel.primaryButtonDisabled$ | async"
        [disabledTooltip]="viewModel.primaryButtonDisabledTooltip$ | async"
        (buttonClicked)="viewModel.primaryButtonAction()">
        {{ viewModel.primaryButtonText$ | async }}
      </lib-button-primary>
    </div>
  </div>
</div>
