import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { AsyncValidatorFn, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SortUtils } from '../../../../utils/sort-utils';
import { PopperUtils } from '../../../../utils/popper-utils';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements } from 'ngx-popperjs';
import { ClientTypeUtils } from '../../../../utils/client-type-utils';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-badge-picker-for-form',
  templateUrl: './badge-picker-for-form.component.html',
  styleUrls: ['./badge-picker-for-form.component.scss'],
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => BadgePickerForFormComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BadgePickerForFormComponent)
    }
  ],
})
export class BadgePickerForFormComponent extends ReactiveFormItemComponent implements ControlValueAccessor, OnChanges {

  // Control Value Accessor
  touched = false;
  onChange = () => {};
  onTouched = () => {};

  @Input() badges: HydratedVariantBadge[];
  @Input() maxBadgesAllowed: number = 5;
  @Input() popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.LEFT;
  @Input() addText: string = 'Add Badges';

  @ViewChild(NgxPopperjsContentComponent) popperContent: NgxPopperjsContentComponent;

  private _badges = new BehaviorSubject<HydratedVariantBadge[]>([]);
  public badges$ = this._badges.pipe(map(badges => badges?.sort(SortUtils.sortBadgesByName)));
  public badgeIds$ = this.badges$.pipe(map(badges => badges?.map(b => b.id)));

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['right'])];
  public popperStyles = { 'background-color': '#FFFFFF' };

  protected readonly ClientTypeUtils = ClientTypeUtils;

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.badges) this._badges.next(exists(this.badges) ? [...this.badges] : []);
  }

  getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  getValidators(): ValidatorFn[] {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    return [...validators, ...this.customValidators];
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  writeValue(obj: any): void {
  }

  updateBadges(badge: HydratedVariantBadge) {
    this.badges$.pipe(take(1)).subscribe(badges => {
      const existingBadges = badges.deepCopy();
      const i = existingBadges?.findIndex(b => b.id === badge?.id);
      if (i > -1) {
        existingBadges.splice(i, 1);
      } else {
        existingBadges.push(badge);
      }
      this._badges.next(existingBadges);
      this.getSelfAsFormItem()?.patchValue(existingBadges.map(b => b?.id));
      this.getSelfAsFormItem()?.markAsDirty();
      this.valueChanged.emit(this.getMyValue());
    });
  }

}
