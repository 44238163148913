import { SmartFilter } from './smart-filter';
import { Deserializable } from '../protocols/deserializable';
import { PillItem } from '../shared/stylesheet/pill-item';
import { SelectableSmartFilter } from './protocols/selectable-smart-filter';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { Asset } from '../image/dto/asset';
import { DatatableData } from '../protocols/datatable-data';
import { exists } from '../../functions/exists';
import { SecondaryCannabinoid } from '../enum/dto/secondary-cannabinoid';
import { Terpene } from '../enum/dto/terpene';
import { PrimaryCannabinoid } from '../enum/shared/primary-cannabinoid.enum';

export class HydratedSmartFilter extends SmartFilter
  implements Deserializable, SelectableSmartFilter, UniquelyIdentifiable, DatatableData {

  public appliedMenuIds: string[];
  public appliedVariantIds: string[];
  public appliedLabelIds: string[];
  public appliedBadgeIds: string[];

  // Not from API
  public appliedOnPills: PillItem[];

  override onDeserialize() {
    super.onDeserialize();
    this.appliedMenuIds = Array.from(this.appliedMenuIds || []);
    this.appliedVariantIds = Array.from(this.appliedVariantIds || []);
    this.appliedOnPills = [];
  }

  override getStandardizedCategoryName(): string {
    return this.category?.name?.trim()?.toLowerCase();
  }

  getSelectionName(): string {
    return this?.name;
  }

  getVariantHits(): number {
    return this?.appliedVariantIds?.length ?? 0;
  }

  getId(): string {
    return this.id;
  }

  some(filters: HydratedSmartFilter[]): boolean {
    const filterIds = filters?.map(filter => filter?.id);
    return filterIds?.some(id => id === this?.id);
  }

  hasProductPropertySet(): boolean {
    const productProperty = this.productType
      || this.variantType
      || this.classification
      || this.minPrice
      || this.maxPrice;
    return exists(productProperty);
  }

  hasCannabinoidPropertySet(): boolean {
    const primaryExists =  Object.values(PrimaryCannabinoid).some(pc => {
      const min = 'min' + pc;
      const max = 'max' + pc;
      return exists(this?.[min] || this?.[max]);
    });
    const secondaryExists = Object.values(SecondaryCannabinoid).some(sc => {
      const min = 'min' + sc;
      const max = 'max' + sc;
      return exists(this?.[min] || this?.[max]);
    });
    return primaryExists || secondaryExists;
  }

  hasTerpenePropertySet(): boolean {
    const topTerpeneExists = exists(this.topTerpene);
    const totalTerpeneExists = exists(this.minTotalTerpene || this.maxTotalTerpene);
    const terpeneExists = Object.values(Terpene).some(t => {
      const terpene = t?.stripWhiteSpace();
      const min = 'min' + terpene;
      const max = 'max' + terpene;
      return exists(this?.[min] || this?.[max]);
    });
    return topTerpeneExists || totalTerpeneExists || terpeneExists;
  }

  hasAdvancedPropertySet(): boolean {
    const advancedProperty = this.packagedQuantities
      || this.unitSizes
      || this.strain
      || this.searchTerm
      || this.minInventory
      || this.maxInventory
      || this.pricingCodes
      || this.activeSale
      || this.badgeIds?.length > 0
      || this.labelIds?.length > 0;
    return exists(advancedProperty);
  }

  override getUniqueIdentifier(): string {
    return super.getUniqueIdentifier().concat(
      `-${this.appliedMenuIds?.sort()?.join(',')}
       -${this.appliedVariantIds?.sort()?.join(',')}
       -${this.appliedLabelIds?.sort()?.join(',')}
       -${this.appliedBadgeIds?.sort()?.join(',')}`
    );
  }

  getNumOfFilters(): number {
    return null;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getSwitchEnabled(): boolean {
    return false;
  }

  getTextColor(): string {
    return '';
  }

  getGroupingImage(): Asset {
    return null;
  }

  getGroupedFilters(): HydratedSmartFilter[] {
    return [this];
  }

}
