import { DefaultStackPaperSizeType } from './default-stack-paper-size-type';

export enum DefaultPrintCardPaperSize {
  DefaultSize_LetterPerforated_Uline = 'DefaultSize_LetterPerforated_Uline',
  DefaultSize_Letter_CustomCut = 'DefaultSize_Letter_CustomCut'
}

export class DefaultPrintCardPaperSizeType extends DefaultStackPaperSizeType {

  override value: DefaultPrintCardPaperSize;

}
