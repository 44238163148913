import { UniversalVariantsNavSubItem } from '../models/universal-variants-sub-nav-item';
import { Pipe, PipeTransform } from '@angular/core';
import { NavSubItem } from '../../models/nav-sub-item';

@Pipe({
  name: 'isUniversalVariantsNavSubItem'
})
export class IsUniversalVariantsSubNavItemPipe implements PipeTransform {

  transform(value: NavSubItem): boolean {
    return value instanceof UniversalVariantsNavSubItem;
  }

}

