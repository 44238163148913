import { BehaviorSubject, defer } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FoundationBaseModalViewModel } from '@mobilefirstdev/base-angular';
import { LoadingOptions } from '../shared/loading-options';
import { map, startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseModalViewModel extends FoundationBaseModalViewModel {

  constructor(
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = this.loadingOpts$.pipe(map(it => it?.isLoading), startWith(false));

  cancel() {
    this.dismissModalSubject.next(true);
  }

}
