import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateBrandModalViewModel } from './create-brand-modal-view-model';

@Component({
  selector: 'app-create-brand-modal',
  templateUrl: './create-brand-modal.component.html',
  styleUrls: ['./create-brand-modal.component.scss'],
  providers: [CreateBrandModalViewModel]
})
export class CreateBrandModalComponent extends BaseModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: CreateBrandModalViewModel
  ) {
    super(activeModal);
  }

}
