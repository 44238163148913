import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Variant } from '../../../../models/product/dto/variant';
import { map } from 'rxjs/operators';

@Injectable()
export class EditVariantFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _variant = new BehaviorSubject<Variant>(null);
  public variant$ = this._variant as Observable<Variant>;
  connectToVariant = (variant: Variant) => this._variant.next(variant);

  public terpenes$ = this.variant$.pipe(
    map(variant => variant?.terpeneMap?.size > 0 ? variant.terpeneMap : null)
  );

}
