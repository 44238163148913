import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CompanyBadgeViewModel } from './company-badge-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';

@Component({
  selector: 'app-company-badge',
  templateUrl: './company-badge.component.html',
  styleUrls: [
    './company-badge.component.scss',
    './company-badge.responsive.scss'
  ],
  providers: [ CompanyBadgeViewModel ],
})
export class CompanyBadgeComponent extends BaseComponent implements OnChanges {

  @Input() badge: HydratedVariantBadge;
  @Input() editable: boolean = false;
  @Input() deletable: boolean = false;
  @Output() badgeClicked: EventEmitter<HydratedVariantBadge> = new EventEmitter();

  constructor(
    public viewModel: CompanyBadgeViewModel,
  ) {
    super();
  }

  override setupViews() {
    this.setBadge(this.badge);
  }

  private setBadge(t: HydratedVariantBadge) {
    if (t) { this.viewModel.setBadge(t); }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setBadge(this.badge);
  }

}
