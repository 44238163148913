import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantProperty } from '../../../utils/variant-property-type';

export class InventoryProviderSpecification implements Deserializable, UniquelyIdentifiable {

  companyId: number;
  fieldId: string;
  displayName: string;
  values: string;
  variantProperty: VariantProperty;
  ignored: boolean;

  onDeserialize() {
  }

  isIncomplete(): boolean {
    return !this.variantProperty && !this.ignored;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.companyId}-
      ${this.fieldId}-
      ${this.displayName}-
      ${this.values}-
      ${this.variantProperty}-
      ${this.ignored}`;
  }

}
