import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { UploadItemInterface } from './upload-item-interface';
import { FileUtils } from '../../../../../utils/file-utils';

@Component({
  selector: 'app-upload-item',
  templateUrl: './upload-item.component.html',
  styleUrls: ['./upload-item.component.scss']
})
export class UploadItemComponent extends BaseComponent {

  @Input() uploadItemInterface: UploadItemInterface;
  @Input() file: BudsenseFile;

  fileUtils = FileUtils;

  constructor() {
    super();
  }

  removeMe(): void {
    this.uploadItemInterface.removeMe(this.file);
  }

}
