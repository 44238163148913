<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body no-padding">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title-container">
        <div class="modal-title">Private Theme Companies</div>
        <div class="text-link" (click)="clearFiltersAndSearch()">Clear</div>
      </div>
    </div>

    <div class="search-bar-container">
      <app-search-with-look-ahead
        #companySearch
        [placeHolder]="'Search by company and location name'"
        [nLookAheadItems]="10"
        (searchText)="viewModel?.setSearchText($event)">
      </app-search-with-look-ahead>
    </div>
  </div>

  <div class="sticky-body-container private-theme-body">
    <ng-container *ngFor="let company of $any(viewModel.companiesFilteredBySearchString$ | async); last as last">
      <div class="private-theme-company-row">
        <app-checkbox-only
          [checked]="viewModel?.selectedCompanyIds$ | async | listIncludes : company?.id?.toString()"
          (valueChanged)="viewModel?.setSelectedValue($event, company?.id)"></app-checkbox-only>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" style="gap: 0.25rem">
          <div>{{ company?.name }}</div>
          <div *ngIf="viewModel.companiesUsingTheme$ | async | listIncludes : company?.id?.toString()" class="bs-bold">
            (Using this theme)
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button (click)="viewModel?.finishedSelection()" class="bs-button preferred-button">Done</button>
  </div>
</div>
