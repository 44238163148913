<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <div class="title-row">
      <div class="form-input-title mb-2">Info</div>
    </div>

    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-text
        [inputName]="'smartFilterName'"
        [label]="'Smart Filter Name'"
        [placeholder]="'My Smart Filter'"
        [inlineLabel]="false"
        [required]="true"
        [maxLength]="128"
        [disabled]="viewModel.isViewOnly$ | push"
        [bindingProperty]="'name'">
      </lib-reactive-form-text>

      <lib-reactive-form-number
        [inputName]="'itemCount'"
        [label]="'Item Count'"
        [placeholder]="'Enter an item count'"
        [inlineLabel]="false"
        [minValue]="0"
        [disabled]="viewModel.isViewOnly$ | push"
        [bindingProperty]="'itemCount'">
      </lib-reactive-form-number>

      <lib-reactive-form-drop-down
        [inputName]="'primarySort'"
        [label]="'Primary Sort'"
        [placeholder]="'Select a primary sort'"
        [inlineLabel]="false"
        [required]="false"
        [clearable]="!(viewModel.isViewOnly$ | push)"
        [useClearableButton]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [dropdowns]="viewModel.primarySortDropdownOptions$ | push"
        [bindingProperty]="'primarySort'"
        (valueChanged)="primarySortDropdownChanged($event)">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-drop-down
        #secondarySort
        [inputName]="'secondarySort'"
        [label]="'Secondary Sort'"
        [placeholder]="'Select a secondary sort'"
        [inlineLabel]="false"
        [required]="false"
        [clearable]="!(viewModel.disableSecondarySort$ | push)"
        [useClearableButton]="false"
        [disabled]="viewModel.disableSecondarySort$ | push"
        [dropdowns]="viewModel.secondarySortDropdownOptions$ | push"
        [bindingProperty]="'secondarySort'">
      </lib-reactive-form-drop-down>

      <div [style.display]="'flex'" [style.gap.rem]="0.5">
        <lib-reactive-form-drop-down
          #categoryDropDown
          class="flex-grow-1"
          [inputName]="'smartFilterCategory'"
          [label]="'Parent Group'"
          [placeholder]="'Select a filter group'"
          [inlineLabel]="false"
          [disabled]="false"
          [required]="true"
          [dropdowns]="dropdownSmartFilterCategories$ | push"
          [bindingProperty]="'categoryId'"
          [clearable]="true"
          [useClearableButton]="false">
        </lib-reactive-form-drop-down>
        <lib-button-primary class="mt-4" (buttonClicked)="newCategoryClicked()">New</lib-button-primary>
      </div>

      <lib-reactive-form-drop-down
        [inputName]="'smartFilterSystemIdentifier'"
        [label]="'System Identifier'"
        [placeholder]="'--'"
        [dropdowns]="dropdownSmartFilterSystemIdentifiers$ | push"
        [bindingProperty]="'systemIdentifier'"
        [disabled]="true"></lib-reactive-form-drop-down>

      <lib-reactive-form-switch
        class="mt-2"
        [style.align-self]="'center'"
        [label]="'This is an Advanced Curated Smart Filter'"
        [bindingProperty]="'advancedFilter'"
        [inputName]="'advancedFilter'">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        class="mt-2"
        [style.align-self]="'center'"
        [label]="'This is a Hidden Smart Filter.'"
        [bindingProperty]="'hidden'"
        [inputName]="'hiddenFilter'"
        [tooltip]="'Hidden smart filters will not appear within the smart filter modal'">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>

    <app-warning-banner *ngIf="viewModel.hasSystemIdentifier$ | push" [style.margin-top.rem]="1">
      This Smart Filter has a system identifier associated and is used in automatic menus and flows. Any changes made
      may break system functionality - proceed with caution.
    </app-warning-banner>
    <hr />
  </lib-reactive-form-group>
</div>
