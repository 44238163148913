import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartFilterRowViewModel } from './smart-filter-row-view-model';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-smart-filter-card-row',
  templateUrl: './smart-filter-card-row.component.html',
  styleUrls: ['./smart-filter-card-row.component.scss'],
})
export class SmartFilterCardRowComponent extends BaseComponent {

  @Input() showTopLine: boolean = true;
  @Output() cardClicked = new EventEmitter<HydratedSmartFilter[]>(true);
  @Output() hasSmartFiltersApplied = new EventEmitter<boolean>(true);

  constructor(
    public viewModel: SmartFilterRowViewModel // global, provided by root
  ) {
    super();
  }

  override setupBindings(): void {
    this.viewModel.hasAppliedFilters$.subscribeWhileAlive({
      owner: this,
      next: filters => this.hasSmartFiltersApplied.emit(filters)
    });
  }

  clearAppliedSmartFilters() {
    this.viewModel.clearAllSmartFilters();
  }

  openSelectSmartFilterModal() {
    this.viewModel.openSelectSmartFilterModal();
  }

}
