import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LabelViewModel } from './label-view-model';
import { BaseComponent } from '../../../models/base/base-component';
import { Label } from '../../../models/shared/label';
import { DisplayLabelInterface } from './display-label-interface';
import { Observable, Subject } from 'rxjs';
import { exists } from '../../../functions/exists';

@Component({ template: '' })
export abstract class LabelComponent extends BaseComponent implements OnChanges {

  protected constructor(
    public viewModel: LabelViewModel
  ) {
    super();
  }

  @Input() displayLabelInterface: DisplayLabelInterface;
  @Input() zoom: number;

  protected _clearClicked = new Subject<Label>();
  public clearClicked$ = this._clearClicked as Observable<Label>;

  override setupViews() {
    this.viewModel.connectToDisplayLabelInterface(this.displayLabelInterface);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.displayLabelInterface) {
      this.viewModel.connectToDisplayLabelInterface(this.displayLabelInterface);
    }
  }

  clearClicked() {
    if (exists(this.displayLabelInterface)) {
      this._clearClicked.next(this.displayLabelInterface?.getLabelsForLabelComponent()?.firstOrNull());
    }
  }

}
