<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header border-0">
      <div class="modal-title">
        <ng-container *ngIf="!!existingAsset">{{ existingAsset.fileName }}</ng-container>
        <ng-container *ngIf="!!uploadFile">{{ uploadFile.name }}</ng-container>
      </div>
    </div>
  </div>

  <div class="sticky-body-container">
    <app-asset class="asset-image" *ngIf="!!existingAsset" [scaleFit]="true" [asset]="existingAsset"></app-asset>
    <img class="asset-image" *ngIf="!!uploadFile" [src]="uploadFile?.url" alt="" />
  </div>

  <div class="sticky-footer-container modal-footer-flex-end w-100">
    <button (click)="cancel()" class="bs-button neutral-button">Done</button>
  </div>
</div>
