import { BaseModalViewModel } from '../../../../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { InventoryProviderSpecification } from '../../../../../../../../models/company/dto/inventory-provider-specification';
import { ToastService } from '../../../../../../../../services/toast-service';
import { BsError } from '../../../../../../../../models/shared/bs-error';

@Injectable()
export class ProviderSpecificationsViewModel extends BaseModalViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public mergeKey$ = of('providerSpecifications');

  public company$ = this.companyDomainModel.selectedCompany$;
  public companyConfig$ = this.company$.pipe(map(c => c?.configuration));
  public companyId$ = this.companyDomainModel.selectedCompanyId$;
  public provider$ = this.company$.pipe(map(c => c?.provider));
  public providerSpecifications$ = this.companyDomainModel.selectedCompanyInventoryProviderSpecifications$;

  private _canSubmit = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;
  connectToCanSubmit = (canSubmit: boolean) => this._canSubmit.next(canSubmit);

  updateProviderSpecifications(specs: InventoryProviderSpecification[]): void {
    const lm = 'Updating Inventory Provider Specifications';
    this._loadingOpts.addRequest(lm);
    this.companyId$.pipe(
      take(1),
      switchMap(companyId => this.companyDomainModel.updateProviderSpecifications(companyId, specs)),
    ).subscribe({
      next: (providerSpecs: InventoryProviderSpecification[]) => {
        this.toastService.publishSuccessMessage('Inventory Provider Specifications successfully updated', 'Success');
        this.companyDomainModel.connectToSelectedCompanyInventoryProviderSpecifications(providerSpecs);
        this._canSubmit.next(false);
        this._loadingOpts.removeRequest(lm);
        this.closeModal();
      },
      error: (error: BsError) => {
        this.toastService.publishError(error);
        this._loadingOpts.removeRequest(lm);
      }
    });
  }

}
