<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="viewModel.mergeKey$ | async"
  (canSubmit$)="viewModel.connectToCanSubmit($event)"
  (formsSubmitted$)="viewModel.updateProviderSpecifications($event)">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header no-border">
      <div class="modal-title">Inventory Provider Specifications</div>
    </div>
  </div>

  <div class="sticky-body-container pt-16px">
    <ng-container *ngFor="let spec of viewModel.providerSpecifications$ | push; let first = first">
      <app-provider-specifications-accordion
        [companyConfiguration]="viewModel.companyConfig$ | async"
        [inventoryProvider]="viewModel.provider$ | async"
        [mergeKey]="viewModel.mergeKey$ | async"
        [first]="first"
        [providerSpecification]="spec">
      </app-provider-specifications-accordion>
    </ng-container>
  </div>

  <div class="sticky-footer-container d-flex justify-content-end gap-2 pt-16px">
    <lib-button-neutral (buttonClicked)="cancel()">Cancel</lib-button-neutral>
    <lib-button-primary
      [disabled]="!(viewModel.canSubmit$ | async)"
      [class.disabled]="viewModel.canSubmit$ | async"
      (buttonClicked)="formCapture.submitForms()">
      Save Changes
    </lib-button-primary>
  </div>
</div>
