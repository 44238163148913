import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicRoundBorderLabelComponent } from './basic-round-border-label/basic-round-border-label.component';
import { FlagLabelComponent } from './flag-label/flag-label.component';
import { LabelInflatorComponent } from './label-inflator/label-inflator.component';
import { FitTextModule } from '../scale-up-text/fit-text.module';
import { LabelInflatorContainerDirective } from './label-inflator/label-inflator-container.directive';
import { LabelThrottleClickDirective } from './label-remove-button/label-throttle-click.directive';
import { LabelRemoveButtonComponent } from './label-remove-button/label-remove-button.component';
import { MatRippleModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LabelLockedComponent } from './label-locked/label-locked.component';
import { LabelAddedBySmartFilterBadgeComponent } from './label-added-by-smart-filter-badge/label-added-by-smart-filter-badge.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { SharedModule } from '../../views/shared/shared.module';
import { SimpleLabelPreviewComponent } from './simple-label-preview/simple-label-preview.component';
import { InlineLabelsPickerComponent } from './inline-labels-picker/inline-labels-picker.component';
import { LabelsPickerComponent } from './labels-picker/labels-picker.component';
import { LabelInterfaceForLabelPickerPipe } from './pipes/label-interface-for-label-picker.pipe';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [
    CommonModule,
    FitTextModule,
    FlexLayoutModule,
    MatRippleModule,
    NgbModule,
    ReactiveFormModule,
    SharedModule,
    NgxPopperjsModule
  ],
  declarations: [
    LabelInflatorContainerDirective,
    BasicRoundBorderLabelComponent,
    FlagLabelComponent,
    LabelInflatorComponent,
    LabelThrottleClickDirective,
    LabelRemoveButtonComponent,
    LabelLockedComponent,
    LabelAddedBySmartFilterBadgeComponent,
    SimpleLabelPreviewComponent,
    InlineLabelsPickerComponent,
    LabelsPickerComponent,
    LabelInterfaceForLabelPickerPipe
  ],
  exports: [
    BasicRoundBorderLabelComponent,
    FlagLabelComponent,
    LabelInflatorComponent,
    InlineLabelsPickerComponent
  ]
})
export class ProductLabelsModule { }
