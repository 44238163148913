import { NavSubItem } from './nav-sub-item';
import { UniquelyIdentifiable } from '../../../../models/protocols/uniquely-identifiable';
import { exists } from '../../../../functions/exists';

export class NavItem implements UniquelyIdentifiable {

  public id: number;
  public name: string;
  public subItems: NavSubItem[];
  public iconSrc: string;
  public iconFilledSrc: string;
  public routerPath: string;
  public savedSubNavPosition: number = 0;
  public savedFragment: string;

  constructor(
    id: number,
    name: string,
    subItems: NavSubItem[],
    routerPath: string,
    iconSrc: string,
    iconFilledSrc: string,
  ) {
    this.id = id;
    this.name = name;
    this.subItems = subItems;
    this.iconSrc = iconSrc;
    this.iconFilledSrc = iconFilledSrc;
    this.routerPath = routerPath;
  }

  public hasSubItems(): boolean {
    return this.subItems?.length > 0;
  }

  public getSavedSubNavItem(): NavSubItem {
    if (this.subItems?.length > this.savedSubNavPosition) {
      return this.subItems[this.savedSubNavPosition];
    } else {
      return this.subItems?.length > 0 ? this.subItems[0] : null;
    }
  }

  public getSavedFragment(): string {
    return this?.savedFragment;
  }

  public saveSubPosition(save: NavSubItem, fragment: string) {
    if (exists(fragment)) {
      this.savedFragment = fragment?.replace('#', '');
    }
    const index = this.subItems?.indexOf(save);
    if (index > -1) { this.savedSubNavPosition = index; } else { this.savedSubNavPosition = 0; }
  }

  getUniqueIdentifier(...opts: any): string {
    return `
      -id: ${this.id}
      -name: ${this.name}
      -subItems: ${this.subItems?.map(m => m?.getUniqueIdentifier())?.join(',')}
      -url: ${this.routerPath}
    `;
  }

}
