<div
  #container
  fxLayout="row"
  fxLayoutAlign=" center"
  fxLayoutGap="1rem"
  class="sync-selection-container"
  [ngClass]="{ disabled: disabled }"
  (click)="checkbox.itemClicked()">
  <div fxFlex fxLayout="column" fxLayoutAlign="center ">
    <div class="sync-selection-name">
      {{ name$ | async }}
      <div *ngIf="!!(syncTypeTooltip$ | async)" class="tooltip-container">
        <img
          [ngbTooltip]="syncTypeTooltip$ | async"
          [src]="'assets/icons/dark/outline/information-circle.svg'"
          alt="" />
      </div>
    </div>
    <ng-container *ngIf="selection | hasDisplayableChildren">
      <div
        *ngFor="let child of selection | getChildSyncTypeNames : (provider$ | async) | async"
        class="child-selection-type">
        • {{ child }}
      </div>
    </ng-container>
  </div>
  <app-checkbox-only #checkbox [checked]="selected$ | async" [disabled]="disabled" (valueChanged)="clicked($event)">
  </app-checkbox-only>
</div>
<hr *ngIf="includeBottomBorder" class="no-margin" />
