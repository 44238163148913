import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class CompanyFeatures implements Deserializable, UniquelyIdentifiable {

  companyId: number;
  supportsLocationLabels: boolean = false;
  supportsTemplates: boolean = false;
  supportsPrintCards: boolean = false;
  supportsCustomDisplayNameParser: boolean = false;

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `
      ${this.companyId}
      -${this.supportsLocationLabels}
      -${this.supportsTemplates}
      -${this.supportsPrintCards}
      -${this.supportsCustomDisplayNameParser}
    `;
  }

}
