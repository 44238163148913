import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-theme-preview-card',
  templateUrl: './theme-preview-card.component.html',
  styleUrls: ['./theme-preview-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThemePreviewCardComponent {

  @Input() card: Card;
  @Input() cardClass: string;

  @Output() cardButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  getCardClasses() {
    if (this.card?.customClass) {
      return `${this.cardClass} ${this.card.customClass}`;
    } else {
      return this.cardClass;
    }
  }

  handleCardButtonClicked(e) {
    e.stopPropagation();
    this.cardButtonClicked.emit(true);
  }

}
