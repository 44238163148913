import { AdminUser } from '../../account/dto/admin-user';

export class EmployeeAction {

  action: string;
  employee: AdminUser;

  constructor(a: string, e: AdminUser) {
    this.action = a;
    this.employee = e;
  }

}
