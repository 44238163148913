import { Deserializable } from '../protocols/deserializable';
import { Asset } from '../image/dto/asset';
import { Selectable } from '@mobilefirstdev/reactive-form/lib/components/reactive-form-item/reactive-form-drop-down/selectable';
import { Orderable } from '../protocols/orderable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';

export class SmartFilterCategory implements Deserializable, Orderable, Selectable, UniquelyIdentifiable {

  public locationId: number;
  public id: string;
  public name: string;
  public priority: number;
  public image: Asset;

  onDeserialize() {
    this.image = window.injector.Deserialize.instanceOf(Asset, this.image);
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableTitle(): string {
    return this.getSelectionTitle();
  }

  getOrderableUniqueId(): string {
    return this.getSelectionUniqueIdentifier();
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }

  getUniqueIdentifier(): string {
    return `${this.locationId}
           -${this.id}
           -${this.name}
           -${this.priority}
           -${this.image?.getUniqueIdentifier()}`;
  }

}
