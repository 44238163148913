import { Component } from '@angular/core';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseViewModel } from '../../../../models/base/base-view-model';

@Component({ template: '' })
 export abstract class BaseSmartFilterFormViewModelComponent extends BaseViewModel {

  private _isEditing = new BehaviorSubject<boolean>(false);
  public isEditing$ = this._isEditing as Observable<boolean>;

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public smartFilter$ = this._smartFilter as Observable<HydratedSmartFilter>;

  private _isViewOnly = new BehaviorSubject<boolean>(false);
  public isViewOnly$ = this._isViewOnly as Observable<boolean>;

  public connectToIsEditing = (isEditing: boolean) => this._isEditing.next(isEditing);
  public connectToSmartFilter = (sf: HydratedSmartFilter) => this._smartFilter.next(sf);
  public connectToIsViewOnly = (isViewOnly: boolean) => this._isViewOnly.next(isViewOnly);

}
