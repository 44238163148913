import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { SelectableComponent } from '../../../components/group-selection/selectable/selectable.component';
import { Location } from '../../../../../models/company/dto/location';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-smart-sync-location-selection',
  templateUrl: './smart-sync-location-selection.component.html',
  styleUrls: ['./smart-sync-location-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartSyncLocationSelectionComponent extends SelectableComponent implements OnChanges {

  constructor() {
    super();
  }

  @Input() override selection: Location;

  protected override _selection = new BehaviorSubject<Location>(null);
  public override selection$ = defer(() => this._selection) as Observable<Location>;
  public name$ = this.selection$.pipe(map(selection => selection?.name));
  public address$ = this.selection$.pipe(map(selection => selection?.address));
  public cityProv$ = this.selection$.pipe(map(selection => selection?.getCityProv()));

}
