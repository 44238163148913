<div
  class="card bs-card section-blueprint-category-preview-card"
  [ngClass]="cardClass"
  (click)="cardPressed.emit(card)">
  <div class="content-container">
    <div class="text-container ms-3">
      <div class="category-name">{{ card.label }}</div>
    </div>
  </div>
</div>
