import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { DatatableData } from '../../protocols/datatable-data';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Pagable } from '../../protocols/pagable';
import { HasChildIds } from '../../protocols/has-child-ids';
import { ProductType } from '../../../utils/product-type-definition';
import { VariantType } from '../../../utils/variant-type-definition';
import { CannabisUnitOfMeasure } from '../../../utils/cannabis-unit-of-measure-type';
import { UnitOfMeasure } from '../../../utils/unit-of-measure-type';
import { StrainType } from '../../../utils/strain-type-type';
import { StringUtils } from 'src/app/utils/string-utils';
import { TerpeneUnitOfMeasure } from '../../../utils/terpene-unit-of-measure-type';
import { exists } from '../../../functions/exists';

export class Variant implements Deserializable, Cachable, DatatableData, UniquelyIdentifiable, Pagable, HasChildIds {

  static readonly invalidParsedCannabinoidOrTerpene = -2;
  static readonly terpenePresentOnVariant = -1;
  // DTO
  public barcode: string;
  public confirmed: boolean = false;
  public displayAttributeGroupId: string;
  public assetGroupId: string;
  public name: string;
  public brand: string;
  public manufacturer: string;
  public description: string;
  public shortDescription: string;
  public terpenes: string;
  public terpeneMap: Map<string, number>;
  public classification: StrainType;
  public unitSize: number;
  public unitOfMeasure: UnitOfMeasure;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  public terpeneUnitOfMeasure: TerpeneUnitOfMeasure;
  public packagedQuantity: number;
  public productType: ProductType;
  public variantType: VariantType;
  public strain: string;
  public THC: string;
  public minTHC: string;
  public maxTHC: string;
  public CBD: string;
  public minCBD: string;
  public maxCBD: string;
  public cultivatorVerified: boolean = false;
  public delisted: boolean = false;
  public dateCreated: number;
  public lastModified: number;
  // Cache
  public cachedTime: number;

  static buildArrayCacheKey(companyId, locationId: number, productId: string): string {
    return `UBVs-${companyId}-${locationId}-${productId}`;
  }

  static buildCacheKey(companyId, locationId: number, variantId: string): string {
    return `UBV-${companyId}-${locationId}-${variantId}`;
  }

  static getNumericValue = (v: string): number => {
    if (exists(v)) {
      if (v.includes('-')) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.]+/g, ''));
    } else {
      return Variant.invalidParsedCannabinoidOrTerpene;
    }
  };

  public onDeserialize() {
    this.terpeneMap = window.injector.Deserialize.genericMap<string, number>(this.terpeneMap);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(companyId, locationId: number): string {
    return Variant.buildCacheKey(companyId, locationId, this.barcode);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  // Datatable Methods

  getChildrenUniqueIds(): string[] {
    return [this.barcode];
  }

  getColor(): string {
    return '#333333';
  }

  getTextColor(): string {
    return '#fefefe';
  }

  // Incomplete variant

  isIncompleteOrUnconfirmed() {
    return this.isUnconfirmed() || this.isIncomplete();
  }

  isIncomplete(): boolean {
    const ipc = this.incompletePropertyCount();
    if (this.productType === ProductType.Accessories || this.productType === ProductType.Other) {
      // Never show Accessories or Other as incomplete
      return false;
    } else {
      return ipc > 0;
    }
  }

  isUnconfirmed(): boolean {
    return !this.confirmed;
  }

  incompletePropertyCount(): number {
    return this.incompleteProperties()?.length ?? 0;
  }

  incompleteProperties(): string[] {
    const incompleteProperties = [];
    if (this.packagedQuantity === 0) {
      incompleteProperties.push('packagedQuantity');
    }
    if (this.brand === '') {
      incompleteProperties.push('brand');
    }
    if (!this.classification) {
      if (this.classification.toString() === '') {
        incompleteProperties.push('classification');
      }
    }
    if (this.unitSize === 0) {
      incompleteProperties.push('unitSize');
    }
    if (!this.unitOfMeasure) {
      if (this.unitOfMeasure.toString() === '') {
        incompleteProperties.push('unitOfMeasure');
      }
    }
    if (this.cannabisUnitOfMeasure?.toString() === '') {
      incompleteProperties.push('cannabisUnitOfMeasure');
    }
    if (!this.productType) {
      if (this.productType.toString() === '') {
        incompleteProperties.push('productType');
      }
    }
    if (!this.variantType) {
      if (this.variantType.toString() === '') {
        incompleteProperties.push('variantType');
      }
    }
    if (this.THC === '') {
      incompleteProperties.push('THC');
    }
    if (this.CBD === '') {
      incompleteProperties.push('CBD');
    }
    if (!this.minCBD) {
      if (this.minCBD?.toString() === '') {
        incompleteProperties.push('minCBD');
      }
    }
    if (!this.maxCBD) {
      if (this.maxCBD?.toString() === '') {
        incompleteProperties.push('maxCBD');
      }
    }
    if (!this.minTHC) {
      if (this.minTHC?.toString() === '') {
        incompleteProperties.push('minTHC');
      }
    }
    if (!this.maxTHC) {
      if (this.maxTHC?.toString() === '') {
        incompleteProperties.push('maxTHC');
      }
    }
    return incompleteProperties;
  }

  // Unassigned Variant
  isUnassigned() {
    return this.displayAttributeGroupId === '';
  }

  isUnassignedToAssetGroup(): boolean {
    return exists(this.displayAttributeGroupId)
        && (!this.assetGroupId || this.assetGroupId === this.displayAttributeGroupId);
  }

  // Update methods

  getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneProperty: string): number {
    const strValue = this.getCannabinoidOrTerpene(cannabinoidOrTerpeneProperty);
    return this.getNumericValue(strValue);
  }

  getCannabinoidOrTerpene(cannabinoidOrTerpeneProperty: string): string {
    if (this.isAccessory()) return '';
    return this?.[cannabinoidOrTerpeneProperty];
  }

  isAccessory(): boolean {
    return this.productType === ProductType.Accessories;
  }

  getNumericValue(v: string): number {
    if (exists(v)) {
      if (v.includes('-') && (v !== Variant.terpenePresentOnVariant.toString())) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.-]+/g, ''));
    } else {
      return Variant.invalidParsedCannabinoidOrTerpene;
    }
  }

  getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const strValue = this.getMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
    return this.getNumericValue(strValue);
  }

  getMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const accessor = StringUtils.uppercaseFirstCharacter(cannabinoidOrTerpeneCamelCased);
    return this?.[`min${accessor}`];
  }

  getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    const strValue = this.getMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
    return this.getNumericValue(strValue);
  }

  getMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): string {
    const accessor = StringUtils.uppercaseFirstCharacter(cannabinoidOrTerpeneCamelCased);
    return this?.[`min${accessor}`];
  }

  getNumericSize(): number {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.InfusedBulkFlower:
      case VariantType.MilledFlower:
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return this.unitSize;
      default:
        return this.packagedQuantity;
    }
  }

  getSize(): string {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.InfusedBulkFlower:
      case VariantType.MilledFlower:
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return `${this.unitSize} ${this.unitOfMeasure}`;
      default:
        return `${this.packagedQuantity} pk`;
    }
  }

  getGridName() {
    switch (this.variantType) {
      case VariantType.Flower:
      case VariantType.BulkFlower:
      case VariantType.InfusedBulkFlower:
      case VariantType.MilledFlower:
        return this.unitSize > 0 ? `${this.unitSize} g` : null;
      case VariantType.Oil:
      case VariantType.Vape:
      case VariantType.VapeCartridge:
      case VariantType.Concentrate:
        return this.unitSize > 0 ? `${this.unitSize} ${this.unitOfMeasure}` : null;
      case VariantType.Capsule:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} caps` : null;
      default:
        return this.packagedQuantity > 0 ? `${this.packagedQuantity} pk` : null;
    }
  }

  formattedSizing(): string {
    let sizeString = '';
    if ((this.packagedQuantity > 0) && (this.unitSize > 0) && (this.unitOfMeasure !== UnitOfMeasure.NA)) {
      const n = this.packagedQuantity;
      if (this.variantType === VariantType.Capsule) {
        sizeString = `${n} cap${n > 1 ? 's' : ''}`;
      } else if (this.productType === ProductType.Edible) {
        sizeString = `${n} pack`;
      } else {
        sizeString = this.packagedQuantity + ' x ' + this.unitSize + this.unitOfMeasure;
      }
    }
    return sizeString;
  }

  getSwitchEnabled(): boolean {
    return true;
  }

  getUniqueIdentifier(): string {
    // generate unique id
    return `${this.barcode}
      -${this.confirmed}
      -${this.displayAttributeGroupId}
      -${this.assetGroupId}
      -${this.name}
      -${this.brand}
      -${this.manufacturer}
      -${this.description}
      -${this.shortDescription}
      -${this.terpenes}
      -${this.classification}
      -${this.unitSize}
      -${this.unitOfMeasure}
      -${this.cannabisUnitOfMeasure}
      -${this.terpeneUnitOfMeasure}
      -${this.packagedQuantity}
      -${this.productType}
      -${this.variantType}
      -${this.strain}
      -${this.THC}
      -${this.minTHC}
      -${this.maxTHC}
      -${this.CBD}
      -${this.minCBD}
      -${this.maxCBD}
      -${this.dateCreated}
      -${this.lastModified}
      -${this.cultivatorVerified}
      -${this.delisted}`;
  }

  getStartKey(): string {
    return this.barcode;
  }

  hasCannabisUnitOfMeasure(): boolean {
    return this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.NA
      && this.cannabisUnitOfMeasure !== CannabisUnitOfMeasure.UNKNOWN;
  }

  hasTerpeneUnitOfMeasure(): boolean {
    return this.terpeneUnitOfMeasure !== TerpeneUnitOfMeasure.NA
      && this.terpeneUnitOfMeasure !== TerpeneUnitOfMeasure.UNKNOWN;
  }

  getCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory() || !this.hasCannabisUnitOfMeasure()) return '';
    let cannabinoidWithUnits = '';
      const cannabinoidValue = this.getNumericCannabinoidOrTerpene(cannabinoidCamelCased);
      if (cannabinoidValue !== Variant.invalidParsedCannabinoidOrTerpene) {
        cannabinoidWithUnits = `${cannabinoidValue}${this.cannabisUnitOfMeasure}`;
      }
    return cannabinoidWithUnits;
  }

  getTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory() || !this.hasTerpeneUnitOfMeasure()) return '';
    let terpeneWithUnits = '';
    const terpeneValue = this.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    if (terpeneValue !== Variant.invalidParsedCannabinoidOrTerpene) {
      terpeneWithUnits = `${terpeneValue}${this.terpeneUnitOfMeasure}`;
    }
    return terpeneWithUnits;
  }

  getMinCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory()) return '';
    const minVal = this.getNumericMinCannabinoidOrTerpene(cannabinoidCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasCannabisUnitOfMeasure()) {
      rangeWithUnits = `${minVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMinTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const minVal = this.getNumericMinCannabinoidOrTerpene(terpeneCamelCased);
    let rangeWithUnits: string = '';
    if (minVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasTerpeneUnitOfMeasure()) {
      rangeWithUnits = `${minVal}${this.terpeneUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxCannabinoidWithUnits(cannabinoidCamelCased: string): string {
    if (this.isAccessory()) return '';
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(cannabinoidCamelCased);
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasCannabisUnitOfMeasure()) {
      rangeWithUnits = `${maxVal}${this.cannabisUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getMaxTerpeneWithUnits(terpeneCamelCased: string): string {
    if (this.isAccessory()) return '';
    const maxVal = this.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased);
    let rangeWithUnits: string = '';
    if (maxVal !== Variant.invalidParsedCannabinoidOrTerpene && this.hasTerpeneUnitOfMeasure()) {
      rangeWithUnits = `${maxVal}${this.terpeneUnitOfMeasure}`;
    }
    return rangeWithUnits || '';
  }

  getChildIds(): string[] {
    return [this.barcode];
  }

  getId(): string {
    return this.barcode;
  }

}
