<div
  #contentContainer
  class="sticky-body-container"
  appStickyAutoPosition
  [stickyCollectionKey]="'pos-sync'"
  [stickyZ]="2"
  [style.overflow-y]="'unset'">
  <div class="desc-and-search" fxLayout="column" [style.gap.rem]="1">
    <hr class="no-margin" />
    <app-search-with-look-ahead
      [placeHolder]="'Search for a location'"
      [lookAheadItems]="viewModel.activeCompanyLocations$ | async"
      [searchTheseProperties]="['name', 'address', 'city', 'state', 'country']"
      [disablePopper]="true"
      [nLookAheadItems]="10000"
      [outputAllItemsWhenSearchIsEmpty]="true"
      (searchText)="viewModel.connectToSearchString($event)"
      (lookAheadHits)="viewModel.connectToSearchedLocations($event)">
    </app-search-with-look-ahead>
    <ng-container [ngSwitch]="(viewModel.searchedLocations$ | async)?.length > 0">
      <div *ngSwitchCase="true" class="select-all-locations-container">
        <div fxLayout="row" fxLayoutAlign="start center">
          <app-group-selection
            #groupSelectionComponent
            [selection]="viewModel.locationsVisibleOnScreen$ | async"
            [selectedIds]="viewModel.selectedLocationIdsAsStringList$ | async"
            (bulkAdd)="viewModel.addLocationIds($event)"
            (bulkRemove)="viewModel.removeLocationIds($event)">
          </app-group-selection>
          <div class="n-selected">
            <span *ngIf="viewModel.nSelectedLocationIds$ | async as nSelected" class="checked-count">
              ({{ nSelected }})
            </span>
          </div>
        </div>
        <div class="select-all-locations-text">Select All Locations</div>
      </div>
      <div *ngSwitchCase="false" class="empty-search-state">No locations found.</div>
    </ng-container>
  </div>
</div>
<div class="sticky-body-container" [style.overflow-y]="'unset'">
  <div class="modal-search-results-container">
    <app-location-selection-group
      *ngFor="
        let grouping of viewModel.searchedLocationsGroupedByProvince$ | async | keyvalue : incompleteLocationsLast;
        trackBy: viewModel.trackByProvince
      "
      [province]="grouping?.key"
      [locations]="grouping?.value"
      [updatedLocationIds]="viewModel.selectedLocationIds$ | async"
      [selectedIds]="viewModel.selectedLocationIdsAsStringList$ | async"
      (addLocationIds)="viewModel.addLocationIds($event)"
      (removeLocationIds)="viewModel.removeLocationIds($event)">
    </app-location-selection-group>
  </div>
</div>
