import { Subscription } from 'rxjs';

declare module 'rxjs/internal/Subscription' {
  interface Subscription {
    addTo(parentSubs: Subscription[]): Subscription;
  }
}

Subscription.prototype.addTo =
  function(parentSubs: Subscription[]): Subscription {
    parentSubs.push(this);
    return this;
  };
