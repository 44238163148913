import { Deserializable } from '../../protocols/deserializable';

export class SignOutRequest implements Deserializable {

  public userId: string = '';
  public accessToken: string = '';

  constructor(userId?: string, accessToken?: string) {
    this.userId = userId;
    this.accessToken = accessToken;
  }

  onDeserialize() {
  }

}
