<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="sticky-header-container">
  <div class="modal-header no-bottom-border pb-none">
    <div class="modal-title">View Sync Job</div>
  </div>
</div>

<div class="modal-body">
  <lib-reactive-form-group #SyncDataForm>
    <lib-reactive-form-text
      [inputName]="'name'"
      [label]="'Sync Job Name'"
      [editable]="false"
      [inlineLabel]="false"
      [programmaticallyChangeValue]="syncDataJob?.name | dateTimeStringIfUnixStringElseOriginal">
    </lib-reactive-form-text>
  </lib-reactive-form-group>

  <div fxLayout="column" [style.gap.rem]="1">
    <div class="review-sync-results-subtitle">Sync Results</div>
    <div class="review-sync-results-description">
      View what information was updated as a result of the POS sync job.
    </div>
    <hr class="no-margin" />
  </div>

  <div>
    <app-review-sync-list-item
      *ngFor="let syncType of syncDataJob?.syncTypes"
      [syncType]="syncType | getSyncTypeDefinition | async"
      [syncJobResults]="syncDataJob?.results"
      [provider]="viewModel?.inventoryProvider$ | async">
    </app-review-sync-list-item>
  </div>

  <div class="modal-footer-flex-end pt-16px" fxLayout="row" [style.gap.rem]="1">
    <lib-button-neutral (buttonClicked)="close(null)"> Close </lib-button-neutral>
  </div>
</div>
