import { DefaultStackPaperSizeType } from './default-stack-paper-size-type';

export enum DefaultPrintLabelPaperSize {
  DefaultSize_LetterLabel_Uline = 'DefaultSize_LetterLabel_Uline',
  DefaultSize_Letter_CustomCut = 'DefaultSize_Letter_CustomCut',
}

export class DefaultPrintLabelPaperSizeType extends DefaultStackPaperSizeType {

  override value: DefaultPrintLabelPaperSize;

}
