import { Component } from '@angular/core';
import { UpdateAssetLibraryTypesViewModel } from './update-asset-library-types-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetGroup } from '../../../../models/product/dto/asset-group';

@Component({
  selector: 'app-update-asset-library-types-modal',
  templateUrl: './update-asset-library-types-modal.component.html',
  styleUrls: ['./update-asset-library-types-modal.component.scss'],
  providers: [UpdateAssetLibraryTypesViewModel]
})
export class UpdateAssetLibraryTypesModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: UpdateAssetLibraryTypesViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  public imgStyleOverrides = {
    position: 'relative',
    width: '9.5rem',
    height: '9.5rem',
  };

  setAssetGroup(as: AssetGroup) {
    this.viewModel?.connectToAssetGroup(as);
  }

}
