import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { map } from 'rxjs/operators';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../models/shared/stylesheet/drop-down-menu-section';
import { BadgeAction } from '../../../../models/company/shared/badge-action';
import { Injectable } from '@angular/core';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';

/**
 * Lifecycle is tied to component lifecycle. Therefore dies when component dies.
 */
@Injectable()
export class CompanyBadgeViewModel extends BaseViewModel {

  public badge: HydratedVariantBadge;
  public displayImg: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public menuItems: DropDownMenuSection[];

  constructor() {
    super();
  }

  setBadge(t: HydratedVariantBadge) {
    this.badge = t;
    this.subToBadgeImg(t);
    this.setupDropDownMenu();
  }

  setupDropDownMenu() {
    this.menuItems = [];
    const ddmi = [];
    ddmi.push(new DropDownMenuItem('Edit', new BadgeAction('Edit', this.badge), new Set<string>()),);
    ddmi.push(new DropDownMenuItem('Delete', new BadgeAction('Delete', this.badge), new Set<string>().add('red-text')));
    this.menuItems.push(new DropDownMenuSection(null, ddmi));
  }

  private subToBadgeImg(badge: HydratedVariantBadge) {
    const imgKey = 'Badge_Image';
    this.destroyImageSub(imgKey);
    const badgeUrl = badge?.image?.getAssetUrl(AssetSize.Medium)?.srcUrl;
    if (badgeUrl) {
      const s = this.displayImg.bind(
        badgeUrl.pipe(map((url) => (!url ? 'assets/placeholder/list-image-dark.jpg' : url)))
      );
      this.pushImageSub(imgKey, s);
    }
  }

}
