import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { HydratedAdminUser } from '../models/account/dto/hydrated-admin-user';
import { Endpoints } from './endpoints';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { Injectable } from '@angular/core';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { SignOutRequest } from '../models/account/requests/sign-out-request';
import { RefreshSessionRequest } from '../models/account/requests/refresh-session-request';
import { CreateUserRequest } from '../models/account/requests/create-user-request';
import { AdminUser } from '../models/account/dto/admin-user';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '../services/logging-service';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { BsError } from '../models/shared/bs-error';

@Injectable({
  providedIn: 'root'
})
export class AccountAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Account';

  // Session

  public SignIn(req: SignInRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.SignIn();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignIn', err));
        return throwError(err);
      })
    );
  }

  public SignInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.SignInNewPassword();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignInNewPassword', err));
        return throwError(err);
      })
    );
  }

  public SignOut(req: SignOutRequest): Observable<any> {
    const url = Endpoints.SignOut();
    return this.apiClient.postObj(AdminUser, url, req, null, 'text').pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignOut', err));
        return throwError(err);
      })
    );
  }

  public RefreshSession(req: RefreshSessionRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.RefreshSession();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'RefreshSession', err));
        return throwError(err);
      })
    );
  }

  // Admin Account

  public AdminCreateUser(req: CreateUserRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.AdminCreateUser();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminCreateUser', err));
        return throwError(err);
      })
    );
  }

  public AdminDeleteUser(user: AdminUser): Observable<string> {
    const url = Endpoints.AdminDeleteUser();
    return this.apiClient.deleteStr(url, user, null, 'text').pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminDeleteUser', err));
        return throwError(err);
      })
    );
  }

  public AdminUpdateUser(employee: AdminUser): Observable<AdminUser> {
    const url = Endpoints.AdminUpdateUser();
    return this.apiClient.postObj(AdminUser, url, employee).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminUpdateUser', err));
        return throwError(err);
      })
    );
  }

  public AdminGetUsers(): Observable<HydratedAdminUser[]> {
    const url = Endpoints.AdminGetUsers();
    return this.apiClient.getArr<HydratedAdminUser>(HydratedAdminUser, url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AdminGetUsers', err));
        return throwError(err);
      })
    );
  }

  // AdminUser Account

  public ChangePassword(req: ChangePasswordRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.ChangePassword();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ChangePassword', err));
        return throwError(err);
      })
    );
  }

  public ConfirmCode(req: ConfirmCodeRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.ConfirmCode();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ConfirmCode', err));
        return throwError(err);
      })
    );
  }

  public GetForgotPasswordCode(email: string): Observable<CodeDeliveryDetails> {
    const url = Endpoints.ForgotPasswordCode(email);
    return this.apiClient.getObj<CodeDeliveryDetails>(CodeDeliveryDetails, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetForgotPasswordCode', err));
        return throwError(err);
      })
    );
  }

  public ResendCode(email, token?: string): Observable<CodeDeliveryDetails> {
    const url = Endpoints.ResendCode(email, token);
    return this.apiClient.getObj<CodeDeliveryDetails>(CodeDeliveryDetails, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResendCode', err));
        return throwError(err);
      })
    );
  }

  public ResetForgottenPassword(req: ResetPasswordRequest): Observable<HydratedAdminUser> {
    const url = Endpoints.ResetForgottenPassword();
    return this.apiClient.postObj(HydratedAdminUser, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResetForgottenPassword', err));
        return throwError(err);
      })
    );
  }

  public UpdateUser(user: HydratedAdminUser): Observable<HydratedAdminUser> {
    const url = Endpoints.UpdateUser();
    return this.apiClient.postObj(HydratedAdminUser, url, user).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateUser', err));
        return throwError(err);
      })
    );
  }

}
