import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-unassigned-asset-group-card',
  templateUrl: './unassigned-asset-group-card.component.html',
  styleUrls: ['./unassigned-asset-group-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnassignedAssetGroupCardComponent {

  @Input() card: Card;
  @Input() cardClass: string;
  @Output() cardPressed:  EventEmitter<Card> = new EventEmitter<Card>();

  handleCardPressed() {
    this.cardPressed.emit(this.card);
  }

}
