import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { SmartFilterCategory } from '../../../../models/automation/smart-filter-category';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ModalUtils } from '../../../../utils/modal-utils';
import { ConfirmationOptions } from '../../../../models/shared/stylesheet/confirmation-options';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Asset } from '../../../../models/image/dto/asset';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';
import { debounceTime, filter, map } from 'rxjs/operators';
import '../../../../utils/subscription.extensions';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';

@Injectable()
export class EditSmartFilterCategoryViewModel extends BaseViewModel {

  public existingSmartFilterCategory = new BehaviorSubject<SmartFilterCategory>(null);
  public createNewSmartFilterCategory = new SmartFilterCategory();
  public isEditing: boolean = false;
  public imgToUpload: BudsenseFile = null;
  public replaceImg: boolean = false;
  public displayImg: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  private asset = new BehaviorSubject<Asset>(null);
  public categoryImgAsset$ = this.asset.asObservable();
  private removeAsset: Asset = null;

  public smartFiltersForCategory$: Observable<HydratedSmartFilter[]> = combineLatest([
    this.existingSmartFilterCategory,
    this.smartFiltersDomainModel.curatedSmartFilters$
  ]).pipe(
    filter(sources => sources.every(s => !!s)),
    debounceTime(100),
    map(([existingCategory, smartFilters]) => smartFilters?.filter(sf => sf.categoryId === existingCategory?.id) ?? [])
  );

  constructor(
    protected activeModal: NgbActiveModal,
    private productDomainModel: ProductDomainModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private toastService: ToastService,
    private ngbModal: NgbModal,
  ) {
    super();
    this.existingSmartFilterCategory.notNull().subscribeWhileAlive({
      owner: this,
      next: (sfc) => {
        this.asset.next(sfc?.image);
        this.subToImg(sfc);
      }
    });
  }

  save() {
    const lm = 'Saving Parent Group';
    this._loadingOpts.addRequest(lm);
    const saveObject = this.isEditing ? this.existingSmartFilterCategory.getValue() : this.createNewSmartFilterCategory;
    this.smartFiltersDomainModel.saveSmartFilterCategory(
      saveObject,
      this.imgToUpload,
      this.removeAsset
    ).subscribe((savedCategory) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Parent Group Saved', 'Success!');
      this.activeModal.close(savedCategory);
    }, err => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishErrorMessage(err.message, 'Failed');
    });
  }

  showDeleteModal() {
    // Note - unable to use modalService.openGenericConfirmationModal in this scenario because
    // it causes a circular dependency
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Smart Filter Category';
    opts.bodyText = `Are you sure you want to delete this parent group? All curated smart filters that were in this ` +
      `group will be moved to the next available group.\n\nThis action cannot be undone.`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Continue';
    const modalRef = this.ngbModal.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) { this.deleteExisting(); }
    });
  }

  deleteExisting() {
    const lm = 'Deleting Parent Group';
    this._loadingOpts.addRequest(lm);
    this.smartFiltersDomainModel.deleteSmartFilterCategory(this.existingSmartFilterCategory.getValue())
      .subscribe((savedCategory) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Parent Group Deleted', 'Success!');
        this.activeModal.close(savedCategory);
      }, err => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishErrorMessage(err.message, 'Failed');
      });
  }

  removeImgClicked() {
    this.replaceImg = true;
    if (this.imgToUpload) {
      this.imgToUpload = undefined;
    } else {
      this.removeAsset = this.asset.getValue();
    }
  }

  private subToImg(smartFilterCategory: SmartFilterCategory) {
    const imgKey = 'smartFilterCategory_Image';
    this.destroyImageSub(imgKey);
    const imgUrl = smartFilterCategory?.image?.getAssetUrl(AssetSize.Medium)?.srcUrl;
    if (imgUrl) {
      const s = this.displayImg.bind(
        imgUrl.pipe(map((url) => (!url ? 'assets/placeholder/list-image-dark.jpg' : url)))
      );
      this.pushImageSub(imgKey, s);
    }
  }

}
