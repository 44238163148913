<app-loading *ngIf="loadingOpts?.isLoading" [options]="loadingOpts"> </app-loading>
<div [hidden]="isHidden">
  <div
    (fileDropped)="onFileDropped($event)"
    [hidden]="!allowTypes"
    [ngClass]="{ animating: animating }"
    appDnd
    class="bs-upload-area dark-animation">
    <form class="bs-upload-form">
      <img src="/assets/icons/dark/solid/manage.svg" class="bs-upload-icon" alt="manage" />
      <span class="bs-upload-text">
        <label class="bs-upload-label" for="fileDropRef{{ id }}">Upload a File</label> or Drag and Drop</span
      >
      <span class="bs-upload-text secondary-upload-text">
        {{ viewModel.acceptTypesDescription }} up to {{ viewModel.maxFileSize / 1048576 }}MB
      </span>
      <input
        type="file"
        #fileDropRef
        class="bs-file-input"
        id="fileDropRef{{ id }}"
        name="fileDropRefName{{ id }}"
        multiple
        [accept]="viewModel.accept"
        (change)="viewModel.fileBrowseHandler($event.target)" />
    </form>
  </div>
  <div [ngStyle]="{ padding: displayList ? '1.25rem 0' : '1rem 0' }" class="bs-upload-list-container">
    <ul *ngIf="viewModel.files.length > 0 && displayList" class="bs-upload-list">
      <app-upload-item *ngFor="let item of viewModel.files" [uploadItemInterface]="viewModel" [file]="item">
      </app-upload-item>
    </ul>
  </div>
</div>
