import { Pipe, PipeTransform } from '@angular/core';
import { InventoryProvider } from '../../../../../../../../utils/inventory-provider-type';
import { ProviderUtils } from '../../../../../../../../utils/provider-utils';

@Pipe({
  name: 'getSyncTypeNameForProvider'
})
export class GetSyncTypeNameForProviderPipe implements PipeTransform {

  transform(syncTypeName: string, provider: InventoryProvider): string {
    if (syncTypeName === 'Promotions') {
      return ProviderUtils.getPromotionsTabName(provider);
    } else {
      return syncTypeName;
    }
  }

}
