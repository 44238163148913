import { Injectable } from '@angular/core';
import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { SmartFilter } from '../models/automation/smart-filter';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { SmartFilterCategory } from '../models/automation/smart-filter-category';

@Injectable({
  providedIn: 'root'
})
export class AutomationAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  public serviceName = 'Automation';

  // Smart Filters

  public createSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    const url = Endpoints.createCuratedSmartFilter();
    return this.apiClient.postObj(HydratedSmartFilter, url, smartFilter).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public updateSmartFilter(smartFilter: SmartFilter): Observable<HydratedSmartFilter> {
    const url = Endpoints.updateCuratedSmartFilter();
    return this.apiClient.postObj(HydratedSmartFilter, url, smartFilter).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public deleteSmartFilter(smartFilter: SmartFilter): Observable<string> {
    const url = Endpoints.deleteCuratedSmartFilter();
    return this.apiClient.deleteStr(url, smartFilter).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteSmartFilter', err));
        return throwError(err);
      })
    );
  }

  public getCuratedSmartFilters(locationId?: string): Observable<HydratedSmartFilter[]> {
    const url = Endpoints.getCuratedSmartFilters(locationId);
    return this.apiClient.getArr<HydratedSmartFilter>(HydratedSmartFilter, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCuratedSmartFilters', err));
        return throwError(err);
      })
    );
  }

  public syncCompanySmartFilters(companyId: number, locationIds: string[]): Observable<any> {
    const url = Endpoints.syncCompanySmartFilters(locationIds?.join(','));
    return this.apiClient.getUntypedObj(url, { companyid: `${companyId}` }).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncCompanySmartFilters', err));
        return throwError(err);
      })
    );
  }

  public syncSmartDisplayAttributes(
    companyId: number,
    locationIds: string[],
    forceSync: boolean = true
  ): Observable<any> {
    const url = Endpoints.syncSmartDisplayAttributes(forceSync, locationIds?.join(','));
    return this.apiClient.getUntypedObj(url, { companyid: `${companyId}` }).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'syncSmartDisplayAttributes', err));
        return throwError(err);
      })
    );
  }

  public getSmartFilterCategories(): Observable<SmartFilterCategory[]> {
    const url = Endpoints.getSmartFilterCategories();
    return this.apiClient.getArr<SmartFilterCategory>(SmartFilterCategory, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getSmartFilterCategories', err));
        return throwError(err);
      })
    );
  }

  public createSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<SmartFilterCategory> {
    const url = Endpoints.createCuratedSmartFilterCategory();
    return this.apiClient.postObj(SmartFilterCategory, url, smartFilterCategory).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createSmartFilterCategory', err));
        return throwError(err);
      })
    );
  }

  public updateSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<SmartFilterCategory> {
    const url = Endpoints.updateCuratedSmartFilterCategory();
    const payload = window.injector.Deserialize.instanceOf(SmartFilterCategory, smartFilterCategory);
    return this.apiClient.postObj(SmartFilterCategory, url, payload).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSmartFilterCategory', err));
        return throwError(err);
      })
    );
  }

  public updateSmartFilterCategoryPriorities(
    smartFilterCategories: SmartFilterCategory[]
  ): Observable<SmartFilterCategory[]> {
    const url = Endpoints.updateSmartFilterCategoryPriorities();
    const payload = window.injector.Deserialize.arrayOf(SmartFilterCategory, smartFilterCategories);
    return this.apiClient.postArr(SmartFilterCategory, url, payload).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSFCategoryPriorities', err));
        return throwError(err);
      })
    );
  }

  public deleteSmartFilterCategory(smartFilterCategory: SmartFilterCategory): Observable<string> {
    const url = Endpoints.deleteCuratedSmartFilterCategory();
    const payload = window.injector.Deserialize.instanceOf(SmartFilterCategory, smartFilterCategory);
    return this.apiClient.deleteStr(url, payload).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteSmartFilterCategory', err));
        return throwError(err);
      })
    );
  }

}
