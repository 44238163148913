<div [ngClass]="{ selected: card.selected }" class="card bs-card bs-theme-preview-card {{ getCardClasses() }}">
  <div class="bs-theme-preview-card-wrapper">
    <!--  Theme Preview  -->
    <div
      [ngClass]="{ placeholder: !card.imageSrc }"
      [ngStyle]="{ 'background-image': card.getBackgroundUrl() }"
      class="bs-theme-preview-image"></div>
    <!--  Theme information  -->
    <div class="bs-card-label">
      <div class="bs-card-label-text">
        {{ card.text }}
      </div>
      <div class="bs-card-label-subtext">
        <div (click)="handleCardButtonClicked($event)" class="text-link">See Details</div>
      </div>
    </div>
    <div class="selected-icon">
      <img [src]="'assets/icons/green/solid/check-circle.svg'" alt="" />
    </div>
  </div>
</div>
