import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Viewable } from '../../../models/protocols/viewable';
import '../../../utils/observable.extensions';
import { BaseComponent } from '../../../models/base/base-component';
import { SidebarNavViewModel } from './sidebar-nav-view-model';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  providers: [SidebarNavViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavComponent extends BaseComponent implements OnInit, Viewable {

  constructor(
    public viewModel: SidebarNavViewModel,
  ) {
    super();
  }

}
