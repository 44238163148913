<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title-container">
        <div class="modal-title">Filter By Category</div>
        <div class="text-link" (click)="clearFiltersAndSearch()">Clear</div>
      </div>
    </div>
  </div>

  <div class="sticky-body-container categories-body">
    <div class="search-bar-container">
      <app-search-with-look-ahead
        #categorySearch
        [placeHolder]="'Search by category name'"
        [nLookAheadItems]="10"
        (searchText)="viewModel?.setSearchText($event)">
      </app-search-with-look-ahead>
    </div>
    <ng-container *ngFor="let category of $any(viewModel.categoriesFilteredBySearchString$ | async); last as last">
      <app-category-selection
        [selection]="category"
        [selectedIds]="viewModel?.selectedCategoryIds$ | async"
        [includeBottomBorder]="!last"
        (add)="viewModel?.addToSelectedCategories($event)"
        (remove)="viewModel?.removeFromSelectedCategories($event)"></app-category-selection>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral (buttonClicked)="cancel()" class="me-2"> Cancel </lib-button-neutral>
    <lib-button-primary (buttonClicked)="viewModel?.finishedFiltering()" class="ms-2"> Done </lib-button-primary>
  </div>
</div>
