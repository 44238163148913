import { Pipe, PipeTransform } from '@angular/core';
import { SyncTypeGrouping } from '../../../../../../../../utils/provider-utils';
import { Observable } from 'rxjs';
import { InventoryProvider } from '../../../../../../../../utils/inventory-provider-type';

@Pipe({
  name: 'getChildSyncTypeNames'
})
export class GetChildSyncTypeNamesPipe implements PipeTransform {

  transform(value: SyncTypeGrouping, provider: InventoryProvider): Observable<string[]> {
    return value?.getChildSyncTypeNames(provider);
  }

}
