import { SectionColumnConfig } from './section-column-config';
import { Deserializable } from '../../protocols/deserializable';

export const DEFAULT_NAME_COLUMN_WIDTH = 25;

export class ThemeSectionColumnConfig extends SectionColumnConfig implements Deserializable {

  public maxColumnWidth: number = 0;
  public minColumnWidth: number = 0;

  override getUniqueIdentifier(): string {
    return `${super.getUniqueIdentifier()}
      -${this.minColumnWidth}
      -${this.maxColumnWidth}`;
  }

}
