import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { ProvinceCode } from '../../../utils/province-type';
import { exists } from '../../../functions/exists';

export class DisplayAttributeGroupDetails implements Deserializable, UniquelyIdentifiable {

  private static invalidParsedCannabinoid: number = -1;

  // Group Details
  public displayAttributeGroupId: string;
  public id: string;
  public groupName: string;
  public variantBarcodes: string[];
  public effects: string[];
  public aromas: string[];
  public flavors: string[];
  public helpsWith: string[];
  public appearance: string[];
  public growingConditions: string[];
  public energyScore: string;
  public stateCode: ProvinceCode;
  public city: string;
  // Terpenes
  public minTotalTerpene: string;
  public maxTotalTerpene: string;
  public presentTerpenes: string[];
  public topTerpene: string;
  // Secondary Cannabinoids
  public secondaryCannabinoids: string[];
  // CBDA
  public minCBDA: string;
  public maxCBDA: string;
  // CBG
  public minCBG: string;
  public maxCBG: string;
  // CBGA
  public minCBGA: string;
  public maxCBGA: string;
  // CBL
  public minCBL: string;
  public maxCBL: string;
  // CBLA
  public minCBLA: string;
  public maxCBLA: string;
  // CBN
  public minCBN: string;
  public maxCBN: string;
  // CBNA
  public minCBNA: string;
  public maxCBNA: string;
  // CBT
  public minCBT: string;
  public maxCBT: string;
  // CBC
  public minCBC: string;
  public maxCBC: string;
  // CBCA
  public minCBCA: string;
  public maxCBCA: string;
  // CBCV
  public minCBCV: string;
  public maxCBCV: string;
  // CBDV
  public minCBDV: string;
  public maxCBDV: string;
  // THC8
  public minTHC8: string;
  public maxTHC8: string;
  // THC9
  public minTHC9: string;
  public maxTHC9: string;
  // THCA
  public minTHCA: string;
  public maxTHCA: string;
  // THCV
  public minTHCV: string;
  public maxTHCV: string;
  // TAC
  public minTAC: string;
  public maxTAC: string;

  isDefaultSharedGroupForDisplayAttribute(): boolean {
    return this.displayAttributeGroupId === this.id;
  }

  onDeserialize() {
    this.variantBarcodes = Array.from(this.variantBarcodes || []);
    this.effects = Array.from(this.effects || []);
    this.aromas = Array.from(this.aromas || []);
    this.flavors = Array.from(this.flavors || []);
    this.helpsWith = Array.from(this.helpsWith || []);
    this.appearance = Array.from(this.appearance || []);
    this.growingConditions = Array.from(this.growingConditions || []);
    this.presentTerpenes = Array.from(this.presentTerpenes || []);
    this.secondaryCannabinoids = Array.from(this.secondaryCannabinoids || []);
  }

  static getEmptyGroupDetails(): DisplayAttributeGroupDetails {
    const emptyDetails = new DisplayAttributeGroupDetails();
    emptyDetails.effects = [];
    emptyDetails.aromas = [];
    emptyDetails.flavors = [];
    emptyDetails.helpsWith = [];
    emptyDetails.appearance = [];
    emptyDetails.growingConditions = [];
    emptyDetails.energyScore = '';
    emptyDetails.minTotalTerpene = '0';
    emptyDetails.maxTotalTerpene = '0';
    emptyDetails.presentTerpenes = [];
    emptyDetails.topTerpene = '';
    emptyDetails.secondaryCannabinoids = [];
    emptyDetails.minCBDA = '0';
    emptyDetails.maxCBDA = '0';
    emptyDetails.minCBG = '0';
    emptyDetails.maxCBG = '0';
    emptyDetails.minCBGA = '0';
    emptyDetails.maxCBGA = '0';
    emptyDetails.minCBL = '0';
    emptyDetails.maxCBL = '0';
    emptyDetails.minCBLA = '0';
    emptyDetails.maxCBLA = '0';
    emptyDetails.minCBN = '0';
    emptyDetails.maxCBN = '0';
    emptyDetails.minCBNA = '0';
    emptyDetails.maxCBNA = '0';
    emptyDetails.minCBT = '0';
    emptyDetails.maxCBT = '0';
    emptyDetails.minCBC = '0';
    emptyDetails.maxCBC = '0';
    emptyDetails.minCBCA = '0';
    emptyDetails.maxCBCA = '0';
    emptyDetails.minCBCV = '0';
    emptyDetails.maxCBCV = '0';
    emptyDetails.minCBDV = '0';
    emptyDetails.maxCBDV = '0';
    emptyDetails.minTHC8 = '0';
    emptyDetails.maxTHC8 = '0';
    emptyDetails.minTHC9 = '0';
    emptyDetails.maxTHC9 = '0';
    emptyDetails.minTHCA = '0';
    emptyDetails.maxTHCA = '0';
    emptyDetails.minTHCV = '0';
    emptyDetails.maxTHCV = '0';
    emptyDetails.minTAC = '0';
    emptyDetails.maxTAC = '0';
    return emptyDetails;
  }

  private static getNumericValue = (v: string): number => {
    if (exists(v)) {
      if (v.includes('-')) {
        v = v.split('-')[0];
      }
      return Number(v.replace(/[^0-9.]+/g, ''));
    } else {
      return DisplayAttributeGroupDetails.invalidParsedCannabinoid;
    }
  };

  getUniqueIdentifier(): string {
    const variantBarcodesId = this.variantBarcodes?.join(',') || '';
    const effectsId = this.effects?.join(',') || '';
    const aromasId = this.aromas?.join(',') || '';
    const flavorsId = this.flavors?.join(',') || '';
    const helpsWithId = this.helpsWith?.join(',') || '';
    const appearanceId = this.appearance?.join(',') || '';
    const growingConditionsId = this.growingConditions?.join(',') || '';
    const energyScoreId = this.energyScore;
    const presentTerpenesId = this.presentTerpenes?.join(',') || '';
    const secondaryCannabinoidsId = this.secondaryCannabinoids?.join(',') || '';
    return `${this.displayAttributeGroupId}
      -${this.id}
      -${this.groupName}
      -${variantBarcodesId}
      -${effectsId}
      -${aromasId}
      -${flavorsId}
      -${helpsWithId}
      -${appearanceId}
      -${growingConditionsId}
      -${energyScoreId}
      -${this.stateCode}
      -${this.city}
      -${this.minTotalTerpene}
      -${this.maxTotalTerpene}
      -${presentTerpenesId}
      -${this.topTerpene}
      -${secondaryCannabinoidsId}
      -${this.minCBDA}
      -${this.maxCBDA}
      -${this.minCBG}
      -${this.maxCBG}
      -${this.minCBGA}
      -${this.maxCBGA}
      -${this.minCBL}
      -${this.maxCBL}
      -${this.minCBLA}
      -${this.maxCBLA}
      -${this.minCBN}
      -${this.maxCBN}
      -${this.minCBNA}
      -${this.maxCBNA}
      -${this.minCBT}
      -${this.maxCBT}
      -${this.minCBC}
      -${this.maxCBC}
      -${this.minCBCA}
      -${this.maxCBCA}
      -${this.minCBCV}
      -${this.maxCBCV}
      -${this.minCBDV}
      -${this.maxCBDV}
      -${this.minTHC8}
      -${this.maxTHC8}
      -${this.minTHC9}
      -${this.maxTHC9}
      -${this.minTHCA}
      -${this.maxTHCA}
      -${this.minTHCV}
      -${this.maxTHCV}
      -${this.minTAC}
      -${this.maxTAC}
    `;
  }

  hasAdditionalProperties(): boolean {
    return this.hasEffects()
      || this.hasAromas()
      || this.hasFlavors()
      || this.hasHelpsWith()
      || this.hasAppearance()
      || this.hasGrowingConditions()
      || this.hasEnergyScore()
      || this.hasMinimumTotalTerpene()
      || this.hasMaximumTotalTerpene()
      || this.hasPresentTerpenes()
      || this.hasTopTerpene()
      || this.hasSecondaryCannabinoids()
      || this.hasMinCbda()
      || this.hasMaxCbda()
      || this.hasMinCbg()
      || this.hasMaxCbg()
      || this.hasMinCbga()
      || this.hasMaxCbga()
      || this.hasMinCbl()
      || this.hasMaxCbl()
      || this.hasMinCbla()
      || this.hasMaxCbla()
      || this.hasMinCbn()
      || this.hasMaxCbn()
      || this.hasMinCbna()
      || this.hasMaxCbna()
      || this.hasMinCbt()
      || this.hasMaxCbt()
      || this.hasMinCbc()
      || this.hasMaxCbc()
      || this.hasMinCbca()
      || this.hasMaxCbca()
      || this.hasMinCbcv()
      || this.hasMaxCbcv()
      || this.hasMinThc8()
      || this.hasMaxThc8()
      || this.hasMinThc9()
      || this.hasMaxThc9()
      || this.hasMinThca()
      || this.hasMaxThca()
      || this.hasMinThcv()
      || this.hasMaxThcv()
      || this.hasMinTac()
      || this.hasMaxTac();
  }

  hasEffects(): boolean {
    return this.effects?.length > 0;
  }

  hasAromas(): boolean {
    return this.aromas?.length > 0;
  }

  hasFlavors(): boolean {
    return this.flavors?.length > 0;
  }

  hasHelpsWith(): boolean {
    return this.helpsWith?.length > 0;
  }

  hasAppearance(): boolean {
    return this.appearance?.length > 0;
  }

  hasGrowingConditions(): boolean {
    return this.growingConditions?.length > 0;
  }

  hasEnergyScore(): boolean {
    return exists(this.energyScore);
  }

  hasMinimumTotalTerpene(): boolean {
    return exists(this.minTotalTerpene);
  }

  hasMaximumTotalTerpene(): boolean {
    return exists(this.maxTotalTerpene);
  }

  hasPresentTerpenes(): boolean {
    return this.presentTerpenes?.length > 0;
  }

  hasTopTerpene(): boolean {
    return exists(this.topTerpene);
  }

  hasSecondaryCannabinoids(): boolean {
    return this.secondaryCannabinoids?.length > 0;
  }

  hasMinCbda(): boolean {
    return exists(this.minCBDA);
  }

  getNumericValueOfCannabinoidOrTerpene<T extends keyof DisplayAttributeGroupDetails>(cannabinoid: T): number {
    const cannabinoidProperty = this[cannabinoid];
    const cannabinoidString = typeof cannabinoidProperty === 'string' ? cannabinoidProperty : null;
    return DisplayAttributeGroupDetails?.getNumericValue(cannabinoidString);
  }

  hasMaxCbda(): boolean {
    return exists(this.maxCBDA);
  }

  hasMinCbg(): boolean {
    return exists(this.minCBG);
  }

  hasMaxCbg(): boolean {
    return exists(this.maxCBG);
  }

  hasMinCbga(): boolean {
    return exists(this.minCBGA);
  }

  hasMaxCbga(): boolean {
    return exists(this.maxCBGA);
  }

  hasMinCbl(): boolean {
    return exists(this.minCBL);
  }

  hasMaxCbl(): boolean {
    return exists(this.maxCBL);
  }

  hasMinCbla(): boolean {
    return exists(this.minCBLA);
  }

  hasMaxCbla(): boolean {
    return exists(this.maxCBLA);
  }

  hasMinCbn(): boolean {
    return exists(this.minCBN);
  }

  hasMaxCbn(): boolean {
    return exists(this.maxCBN);
  }

  hasMinCbna(): boolean {
    return exists(this.minCBNA);
  }

  hasMaxCbna(): boolean {
    return exists(this.maxCBNA);
  }

  hasMinCbt(): boolean {
    return exists(this.minCBT);
  }

  hasMaxCbt(): boolean {
    return exists(this.maxCBT);
  }

  hasMinCbc(): boolean {
    return exists(this.minCBC);
  }

  hasMaxCbc(): boolean {
    return exists(this.maxCBC);
  }

  hasMinCbca(): boolean {
    return exists(this.minCBCA);
  }

  hasMaxCbca(): boolean {
    return exists(this.maxCBCA);
  }

  hasMinCbcv(): boolean {
    return exists(this.minCBCV);
  }

  hasMaxCbcv(): boolean {
    return exists(this.maxCBCV);
  }

  hasMinThc8(): boolean {
    return exists(this.minTHC8);
  }

  hasMaxThc8(): boolean {
    return exists(this.maxTHC8);
  }

  hasMinThc9(): boolean {
    return exists(this.minTHC9);
  }

  hasMaxThc9(): boolean {
    return exists(this.maxTHC9);
  }

  hasMinThca(): boolean {
    return exists(this.minTHCA);
  }

  hasMaxThca(): boolean {
    return exists(this.maxTHCA);
  }

  hasMinThcv(): boolean {
    return exists(this.minTHCV);
  }

  hasMaxThcv(): boolean {
    return exists(this.maxTHCV);
  }

  hasMinTac(): boolean {
    return exists(this.minTAC);
  }

  hasMaxTac(): boolean {
    return exists(this.maxTAC);
  }

}
