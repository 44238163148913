import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { BehaviorSubject, combineLatest, defer } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectableSmartFilter } from '../../../../models/automation/protocols/selectable-smart-filter';

@Component({
  selector: 'app-smart-filter-selection',
  templateUrl: './smart-filter-selection.component.html',
  styleUrls: ['./smart-filter-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterSelectionComponent extends BaseComponent implements OnChanges {

  @Input() selection: SelectableSmartFilter;
  @Input() previouslyAddedIds: string[] = [];
  @Input() selectedIds: string[] = [];
  @Input() isChild: boolean = false;
  @Input() includeBottomBorder: boolean = true;
  @Output() add = new EventEmitter<string>(true);
  @Output() remove = new EventEmitter<string>(true);

  protected _selection = new BehaviorSubject<SelectableSmartFilter>(null);
  public selection$ = defer(() => this._selection);
  public name$ = this.selection$.pipe(map(selection => selection?.getSelectionName()));
  public variantHitCount$ = this.selection$.pipe(map(filter => filter?.getVariantHits()));
  public variantHitsString$ = this.variantHitCount$.pipe(map(hits => `${hits} Variant${hits === 0 ? '' : 's'}`));
  public id$ = this.selection$.pipe(map(selection => selection?.getId()));
  protected _previouslyAddedIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedIds$ = defer(() => this._previouslyAddedIds);
  public previouslyAdded$ = combineLatest([this.id$, this.previouslyAddedIds$]).pipe(
    map(([id, prevAdded]) => prevAdded?.contains(id))
  );
  protected _selectedIds = new BehaviorSubject<string[]>([]);
  public selectedIds$ = defer(() => this._selectedIds);
  public selectedPipe$ = combineLatest([this.id$, this.selectedIds$, this.previouslyAdded$]);
  public selected$ = this.selectedPipe$.pipe(
    map(([id, ids, prevAdded]) => prevAdded || ids?.contains(id))
  );
  protected _indeterminate = new BehaviorSubject<boolean>(false);
  public indeterminate$ = defer(() => this._indeterminate);

  constructor() {
    super();
  }

  override setupViews() {
    this._selection.next(this.selection);
    this._previouslyAddedIds.next(this.previouslyAddedIds);
    this._selectedIds.next(this.selectedIds);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection) { this._selection.next(this.selection); }
    if (changes.previouslyAddedIds) { this._previouslyAddedIds.next(this.previouslyAddedIds); }
    if (changes.selectedIds) { this._selectedIds.next(this.selectedIds); }
  }

  public clicked(selected: boolean) {
    selected ? this.add.emit(this.selection?.getId()) : this.remove.emit(this.selection?.getId());
  }

}
