import { Component } from '@angular/core';
import { CreateSectionBlueprintModalViewModel } from './create-section-blueprint-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';

@Component({
  selector: 'app-create-section-blueprint-modal',
  templateUrl: './create-section-blueprint-modal.component.html',
  styleUrls: ['./create-section-blueprint-modal.component.scss'],
  providers: [CreateSectionBlueprintModalViewModel]
})
export class CreateSectionBlueprintModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: CreateSectionBlueprintModalViewModel,
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
