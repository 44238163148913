import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../../../../models/base/base-modal-view-model';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { SyncDomainModel } from '../../../../../../../../domainModels/sync-domain-model';
import { ToastService } from '../../../../../../../../services/toast-service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { InventoryProvider } from '../../../../../../../../utils/inventory-provider-type';
import { map } from 'rxjs/operators';
import { CompanyConfiguration } from '../../../../../../../../models/company/dto/company-configuration';
import { ProviderUtils, SyncTypeGrouping } from '../../../../../../../../utils/provider-utils';
import { SyncType } from '../../../../../../../../utils/sync-type-type';
import { formatDate } from '@angular/common';
import { SyncDataJob } from '../../../../../../../../models/automation/sync-data-job';
import { BsError } from '../../../../../../../../models/shared/bs-error';
import { Location } from '../../../../../../../../models/company/dto/location';
import { HasChildIds } from '../../../../../../../../models/protocols/has-child-ids';
import { SyncJobStep } from './sync-job-step';
import { exists } from '../../../../../../../../functions/exists';

@Injectable()
export class NewSyncJobViewModel extends BaseModalViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private syncDomainModel: SyncDomainModel,
    public toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
    this.activeCompanyLocations$.once(locs => this._selectedLocationIds.next(locs?.map(loc => loc?.id) || []));
  }

  companyId$ = this.companyDomainModel.selectedCompanyId$;
  company$ = this.companyDomainModel.selectedCompany$;
  companyConfig$ = this.company$.pipe(map(company => company?.configuration));
  inventoryProvider$ = this.company$.pipe(map(company => company?.provider));
  currentDate$ = timer(0, 5000).pipe(map(() => this.getFormattedTimeString()));
  activeCompanyLocations$ = this.company$.pipe(
    map(company => company?.locations?.filter(c => !c.archived) ?? [])
  );
  supportsLocationSpecificPOSSyncing$ = this.inventoryProvider$.pipe(
    map(provider => ProviderUtils.supportsLocationSpecificPOSSyncing(provider))
  );
  supportsSyncSinceLastUpdated$ = this.inventoryProvider$.pipe(
    map(provider => ProviderUtils.supportsSyncSinceLastUpdated(provider))
  );

  private _locationsSpecificPOSSyncingStep = new BehaviorSubject(SyncJobStep.SelectSyncTypes);
  public locationsSpecificPOSSyncingStep$ = this._locationsSpecificPOSSyncingStep as Observable<SyncJobStep>;
  connectToLocationsSpecificPOSSyncingStep = (step: SyncJobStep) => this._locationsSpecificPOSSyncingStep.next(step);

  public readonly showClearSelectionsButton$ = this.locationsSpecificPOSSyncingStep$.pipe(
    map(step => step === SyncJobStep.SelectLocations)
  );

  public readonly modalTitle$ = this.locationsSpecificPOSSyncingStep$.pipe(
    map(step => {
      switch (true) {
        case step === SyncJobStep.SelectLocations:
          return 'Select Sync Locations';
        default:
          return 'New Sync Job';
      }
    })
  );

  public readonly modalSubtitle$ = this.supportsLocationSpecificPOSSyncing$.pipe(
    map(supportsLocationSpecificPOSSyncing => {
      switch (true) {
        case !supportsLocationSpecificPOSSyncing:
          return 'All Locations';
        default:
          return null;
      }
    })
  );

  private readonly _name = new BehaviorSubject<string>(null);
  public readonly name$ = this._name as Observable<string>;
  connectToName = (name: string) => this._name.next(name);

  private getSyncJobList = (
    [companyConfig, inventoryProvider]: [CompanyConfiguration, InventoryProvider]
  ): SyncTypeGrouping[] => {
    return ProviderUtils.getManualSyncTypeGroupings(companyConfig, inventoryProvider);
  };
  public manualSyncTypeList$ = combineLatest([
    this.companyConfig$,
    this.inventoryProvider$,
  ]).pipe(
    map((data) => this.getSyncJobList(data))
  );

  private _selectedSyncTypeIds = new BehaviorSubject<string[]>([]);
  public selectedSyncTypeIds$ = this._selectedSyncTypeIds as Observable<string[]>;
  public selectedSyncTypes$ = this.selectedSyncTypeIds$.pipe(
    map(ids => ids?.map(id => SyncTypeGrouping.buildFromId(id)) ?? []),
    map(syncGroupings => syncGroupings.flatMap(grouping => grouping?.childSyncTypes?.flatten<SyncType[]>() ?? [])),
    map(syncTypes => syncTypes?.filter(syncType => syncType !== SyncType.Promotions))
  );

  public productAuditSyncTypeSelected$ = this.selectedSyncTypes$.pipe(
    map(syncTypes => syncTypes?.includes(SyncType.ProductAudit))
  );

  public syncAllTimeInputDisabled$ = this.selectedSyncTypes$.pipe(
    map(syncTypeIds => syncTypeIds?.includes(SyncType.ProductAudit))
  );

  private _searchString = new BehaviorSubject<string>(null);
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _searchedLocations = new BehaviorSubject<Location[]>(null);
  public searchedLocations$ = this._searchedLocations as Observable<Location[]>;
  connectToSearchedLocations = (locations: Location[]) => this._searchedLocations.next(locations);

  public readonly searchedLocationsGroupedByProvince$ = this.searchedLocations$.pipe(
    map(locations => Location.groupLocationsByProvince([locations?.map(loc => loc?.id), locations]))
  );

  public readonly locationsVisibleOnScreen$ = this.searchedLocations$.pipe(
    map(locations => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => locations?.map(location => location?.getId()) ?? [];

      }();
    })
  );

  private _selectedLocationIds = new BehaviorSubject<number[]>([]);
  public selectedLocationIds$ = this._selectedLocationIds as Observable<number[]>;
  public selectedLocationIdsAsStringList$ = this.selectedLocationIds$.pipe(
    map(ids => ids?.map(id => id.toString()) ?? [])
  );
  connectToSelectedLocationIds = (selectedLocationIds: number[]) => {
    this._selectedLocationIds.next(selectedLocationIds);
  };
  public nSelectedLocationIds$ = this.selectedLocationIds$.pipe(map(ids => ids?.length ?? 0));

  public _forceSyncAllTime = new BehaviorSubject<boolean>(false);
  public forceSyncAllTime$ = this._forceSyncAllTime as Observable<boolean>;
  connectToForceSyncAllTime = (forceSyncAllTime: boolean) => {
    this._forceSyncAllTime.next(forceSyncAllTime);
  };

  public resetSyncAllTimeFlagMechanism = combineLatest([
    this.productAuditSyncTypeSelected$,
    this.forceSyncAllTime$,
  ]).subscribeWhileAlive({
    owner: this,
    next: ([productAuditSelected, forceSyncAllTime]) => {
      if (productAuditSelected && forceSyncAllTime) {
        this._forceSyncAllTime.next(false);
      }
    }
  });

  private addToLocationIds = (id: number) =>  this.selectedLocationIds$.once(ids => {
    this._selectedLocationIds.next(ids?.concat([id])?.unique());
  });

  public addLocationIds(ids: string[]): void {
    ids?.forEach(id => this.addToLocationIds(Number.parseInt(id, 10)));
  }

  private removeFromLocationIds = (id: number) => this.selectedLocationIds$.once(ids => {
    this._selectedLocationIds.next(ids?.filter(currId => currId !== id));
  });

  public removeLocationIds(ids: string[]): void {
    ids?.forEach(id => this.removeFromLocationIds(Number.parseInt(id, 10)));
  }

  neutralButtonText$ = this.locationsSpecificPOSSyncingStep$.pipe(
    map(step => {
      switch (step) {
        case SyncJobStep.SelectSyncTypes:
          return 'Cancel';
        default:
          return 'Back';
      }
    })
  );

  primaryButtonText$ = combineLatest([
    this.locationsSpecificPOSSyncingStep$,
    this.supportsLocationSpecificPOSSyncing$,
    this.productAuditSyncTypeSelected$
  ]).pipe(
    map(([step, supportsLocationSpecificPOSSyncing, isProductAuditSync]) => {
      switch (true) {
        case !supportsLocationSpecificPOSSyncing:
          return 'Sync';
        case step === SyncJobStep.SelectSyncTypes && !isProductAuditSync:
          return 'Select Locations';
        case step === SyncJobStep.SelectLocations || isProductAuditSync:
          return 'Start Sync Job';
        default:
          return null;
      }
    })
  );

  primaryButtonDisabled$ = combineLatest([
    this.isLoading$,
    this.selectedSyncTypeIds$,
    this.locationsSpecificPOSSyncingStep$,
    this.supportsLocationSpecificPOSSyncing$,
    this.selectedLocationIds$,
    this.forceSyncAllTime$,
  ]).pipe(
    map(([isLoading, selectedSyncTypeIds, step, supportsLocSpecificPOSSyncing, selectedLocIds, forceSyncAllTime]) => {
      return isLoading
          || (!supportsLocSpecificPOSSyncing && !selectedSyncTypeIds?.length)
          || (supportsLocSpecificPOSSyncing && step === SyncJobStep.SelectSyncTypes && !selectedSyncTypeIds?.length)
          || (supportsLocSpecificPOSSyncing && step === SyncJobStep.SelectLocations && !selectedLocIds?.length)
          || (step === SyncJobStep.SelectLocations && selectedLocIds?.length > (forceSyncAllTime ? 1 : 9999));
    })
  );

  primaryButtonDisabledTooltip$ = combineLatest([
    this.primaryButtonDisabled$,
    this.selectedSyncTypeIds$,
    this.locationsSpecificPOSSyncingStep$,
    this.supportsLocationSpecificPOSSyncing$,
    this.selectedLocationIds$,
    this.forceSyncAllTime$,
  ]).pipe(
    map(([disabled, selectedSyncTypeIds, step, supportsLocSpecificPOSSyncing, selectedLocIds, forceSyncAllTime]) => {
      if (disabled) {
        switch (true) {
          case (!supportsLocSpecificPOSSyncing && !selectedSyncTypeIds?.length):
          case (supportsLocSpecificPOSSyncing && step === SyncJobStep.SelectSyncTypes && !selectedSyncTypeIds?.length):
            return 'Please select at least one sync type';
          case (supportsLocSpecificPOSSyncing && step === SyncJobStep.SelectLocations && !selectedLocIds?.length):
            return 'Please select at least one location';
          case (step === SyncJobStep.SelectLocations && forceSyncAllTime && selectedLocIds?.length > 1):
            return `When syncing all time, you can only select one location`;
        }
      }
      return null;
    })
  );

  addSyncTypeIdToJob = (id: string) => {
    this._selectedSyncTypeIds.once(ids => {
      let updatedIds = ids?.shallowCopy() ?? [];
      // Validate that Product Audit is not selected with another sync type
      const updatedIdsContainsProductAudit = updatedIds
        ?.map(syncTypeId => SyncTypeGrouping.buildFromId(syncTypeId))
        ?.some(grouping => grouping.parentSyncType === SyncType.ProductAudit);
      const addedIdIsProductAudit = SyncTypeGrouping.buildFromId(id)?.parentSyncType === SyncType.ProductAudit;
      if (updatedIdsContainsProductAudit && !addedIdIsProductAudit) {
        // If Product Audit is selected and a new sync type is selected, remove Product Audit
        updatedIds.splice(updatedIds.indexOf(SyncType.ProductAudit), 1);
      } else if (addedIdIsProductAudit) {
        // If Product Audit is being selected, remove any other sync types
        updatedIds = [];
      }
      updatedIds.push(id);
      this._selectedSyncTypeIds.next(updatedIds);
    });
  };

  removeSyncTypeIdFromJob = (id: string) => {
    this._selectedSyncTypeIds.once(ids => {
      const updatedIds = ids?.shallowCopy() ?? [];
      const i = updatedIds.indexOf(id);
      if (i > -1) {
        updatedIds.splice(i, 1);
        this._selectedSyncTypeIds.next(updatedIds);
      }
    });
  };

  syncDataJob$ = combineLatest([
    this.companyId$,
    this.selectedSyncTypes$,
    this.selectedLocationIds$,
    this.forceSyncAllTime$,
  ]).pipe(
    map(([companyId, syncTypes, locationIds, forceAllTime]) => {
      return new SyncDataJob(companyId, syncTypes, locationIds, forceAllTime);
    })
  );

  getFormattedTimeString(): string {
    return formatDate(new Date(), 'MMM d, y h:mm a', 'en');
  }

  neutralButtonAction(): void {
    this.locationsSpecificPOSSyncingStep$.once(step => {
      switch (step) {
        case SyncJobStep.SelectLocations:
          this.connectToLocationsSpecificPOSSyncingStep(0);
          break;
        default:
          this.closeModal();
      }
    });
  }

  primaryButtonAction(): void {
    combineLatest([
      this.locationsSpecificPOSSyncingStep$,
      this.supportsLocationSpecificPOSSyncing$,
      this.productAuditSyncTypeSelected$
    ]).once(([step, supportsLocationSpecificPOSSyncing, isProductAuditSync]) => {
      switch (true) {
        case supportsLocationSpecificPOSSyncing && step === SyncJobStep.SelectSyncTypes && !isProductAuditSync:
          this.connectToLocationsSpecificPOSSyncingStep(1);
          break;
        default:
          this.sync();
      }
    });
  }

  sync() {
    combineLatest([
      this.name$,
      this.syncDataJob$
    ]).once(([name, job]) => {
      job.name = exists(name) ? name : this.getFormattedTimeString();
      const loadingMsg = 'Creating Sync Job';
      this._loadingOpts.addRequest(loadingMsg);
      this.syncDomainModel.createSyncJob(job).subscribe({
        next: () => {
          this.toastService.publishSuccessMessage('Sync job created successfully', 'Success');
          this._loadingOpts.removeRequest(loadingMsg);
          this.closeModal();
        },
        error: (err: BsError) => {
          this.toastService.publishErrorMessage(err.message, 'Create Sync Job Failed');
          this._loadingOpts.removeRequest(loadingMsg);
          this.closeModal();
        }
      });
    });
  }

  trackByProvince(index: number, item: { key: string; value: Location[] }) {
    return item?.key;
  }

}

