import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';
import { ProductAddEditSmartFilterFormViewModel } from './product-add-edit-smart-filter-form-view-model';
import { ProductType } from '../../../../../utils/product-type-definition';
import { window } from 'ngx-bootstrap/utils';
import { BehaviorSubject } from 'rxjs';
import { VariantTypeDefinition } from '../../../../../utils/variant-type-definition';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-product-add-edit-smart-filter-form',
  templateUrl: './product-add-edit-smart-filter-form.component.html',
  styleUrls: ['./product-add-edit-smart-filter-form.component.scss'],
  providers: [ProductAddEditSmartFilterFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  constructor(
    public viewModel: ProductAddEditSmartFilterFormViewModel
  ) {
    super();
  }

  @ViewChild('variantType') variantTypeInput: ReactiveFormDropDownComponent;

  public dropdownProductTypes$ = window.types.productTypes$;
  public dropdownVariantTypes = new BehaviorSubject<VariantTypeDefinition[]>([]);
  public dropdownClassificationTypes$ = window?.types?.strainTypes$;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) {
      this.listenToProductType(this.bindTo.productType);
      this.viewModel.connectToSmartFilter(this.bindTo);
      this.viewModel.connectToSelectedProductType(this.bindTo.productType);
    }
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
  }

  listenToProductType(productType: ProductType) {
    this.viewModel.connectToSelectedProductType(productType);
    if (!productType) {
      this.dropdownVariantTypes.next(null);
      this.variantTypeInput?.clear();
    } else {
      window?.types?.getVariantTypesForProductType(productType).once(vts => this.dropdownVariantTypes.next(vts));
      this.variantTypeInput?.clear();
    }
  }

}
