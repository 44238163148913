<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <div class="terpene-title">
      <span class="text"> Primary Terpene Info </span>
    </div>
    <lib-reactive-form-column-layout nColumns="2">
      <lib-reactive-form-number
        [inputName]="'minTotalTerpene'"
        [label]="'Minimum Total Terpene'"
        [placeholder]="'Minimum total terpene'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="
          'The smart filter will include products with total terpene values greater than or equal to this value.'
        "
        [bindingProperty]="'minTotalTerpene'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'maxTotalTerpene'"
        [label]="'Maximum Total Terpene'"
        [placeholder]="'Maximum total terpene'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="'The smart filter will include products with total terpene values less than or equal to this value.'"
        [bindingProperty]="'maxTotalTerpene'">
      </lib-reactive-form-number>
    </lib-reactive-form-column-layout>

    <lib-reactive-form-drop-down
      [inputName]="'topTerpene'"
      [label]="'Top Terpene'"
      [placeholder]="'Select a top terpene'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [tooltip]="'The smart filter will include products with a top terpene set here.'"
      [dropdowns]="viewModel.terpeneDropdowns$ | push"
      [clearable]="true"
      [bindingProperty]="'topTerpene'"></lib-reactive-form-drop-down>

    <lib-reactive-form-pill-drop-down
      [inputName]="'presentTerpenes'"
      [label]="'Present Terpenes'"
      [emptyPlaceholder]="'Enter present terpenes'"
      [placeholder]="'Enter present terpenes'"
      [dropdowns]="viewModel.terpeneDropdowns$ | push"
      [bindingProperty]="'presentTerpenes'"></lib-reactive-form-pill-drop-down>

    <div class="terpene-title">
      <span class="text"> Individual Terpenes </span>
    </div>
    <lib-reactive-form-column-layout nColumns="2">
      <ng-container *rxFor="let terpene of viewModel.terpenes$ | push">
        <lib-reactive-form-number
          [inputName]="'min' | randomString"
          [label]="'Minimum ' + terpene.text"
          [placeholder]="'Minimum ' + terpene.text + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' +
            terpene.text +
            ' values greater than or equal to this value.'
          "
          [bindingProperty]="'min' + terpene.key">
        </lib-reactive-form-number>

        <lib-reactive-form-number
          [inputName]="'max' | randomString"
          [label]="'Maximum ' + terpene.text"
          [placeholder]="'Maximum ' + terpene.text + ' content of products'"
          [inlineLabel]="false"
          [required]="false"
          [disabled]="viewModel.isViewOnly$ | push"
          [integersOnly]="false"
          [step]="0.01"
          [tooltip]="
            'The smart filter will include products with ' + terpene.text + ' values less than or equal to this value.'
          "
          [bindingProperty]="'max' + terpene.key">
        </lib-reactive-form-number>
      </ng-container>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</div>
