import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent extends BaseComponent {

  @Input() title: string;
  @Input() padding: string = '1.5rem 0 1.5rem 0';

  constructor() {
    super();
  }

}
