import { HydratedVariantBadge } from './hydrated-variant-badge';
import { Deserializable } from '../../protocols/deserializable';

export class CuratedVariantBadgeSection implements Deserializable {

  public title: string;
  public badges: HydratedVariantBadge[];
  public subSectionBadges: Map<string, HydratedVariantBadge[]> = new Map<string, HydratedVariantBadge[]>();

  onDeserialize() {
    this.badges = window.injector.Deserialize.arrayOf(HydratedVariantBadge, this.badges);
    this.subSectionBadges = window.injector.Deserialize.typedArrayMapOf(HydratedVariantBadge, this.subSectionBadges);
  }

}
