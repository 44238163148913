import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SearchWithLookAheadViewModel } from './search-with-look-ahead-view-model';
import { takeUntil } from 'rxjs/operators';
import { LookAheadItem } from './look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { NgxPopperjsContentComponent } from 'ngx-popperjs';

@Component({
  selector: 'app-search-with-look-ahead',
  templateUrl: './search-with-look-ahead.component.html',
  styleUrls: ['./search-with-look-ahead.component.scss'],
  providers: [SearchWithLookAheadViewModel]
})
export class SearchWithLookAheadComponent extends BaseComponent implements OnChanges {

  // disablePopper: will continue to find search items and spit them out via lookAheadHits,
  // but will not show look ahead popper
  @Input() disablePopper: boolean = false;
  @Input() placeHolder: string = 'Search';
  @Input() nLookAheadItems: number = 5;
  @Input() lookAheadItems: LookAheadItem[];
  @Input() outputAllItemsWhenSearchIsEmpty: boolean = false;
  @Input() searchTheseProperties: string[];
  @Input() popperHideOnClickOutside = true;
  @Input() popperHideOnMouseLeave = true;
  @Input() popperHideOnScroll = true;
  @Output() searchText = new EventEmitter(true);
  @Output() lookAheadHits = new EventEmitter<any[]>(true);
  @Output() lookAheadSelected = new EventEmitter<any>(true);
  @ViewChild('popperContent') popperContent: NgxPopperjsContentComponent;
  @ViewChild('clearButton') clearButton: ElementRef;

  constructor(
    public viewModel: SearchWithLookAheadViewModel,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToNLookAheadItems(this.nLookAheadItems);
    this.viewModel.connectToSearchItems(this.lookAheadItems);
    this.viewModel.connectToSearchProperties(this.searchTheseProperties);
  }

  override setupBindings() {
    this.searchText.pipe(takeUntil(this.onDestroy)).subscribe(text => this.viewModel.connectToSearchText(text));
    this.viewModel.fuzzyHits$.pipe(takeUntil(this.onDestroy)).subscribe(hits => {
      this.lookAheadHits.emit(hits);
      (hits?.length > 0 && !this.disablePopper) ? this.popperContent?.show() : this.popperContent?.hide();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nLookAheadItems) this.viewModel.connectToNLookAheadItems(this.nLookAheadItems);
    if (changes.lookAheadItems) this.viewModel.connectToSearchItems(this.lookAheadItems);
    if (changes.searchTheseProperties) this.viewModel.connectToSearchProperties(this.searchTheseProperties);
    if (changes.outputAllItemsWhenSearchIsEmpty) {
      this.viewModel.connectToOutputAllItemsWhenSearchIsEmpty(this.outputAllItemsWhenSearchIsEmpty);
    }
  }

  searchChanged(e) {
    this.searchText.emit(e.target.value);
  }

  clear() {
    (this.clearButton.nativeElement as HTMLButtonElement)?.click();
  }

  emitEmpty() {
    this.searchText.emit('');
  }

}
