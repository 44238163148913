import { environment } from '../../environments/environment';
import { ProductType } from '../utils/product-type-definition';

export class Endpoints {

  // Automation

  static UpdateMenuSchedule(): string {
    return environment.automationServiceUrl + `/update-menu-schedule`;
  }

  static createSmartFilter(): string {
    return environment.automationServiceUrl + `/create-smart-filter`;
  }

  static updateSmartFilter(): string {
    return environment.automationServiceUrl + `/update-smart-filter`;
  }

  static deleteSmartFilter(): string {
    return environment.automationServiceUrl + `/delete-smart-filter`;
  }

  static getSmartFilters(locationId: string): string {
    return environment.automationServiceUrl + `/get-smart-filters?LocationId=${locationId}`;
  }

  static getCuratedSmartFilters(locationId: string): string {
    if (locationId) {
      return environment.automationServiceUrl + `/get-curated-smart-filters?LocationId=${locationId}`;
    } else {
      return environment.automationServiceUrl + `/get-curated-smart-filters`;
    }
  }

  static getHydratedSmartFilter(locationId: string, filterId: string): string {
    return environment.automationServiceUrl + `/get-hydrated-smart-filter?LocationId=${locationId}&Id=${filterId}`;
  }

  static syncCompanySmartFilters(locationIds: string): string {
    return environment.automationServiceUrl + `/sync-company-smart-filters?LocationIds=${locationIds}`;
  }

  static syncLocationSmartFilters(locationId: string): string {
    return environment.automationServiceUrl + `/sync-location-smart-filters?LocationId=${locationId}`;
  }

  static syncSectionSmartFilter(locationId: string, filterId: string): string {
    return environment.automationServiceUrl + `/sync-section-smart-filters?MenuId=${locationId}&SectionId=${filterId}`;
  }

  static syncSmartDisplayAttributes(forceSync: boolean, locationIds: string): string {
    return environment.automationServiceUrl +
      `/sync-smart-display-attributes?ForceSync=${forceSync}&LocationIds=${locationIds}`;
  }

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static SignIn(): string {
    return environment.adminAccountServiceUrl + `/sign-in`;
  }

  static SignInNewPassword(): string {
    return environment.adminAccountServiceUrl + `/sign-in-new-password`;
  }

  static SignOut(): string {
    return environment.adminAccountServiceUrl + `/sign-out`;
  }

  static RefreshSession(): string {
    return environment.adminAccountServiceUrl + `/refresh-session`;
  }

  // Admin Account

  static AdminCreateUser(): string {
    return environment.adminAccountServiceUrl + `/admin-create-user`;
  }

  static AdminDeleteUser(): string {
    return environment.adminAccountServiceUrl + `/admin-delete-user`;
  }

  static AdminGetUsers(): string {
    return environment.adminAccountServiceUrl + `/admin-get-users`;
  }

  static AdminUpdateUser(): string {
    return environment.adminAccountServiceUrl + `/admin-update-user`;
  }

  // AdminUser Account

  static ChangePassword(): string {
    return environment.adminAccountServiceUrl + `/change-password`;
  }

  static ConfirmCode(): string {
    return environment.adminAccountServiceUrl + `/confirm-code`;
  }

  static ForgotPasswordCode(email: string): string {
    return environment.adminAccountServiceUrl + `/forgot-password-code?Email=${this.encodeParam(email)}`;
  }

  static ResendCode(email, token: string): string {
    return environment.adminAccountServiceUrl + `/resend-code?Email=${this.encodeParam(email)}&Token=${token}`;
  }

  static ResetForgottenPassword(): string {
    return environment.adminAccountServiceUrl + `/reset-forgotten-password`;
  }

  static UpdateUser(): string {
    return environment.adminAccountServiceUrl + `/update-user`;
  }

  // Image

  static DeleteAsset(id, md5Hash: string): string {
    return environment.imageServiceUrl + `/delete-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  static GenerateUploadUrl(): string {
    return environment.imageServiceUrl + `/generate-upload-url`;
  }

  static GetPreview(
    locationId: number,
    displayId,
    menuId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly: boolean = false
  ): string {
    const params = `?LocationId=${locationId}` +
      `&DisplayId=${displayId}` +
      `&ConfigurationId=${menuId}` +
      `&ReturnLastSaved=${returnLastSaved}` +
      `&PreviewOnly=${previewOnly}` +
      `&ForceUpdate=${forceUpdate}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetAsset(id, md5Hash: string): string {
    return environment.imageServiceUrl + `/get-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  // Onboarding

  static CreateCompany(): string {
    return environment.adminCompanyServiceUrl + `/create-company`;
  }

  static CreateLocation(): string {
    return environment.adminCompanyServiceUrl + `/create-location`;
  }

  static UpdateLocation(): string {
    return environment.mainServiceUrl + `/update-location`;
  }

  static AdminGetCompanies(id?: string): string {
    if (id) {
      return environment.adminCompanyServiceUrl + `/get-companies?CompanyId=${id}`;
    } else {
      return environment.adminCompanyServiceUrl + `/get-companies`;
    }
  }

  static UpdateCompanyFeatures(): string {
    return environment.adminCompanyServiceUrl + `/update-company-features`;
  }

  // Inventory Provider

  static GetProviderSpecifications(id: number): string {
    return environment.adminCompanyServiceUrl + `/get-provider-specifications?CompanyId=${id}`;
  }

  static UpdateProviderSpecifications(id: number): string {
    return environment.adminCompanyServiceUrl + `/update-provider-specifications?CompanyId=${id}`;
  }

  // Product

  static SyncDisplayNames(): string {
    return environment.productServiceUrl + `/sync-display-names`;
  }

  static GetSyncJobs(ids: string[]): string {
    const jobIds = ids?.join(',') ?? '';
    return environment.productServiceUrl + `/get-sync-jobs?Ids=${jobIds}`;
  }

  static CreateSyncJob(): string {
    return environment.productServiceUrl + `/create-sync-job`;
  }

  // Admin Product

  static createCuratedSmartFilter(): string {
    return environment.adminProductServiceUrl + '/create-curated-smart-filter';
  }

  static deleteCuratedSmartFilter(): string {
    return environment.adminProductServiceUrl + '/delete-curated-smart-filter';
  }

  static updateCuratedSmartFilter(): string {
    return environment.adminProductServiceUrl + '/update-curated-smart-filter';
  }

  static getSmartFilterCategories(): string {
    return environment.adminProductServiceUrl + `/get-smart-filter-categories`;
  }

  static createCuratedSmartFilterCategory(): string {
    return environment.adminProductServiceUrl + '/create-smart-filter-category';
  }

  static updateCuratedSmartFilterCategory(): string {
    return environment.adminProductServiceUrl + '/update-smart-filter-category';
  }

  static updateSmartFilterCategoryPriorities(): string {
    return environment.adminProductServiceUrl + '/update-sf-category-priorities';
  }

  static deleteCuratedSmartFilterCategory(): string {
    return environment.adminProductServiceUrl + '/delete-smart-filter-category';
  }

  static GetAdminCuratedBadges(id?: string): string {
    if (id) {
      return environment.adminProductServiceUrl + `/get-curated-badges?BadgeIds=${id}`;
    } else {
      return environment.adminProductServiceUrl + `/get-curated-badges`;
    }
  }

  static DeleteCuratedBadges(badgeIds: string): string {
    return environment.adminProductServiceUrl + `/delete-curated-badges?BadgeIds=${badgeIds}`;
  }

  static UpdateCuratedBadges(): string {
    return environment.adminProductServiceUrl + `/update-curated-badges`;
  }

  static CreateCuratedBadge(): string {
    return environment.adminProductServiceUrl + `/create-curated-badge`;
  }

  static GetVariantLookupLogs(): string {
    return environment.adminProductServiceUrl + `/get-variant-lookup-logs`;
  }

  static GetVariantsFromBarcode(barcode: string): string {
    return environment.adminProductServiceUrl + `/get-variants-from-barcode?Barcode=${barcode}`;
  }

  static GetUniversalVariants(ignoredProductTypes: ProductType[]): string {
    let url = environment.adminProductServiceUrl + `/get-universal-variants`;
    if (ignoredProductTypes.length > 0) {
      url += `?IgnoredProductTypes=${ignoredProductTypes.sort().join(',')}`;
    }
    return url;
  }

  static UpdateUniversalVariant(): string {
    return environment.adminProductServiceUrl + `/update-universal-variant`;
  }

  // Global Display Attributes

  static CreateDisplayAttributeGroup(): string {
    return environment.adminProductServiceUrl + `/create-display-attribute-group`;
  }

  static DeleteDisplayAttributeGroup(): string {
    return environment.adminProductServiceUrl + `/delete-display-attribute-group`;
  }

  static UpdateDisplayAttributeGroup(): string {
    return environment.adminProductServiceUrl + `/update-display-attribute-group`;
  }

  static GetDisplayAttributeGroups(ids?: string): string {
    if (ids) {
      return environment.adminProductServiceUrl + `/get-display-attribute-groups?Ids=${ids}`;
    } else {
      return environment.adminProductServiceUrl + `/get-display-attribute-groups`;
    }
  }

  // Display Attribute Group Details

  static CreateDisplayGroupDetails(): string {
    return environment.adminProductServiceUrl + `/create-display-group-details`;
  }

  static DeleteDisplayGroupDetails(): string {
    return environment.adminProductServiceUrl + `/delete-display-group-details`;
  }

  // Asset Groups

  static CreateAssetGroup(): string {
    return environment.adminProductServiceUrl + `/create-asset-group`;
  }

  static DeleteAssetGroup(): string {
    return environment.adminProductServiceUrl + `/delete-asset-group`;
  }

  static UpdateAssetGroup(): string {
    return environment.adminProductServiceUrl + `/update-asset-group`;
  }

  static GetAssetGroups(displayGroupIds: string, brandDisplayGroupIds: string): string {
    if (!!brandDisplayGroupIds) {
      return environment.adminProductServiceUrl + `/get-asset-groups?BrandDisplayGroupIds=${brandDisplayGroupIds}`;
    }
    return environment.adminProductServiceUrl + `/get-asset-groups?DisplayGroupIds=${displayGroupIds}`;
  }

  static GetBrandAssetGroups(): string {
    return environment.adminProductServiceUrl + `/get-brand-asset-groups`;
  }

  // Display/Menus

  static GetCompanyMenus(companyId?: number, locationId?: number): string {
    return environment.adminCompanyServiceUrl + `/get-company-menus?CompanyId=${companyId}&LocationId=${locationId}`;
  }

  static GetCompanyDisplays(id?: number): string {
    return environment.adminCompanyServiceUrl + `/get-company-displays?CompanyId=${id}`;
  }

  // Section Blueprints

  static CreateSectionBlueprint(): string {
    return environment.templateServiceUrl + `/create-section-blueprint`;
  }

  static CreateSectionBlueprintCategory(): string {
    return environment.templateServiceUrl + '/create-section-blueprint-category';
  }

  static DeleteSectionBlueprint(): string {
    return environment.templateServiceUrl + '/delete-section-blueprint';
  }

  static DeleteSectionBlueprintCategory() {
    return environment.templateServiceUrl + '/delete-section-blueprint-category';
  }

  static GetSectionBlueprints(ids?: string): string {
    if (ids) {
      return environment.templateServiceUrl + `/get-section-blueprints?Ids=${ids}`;
    }
    return environment.templateServiceUrl + `/get-section-blueprints`;

  }

  static GetSectionBlueprintCategories(): string {
    return environment.templateServiceUrl + '/get-section-blueprint-categories';
  }

  static UpdateSectionBlueprint(): string {
    return environment.templateServiceUrl + '/update-section-blueprint';
  }

  static UpdateSectionBlueprintCategory(): string {
    return environment.templateServiceUrl + '/update-section-blueprint-category';
  }

  // Main Service

  static SyncLocations(): string {
    return environment.mainServiceUrl + `/sync-locations`;
  }

  // Admin General Service

  static getAllThemes(): string {
    return environment.adminGeneralServiceUrl + `/get-all-themes`;
  }

  static createTheme(): string {
    return environment.adminGeneralServiceUrl + `/create-theme`;
  }

  static updateTheme(): string {
    return environment.adminGeneralServiceUrl + `/update-theme`;
  }

  static PopulateSandboxData(): string {
    return environment.adminGeneralServiceUrl + `/populate-sandbox-data`;
  }

  // Utils

  static GetTypes(countryCode: string): string {
    return environment.mainServiceUrl + `/get-types?Country=${countryCode}`;
  }

}

