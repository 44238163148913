import { TypeDefinition } from './type-definition';

export enum UniversalVariantStatusType {
  All = 'All',
  Active = 'Active',
  Delisted = 'Delisted'
}

export class UniversalVariantStatusTypeDefinition extends TypeDefinition {

  override value: UniversalVariantStatusType;

}
