import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { HydratedVariantBadge } from '../../../../../models/product/dto/hydrated-variant-badge';
import { BadgeWithinPickerViewModel } from './badge-within-picker-view-model';

@Component({
  selector: 'app-badge-within-picker',
  templateUrl: './badge-within-picker.component.html',
  styleUrls: ['./badge-within-picker.component.scss'],
  providers: [BadgeWithinPickerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeWithinPickerComponent extends BaseComponent implements OnChanges {

  constructor(public viewModel: BadgeWithinPickerViewModel) {
    super();
  }

  @Input() badge: HydratedVariantBadge;
  @Input() addedBadgeIds: string[];
  @Input() preselectedBadgeIds: string[];
  @Input() asListItems: boolean = false;
  @Input() maximumNumberOfBadges: number;
  @Output() selected: EventEmitter<HydratedVariantBadge> = new EventEmitter<HydratedVariantBadge>();

  override setupViews() {
    this.viewModel.connectToBadge(this.badge);
    this.viewModel.connectToAddedBadgeIds(this.addedBadgeIds);
    this.viewModel.connectToPreselectedBadgeIds(this.preselectedBadgeIds);
    this.viewModel.connectToAsListItems(this.asListItems);
    this.viewModel.connectToMaximumNumberOfBadges(this.maximumNumberOfBadges);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.badge) this.viewModel.connectToBadge(this.badge);
    if (changes.addedBadgeIds) this.viewModel.connectToAddedBadgeIds(this.addedBadgeIds);
    if (changes.preselectedBadgeIds) this.viewModel.connectToPreselectedBadgeIds(this.preselectedBadgeIds);
    if (changes.asListItems) this.viewModel.connectToAsListItems(this.asListItems);
    if (changes.maximumNumberOfBadges) this.viewModel.connectToMaximumNumberOfBadges(this.maximumNumberOfBadges);
  }

}
