import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { window } from 'ngx-bootstrap/utils';
import { BasicAddEditSmartFilterFormViewModel } from './basic-add-edit-smart-filter-form-view-model';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';
import { SmartFiltersDomainModel } from '../../../../../domainModels/smart-filters-domain-model';
import { ModalService } from '../../../../../services/modal.service';

@Component({
  selector: 'app-basic-add-edit-smart-filter-form',
  templateUrl: './basic-add-edit-smart-filter-form.component.html',
  styleUrls: ['./basic-add-edit-smart-filter-form.component.scss'],
  providers: [BasicAddEditSmartFilterFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  @ViewChild('secondarySort') secondarySortInput: ReactiveFormDropDownComponent;
  @ViewChild('categoryDropDown') categoryDropDown: ReactiveFormDropDownComponent;

  public dropdownSmartFilterCategories$ = this.smartFiltersDomainModel.smartFilterCategories$;
  public dropdownSmartFilterSystemIdentifiers$ = window?.types?.smartFilterSystemIdentifiers$;

  private selectedPrimarySortOption = new BehaviorSubject<string>(null);

  private listenToPrimarySortDropDown = this.selectedPrimarySortOption.subscribeWhileAlive({
    owner: this,
    next: op => {
      if (!op) {
        this.secondarySortInput?.clear(true);
      }
    }
  });

  constructor(
    public viewModel: BasicAddEditSmartFilterFormViewModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) {
      this.viewModel.connectToSmartFilter(this.bindTo);
      this.primarySortDropdownChanged(this.bindTo.primarySort);
    }
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
  }

  public primarySortDropdownChanged(sortOption: string) {
    this.selectedPrimarySortOption.next(sortOption);
    this.viewModel.primarySortDropdownChanged(sortOption);
  }

  newCategoryClicked() {
    this.modalService.openEditSmartFilterCategoryModal(null, createdCategory => {
      this.dropdownSmartFilterCategories$.firstNotNull().subscribe(() => {
        setTimeout(() => {
          this.bindTo.categoryId = createdCategory.id;
          this.categoryDropDown.getSelfAsFormItem().setValue(createdCategory.id);
          this.categoryDropDown.getSelfAsFormItem().markAsDirty();
        }, 500);
      });
    });
  }

}
