import Fuse from 'fuse.js';
import { Variant } from '../models/product/dto/variant';
import { Card } from '../models/shared/stylesheet/card';
import { LookAheadItem } from '../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import { Company } from '../models/company/dto/company';
import { Location } from '../models/company/dto/location';
import { AssetGroup } from '../models/product/dto/asset-group';
import { SectionBlueprintCategory } from '../models/menu/dto/section-blueprint-category';
import { SectionBlueprint } from '../models/menu/dto/section-blueprint';
import { DisplayAttributeGroupDetails } from '../models/product/dto/display-attribute-group-details';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { exists } from '../functions/exists';

export class SearchUtils {

  static getFuseOptionsForSearchLookAhead(searchProperties: string[]): Fuse.IFuseOptions<LookAheadItem> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.15,
      ignoreLocation: true,
      keys: searchProperties
    };
  }

  static getFuseOptionsForIncompleteUniversalVariantCards(): Fuse.IFuseOptions<Card> {
    let variantSearchProperties = this.variantSearchPropertyList();
    variantSearchProperties = variantSearchProperties.map(property => `data.${property}`);
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: variantSearchProperties
    };
  }

  static getFuseOptionsForVariants(): Fuse.IFuseOptions<any> {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: SearchUtils.variantSearchPropertyList()
    };
  }

  static getFuseOptionsForCompaniesAndLocations(): Fuse.IFuseOptions<any> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.15,
      ignoreLocation: true,
      keys: ['name']
    };
  }

  static getFuseOptionsForDisplayAttributeGroups(): Fuse.IFuseOptions<DisplayAttributeGroup> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: ['displayName', 'groupName']
    };
  }

  static getFuseOptionsForDisplayAttributeGroupDetails(): Fuse.IFuseOptions<DisplayAttributeGroupDetails> {
    return {
      isCaseSensitive: false,
      shouldSort: false,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: ['groupName']
    };
  }

  static getFuseOptionsForBrands(): Fuse.IFuseOptions<AssetGroup> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: ['groupName']
    };
  }

  static getFuseOptionsForSectionBlueprints(): Fuse.IFuseOptions<SectionBlueprint> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: ['title', 'subTitle']
    };
  }

  static getFuseOptionsForCategories(): Fuse.IFuseOptions<SectionBlueprintCategory> {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      includeMatches: false,
      minMatchCharLength: 2,
      threshold: 0.1,
      ignoreLocation: true,
      keys: ['name']
    };
  }

  static variantSearchPropertyList(): string[] {
    return [
      'barcode', 'name', 'brand', 'manufacturer', 'classification',
      'size', 'productType', 'variantType', 'strain'
    ];
  }

  static getVariantPropertyValueFromKey(v: Variant, key: string): string {
    switch (key) {
      case 'barcode': return v?.barcode?.stripWhiteSpaceAndLowerCase();
      case 'name': return v?.name.stripWhiteSpaceAndLowerCase();
      case 'brand': return v?.brand?.stripWhiteSpaceAndLowerCase();
      case 'manufacturer': return v?.manufacturer?.stripWhiteSpaceAndLowerCase();
      case 'classification': return v?.classification?.stripWhiteSpaceAndLowerCase();
      case 'size': return v?.getSize()?.stripWhiteSpaceAndLowerCase();
      case 'productType': return v?.productType?.stripWhiteSpaceAndLowerCase();
      case 'variantType': return v?.variantType?.stripWhiteSpaceAndLowerCase();
      case 'strain': return v?.strain?.stripWhiteSpaceAndLowerCase();
      case 'THC': return (v?.getCannabinoidWithUnits(PrimaryCannabinoid.THC))?.stripWhiteSpaceAndLowerCase();
      case 'minTHC': return (v?.getMinCannabinoidWithUnits(PrimaryCannabinoid.THC))?.stripWhiteSpaceAndLowerCase();
      case 'maxTHC': return (v?.getMaxCannabinoidWithUnits(PrimaryCannabinoid.THC))?.stripWhiteSpaceAndLowerCase();
      case 'CBD': return (v?.getCannabinoidWithUnits(PrimaryCannabinoid.CBD))?.stripWhiteSpaceAndLowerCase();
      case 'minCBD': return (v?.getMinCannabinoidWithUnits(PrimaryCannabinoid.CBD))?.stripWhiteSpaceAndLowerCase();
      case 'maxCBD': return (v?.getMaxCannabinoidWithUnits(PrimaryCannabinoid.CBD))?.stripWhiteSpaceAndLowerCase();
    }
    return '';
  }

  static getSearchableVariant(v: Variant): any {
    return {
      barcode: v?.barcode?.stripWhiteSpaceAndLowerCase(),
      name: v?.name?.stripWhiteSpaceAndLowerCase(),
      brand: v?.brand?.stripWhiteSpaceAndLowerCase(),
      manufacturer: v?.manufacturer?.stripWhiteSpaceAndLowerCase(),
      classification: v?.classification?.stripWhiteSpaceAndLowerCase(),
      size: v?.getSize()?.stripWhiteSpaceAndLowerCase(),
      productType: v?.productType?.stripWhiteSpaceAndLowerCase(),
      variantType: v?.variantType?.stripWhiteSpaceAndLowerCase(),
      strain: v?.strain?.stripWhiteSpaceAndLowerCase(),
      original: v
    };
  }

  static getSearchableVariantWithSpecificProperty(v: Variant, key: string): any {
    return {
      [key]: SearchUtils.getVariantPropertyValueFromKey(v, key),
      original: v
    };
  }

  static getSpecificVariantPropertySearchData(searchText: string): [string, string] {
    const [, key, searchTerm] = /(\w+):(.+)/.exec(searchText) || [null, null, null];
    let updatedKey = key;
    if (key?.search(/^\d+$/) >= 0) { return [null, null]; }
    if (exists(key)) {
      switch (key?.toLowerCase()?.replace(' ', '')) {
        case 'name':        updatedKey = 'name';           break;
        case 'brand':       updatedKey = 'brand';          break;
        case 'product':     updatedKey = 'productType';    break;
        case 'producttype': updatedKey = 'productType';    break;
        case 'variant':     updatedKey = 'variantType';    break;
        case 'varianttype': updatedKey = 'variantType';    break;
        case 'straintype':  updatedKey = 'classification'; break;
        case 'barcode':     updatedKey = 'barcode';        break;
        case 'thc':         updatedKey = 'THC';            break;
        case 'minthc':      updatedKey = 'minTHC';         break;
        case 'maxthc':      updatedKey = 'maxTHC';         break;
        case 'cbd':         updatedKey = 'CBD';            break;
        case 'mincbd':      updatedKey = 'minCBD';         break;
        case 'maxcbd':      updatedKey = 'maxCBD';         break;
      }
    }
    return [updatedKey?.trim(), searchTerm?.trim()];
  }

  static isCannabinoidKey(key: string): boolean {
    return ['THC', 'minTHC', 'maxTHC', 'CBD', 'minCBD', 'maxCBD'].includes(key);
  }

  static searchVariants(variants: Variant[], searchText: string): Variant[] {
    const options = SearchUtils.getFuseOptionsForVariants();
    const [key, specificSearch] = SearchUtils.getSpecificVariantPropertySearchData(searchText);
    let finalSearchString = searchText;
    let searchableVariants: any[];
    if (exists(key) && specificSearch?.length >= 2) {
      options.keys = [key];
      if (SearchUtils.isCannabinoidKey(key)) {
        options.ignoreLocation = false;
        options.distance = 0;
      }
      finalSearchString = specificSearch;
      searchableVariants = variants?.map(v => SearchUtils.getSearchableVariantWithSpecificProperty(v, key)) || [];
    } else {
      searchableVariants = variants?.map(v => SearchUtils.getSearchableVariant(v)) || [];
    }
    return new Fuse(searchableVariants, options)
      .search(finalSearchString)
      .map(match => match.item)
      .map(searchObject => searchObject?.original);
  }

  static searchCompanyAndLocations(companies: Company[], searchText: string): Company[] {
    const options = SearchUtils.getFuseOptionsForCompaniesAndLocations();
    const companySearcher: Fuse<Company> = new Fuse(companies, options);
    const companyHits = companySearcher.search(searchText).map(match => match.item) || [];
    const locations = companies?.map(company => company?.locations).flatten<Location[]>();
    const locationIdHits = SearchUtils.searchLocations(locations, searchText).map(location => location?.id) || [];
    const locationHits = companies?.filter(company => {
      return locationIdHits?.intersection(company?.locations?.map(l => l?.id))?.length > 0;
    });
    return [...companyHits, ...locationHits]?.uniqueByProperty('id');
  }

  static searchLocations(locations: Location[], searchText: string): Location[] {
    const options = SearchUtils.getFuseOptionsForCompaniesAndLocations();
    const locationSearcher: Fuse<Location> = new Fuse(locations, options);
    return locationSearcher.search(searchText).map(match => match.item) || [];
  }

  static searchIncompleteVariantCards(variantCards: Card[], searchText: string): Card[] {
    const options = SearchUtils.getFuseOptionsForIncompleteUniversalVariantCards();
    const fuse = new Fuse(variantCards, options);
    return fuse.search(searchText).map(match => match.item);
  }

  static searchDisplayAttributeGroups(groups: DisplayAttributeGroup[], searchText: string): DisplayAttributeGroup[] {
    const options = SearchUtils.getFuseOptionsForDisplayAttributeGroups();
    const fuse = new Fuse(groups ?? [], options);
    return fuse.search(searchText).map(match => match.item);
  }

  static searchBrand(brands: AssetGroup[], searchText: string): AssetGroup[] {
    const options = SearchUtils.getFuseOptionsForBrands();
    const fuse = new Fuse(brands ?? [], options);
    return fuse.search(searchText).map(match => match.item);
  }

  static searchSectionBlueprints(
    sectionBlueprints: SectionBlueprint[],
    searchText: string
  ): SectionBlueprint[] {
    const options = SearchUtils.getFuseOptionsForSectionBlueprints();
    const fuse = new Fuse(sectionBlueprints ?? [], options);
    return fuse.search(searchText).map(match => match.item);
  }

  static searchCategories(categories: SectionBlueprintCategory[], searchText: string): SectionBlueprintCategory[] {
    const options = SearchUtils.getFuseOptionsForCategories();
    const fuse = new Fuse(categories ?? [], options);
    return fuse.search(searchText).map(match => match.item);
  }

  static lookAheadSearchElseEmptyList(
    items: LookAheadItem[],
    searchText: string,
    searchProperties: string[],
    returnNItems: number,
    outputAllItemsWhenSearchIsEmpty: boolean
  ): [string, LookAheadItem[]] {
    const hasSearchProperties = exists(searchProperties) && searchProperties?.length > 0;
    const hasSearchableItems = items?.length > 0;
    let matches = outputAllItemsWhenSearchIsEmpty ? items : [];
    if (exists(searchText) && hasSearchProperties && hasSearchableItems) {
      const options = SearchUtils.getFuseOptionsForSearchLookAhead(searchProperties);
      const enoughCharacterForSearch = searchText.length >= options.minMatchCharLength;
      if (enoughCharacterForSearch) {
        const fuse = new Fuse(items, options);
        matches = fuse.search(searchText).map(match => match.item);
        return [
          searchText,
          Number.isFinite(returnNItems) ? matches.take<any>(returnNItems) : matches
        ] as [string, LookAheadItem[]];
      }
    }
    return [searchText, matches] as [string, LookAheadItem[]];
  }

}
