<div
  class="theme-container"
  [ngClass]="{
    'portrait-preview': orientation === ClientTypes.Orientation.Portrait,
    'landscape-preview': orientation === ClientTypes.Orientation.Landscape,
    print: isPrint,
    'print-card': isPrintCard,
    'print-label': isPrintLabel,
  }">
  <app-asset
    [class.print]="isPrint"
    [class.print-card]="isPrintCard"
    [class.print-label]="isPrintLabel"
    [borderRadius]="'0.5rem'"
    [scaleFit]="true"
    [asset]="
      orientation === ClientTypes.Orientation.Portrait
        ? (theme | firstPortraitPreviewImage)
        : (theme | firstLandscapePreviewImage)
    ">
  </app-asset>
  <app-private-theme-indicator
    *ngIf="theme | isPrivateTheme"
    class="private-theme-indicator"></app-private-theme-indicator>
</div>
