import { Injectable, Injector } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { AddEditSmartFilterModalComponent } from '../views/smart-filters/modal/add-edit-smart-filter-modal/add-edit-smart-filter-modal.component';
import { SmartFilterCategory } from '../models/automation/smart-filter-category';
import { EditSmartFilterCategoryModalComponent } from '../views/smart-filters/modal/edit-smart-filter-category-modal/edit-smart-filter-category-modal.component';
import { BudsenseFile } from '../models/shared/budsense-file';
import { UploadThemeImageModalComponent } from '../views/themes/components/create-or-edit-theme/shared/upload-theme-image-modal/upload-theme-image-modal.component';
import { ThemePreviewModalComponent } from '../views/themes/components/create-or-edit-theme/shared/theme-preview-modal/theme-preview-modal.component';
import { Asset } from '../models/image/dto/asset';
import { SmartFilterSelectionModalComponent } from '../views/smart-filters/modal/smart-filter-selection-modal/smart-filter-selection-modal.component';
import { EditVariantModalComponent } from '../views/products/components/edit-variant-modal/edit-variant-modal.component';
import { Variant } from '../models/product/dto/variant';
import { CreateDisplayAttributeGroupModalComponent } from '../views/shared/modals/create-display-attribute-group-modal/create-display-attribute-group-modal.component';
import { AddVariantToDisplayGroupModalComponent } from '../views/shared/modals/add-variant-to-display-group-modal/add-variant-to-display-group-modal.component';
import { AssetPreviewModalComponent } from '../views/shared/modals/asset-preview-modal/asset-preview-modal.component';
import { CreateAssetGroupModalComponent } from '../views/shared/modals/create-asset-group-modal/create-asset-group-modal.component';
import { AssetGroup } from '../models/product/dto/asset-group';
import { AssignVariantToAssetGroupModalComponent } from '../views/shared/modals/assign-variant-to-asset-group-modal/assign-variant-to-asset-group-modal.component';
import { AddAssetModalComponent } from '../views/shared/modals/add-asset-modal/add-asset-modal.component';
import { UpdateAssetLibraryTypesModalComponent } from '../views/shared/modals/update-asset-library-types-modal/update-asset-library-types-modal.component';
import { ConfirmationModalComponent } from '../views/shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { EditBadgeSectionComponent } from '../views/defaults/components/badges/edit-badge-section/edit-badge-section.component';
import { SelectableBadgeSection } from '../models/product/shared/selectable-badge-section';
import { InformationModalComponent } from '../views/shared/modals/information-modal/information-modal.component';
import { InformationItem } from '../models/shared/information-item';
import { CreateBrandModalComponent } from '../views/shared/modals/create-brand-modal/create-brand-modal.component';
import { FilterByBrandModalComponent } from '../views/shared/modals/filter-by-brand-modal/filter-by-brand-modal.component';
import { TypeCheckUtils } from '../utils/type-check-utils';
import { CompanySmartSyncRequestType } from '../models/company/enum/company-smart-sync-request-type.enum';
import { Company } from '../models/company/dto/company';
import { SmartSyncLocationSelectModalComponent } from '../views/shared/modals/smart-sync-location-select-modal/smart-sync-location-select-modal.component';
import { SectionBlueprint } from '../models/menu/dto/section-blueprint';
import { SectionBlueprintCategory } from '../models/menu/dto/section-blueprint-category';
import { ColumnOptionsModalComponent } from '../views/defaults/components/modals/column-options-modal/column-options-modal.component';
import { FilterByCategoryModalComponent } from '../views/defaults/components/modals/filter-by-category-modal/filter-by-category-modal.component';
import { CreateSectionBlueprintModalComponent } from '../views/defaults/components/modals/create-section-blueprint-modal/create-section-blueprint-modal.component';
import { AddEditSmartFilterModalOpenedFrom } from '../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { PrivateCompanyThemeModalComponent } from '../views/shared/modals/private-company-theme-modal/private-company-theme-modal.component';
import { SyncDataJob } from '../models/automation/sync-data-job';
import { ReviewSyncJobModalComponent } from '../views/customers/components/customer-details/components/customer-details-integration/components/review-sync-job-modal/review-sync-job-modal.component';
import { NewSyncJobModalComponent } from '../views/customers/components/customer-details/components/customer-details-integration/components/new-sync-job-modal/new-sync-job-modal.component';
import { EditSectionBlueprintCategoryModalComponent } from '../views/defaults/components/modals/edit-section-blueprint-category-modal/edit-section-blueprint-category-modal.component';
import { ProviderSpecificationsModalComponent } from '../views/customers/components/customer-details/components/customer-details-integration/components/provider-specifications-modal/provider-specifications-modal.component';
import { EditLocationUsePurposeComponent } from '../views/defaults/components/modals/edit-location-use-purpose/edit-location-use-purpose.component';
import { Location } from '../models/company/dto/location';
import { UpdatePrintCardPreviewsModalComponent } from '../views/themes/components/create-or-edit-theme/stack/update-print-card-previews-modal/update-print-card-previews-modal.component';
import { Theme } from '../models/menu/dto/theme';
import { PopulateSandboxDataModalComponent } from '../views/defaults/components/modals/populate-sandbox-data-modal/populate-sandbox-data-modal.component';
import { Observable } from 'rxjs';
import { ChangeMediaOptions } from '../models/shared/stylesheet/change-media-options';
import { ChangeMediaModalComponent } from '../views/shared/modals/change-media-modal/change-media-modal.component';
import type { DisplayAttributeGroupDetails } from '../models/product/dto/display-attribute-group-details';
import { CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent } from '../views/products/components/display-attributes/edit-display-attribute-group/create-display-attribute-group-details-or-edit-variants-modal/create-display-attribute-group-details-or-update-variants-modal.component';
import { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import { SnoozeAssetGroupModalComponent } from '../views/shared/modals/snooze-asset-group-modal/snooze-asset-group-modal.component';
import { DefaultStackSize } from '../utils/default-stack-size';
import { exists } from '../functions/exists';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private ngbModal: NgbModal
  ) {
  }

  openCreateSmartFilter(
    ngbModal: NgbModal,
    injector: Injector,
    openedFrom: AddEditSmartFilterModalOpenedFrom,
    onClose?: (req: HydratedSmartFilter) => void
  ) {
    const modalRef = ngbModal.open(
      AddEditSmartFilterModalComponent,
      ModalUtils.addEditSmartFiltersModalOptions()
    );
    const compInstance = modalRef.componentInstance as AddEditSmartFilterModalComponent;
    compInstance.viewModel.connectToOpenedFrom(openedFrom);
    modalRef.result.then((smartFilter) => {
      if (exists(smartFilter) && exists(onClose)) {
        onClose(smartFilter);
      }
    }).catch();
  }

  openSelectSmartFilterModal(
    previouslySelectedIds: string[],
    onClose: (smartFilterIds: string[]|null) => void,
    onDismiss: (reason: string) => void,
  ) {
    const modalRef = this.ngbModal.open(
      SmartFilterSelectionModalComponent,
      ModalUtils.selectSmartFiltersModalOptions()
    );
    const compInstance = modalRef.componentInstance as SmartFilterSelectionModalComponent;
    compInstance.alreadyAddedSmartFilterIds(previouslySelectedIds);
    modalRef.result.then(onClose).catch(onDismiss);
  }

  openCreateSmartFilterModal(onClose?: (req: HydratedSmartFilter) => void) {
    const modalRef = this.ngbModal.open(
      AddEditSmartFilterModalComponent,
      ModalUtils.addEditSmartFiltersModalOptions()
    );
    modalRef.result.then((smartFilter) => {
      if (exists(smartFilter) && exists(onClose)) {
        onClose(smartFilter);
      }
    }).catch();
  }

  openEditSmartFilterModal(smartFilter: HydratedSmartFilter, onClose?: (req: HydratedSmartFilter) => void) {
    const modalRef = this.ngbModal.open(
      AddEditSmartFilterModalComponent,
      ModalUtils.addEditSmartFiltersModalOptions()
    );
    const compInstance = modalRef.componentInstance as AddEditSmartFilterModalComponent;
    compInstance.viewModel.isEditing = true;
    const smartFilterCopy = window.injector.Deserialize.instanceOf(HydratedSmartFilter, smartFilter);
    compInstance.viewModel.existingSmartFilter.next(smartFilterCopy);
    modalRef.result.then((editedFilter) => {
      if (!exists(editedFilter) && exists(onClose)) {
        onClose(editedFilter);
      }
    }).catch();
  }

  openEditSmartFilterCategoryModal(editCategory?: SmartFilterCategory, onClose?: (req: SmartFilterCategory) => void) {
    const modalRef = this.ngbModal.open(
      EditSmartFilterCategoryModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as EditSmartFilterCategoryModalComponent;
    compInstance.viewModel.isEditing = editCategory !== null;
    const editCategoryCopy = window.injector.Deserialize.instanceOf(SmartFilterCategory, editCategory);
    compInstance.viewModel.existingSmartFilterCategory.next(editCategoryCopy);
    modalRef.result.then((createdFilterCategory) => {
      if (exists(createdFilterCategory) && exists(onClose)) {
        onClose(createdFilterCategory);
      }
    }).catch();
  }

  openAddThemeImageModal(portrait: boolean, onClose?: (fileList: BudsenseFile[]) => void) {
    const modalRef = this.ngbModal.open(
      UploadThemeImageModalComponent,
      ModalUtils.defaultModalOptions()
    );
    (modalRef.componentInstance as UploadThemeImageModalComponent).portrait = portrait;
    modalRef.result.then((fileList) => {
      if (exists(fileList) && exists(onClose)) {
        onClose(fileList);
      }
    }).catch();
  }

  openThemeImagePreviewModal(existingAsset: Asset, uploadFile: BudsenseFile) {
    const modalRef = this.ngbModal.open(
      ThemePreviewModalComponent,
      ModalUtils.large()
    );
    (modalRef.componentInstance as ThemePreviewModalComponent).existingAsset = existingAsset;
    (modalRef.componentInstance as ThemePreviewModalComponent).uploadFile = uploadFile;
  }

  openUpdatePrintCardPreviewsModal(
    previews: Asset[],
    uploads: BudsenseFile[],
    theme: Theme,
    uploadCardSizeMap: Map<string, DefaultStackSize>,
    cardSizes: string[],
    onClose?: (
      cardPreviewMap: Map<DefaultStackSize, string[]>,
      uploadCardMap?: Map<string, DefaultStackSize>
    ) => void
  ) {
    const modalRef = this.ngbModal.open(
      UpdatePrintCardPreviewsModalComponent,
      ModalUtils.large()
    );
    const compInstance = modalRef.componentInstance as UpdatePrintCardPreviewsModalComponent;
    compInstance.setPreviews(previews);
    compInstance.setUploads(uploads);
    compInstance.setTheme(theme);
    compInstance.setUploadCardSizeMap(uploadCardSizeMap);
    compInstance.setCardSizes(cardSizes);
    modalRef.result.then((cardPreviewMaps) => {
      if (exists(cardPreviewMaps)) {
        const [onClosePreviewMap, onCloseUploadCardMap] = cardPreviewMaps || [null, null];
        onClose?.(onClosePreviewMap, onCloseUploadCardMap);
      }
    });
  }

  openEditVariantModal(variant: Variant, incompleteVariantFlow: boolean, onClose?: () => void): void {
    const modalRef = this.ngbModal.open(
      EditVariantModalComponent,
      ModalUtils.editVariantModalOptions()
    );
    const compInstance = modalRef.componentInstance as EditVariantModalComponent;
    compInstance.viewModel.setVariant(variant, incompleteVariantFlow);
    modalRef.result.then(res => {
      if (res && exists(onClose)) { onClose(); }
    }).catch();
  }

  openCreateNewDisplayAttributeGroupModal(barcodesToAdd: string[], onCreateSuccess?: () => void): void {
    const modalRef = this.ngbModal.open(
      CreateDisplayAttributeGroupModalComponent,
      ModalUtils.createNewGDAGModalOptions()
    );
    const compInstance = modalRef.componentInstance as CreateDisplayAttributeGroupModalComponent;
    if (exists(barcodesToAdd)) {
      compInstance.viewModel.connectToBarcodesToAdd(barcodesToAdd);
    }
    modalRef.result.then(handleSuccess => {
      if (handleSuccess && exists(onCreateSuccess)) { onCreateSuccess(); }
    }).catch();
  }

  addToDisplayAttributeGroupModal(v: Variant, ids?: string[], onSuccess?: () => void): void {
    const modalRef = this.ngbModal.open(
      AddVariantToDisplayGroupModalComponent,
      ModalUtils.addToGDAGModalOptions()
    );
    const compInstance = modalRef.componentInstance as AddVariantToDisplayGroupModalComponent;
    if (v && ids === undefined) {
      compInstance.setUBVToAdd(v);
    } else if (v === undefined && ids) {
      compInstance.setIdsToAdd(ids);
    }
    modalRef.result.then(idsToAddToNewGroupOrSuccess => {
      if (idsToAddToNewGroupOrSuccess) {
        if (TypeCheckUtils.isBool(idsToAddToNewGroupOrSuccess)) {
          if (exists(onSuccess)) onSuccess();
        } else {
          this.openCreateNewDisplayAttributeGroupModal(idsToAddToNewGroupOrSuccess, onSuccess);
        }
      }
    }).catch();
  }

  openAssetPreviewModal(
    displayGroupId: string,
    libraryTypeName: string,
    asset: Asset,
    canDelete: boolean,
    isDisabled: boolean,
    isLinkedAsset: boolean,
    isBrandAssetGroup: boolean,
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      AssetPreviewModalComponent,
      ModalUtils.assetPreviewModalOptions()
    );
    const compInstance = modalRef.componentInstance as AssetPreviewModalComponent;
    compInstance.setAssetPreviewInfo(
      displayGroupId,
      libraryTypeName,
      asset,
      canDelete,
      isDisabled,
      isLinkedAsset,
      isBrandAssetGroup
    );
    modalRef.result.then(updatedAssetGroups => {
      if (exists(updatedAssetGroups)) {
        onClose?.(updatedAssetGroups);
      }
    }).catch();
  }

  openCreateAssetGroupModal(
    displayAttributeGroupId: string,
    unassignedVariants: Variant[],
    barcodes?: string[],
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      CreateAssetGroupModalComponent,
      ModalUtils.createAssetGroupsModalOptions()
    );
    const compInstance = modalRef.componentInstance as CreateAssetGroupModalComponent;
    compInstance.setDisplayAttributeGroupId(displayAttributeGroupId);
    compInstance.setUnassignedVariants(unassignedVariants);
    if (exists(barcodes)) { compInstance.setSelectedVariants(barcodes); }
    modalRef.result.then(newAssetGroups => {
      if (exists(newAssetGroups)) {
        onClose?.(newAssetGroups);
      }
    }).catch();
  }

  openAssignVariantToAssetGroupModal(
    variant: Variant,
    assetGroups: AssetGroup[],
    onClose?: (ags: AssetGroup[]) => void,
    onDismiss?: (v: Variant) => void,
  ): void {
    const modalRef = this.ngbModal.open(
      AssignVariantToAssetGroupModalComponent,
      ModalUtils.assignVariantToAssetGroupModalOptions()
    );
    const compInstance = modalRef.componentInstance as AssignVariantToAssetGroupModalComponent;
    compInstance.setVariantToAssign(variant);
    compInstance.setAssetGroups(assetGroups);
    modalRef.result.then(updatedAssetGroups => {
      if (exists(updatedAssetGroups)) {
        onClose?.(updatedAssetGroups);
      }
    }).catch(onDismiss);
  }

  openAddAssetModal(
    assetGroup: AssetGroup,
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      AddAssetModalComponent,
      ModalUtils.addAssetModalOptions()
    );
    const compInstance = modalRef.componentInstance as AddAssetModalComponent;
    compInstance.setAssetGroup(assetGroup);
    modalRef.result.then(updatedAssetGroups => {
      if (exists(updatedAssetGroups)) {
        onClose?.(updatedAssetGroups);
      }
    }).catch();
  }

  openUpdateAssetLibraryTypesModal(
    assetGroup: AssetGroup,
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      UpdateAssetLibraryTypesModalComponent,
      ModalUtils.updateAssetLibraryTypesModalOptions()
    );
    const compInstance = modalRef.componentInstance as UpdateAssetLibraryTypesModalComponent;
    compInstance.setAssetGroup(assetGroup);
    modalRef.result.then(updateAssetGroups => {
      if (exists(updateAssetGroups)) {
        onClose?.(updateAssetGroups);
      }
    }).catch();
  }

  openCreateBrandModal(
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      CreateBrandModalComponent,
      ModalUtils.createBrandsModalOptions()
    );
    modalRef.result.then(ags => {
      if (exists(ags)) {
        onClose?.(ags);
      }
    }).catch();
  }

  openFilterByBrandModal(
    filterBrands: AssetGroup[],
    selectingForFilter: boolean,
    onClose?: (ags: AssetGroup[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      FilterByBrandModalComponent,
      ModalUtils.filterByBrandModalOptions()
    );
    const compInstance = modalRef.componentInstance as FilterByBrandModalComponent;
    compInstance.setFilterBrands(filterBrands);
    compInstance.isSelectingForFilter(selectingForFilter);
    modalRef.result.then(selectedFilterBrands => {
      onClose?.(selectedFilterBrands);
    }).catch();
  }

  openPrivateCompanyThemeModal(
    selectedCompanyIds: number[],
    companiesUsingTheme: number[],
    onClose?: (companyIds: number[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      PrivateCompanyThemeModalComponent,
      ModalUtils.privateCompanyThemeModalOptions()
    );
    const compInstance = modalRef.componentInstance as PrivateCompanyThemeModalComponent;
    compInstance.setSelectedCompanyIds(selectedCompanyIds);
    compInstance.setCompaniesUsingTheme(companiesUsingTheme);
    modalRef.result.then(companyIds => {
      if (exists(companyIds)) {
        onClose?.(companyIds);
      }
    }).catch();
  }

  openFilterByCategoryModal(
    selectedCategories: SectionBlueprintCategory[],
    onClose?: (categories: SectionBlueprintCategory[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      FilterByCategoryModalComponent,
      ModalUtils.filterByCategoryModalOptions()
    );
    const compInstance = modalRef.componentInstance as FilterByCategoryModalComponent;
    compInstance.setSelectedCategories(selectedCategories);
    modalRef.result.then(selectedCategoriesForFilter => {
      if (exists(selectedCategoriesForFilter)) {
        onClose?.(selectedCategoriesForFilter);
      }
    }).catch();
  }

  openChangeMediaModal(
    changeMediaOptions: ChangeMediaOptions,
    changeMediaOperation: (files: BudsenseFile[]) => Observable<string[]>,
    onClose: (uploadedFileNames: string[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      ChangeMediaModalComponent,
      ModalUtils.changeMediaModalOptions()
    );
    const inst = modalRef.componentInstance as ChangeMediaModalComponent;
    inst.options = changeMediaOptions;
    inst.changeMediaOperation = changeMediaOperation;
    modalRef.result.then(onClose).catch(() => {});
  }

  openCreateSectionBlueprintModal(
    onClose?: (sectionBlueprint: SectionBlueprint) => void
  ): void {
    const modalRef = this.ngbModal.open(
      CreateSectionBlueprintModalComponent,
      ModalUtils.createSectionBlueprintModalOptions()
    );
    modalRef.result.then(sectionBlueprint => {
      if (exists(sectionBlueprint)) {
        onClose?.(sectionBlueprint);
      }
    }).catch();
  }

  openEditSectionBlueprintCategoryModal(
    editCategory?: SectionBlueprintCategory,
    onSuccess?: (req: SectionBlueprintCategory) => void
  ) {
    const modalRef = this.ngbModal.open(
      EditSectionBlueprintCategoryModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInst = modalRef.componentInstance as EditSectionBlueprintCategoryModalComponent;
    if (exists(editCategory)) {
      compInst.setCategoryToEdit(editCategory);
    }
    modalRef.result.then((createdBlueprintCategory) => {
      if (exists(onSuccess)) {
        onSuccess?.(createdBlueprintCategory);
      }
    }).catch();
  }

  openColumnOptionsModal(
    blueprint: SectionBlueprint,
    onClose?: (sectionBlueprint: SectionBlueprint) => void
  ): void {
    const modalRef = this.ngbModal.open(
      ColumnOptionsModalComponent,
      ModalUtils.columnOptionsModalOptions()
    );
    const compInst = modalRef.componentInstance as ColumnOptionsModalComponent;
    compInst.setSectionBlueprint(blueprint);
    modalRef.result.then((columnConfig) => {
      if (exists(columnConfig)) {
        onClose?.(columnConfig);
      }
    });
  }

  openAddNewSectionModal(
    onCompletion?: (newSectionName: string) => void
  ): void {
    const modalRef = this.ngbModal.open(
      EditBadgeSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    (modalRef.componentInstance as EditBadgeSectionComponent).addSectionOrSubSection();
    (modalRef.componentInstance as EditBadgeSectionComponent).handleSection();
    modalRef.result.then((newSectionName) => {
      onCompletion(newSectionName);
    }).catch();
  }

  openEditSectionModal(
    sectionName: string,
    badgeSections: SelectableBadgeSection[],
    onCompletion?: (newSectionName: string) => void
  ): void {
    const modalRef = this.ngbModal.open(
      EditBadgeSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    (modalRef.componentInstance as EditBadgeSectionComponent).addSectionOrSubSection();
    (modalRef.componentInstance as EditBadgeSectionComponent).setSectionInformation([sectionName, '']);
    (modalRef.componentInstance as EditBadgeSectionComponent).setSelectableBadgeSections(badgeSections);
    modalRef.result.then((newSubSectionName) => {
      onCompletion(newSubSectionName);
    }).catch();
  }

  openGenericConfirmationModal(
    confirmationOpts: ConfirmationOptions,
    onConfirmation?: (confirmed: boolean) => void
  ): void {
    const modalRef = this.ngbModal.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(confirmationOpts);
    modalRef.result.then((cont) => onConfirmation?.(cont)).catch();
  }

  openTooltipInfoModal(
    tooltipModalInfoItems: InformationItem[],
    title: string = 'More Information'
  ) {
    const modalRef = this.ngbModal.open(
      InformationModalComponent,
      ModalUtils.informationModalOptions()
    );
    const compInstance = modalRef.componentInstance as InformationModalComponent;
    compInstance.title = title;
    compInstance.informationItems = tooltipModalInfoItems;
    modalRef.result.then().catch();
  }

  openSmartSyncLocationSelectModal(
    company: Company,
    requestType: CompanySmartSyncRequestType,
    onClose?: (companyId: number, locationIds: string[]) => void
  ) {
    const modalRef = this.ngbModal.open(
      SmartSyncLocationSelectModalComponent,
      ModalUtils.smartSyncLocationSelectModalOptions()
    );
    const compInstance = modalRef.componentInstance as SmartSyncLocationSelectModalComponent;
    compInstance.setCompanyAndRequestType(company, requestType);
    modalRef.result.then((locationIds) => {
      if (exists(locationIds)) {
        onClose(company?.id, locationIds);
      }
    }).catch();
  }

  openReviewSyncJobModal(job: SyncDataJob) {
    const modalRef = this.ngbModal.open(
      ReviewSyncJobModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as ReviewSyncJobModalComponent;
    compInstance.syncDataJob = job;
    modalRef.result.then().catch();
  }

  openNewSyncJobModal() {
    const modalRef = this.ngbModal.open(
      NewSyncJobModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then().catch();
  }

  openProviderSpecificationsModal() {
    const modalRef = this.ngbModal.open(
      ProviderSpecificationsModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then().catch();
  }

  openEditUsePurposeModal(
    location: Location,
    onCompletion: (location: Location) => void
  ): void {
    const modalRef = this.ngbModal.open(
      EditLocationUsePurposeComponent,
      ModalUtils.defaultModalOptions()
    );
    (modalRef.componentInstance as EditLocationUsePurposeComponent).location = location;
    modalRef.result.then((updatedLocation) => {
      if (exists(onCompletion)) {
        onCompletion(updatedLocation);
      }
    }).catch();
  }

  openCreateSandboxDataModal(
    companyId: number
  ): void {
    const modalRef = this.ngbModal.open(
      PopulateSandboxDataModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as PopulateSandboxDataModalComponent;
    compInstance.createPopulateSandboxRequest(companyId);
    modalRef.result.then().catch();
  }

  openCreateDisplayAttributeGroupDetailsModal(
    displayAttributeGroup: DisplayAttributeGroup,
    liveVariantBarcodesList: Map<string, string[]>,
    onClose?: (created: DisplayAttributeGroupDetails) => void
  ): void {
    const modalRef = this.ngbModal.open(
      CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent;
    compInstance.viewModel.connectToLiveVariantBarcodes(liveVariantBarcodesList);
    compInstance.viewModel.connectToDisplayAttributeGroupId(displayAttributeGroup?.id);
    compInstance.viewModel.connectToUniversalBudSenseVariants(displayAttributeGroup?.variants);
    modalRef.result.then((created) => onClose?.(created)).catch();
  }

  updateVariantsInDisplayAttributeGroupDetails(
    details: DisplayAttributeGroupDetails,
    liveVariantBarcodesList: Map<string, string[]>,
    currentVariantBarcodes: string[],
    universalBudSenseVariants: Variant[],
    onClose?: (variantBarcodes: string[]) => void
  ): void {
    const modalRef = this.ngbModal.open(
      CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent;
    compInstance.viewModel.connectToModalMode('update');
    compInstance.viewModel.connectToEditThisDetailsId(details?.id);
    compInstance.viewModel.connectToGroupDetailsName(details?.groupName);
    compInstance.viewModel.connectToLiveVariantBarcodes(liveVariantBarcodesList);
    compInstance.viewModel.connectToCurrentVariantBarcodes(currentVariantBarcodes);
    compInstance.viewModel.connectToUniversalBudSenseVariants(universalBudSenseVariants);
    modalRef.result.then((updateVariantBarcodes) => onClose?.(updateVariantBarcodes)).catch();
  }

  openSnoozeAssetGroupModal(
    displayAttributeGroup: DisplayAttributeGroup,
    assetGroup: AssetGroup,
    onClose?: (ags: AssetGroup) => void
  ): void {
    const modalRef = this.ngbModal.open(SnoozeAssetGroupModalComponent, ModalUtils.defaultModalOptions());
    const compInstance = modalRef.componentInstance as SnoozeAssetGroupModalComponent;
    compInstance.viewModel.connectToDisplayAttributeGroup(displayAttributeGroup);
    compInstance.viewModel.connectToAssetGroup(assetGroup);
    modalRef.result.then((updatedAssetGroup) => exists(updatedAssetGroup) && onClose?.(updatedAssetGroup)).catch();
  }

}
