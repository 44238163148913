import { Pipe, PipeTransform } from '@angular/core';
import type { Theme } from '../../../../models/menu/dto/theme';
import type { Asset } from '../../../../models/image/dto/asset';

@Pipe({
  name: 'firstLandscapePreviewImage'
})
export class FirstLandscapePreviewImagePipe implements PipeTransform {

  transform(theme: Theme): Asset | null {
    return theme?.firstLandscapePreviewImage() ?? null;
  }

}
