import { TypeDefinition } from './type-definition';

export enum SectionSortOption {
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  CBDAsc = 'CBD_ASC',
  CBDDesc = 'CBD_DESC',
  StrainTypeAsc = 'CLASSIFICATION_ASC', // Leave underlying for backwards compatability
  StrainTypeDesc = 'CLASSIFICATION_DESC', // Leave underlying for backwards compatability
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  PackagedQuantityAsc = 'PACKAGED_QUANTITY_ASC',
  PackagedQuantityDesc = 'PACKAGED_QUANTITY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  SecondaryPriceAsc = 'SECONDARY_PRICE_ASC',
  SecondaryPriceDesc = 'SECONDARY_PRICE_DESC',
  StockAsc = 'STOCK_ASC',
  StockDesc = 'STOCK_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  THCAsc = 'THC_ASC',
  THCDesc = 'THC_DESC',
  UnitSizeAsc = 'UNIT_SIZE_ASC',
  UnitSizeDesc = 'UNIT_SIZE_DESC',
}

export class SectionSortType extends TypeDefinition {

  override value: SectionSortOption;

}
