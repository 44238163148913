import { TypeDefinition } from './type-definition';
import { SectionColumnConfigDataValue } from '../models/enum/dto/section-column-config-data-value';

export class SectionColumnConfigDataValueType extends TypeDefinition {

  override value: SectionColumnConfigDataValue;

  public getSelectionInformationDescription(): string {
    return this.description;
  }

}
