import { Deserializable } from './deserializable';
import { Type } from '@angular/core';
import { exists } from '../../functions/exists';

/**
 * Don't import this class directly, use window?.injector?.Deserialize to access the static methods of this class.
 * Accessing directly will cause circular dependency warnings.
 */
export class InjectedDeserializer {

  /**
   * If ObjectType implements getPolymorphicDeserializationKey, then this method will return a polymorphic object.
   * Example:
   * const Deserialize = window?.injector?.Deserialize;
   * Deserialize?.instanceOf(Label, saleSystemLabelData), will return an object of type SaleSystemLabel and not Label.
   */
  static instanceOf<T extends Deserializable>(ObjectType: Type<T>, data: any): T {
    if (!data) {
      return null;
    } else {
      const instance: T = Object.assign(Object.create(ObjectType.prototype), data);
      instance?.onDeserialize();
      return instance as T;
    }
  }

  static arrayOf<T extends Deserializable>(ObjectType: Type<T>, data: any[]): T[] {
    if (!data) {
      return null;
    } else {
      const arr: T[] = [];
      data.forEach(d => {
        const inst: T = window?.injector?.Deserialize?.instanceOf(ObjectType, d);
        if (exists(inst)) arr.push(inst);
      });
      return arr;
    }
  }

  static mapOf<X extends string | number, Y extends Deserializable>(
    ObjectType: Type<Y>,
    data: Map<X, Y>
  ): Map<X, Y> {
    if (!data) {
      return new Map();
    } else {
      const newMap = new Map<X, Y>();
      const dataMap: Map<any, any> = data instanceof Map ? data : new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => newMap.set(key as X, this.instanceOf(ObjectType, val)));
      }
      return newMap;
    }
  }

  static genericMap<X extends string | number, Y>(data: Map<X, Y>): Map<X, Y> {
    if (!data) {
      return null;
    } else {
      const dataMap: Map<any, any> = data instanceof Map ? data : new Map(Object.entries(data));
      return dataMap.deepCopy();
    }
  }

  static genericArrayMap<X extends string | number, Y extends string | number>(data: Map<X, Y[]>): Map<X, Y[]> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      const dataMap = data instanceof Map ? data : new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => newMap.set(key as X, val as Y[]));
      }
      return newMap;
    }
  }

  static typedArrayMapOf<X extends string | number, Y extends Deserializable>(
    ObjectType: Type<Y>,
    data: Map<X, Y[]>
  ): Map<X, Y[]> {
    if (!data) {
      return null;
    } else {
      const newMap = new Map<X, Y[]>();
      const dataMap = data instanceof Map ? data : new Map(Object.entries(data));
      if (dataMap instanceof Map) {
        dataMap.forEach((val, key) => newMap.set(key as X, this.arrayOf(ObjectType, val)));
      }
      return newMap;
    }
  }

}
