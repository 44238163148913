import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { DisplayAttributeGroup } from '../../../../models/product/dto/display-attribute-group';
import * as moment from 'moment';
import { exists } from '../../../../functions/exists';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsError } from '../../../../models/shared/bs-error';
import { ToastService } from '../../../../services/toast-service';

type RadioChoice = 30 | 60 | 90 | 'custom' | null;

@Injectable()
export class SnoozeAssetGroupModalViewModel extends BaseModalViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private readonly _displayAttributeGroup = new BehaviorSubject<DisplayAttributeGroup|null>(null);
  public readonly displayAttributeGroup$ = this._displayAttributeGroup.pipe(
    map(displayAttributeGroup => window.injector.Deserialize.instanceOf(DisplayAttributeGroup, displayAttributeGroup)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToDisplayAttributeGroup = (x: DisplayAttributeGroup) => this._displayAttributeGroup.next(x);

  public readonly groupName$ = this.displayAttributeGroup$.pipe(map(dag => dag?.groupName));

  private readonly _assetGroup = new BehaviorSubject<AssetGroup|null>(null);
  public readonly assetGroup$ = this._assetGroup.pipe(
    map(assetGroup => window.injector.Deserialize.instanceOf(AssetGroup, assetGroup)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToAssetGroup = (x: AssetGroup) => this._assetGroup.next(x);

  public readonly previousSnoozedDate$ = this.assetGroup$.pipe(
    map(assetGroup => {
      return exists(assetGroup?.snoozeUntil) && moment(assetGroup?.snoozeUntil).isBefore(moment())
        ? null
        : assetGroup?.snoozeUntil;
    })
  );

  private readonly _radioChoice = new BehaviorSubject<RadioChoice>(null);
  public readonly radioChoice$ = this._radioChoice as Observable<RadioChoice>;
  connectToRadioChoice = (x: RadioChoice) => this._radioChoice.next(x);

  private readonly _customDate = new BehaviorSubject<string|null>(null);
  public readonly customDate$ = this._customDate as Observable<string|null>;
  connectToCustomDate = (x: string) => this._customDate.next(x);

  public readonly customChoiceSelected$ = this.radioChoice$.pipe(map(x => x === 'custom'));

  formSubmitted(): void {
    this.snoozeAssetGroup();
  }

  private snoozeAssetGroup(): void {
    combineLatest([
      this.displayAttributeGroup$,
      this.assetGroup$,
      this.radioChoice$,
      this.customDate$
    ]).once(([displayAttributeGroup, assetGroup, choice, customDate]) => {
      const assetGroupCopy = window.injector.Deserialize.instanceOf(AssetGroup, assetGroup);
      assetGroupCopy.snoozeUntil = this.getSnoozeTimestamp(choice, customDate);
      const lm = 'Snoozing Asset Group';
      this._loadingOpts.addRequest(lm);
      this.productDomainModel.updateAssetGroup(assetGroupCopy).subscribe({
        next: () => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(
            `Successfully snoozed ${displayAttributeGroup?.groupName}`,
            'Success'
          );
          this.activeModal.close(assetGroupCopy);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
        }
      });
    });
  }

  /**
   * Moment timestamps are in milliseconds
   */
  private getSnoozeTimestamp(choice: RadioChoice, customDate: string): number {
    switch (choice) {
      case 30:
        return moment().add(30, 'days').valueOf();
      case 60:
        return moment().add(60, 'days').valueOf();
      case 90:
        return moment().add(90, 'days').valueOf();
      case 'custom':
        return moment(customDate, 'YYYY-MM-DD').valueOf();
    }
  }

}
