import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAssetGroupModalViewModel } from './create-asset-group-modal-view-model';
import { Variant } from '../../../../models/product/dto/variant';

@Component({
  selector: 'app-create-asset-group-modal',
  templateUrl: './create-asset-group-modal.component.html',
  styleUrls: ['./create-asset-group-modal.component.scss'],
  providers: [CreateAssetGroupModalViewModel]
})
export class CreateAssetGroupModalComponent extends BaseModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: CreateAssetGroupModalViewModel,
  ) {
    super(activeModal);
  }

  setDisplayAttributeGroupId(daGroupId: string) {
    this.viewModel.displayAttributeGroupId = daGroupId;
  }

  setUnassignedVariants(unassignedVariants: Variant[]) {
    this.viewModel?.connectToUnassignedVariants(unassignedVariants);
  }

  setSelectedVariants(barcodes: string[]) {
    this.viewModel?.setSelectedVariants(barcodes);
  }

}
