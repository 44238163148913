import { Component, ViewChild } from '@angular/core';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { SignInRequest } from '../../../../../models/account/requests/sign-in-request';
import { AuthFlowTitle } from '../../../../../models/account/enum/auth-flow.enum';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ReactiveFormEmailComponent, ReactiveFormPasswordComponent } from '@mobilefirstdev/reactive-form';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [
    './sign-in.component.scss',
    '../auth.component.scss'
  ]
})
export class SignInComponent extends BaseComponent {

  @ViewChild(ReactiveFormEmailComponent) emailComponent: ReactiveFormEmailComponent;
  @ViewChild(ReactiveFormPasswordComponent) passwordComponent: ReactiveFormPasswordComponent;
  private deleteEmailAstrixMutationObserver: MutationObserver;
  private deletePasswordAstrixMutationObserver: MutationObserver;
  // View
  private _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public readonly req$ = combineLatest([
    this.viewModel.preFillEmail$,
    this.viewModel.preFillPassword$
  ]).pipe(
    map(([email, password]) => new SignInRequest(email, password))
  );
  private deleteMutationConfig = { attributes: false, childList: true, subtree: true };

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    super();
  }

  private deleteAstrixBlock = (div: HTMLDivElement): void => {
    const deleteMe = div?.getElementsByClassName('reactive-form-required-star');
    for (const astrix of deleteMe[Symbol.iterator]()) { astrix.remove(); }
  };

  override setupBindings() {
    this.bindUrlParams();
    this.deleteEmailAstrix();
    this.deletePasswordAstrix();
  }

  bindUrlParams() {
  }

  getAuthFlowTitle(): string {
    return AuthFlowTitle(this.viewModel.authFlow);
  }

  // View Actions

  formSubmitted(req: SignInRequest) {
    const loadingMess = 'Signing In';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.signIn(req).subscribe({
        next: (_) => {
          this._loadingOpts.removeRequest(loadingMess);
        },
        error: (err: BsError) => {
          this._loadingOpts.removeRequest(loadingMess);
          this.toastService.publishError(err);
          throwError(() => err);
        }
      });
    }
  }

  secondaryButtonPressed() {
    const tempEmail = this.emailComponent?.getMyValue();
    this.router.navigate(['auth/forgot-password'], { queryParams: { email: tempEmail } }).then();
  }

  private deleteEmailAstrix(): void {
    const emailDiv = this.emailComponent.getNativeElement() as HTMLDivElement;
    if (!!emailDiv) {
      const callback = () => this.deleteAstrixBlock(emailDiv);
      this.deleteEmailAstrixMutationObserver = new MutationObserver(callback);
      this.deleteEmailAstrixMutationObserver.observe(emailDiv, this.deleteMutationConfig);
    }
  }

  private deletePasswordAstrix(): void {
    const passwordDiv = this.passwordComponent.getNativeElement() as HTMLDivElement;
    if (!!passwordDiv) {
      const callback = () => this.deleteAstrixBlock(passwordDiv);
      this.deletePasswordAstrixMutationObserver = new MutationObserver(callback);
      this.deletePasswordAstrixMutationObserver.observe(passwordDiv, this.deleteMutationConfig);
    }
  }

  override destroy() {
    super.destroy();
    this.deleteEmailAstrixMutationObserver?.disconnect();
    this.deletePasswordAstrixMutationObserver?.disconnect();
  }

}
