<div
  class="bs-asset-preview-container cardClass"
  [ngbTooltip]="card?.data?.tooltip?.length > 1 ? duplicateInformation : ''"
  [class.disabled-asset-preview-container]="card.data?.isDisabled"
  (click)="handleCardPressed()">
  <div
    class="card bs-card bs-asset-preview-card"
    [ngClass]="{
      'bs-alternate-brand-asset': card.label === 'Alternate Brand',
      'bs-custom-group-asset-preview-card ': !!card.data?.showUploadInfo || !!card.data?.isBrand,
      'bs-stacked-asset-preview-card ': card?.data?.tooltip?.length > 1
    }">
    <app-asset
      class="asset-preview-image"
      [asset]="card.asset"
      [styleOverrides]="imgStyleOverrides"
      [scaleFit]="true"></app-asset>

    <div *ngIf="!!card.label" class="asset-library-type">{{ card.label }}</div>
    <div *ngIf="!card.label" class="asset-library-no-type">
      <img src="assets/icons/red/outline/exclamation-circle.svg" alt="" />
    </div>
  </div>

  <div *ngIf="card.data?.showUploadInfo" class="asset-upload-info">
    <img *ngIf="card.data?.isLinkedAsset" src="assets/icons/dark/solid/link.svg" alt="" />
    <img *ngIf="!card.data?.isLinkedAsset" src="assets/icons/dark/solid/upload.svg" alt="" />
    {{ card.text }}
  </div>
</div>

<ng-template #duplicateInformation>
  <div *ngFor="let tooltipEntry of card?.data?.tooltip">
    <img *ngIf="tooltipEntry.isLinked" src="assets/icons/light/solid/link.svg" alt="" />
    <img *ngIf="!tooltipEntry.isLinked" src="assets/icons/light/solid/upload.svg" alt="" />
    {{ tooltipEntry.text }}
  </div>
</ng-template>
