import { Label } from '../../../models/shared/label';
import { DisplayLabelInterface } from '../label/display-label-interface';

export class LabelUtils {

  static getDisplayLabelInterfaceForLabelPicker(
    label: Label,
    isClearable: boolean,
  ): DisplayLabelInterface {
    return new class implements DisplayLabelInterface {

      getLabelsForLabelComponent = () => [label];
      getClearableForLabelComponent = () => isClearable;
      overrideLabelTextIfDefined = () => undefined;

}();
  }

}
