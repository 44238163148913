<div class="info-banner" fxLayout="row">
  <img
    class="info-icon"
    fxFlexAlign="center"
    [src]="'assets/icons/light/solid/information-circle.svg'"
    alt="information-circle.svg" />
  <div class="info-text" fxFlex fxFlexAlign="center">
    <ng-container *ngIf="!!message; else content">{{ message }}</ng-container>
    <ng-template #content><ng-content></ng-content></ng-template>
  </div>
</div>
