import { Pipe, PipeTransform } from '@angular/core';
import { Display } from '../../../models/display/dto/display';
import { Orientation } from '../../../utils/orientation-type';

@Pipe({
  name: 'displayOrientation',
  pure: false
})

export class DisplayOrientationPipe implements PipeTransform {

  transform(items: Display[], orientation: string): any {
    if (!items && ! orientation) {
      return items;
    }
    if (orientation === 'landscape') {
      return items.filter(item => item.displaySize.orientation === Orientation.Landscape);
    } else if (orientation === 'portrait') {
      return items.filter(item => {
        return (item.displaySize.orientation === Orientation.Portrait) ||
          (item.displaySize.orientation === Orientation.ReversePortrait);
      });
    }
  }

}
