<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Assign UBV to Asset Group</div>
      <div class="modal-subtitle">
        {{ viewModel?.variantName$ | async }} {{ viewModel?.variantToAssign$ | async | variantFormattedSizing }}
      </div>
    </div>
  </div>

  <div class="sticky-body-container assign-variant-to-group-form-container">
    <ng-container *ngFor="let assetGroup of viewModel?.assetGroups$ | async">
      <app-assign-to-asset-group-button
        [assetGroup]="assetGroup"
        [selectedId]="viewModel?.selectedAssetGroupId$ | async"
        (selectionChanged)="viewModel?.changeSelectedAssetGroup($event)"></app-assign-to-asset-group-button>
    </ng-container>
  </div>

  <div class="sticky-footer-container assign-variant-footer">
    <div>
      <button class="bs-button text-button create-new-group-button" (click)="viewModel?.openCreateAssetGroupModal()">
        Create Group
        <img src="assets/icons/dark/outline/plus.svg" alt="" />
      </button>
    </div>

    <div class="assignment-action-button-container">
      <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
      <button
        class="bs-button preferred-button"
        (click)="viewModel?.updateAssetGroup()"
        [disabled]="!(viewModel?.canSubmit$ | async)"
        [ngClass]="{ disabled: !(viewModel?.canSubmit$ | async) }">
        Done
      </button>
    </div>
  </div>
</div>
