import { DefaultStackedSizeType } from './default-stacked-size-type';

export enum DefaultPrintLabelSize {
  DefaultSize_CustomLabel2x4 = 'DefaultSize_CustomLabel2x4'
}

export class DefaultPrintLabelSizeType extends DefaultStackedSizeType {

  override value : DefaultPrintLabelSize;

}
