import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Injectable()
export class SmartFilterLineItemViewModel extends BaseViewModel {

  constructor(
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private userDomainModel: UserDomainModel
  ) {
    super();
  }

  public loadingOpts = LoadingOptions.defaultWhiteBackground();

  private _smartFilterId = new BehaviorSubject<string>(null);

  public smartFilter$ = combineLatest([
    this._smartFilterId,
    this.smartFiltersDomainModel.curatedSmartFilters$
  ]).pipe(
    map(([id, filters]) => filters?.find(filter => filter?.id === id))
  );

  public smartFilterTitle$ = this.smartFilter$.pipe(map(filter => filter?.name));
  public smartFilterVariantCount$ = this.smartFilter$.pipe(
    map((filter) => {
      const singularOrPluralSuffix = filter?.appliedVariantIds?.length && filter.appliedVariantIds.length === 1
        ? 'Variant'
        : 'Variants';
      return `${filter?.appliedVariantIds?.length} ${singularOrPluralSuffix}`;
    })
  );

  /**
   * If the smart filter is curated, the user is not an admin the user should be restricted 'View Only'
   */
  public editViewButtonText$ = combineLatest([
    this.smartFilter$,
    this.userDomainModel.user$,
    this.userDomainModel.userSession$
  ]).pipe(
    map(([sf, user, sess]) => {
      const curatedSF = sf?.locationId === -1;
      const isAdminUser = user?.isCompanyAdmin || false;
      if (curatedSF || !isAdminUser) return 'View';
      return 'Edit';
    })
  );

  setSmartFilterId(smartFilterId: string) {
    this._smartFilterId.next(smartFilterId);
  }

}
