export enum NavItemId {
  Dashboard,
  Create,
  Customers,
  Products,
  Themes,
  Defaults,
  Settings,
  Profile,
  SignOut,
}
