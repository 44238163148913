import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { NavItem } from '../models/nav-item';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavSubItem } from '../models/nav-sub-item';
import { RouteUtils } from '../../../../utils/route-utils';
import { exists } from '../../../../functions/exists';

@Injectable()
export class NavSubItemViewModel extends BaseViewModel {

  private _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());
  private navItem = new BehaviorSubject<NavItem>(null);
  private subItem = new BehaviorSubject<NavSubItem>(null);
  public isNavItemActive$ = combineLatest([
    this.navItem,
    this.subItem,
    this.currentNavUrl$
  ]).pipe(
    map(([nav, sub, url]) => {
      const isCurrentPath = url.indexOf(`${nav?.routerPath}/${sub?.relativeRouterPath}`) === 0;
      const fragment = RouteUtils.getFragmentFromUrl(url);
      if (exists(nav) && exists(sub) && isCurrentPath) {
        nav?.saveSubPosition(sub, fragment);
        sub?.saveSubFragment(fragment);
      }
      return isCurrentPath;
    }),
  );

  constructor(
    protected router: Router,
  ) {
    super();
  }

  connectToNavItem(item: NavItem) {
    this.navItem.next(item);
  }

  connectToSubNavItem(item: NavSubItem) {
    this.subItem.next(item);
  }

  connectToCurrentNavUrl(url: string) {
    this._currentNavUrl.next(url);
  }

}
