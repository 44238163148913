import { Asset } from '../models/image/dto/asset';
import { Variant } from '../models/product/dto/variant';
import { AssetGroup } from '../models/product/dto/asset-group';
import { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import { exists } from '../functions/exists';

export class AssetGroupUtils {

  static createAssetUniqueId(asset: Asset): string {
    return asset?.id + ':' + asset?.md5Hash;
  }

  static isAssetDisabled(asset: Asset, disabledAssets: string[]): boolean {
    const disabledAssetUniqueId = AssetGroupUtils.createAssetUniqueId(asset);
    const disabledAsset = disabledAssets?.find(da => da === disabledAssetUniqueId);
    return exists(disabledAsset);
  }

  static getAssetUniqueIdComponents(assetUniqueId: string): string[] {
    return assetUniqueId?.split(':') || [null, null];
  }

  static findAssetWithIdHash(assetUniqueId: string, assets: Asset[]): Asset {
    const [id, md5Hash] = AssetGroupUtils.getAssetUniqueIdComponents(assetUniqueId);
    return assets?.find(a => a.id === id && a.md5Hash === md5Hash);
  }

  static findVariantForAsset(asset: Asset, variants: Variant[]): Variant {
    return variants?.find(v => v?.barcode === asset?.id);
  }

  static findVariantIndexForAsset(asset: Asset, variants: Variant[]): number {
    if (!variants || variants.length < 1) { return -1; }
    return variants?.findIndex(v => v?.barcode === asset?.id);
  }

  static getUnassignedVariantsCount(gdag: DisplayAttributeGroup): number {
    const assignedVariants = gdag?.variants
      ?.filter(variant => exists(variant?.assetGroupId) && variant?.assetGroupId !== variant?.displayAttributeGroupId);
    return gdag?.variants?.length - assignedVariants?.length || 0;
  }

  static getUnassignedAssetTypesCountForSingleGroup(ag: AssetGroup): number {
    const assetCount = ag?.assets?.length;
    const disabledAssetCount = ag?.disabledAssets?.length || 0;

    let typedAssetCount = 0;
    ag?.assetTypeMap?.forEach((assetIds, type) => {
      typedAssetCount += assetIds?.length || 0;
    });
    return assetCount - disabledAssetCount - typedAssetCount || 0;
  }

  static getUnassignedAssetTypesCount(ags: AssetGroup[]): number {
    const allAssetsCounts = ags?.map(ag => ag?.assets?.length || 0);
    const assetCount = allAssetsCounts?.reduce((accum, assetCountForGroup) => accum += assetCountForGroup, 0);

    const allDisabledAssetsCount = ags?.map(ag => ag?.disabledAssets?.length || 0);
    const disabledAssetCount = allDisabledAssetsCount?.reduce(
      (accum, numOfDisabledAssets) => accum += numOfDisabledAssets,
      0
    );

    let typedAssetCount = 0;
    ags?.forEach(ag => {
      ag?.assetTypeMap?.forEach((assetIds, type) => {
        typedAssetCount += assetIds.length;
      });
    });
    return assetCount - disabledAssetCount - typedAssetCount;
  }

}
