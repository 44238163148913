<lib-reactive-form-merge-groups
  #assetLibraryForm
  [mergeKey]="'print-card-previews'"
  (canSubmit$)="viewModel?.setCanSubmit($event)"
  (formsSubmitted$)="viewModel.updateCardSizePreviews($event)"></lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header border-0">
      <div class="modal-title">Update Print Card Previews</div>
      <div class="modal-subtitle">
        {{ viewModel.themeName$ | async }}
      </div>
    </div>

    <div class="sticky-body-container">
      <ng-container *ngFor="let preview of viewModel.cardSizePreviews$ | async; let previewIndex = index">
        <div class="print-card-preview-container">
          <div>
            <app-asset
              *ngIf="!!preview.existingAsset"
              [asset]="preview?.existingAsset"
              [styleOverrides]="imgStyleOverrides"
              [scaleFit]="true"></app-asset>
            <img *ngIf="!!preview.uploadedImage" class="upload-image" [src]="preview.uploadedImage.url" alt="" />
          </div>
          <div class="print-card-preview-form-container col-9">
            <lib-reactive-form-group [bindTo]="preview" [mergeKey]="'print-card-previews'">
              <lib-reactive-form-drop-down
                [inputName]="'printCardSize' + previewIndex"
                [label]="'Card Size'"
                [placeholder]="'Select print card size to associate with preview'"
                [bindingProperty]="'previewCardSize'"
                [dropdowns]="viewModel.dropdown$ | async">
              </lib-reactive-form-drop-down>
            </lib-reactive-form-group>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(viewModel?.previews$ | async)?.length < 1">
        <div class="no-asset-message">
          No previews yet uploaded for this theme. When you upload some previews for this theme they will appear here.
        </div>
      </ng-container>
    </div>

    <div class="sticky-footer-container modal-footer-flex-end w-100">
      <lib-button-neutral (buttonClicked)="cancel()" [style.padding-right]="'1rem'">Cancel</lib-button-neutral>
      <lib-button-primary (buttonClicked)="assetLibraryForm.submitForms()"> Done </lib-button-primary>
    </div>
  </div>
</div>
