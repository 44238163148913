<div
  class="card bs-card bs-asset-preview-card cardClass"
  [ngClass]="{ 'bs-alternate-brand-asset': card.label === 'Alternate Brand' }"
  [ngbTooltip]="card?.data?.tooltip?.length > 1 && !card?.data?.isBrand ? duplicateInformation : ''"
  [class.disabled-asset-preview-card]="card.data?.isDisabled"
  (click)="handleCardPressed()">
  <app-asset [asset]="card.asset" [styleOverrides]="imgStyleOverrides" [scaleFit]="true"></app-asset>

  <div *ngIf="!card?.data?.isBrand" class="asset-library-type" [class.asset-library-has-type]="!!card.label">
    {{ card.label }}
  </div>
</div>

<ng-template #duplicateInformation>
  <div *ngFor="let tooltipEntry of card?.data?.tooltip">
    <img *ngIf="tooltipEntry.isLinked" src="assets/icons/light/solid/link.svg" alt="" />
    <img *ngIf="!tooltipEntry.isLinked" src="assets/icons/light/solid/upload.svg" alt="" />
    {{ tooltipEntry.text }}
  </div>
</ng-template>
