import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterByCategoryModalViewModel } from './filter-by-category-modal-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { SearchWithLookAheadComponent } from '../../../../shared/components/search-with-look-ahead/search-with-look-ahead.component';
import { SectionBlueprintCategory } from '../../../../../models/menu/dto/section-blueprint-category';

@Component({
  selector: 'app-filter-by-category-modal',
  templateUrl: './filter-by-category-modal.component.html',
  styleUrls: ['./filter-by-category-modal.component.scss'],
  providers: [FilterByCategoryModalViewModel]
})
export class FilterByCategoryModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: FilterByCategoryModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  @ViewChild('categorySearch') categorySearch: SearchWithLookAheadComponent;

  setSelectedCategories(selectedCategories: SectionBlueprintCategory[]) {
    this.viewModel.setSelectedCategories(selectedCategories);
  }

  clearFiltersAndSearch() {
    this.viewModel.clearFilters();
    this.categorySearch.clear();
  }

}
