<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #assetLibraryForm
  [mergeKey]="'asset-library-type-entries'"
  (canSubmit$)="viewModel?.canSubmit$.next($event)"
  (formsSubmitted$)="viewModel.updateAssetLibraryTypes($event)"></lib-reactive-form-merge-groups>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Update Asset Library Types</div>
      <div class="modal-subtitle">{{ viewModel.assetGroupName$ | async }}</div>
    </div>
  </div>

  <div class="sticky-body-container asset-library-container">
    <ng-container *ngFor="let assetEntry of viewModel.assetLibraryEntries$ | async">
      <div class="asset-library-entry-container">
        <div [ngStyle]="{ 'background-color': assetEntry.backgroundColor }">
          <app-asset [asset]="assetEntry.asset" [styleOverrides]="imgStyleOverrides" [scaleFit]="true"></app-asset>
        </div>
        <lib-reactive-form-group
          class="asset-library-form"
          [bindTo]="assetEntry"
          [mergeKey]="'asset-library-type-entries'">
          <lib-reactive-form-drop-down
            [inputName]="assetEntry.uniqueAssetLibraryInputName"
            [label]="'Asset Type'"
            [placeholder]="'Select Asset Library Type'"
            [bindingProperty]="'assetLibraryType'"
            [dropdowns]="viewModel?.libraryTypesDropdowns$ | async"
            [disabled]="!assetEntry.enabled"
            (valueChanged)="viewModel.handleChangeOfAssetLibraryType($event, assetEntry)"></lib-reactive-form-drop-down>

          <lib-reactive-form-column-layout nColumns="1" [reversed]="true">
            <lib-reactive-form-switch
              class="asset-enable-switch"
              [inputName]="assetEntry.uniqueAssetEnableInputName"
              [label]="'Enable Asset'"
              [bindingProperty]="'enabled'"
              (valueChanged)="viewModel.toggleAssetEnable(assetEntry)"></lib-reactive-form-switch>
          </lib-reactive-form-column-layout>
        </lib-reactive-form-group>
      </div>
    </ng-container>
    <ng-container *ngIf="(viewModel?.assetLibraryEntries$ | async)?.length < 1">
      <div class="no-asset-message">
        No assets yet uploaded for this group. When you upload some assets for this group they will appear here.
      </div>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      class="bs-button preferred-button"
      (click)="assetLibraryForm.submitForms()"
      [disabled]="!(viewModel?.canSubmit$ | async)"
      [ngClass]="{ disabled: !(viewModel?.canSubmit$ | async) }">
      Done
    </button>
  </div>
</div>
