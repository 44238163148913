import { NavItemViewModel } from '../nav-item-view-model';
import { Injectable } from '@angular/core';
import { BsError } from '../../../../../models/shared/bs-error';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { throwError } from 'rxjs';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';

@Injectable()
export class SignOutNavItemViewModel extends NavItemViewModel {

  constructor(
    protected userDomainModel: UserDomainModel,
    protected toastService: ToastService,
    router: Router,
  ) {
    super(router);
  }

  signOut() {
    const loadingMess = 'Signing Out';
    this.loadingOpts.addRequest(loadingMess);
    return this.userDomainModel.signOut().subscribe({
      next: (_) => {
        this.loadingOpts.removeRequest(loadingMess);
        this.router.navigate(['/auth/sign-in']).then(() => {});
      },
      error: (err: BsError) => {
        this.loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(err);
        throwError(() => err);
      }
    });
  }

}
