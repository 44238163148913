<lib-reactive-form-group [bindTo]="viewModel?.formColumnConfig$ | push" [mergeKey]="formMergeKey">
  <lib-reactive-form-column-layout nColumns="2">
    <lib-reactive-form-drop-down
      #columnDefaultState
      [inputName]="(viewModel?.columnSectionKey$ | push) + 'DefaultState'"
      [label]="'Default State'"
      [placeholder]="'Select Default State'"
      [bindingProperty]="'defaultState'"
      [dropdowns]="viewModel?.sectionDefaultStateOptions$ | push"
      [tooltip]="viewModel?.tooltip$ | push"
      [disabled]="viewModel?.defaultStateInputDisabled$ | push"
      [programmaticallyChangeValue]="viewModel?.columnConfigDefaultState$ | push"
      (valueChanged)="changeToDefaultState($event)">
    </lib-reactive-form-drop-down>

    <div [hidden]="viewModel?.hideColumnName$ | push">
      <lib-reactive-form-text
        #columnName
        [inputName]="(viewModel?.columnSectionKey$ | push) + 'ColumnName'"
        [label]="'Column Name'"
        [placeholder]="'Enter a column name'"
        [bindingProperty]="'columnName'"
        [disabled]="viewModel?.inputDisabled$ | push">
      </lib-reactive-form-text>
    </div>

    <lib-reactive-form-drop-down
      #columnDataValue
      *ngIf="viewModel?.showColumnDataOptions$ | push"
      [inputName]="(viewModel?.columnSectionKey$ | push) + 'DataValue'"
      [label]="viewModel?.columnDataOptionsLabel$ | push"
      [placeholder]="'Select Value to Use'"
      [bindingProperty]="'dataValue'"
      [dropdowns]="viewModel?.columnDataOptionsDropdowns$ | push"
      [disabled]="viewModel?.inputDisabled$ | push"
      [tooltipModalTitle]="viewModel?.columnDataOptionsToolTipModalTitle$ | push"
      [tooltipModalInfoItems]="viewModel?.columnDataOptionsToolTipModalItems$ | push">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #columnWidth
      [inputName]="(viewModel?.columnSectionKey$ | push) + 'ColumnWidth'"
      [label]="'Column Width'"
      [placeholder]="'Enter Column Width'"
      [bindingProperty]="'columnWidth'"
      [integersOnly]="true"
      [minValue]="1"
      [maxValue]="75"
      [tooltip]="'Will be validated by the lower and upper bounds set on the theme config'"
      [disabled]="viewModel?.inputDisabled$ | push"
      [required]="viewModel?.widthRequired$ | push"
      (valueChanged)="changeToColumnWidth($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
