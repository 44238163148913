import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'doubleDashIfEmpty'
})
export class DoubleDashIfEmptyPipe implements PipeTransform {

  transform(value: string | number | undefined | null): string {
    if (value === null || value === undefined || value === '' || value < 0) {
      return '--';
    }
    return value.toString();
  }

}
