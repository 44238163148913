import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../../../../../../../../../../models/company/dto/location';

@Pipe({
  name: 'getAddedOrRemovedStatus'
})
export class GetAddedOrRemovedStatusPipe implements PipeTransform {

  transform(location: Location, original: number[], updated: number[]): string {
    return Location.addedOrRemoved(location, original, updated);
  }

}
