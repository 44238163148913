<div class="asset-group-assign-button" (click)="assetGroupClicked()">
  <div>
    <div class="group-name-text">{{ assetGroup?.groupName }}</div>
    <div class="group-variant-count">
      {{ assetGroup?.variants?.length ? assetGroup?.variants?.length : 0 }}
      {{ assetGroup?.variants?.length !== 1 ? 'Variants' : 'Variant' }}
    </div>
  </div>
  <img *ngIf="assetGroup.id !== selectedId" src="assets/icons/dark/outline/plus.svg" alt="" />
  <img *ngIf="assetGroup.id === selectedId" src="assets/icons/dark/outline/check.svg" alt="" />
</div>
