import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModal } from '../../../../models/base/base-modal';
import { UploadAssetComponent } from '../../components/upload-asset/upload-asset.component';
import { ChangeMediaOptions } from '../../../../models/shared/stylesheet/change-media-options';
import { BudsenseFile } from '../../../../models/shared/budsense-file';
import { Observable, throwError } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../services/toast-service';
import { NavigationEnd, Router } from '@angular/router';
import { BsError } from '../../../../models/shared/bs-error';

@Component({
  selector: 'app-change-media-modal',
  templateUrl: './change-media-modal.component.html',
  styleUrls: ['./change-media-modal.component.scss'],
})
export class ChangeMediaModalComponent extends BaseModal implements AfterViewInit {

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;
  @ViewChild('changeMedia') changeMedia: UploadAssetComponent;

  public options: ChangeMediaOptions = new ChangeMediaOptions();
  public changeMediaOperation: (files: BudsenseFile[]) => Observable<any>;
  public assets: BudsenseFile[] = [];

  // Loading
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(
    protected activeModal: NgbActiveModal,
    private toastService: ToastService,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super(activeModal);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  override cancel() {
    this.changeMedia.clear();
    this.activeModal.close();
  }

  override setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  fileList(f: BudsenseFile[], _: number) {
    this.assets = f;
  }

  handleChangeMedia() {
    const lm = this.options.loadingMess;
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.changeMediaOperation(this.assets).subscribe((uploadedFileNames) => {
        if (uploadedFileNames?.length > 0) {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(this.options.successMess, this.options.successTitle);
          this.changeMedia.clear();
          this.activeModal.close(uploadedFileNames);
        } else {
          this.loadingOpts.removeRequest(lm);
          this.toastService.publishErrorMessage(this.options.failureMess, this.options.failureTitle);
        }
      }, (err: BsError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
        throwError(() => err);
      });
    }
  }

}
