<div class="modal-padding">
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-string-number
        [inputName]="'packagedQuantity'"
        [label]="'Packaged Quantity'"
        [placeholder]="'The number of units in a package. (ie: 1, 3, 5)'"
        [inlineLabel]="false"
        [integersOnly]="true"
        [disabled]="viewModel.isViewOnly$ | async"
        [tooltip]="
          'The smart filter will include products that exactly match one of the specified packaged ' +
          'quantities (comma separated)'
        "
        [bindingProperty]="'packagedQuantities'"
        [forceMinValueZeroOrLower]="0"
        [allowCommas]="true"
        [customValueParser]="cleanString">
      </lib-reactive-form-string-number>

      <lib-reactive-form-string-number
        [inputName]="'unitSize'"
        [label]="'Unit Size'"
        [placeholder]="'The size of each unit (ie: 1, 3.5 ,7)'"
        [inlineLabel]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [customValueParser]="cleanString"
        [tooltip]="
          'The smart filter will include products that exactly match one of the specified unit ' +
          'sizes (comma separated)'
        "
        [bindingProperty]="'unitSizes'"
        [forceMinValueZeroOrLower]="0"
        [allowCommas]="true">
      </lib-reactive-form-string-number>

      <lib-reactive-form-text
        [inputName]="'productName'"
        [label]="'Product Name'"
        [placeholder]="'Terms within product name (ie: OG, Kush)'"
        [inlineLabel]="false"
        [maxLength]="1023"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="
          'The smart filter will include products that contain any of the specified search terms in ' +
          'the product name (comma separated)'
        "
        [bindingProperty]="'productName'">
      </lib-reactive-form-text>

      <lib-reactive-form-text
        [inputName]="'brandName'"
        [label]="'Brand Name'"
        [placeholder]="'Brand Names (Wana, Wana Quick)'"
        [inlineLabel]="false"
        [maxLength]="1023"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="
          'The smart filter will include products that exactly match one of the specified brand names ' +
          '(comma separated)'
        "
        [bindingProperty]="'brandName'">
      </lib-reactive-form-text>

      <lib-reactive-form-text
        [inputName]="'strain'"
        [label]="'Strain'"
        [placeholder]="'Strain Names (White Widow, Pink Kush)'"
        [inlineLabel]="false"
        [maxLength]="1023"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="
          'The smart filter will include products that exactly match one of the specified strains ' +
          '(comma separated)'
        "
        [bindingProperty]="'strain'">
      </lib-reactive-form-text>

      <lib-reactive-form-text
        [inputName]="'search'"
        [label]="'General Search Term'"
        [placeholder]="'Search Terms (ie: Full-Spectrum, Pinene)'"
        [inlineLabel]="false"
        [maxLength]="1023"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="
          'The smart filter will include products that contain any of the specified search terms in ' +
          'the products manufacturer, description or terpenes fields (comma separated)'
        "
        [bindingProperty]="'searchTerm'">
      </lib-reactive-form-text>

      <lib-reactive-form-number
        [inputName]="'minInventory'"
        [label]="'Minimum Inventory'"
        [placeholder]="'Minimum inventory of product'"
        [inlineLabel]="false"
        [integersOnly]="true"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="'The smart filter will include products with inventory values greater than or equal to this value.'"
        [bindingProperty]="'minInventory'">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'maxInventory'"
        [label]="'Maximum Inventory'"
        [placeholder]="'Maximum inventory of product'"
        [inlineLabel]="false"
        [integersOnly]="true"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="'The smart filter will include products with inventory values less than or equal to this value.'"
        [bindingProperty]="'maxInventory'">
      </lib-reactive-form-number>
    </lib-reactive-form-column-layout>

    <lib-reactive-form-string-number
      [inputName]="'pricingCode'"
      [label]="'Pricing Code'"
      [placeholder]="'Pricing code of product active price (ie: 0.98, 0.99)'"
      [inlineLabel]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [tooltip]="'The smart filter will include products that exactly match one of the pricing tiers (comma separated)'"
      [bindingProperty]="'pricingCodes'"
      [forceMinValueZeroOrLower]="0"
      [allowCommas]="true"
      [customValueParser]="cleanString">
    </lib-reactive-form-string-number>

    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-switch
        style="align-self: center"
        class="mt-2"
        [label]="'Active Sale'"
        [bindingProperty]="'activeSale'"
        [inputName]="'activeSale'"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="'The smart filter will only include products on sale.'">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        style="align-self: center"
        class="mt-2"
        [label]="'Require Pricing Tier'"
        [bindingProperty]="'requirePricingTier'"
        [inputName]="'pricingTier'"
        [disabled]="viewModel.isViewOnly$ | push"
        [tooltip]="'The smart filter will only include products with pricing tiers.'">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>

  <div class="label-badge-container">
    <app-inline-labels-picker class="mt-3" [bindTo]="bindTo" [mergeKey]="mergeKey"> </app-inline-labels-picker>

    <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
      <div class="mt-3">
        <app-badge-picker-for-form
          [inputName]="'badgePicker'"
          [bindingProperty]="'badgeIds'"
          [badges]="viewModel?.hydratedBadgesOnSmartFilter$ | push"
          [popperPlacement]="ClientTypes.NgxPopperjsPlacements.RIGHT">
        </app-badge-picker-for-form>
      </div>
    </lib-reactive-form-group>
  </div>
</div>
