import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SmartFilterGrouping } from '../../../../models/automation/smart-filter-grouping';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-smart-filter-selection-group',
  templateUrl: './smart-filter-selection-group.component.html',
  styleUrls: ['./smart-filter-selection-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterSelectionGroupComponent extends BaseComponent implements OnChanges {

  @Input() grouping: SmartFilterGrouping;
  @Input() previouslyAddedIds: string[];
  @Input() selectedIds: string[];
  @Output() add = new EventEmitter<string>(true);
  @Output() bulkAdd = new EventEmitter<string[]>(true);
  @Output() remove = new EventEmitter<string>(true);
  @Output() bulkRemove = new EventEmitter<string[]>(true);

  private _grouping = new BehaviorSubject<SmartFilterGrouping>(null);
  public grouping$ = this._grouping.asObservable();
  public name$ = this.grouping$.pipe(map(grp => grp?.getSelectionName()));
  public variantHitCount$ = this.grouping$.pipe(map(grp => grp?.getVariantHits()));
  public variantHitsString$ = this.variantHitCount$.pipe(map(hits => `${hits} Variant${hits === 0 ? '' : 's'}`));
  public children$ = this.grouping$.pipe(map(grouping => grouping?.grouping || []));

  constructor() {
    super();
  }

  override setupViews() {
    this._grouping.next(this.grouping);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grouping) { this._grouping.next(this.grouping); }
  }

}
