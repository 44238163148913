import { TypeDefinition } from './type-definition';

export enum InventoryProvider {
  // POS Integrated
  AllLeaves = 'AllLeaves',
  Blaze = 'Blaze',
  Cova = 'Cova',
  Dutchie = 'Dutchie',
  FlowHub = 'FlowHub',
  Greenline = 'Greenline',
  GrowFlow = 'GrowFlow',
  IndicaOnline = 'IndicaOnline',
  TechPOS = 'TechPOS',
  TendyPOS = 'TendyPOS',
  Treez = 'Treez',
  // Non-integrated
  BudSense = 'BudSense'
}

export class InventoryProviderType extends TypeDefinition {

  override value: InventoryProvider;

}
