import { Type } from '@angular/core';
import { Tabbable } from '../../protocols/tabbable';

export class TabBarItem {

  public active: boolean = false;
  public title: string;
  public iconSrc: string;
  public componentType: Type<Tabbable>;

  constructor(componentType: Type<Tabbable>, title, iconSrc: string, active: boolean = false) {
    this.title = title;
    this.iconSrc = iconSrc;
    this.componentType = componentType;
    this.active = active;
  }

}
