import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NavItem } from './models/nav-item';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { NavItemId } from '../../../models/enum/shared/nav-item-id.enum';
import { DefaultNavItem } from './models/default-nav-item';
import { UniversalVariantsNavSubItem } from './nav-sub-item/models/universal-variants-sub-nav-item';
import { DisplayAttributesNavSubItem } from './nav-sub-item/models/display-attributes-sub-nav-item';
import { DefaultNavSubItem } from './nav-sub-item/models/default-nav-sub-item';
import { UserDomainModel } from '../../../domainModels/user-domain-model';

@Injectable()
export class SidebarNavViewModel extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private router: Router,
  ) {
    super();
    this.bindUrlParams();
  }

  protected override _loadingOpts = new BehaviorSubject(this.getLoadingOptions());
  private readonly user$ = this.userDomainModel.user$;

  protected _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());

  // @ts-ignore
  public readonly versionString$ = of(require('package.json')?.version);
  public readonly navItems$: Observable<NavItem[]> = this.user$.pipe(
    map(user => this.getNavItems(user)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  private bindUrlParams() {
    this._currentNavUrl.next(this.router.url);
    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
    ).subscribeWhileAlive({
      owner: this,
      next: (evt) => this.urlChanged(evt)
    });
  }

  private getLoadingOptions(): LoadingOptions {
    const loadingOpts = LoadingOptions.default();
    loadingOpts.fullscreen = true;
    loadingOpts.zIndex = 100;
    return loadingOpts;
  }

  private getNavItems(user: HydratedAdminUser): NavItem[] {
    const navItems = [];
    const dashboard = this.getDashboardNavItem();
    const create = this.getCreateNavItem();
    const customers = this.getCustomersNavItem();
    const products = this.getProductsNavItem();
    const themes = this.getThemesNavItem();
    const defaults = this.getDefaultsNavItem();
    const settings = this.getSettingsNavItem();
    const profile = this.getProfileNavItem();
    const signOut = this.getSignOutNavItem();

    if (user?.productOnlyAccess) {
      navItems.push(products, profile, signOut);
    } else {
      navItems.push(dashboard, create, customers, products, themes, defaults, settings, profile, signOut);
    }
    return navItems;
  }

  private getDashboardNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/home.svg';
    const filledIcon = 'assets/icons/dark/solid/home.svg';
    return new DefaultNavItem(NavItemId.Dashboard, 'Dashboard', [], '/metrics', nonFilledIcon, filledIcon);
  }

  private getCreateNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/plus-circle.svg';
    const filledIcon = 'assets/icons/dark/solid/plus-circle.svg';
    return new DefaultNavItem(NavItemId.Create, 'Create', [], '/create', nonFilledIcon, filledIcon);
  }

  private getCustomersNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/users.svg';
    const filledIcon = 'assets/icons/dark/solid/users.svg';
    return new DefaultNavItem(NavItemId.Customers, 'Customers', [], '/customers', nonFilledIcon, filledIcon);
  }

  private getProductsNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/view-grid.svg';
    const filledIcon = 'assets/icons/dark/solid/view-grid.svg';
    const universalVariants = new UniversalVariantsNavSubItem('Universal Variants', 'universalvariants');
    const displayAttributes = new DisplayAttributesNavSubItem('Display Attributes', 'displayattributes');
    const assetGroups = new DisplayAttributesNavSubItem('Asset Groups', 'assetgroups');
    const brands = new DefaultNavSubItem('Brands', 'brands');
    const variantLookup = new DefaultNavSubItem('Variant Lookup', 'variantlookup');
    const productSubItems = [universalVariants, displayAttributes, assetGroups, brands, variantLookup];
    return new DefaultNavItem(NavItemId.Products, 'Products', productSubItems, '/products', nonFilledIcon, filledIcon);
  }

  private getThemesNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/template.svg';
    const filledIcon = 'assets/icons/dark/solid/template.svg';
    return new DefaultNavItem(NavItemId.Themes, 'Themes', [], '/themes', nonFilledIcon, filledIcon);
  }

  private getDefaultsNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/lock-closed.svg';
    const filledIcon = 'assets/icons/dark/solid/lock-closed.svg';
    const sections = new DefaultNavSubItem('Sections', 'sections');
    const smartFilters = new DefaultNavSubItem('Smart Filters', 'smartfilters');
    const customization = new DefaultNavSubItem('Customization', 'customization');
    const productSubItems = [sections, smartFilters, customization];
    return new DefaultNavItem(NavItemId.Defaults, 'Defaults', productSubItems, '/defaults', nonFilledIcon, filledIcon);
  }

  private getSettingsNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/cog.svg';
    const filledIcon = 'assets/icons/dark/solid/cog.svg';
    return new DefaultNavItem(NavItemId.Settings, 'Settings', [], '/settings', nonFilledIcon, filledIcon);
  }

  private getProfileNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/user-circle.svg';
    const filledIcon = 'assets/icons/dark/solid/user-circle.svg';
    return new DefaultNavItem(NavItemId.Profile, 'Profile', [], '/account', nonFilledIcon, filledIcon);
  }

  private getSignOutNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/dark/outline/logout.svg';
    const filledIcon = 'assets/icons/dark/solid/logout.svg';
    return new DefaultNavItem(NavItemId.SignOut, 'Sign Out', [], '/sign-out', nonFilledIcon, filledIcon);
  }

  navigateToRoot() {
    this.router.navigate(['']).then(() => {
    });
  }

  private urlChanged(nav: NavigationEnd) {
    this._currentNavUrl.next(nav.url);
  }

}
