import { Component } from '@angular/core';
import { BaseModalComponent } from 'src/app/models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateDisplayAttributeGroupViewModel } from './create-display-attribute-group-view-model';

@Component({
  selector: 'app-create-display-attribute-group-modal',
  templateUrl: './create-display-attribute-group-modal.component.html',
  styleUrls: ['./create-display-attribute-group-modal.component.scss'],
  providers: [CreateDisplayAttributeGroupViewModel]
})
export class CreateDisplayAttributeGroupModalComponent extends BaseModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: CreateDisplayAttributeGroupViewModel
  ) {
    super(activeModal);
  }

}
