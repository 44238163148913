<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-group
  #snoozeAssetGroupForm
  [bindTo]="viewModel.assetGroup$ | push"
  (formSubmitted$)="viewModel.formSubmitted()">
  <div class="modal-body">
    <div appStickyAutoPosition [stickyZ]="2" [stickyCollectionKey]="'snooze-asset-group'">
      <div class="modal-header no-bottom-border">
        <div class="modal-title-container">
          <div class="modal-title">Snooze Asset Group</div>
          <div class="modal-subtitle">{{ viewModel.groupName$ | push }}</div>
        </div>
      </div>
    </div>
    <app-message-banner *ngIf="viewModel.previousSnoozedDate$ | push as previousDate" class="pb-16px">
      This asset group is currently snoozed until {{ previousDate | date : 'MMMM d, YYYY' }}. Setting a new date will
      override this value.
    </app-message-banner>
    <lib-reactive-form-capture-radio-buttons
      [inputName]="'nDaysToSnooze'"
      [label]="'Number of days to snooze'"
      [required]="true"
      (valueChanged)="viewModel.connectToRadioChoice($event)">
      <lib-reactive-form-column-layout [nColumns]="1" [rowGap]="'0.5rem'">
        <lib-reactive-form-radio-button [value]="30">30 days</lib-reactive-form-radio-button>
        <lib-reactive-form-radio-button [value]="60">60 days</lib-reactive-form-radio-button>
        <lib-reactive-form-radio-button [value]="90">90 days</lib-reactive-form-radio-button>
        <lib-reactive-form-radio-button [value]="'custom'">Custom</lib-reactive-form-radio-button>
      </lib-reactive-form-column-layout>
    </lib-reactive-form-capture-radio-buttons>
    <lib-reactive-form-date
      [hidden]="!(viewModel.customChoiceSelected$ | push)"
      [disabled]="!(viewModel.customChoiceSelected$ | push)"
      [inputName]="'customDate'"
      [label]="'Snooze Until'"
      [parseDateAsUtcElseLocal]="false"
      [required]="viewModel.customChoiceSelected$ | push"
      (valueChanged)="viewModel.connectToCustomDate($event)">
    </lib-reactive-form-date>
    <div class="flex-container flex-justify-end gap-16px">
      <lib-button-neutral (buttonClicked)="cancel()">Cancel</lib-button-neutral>
      <lib-button-primary
        [disabled]="snoozeAssetGroupForm.disableSubmit$ | push"
        (buttonClicked)="snoozeAssetGroupForm.submitForm()">
        Snooze
      </lib-button-primary>
    </div>
  </div>
</lib-reactive-form-group>
