<div class="label-picker-container" [style.padding]="'0.5rem 1rem'">
  <div class="label-picker-title">Select labels for Smart Filter</div>
  <div class="label-picker-info-text">
    Products that currently have any of the selected labels applied will be included on the Smart Filter.
  </div>
  <lib-reactive-form-group [bindTo]="bindTo" [mergeKey]="mergeKey">
    <lib-reactive-form-checkboxes
      #formItem
      [bindingProperty]="'labelIds'"
      [inputName]="'labelIds'"
      [hideBulkCheckbox]="true"
      [inlineOptions]="true"
      [customValueParser]="parseLabelIds">
      <div class="divider-block">
        <hr class="radio-divider" />
        <div class="label-picker-subtitle">System Labels</div>
      </div>
      <div class="selection-container" *ngFor="let l of viewModel.systemLabels$ | async">
        <lib-reactive-form-checkbox
          #customLabel
          [inputName]="l?.text"
          [disabled]="(disableCheckboxes$ | async) && !customLabel.isSelected()"
          [selectedValue]="l?.id"
          [programmaticallyChangeValue]="(selectedLabelIds$ | async).contains(l?.id)"
          [notSelectedValue]="null"
          (clicked)="checkboxSelected(l?.id)">
          {{ l?.text }}
        </lib-reactive-form-checkbox>
        <app-label-inflator [zoom]="0.8" [displayLabelInterface]="l | labelInterfaceForLabelPicker : false | async">
        </app-label-inflator>
      </div>
    </lib-reactive-form-checkboxes>
  </lib-reactive-form-group>
</div>
