import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../domainModels/smart-filters-domain-model';

@Injectable()
export class LabelsPickerViewModel extends BaseViewModel {

  constructor(
    private smartFiltersDomainModel: SmartFiltersDomainModel
  ) {
    super();
  }

  public readonly systemLabels$ = this.smartFiltersDomainModel.systemLabels$;

}
