import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePrintCardPreviewsModalViewModel } from './update-print-card-previews-modal-view-model';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { Asset } from '../../../../../../models/image/dto/asset';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { DefaultStackSize } from '../../../../../../utils/default-stack-size';

@Component({
  selector: 'app-update-print-card-previews',
  templateUrl: './update-print-card-previews-modal.component.html',
  styleUrls: ['./update-print-card-previews-modal.component.scss'],
  providers: [UpdatePrintCardPreviewsModalViewModel]
})
export class UpdatePrintCardPreviewsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: UpdatePrintCardPreviewsModalViewModel,
    activeModel: NgbActiveModal,
  ) {
    super(activeModel);
  }

  public imgStyleOverrides = {
    position: 'relative',
    width: '9.5rem',
    height: '9.5rem',
  };

  setPreviews(previews: Asset[]) {
    this.viewModel.connectToPreviews(previews);
  }

  setUploads(uploads: BudsenseFile[]) {
    this.viewModel.connectToUploads(uploads);
  }

  setTheme(theme: Theme) {
    this.viewModel.connectToTheme(theme);
  }

  setUploadCardSizeMap(uploadCardSizeMap: Map<string, DefaultStackSize>) {
    this.viewModel.connectToUploadCardSizeMap(uploadCardSizeMap);
  }

  setCardSizes(cardSizes: string[]) {
    this.viewModel.connectToCardSizes(cardSizes);
  }

}
