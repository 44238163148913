import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Card } from '../../../../../models/shared/stylesheet/card';

@Component({
  selector: 'app-smart-filter-category-preview-card',
  templateUrl: './smart-filter-category-preview-card.component.html',
  styleUrls: ['./smart-filter-category-preview-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartFilterCategoryPreviewCardComponent {

  constructor() {
  }

  @Input() card: Card;
  @Input() cardClass: string;
  @Output() cardPressed:  EventEmitter<Card> = new EventEmitter<Card>();

}
