<div class="badge-picker-container">
  <mat-accordion multi [displayMode]="'flat'">
    <div *ngIf="maximumNumberOfBadges" class="f16px bs-bold">
      Only {{ maximumNumberOfBadges }} badges can be applied.
    </div>
    <ng-container *ngFor="let badgeSection of badgeSectionList$ | async; index as i">
      <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="i === 0">
        <mat-expansion-panel-header>
          <mat-panel-title class="badge-section-title">
            {{ badgeSection.title | titlecase }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="!asListItems" fxLayout="row wrap" fxLayoutGap="1rem">
          <app-badge-within-picker
            *ngFor="let badge of badgeSection.badges"
            [addedBadgeIds]="addedBadgeIds"
            [asListItems]="asListItems"
            [badge]="badge"
            [maximumNumberOfBadges]="maximumNumberOfBadges"
            [preselectedBadgeIds]="preselectedBadgeIds"
            (selected)="selected.emit($event)">
          </app-badge-within-picker>
          <div class="badge-subsection">
            <ng-container *ngFor="let subSection of badgeSection.subSectionBadges | keyvalue">
              <mat-panel-title class="badge-subsection-title">
                {{ subSection.key | titlecase }}
              </mat-panel-title>
              <div fxLayout="row wrap" fxLayoutGap="1rem">
                <app-badge-within-picker
                  *ngFor="let badge of subSection.value"
                  [addedBadgeIds]="addedBadgeIds"
                  [asListItems]="asListItems"
                  [badge]="badge"
                  [maximumNumberOfBadges]="maximumNumberOfBadges"
                  [preselectedBadgeIds]="preselectedBadgeIds"
                  (selected)="selected.emit($event)">
                </app-badge-within-picker>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="asListItems" fxLayout="column">
          <app-variant-badge
            *ngFor="let badge of badgeSection.badges; let last = last"
            appThrottleClick
            matRipple
            class="badge-as-list-variant"
            [addedBadgeIds]="addedBadgeIds"
            [includeTopDivider]="false"
            [includeBottomDivider]="!last"
            (throttleClick)="selected.emit(badge)"
            [showPlusSign]="true"
            [badge]="badge">
          </app-variant-badge>
          <ng-container *ngFor="let subSection of badgeSection.subSectionBadges | keyvalue">
            <mat-panel-title class="badge-subsection-title">
              {{ subSection.key | titlecase }}
            </mat-panel-title>
            <app-variant-badge
              *ngFor="let badge of subSection.value; let last = last"
              appThrottleClick
              matRipple
              class="badge-as-list-variant"
              [addedBadgeIds]="addedBadgeIds"
              [includeTopDivider]="false"
              [includeBottomDivider]="last"
              (throttleClick)="selected.emit(badge)"
              [showPlusSign]="true"
              [badge]="badge">
            </app-variant-badge>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <hr class="no-margin" />
    </ng-container>
  </mat-accordion>
</div>
