export enum UploadFilePath {
  AssetGroupPath = 'AssetGroupPath',
  CompanyLogoPath = 'CompanyLogoPath',
  CompanyAltLogoPath = 'CompanyAltLogoPath',
  VariantBadgePath = 'VariantBadgePath',
  SectionImagePath = 'SectionImagePath',
  ConfigurationBackgroundImagePath = 'ConfigurationBackgroundImagePath',
  DisplayPreviewPath = 'DisplayPreviewPath',
  ConfigurationPreviewPath = 'ConfigurationPreviewPath',
  MarketingImagePath = 'MarketingImagePath',
  MarketingVideoPath = 'MarketingVideoPath',
  ProfilePicturePath = 'ProfilePicturePath',
  SmartFilterImagePath = 'SmartFilterImagePath',
  ThemePreviewPortraitPath = 'ThemePreviewPortraitPath',
  ThemePreviewLandscapePath = 'ThemePreviewLandscapePath',
  VariantLookupPath = 'VariantLookupPath',
}
