import { TypeDefinition } from './type-definition';

export enum VariantProperty {
  Brand = 'Brand',
  Terpenes = 'Terpenes',
  Classification = 'Classification',
  UnitSize = 'UnitSize',
  ProductVariantType = 'ProductVariantType',
  Strain = 'Strain',
  IsMedical = 'IsMedical',
  THC = 'THC',
  MinTHC = 'MinTHC',
  MaxTHC = 'MaxTHC',
  CBD = 'CBD',
  MinCBD = 'MinCBD',
  MaxCBD = 'MaxCBD',
}

export class VariantPropertyType extends TypeDefinition {

  override value: VariantProperty;

}
