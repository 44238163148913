<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #columnOptionsFormGroup
  [mergeKey]="viewModel?.mergeKey"
  (canSubmit$)="viewModel?.connectToFormatIsValid($event)"
  (unsavedChanges$)="viewModel.connectToUnsavedChangesInTab($event)"
  (formsAutoSubmitted$)="viewModel.saveChangesWithinModal()"
  (formsSubmitted$)="viewModel.submitForms()">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky" [style.height.vh]="90">
  <div
    class="sticky-header-container"
    appStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickyZ]="20">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">
        Column Options
        <img
          [ngbTooltip]="viewModel?.message$ | push"
          [container]="'body'"
          [src]="'assets/icons/dark/outline/information-circle.svg'"
          alt="" />
      </div>
    </div>
  </div>

  <div
    #contentContainer
    appStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickChildClassInstead]="'mat-tab-header'"
    [stickyZ]="10"
    class="sticky-body-container column-option-modal-body-container">
    <app-tab-bar
      #tabController
      [tabs]="tabs$ | push"
      [skipFragmentAppending]="true"
      [showLoadingSpinnerOnTabChange]="true"
      [loadingTimeMs]="500"
      [delayTabClick]="100"
      [canChangeTabs]="viewModel.canChangeTabs$ | push"
      [canChangeTabsErrorMsg]="canChangeTabsErrorMsg$ | push"
      (previousTab)="setPreviousTab($event); checkForChanges()"
      (selectedTab)="setCurrentTab($event)"
      class="flex-fill">
    </app-tab-bar>
  </div>

  <div
    class="sticky-footer-container column-option-modal-footer-container"
    [style.position]="viewModel.bottomButtonPosition$ | push">
    <div *ngIf="this.viewModel?.columnsWidthTotalError$ | push" class="error-banner-container">
      <app-error-banner [message]="viewModel?.columnsWidthTotalErrorMessage$ | push"></app-error-banner>
    </div>
    <div class="modal-footer-flex-end button-holder">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        (buttonClicked)="columnOptionsFormGroup.submitForms()"
        [disabled]="!(viewModel?.canSubmitForm$ | push)">
        Done
      </lib-button-primary>
    </div>
  </div>
</div>
