import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionBlueprintCategory } from '../../../../../models/menu/dto/section-blueprint-category';
import { map, switchMap, take } from 'rxjs/operators';
import { BsError } from '../../../../../models/shared/bs-error';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ConfirmationModalComponent } from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ModalUtils } from '../../../../../utils/modal-utils';
import { SectionBlueprint } from '../../../../../models/menu/dto/section-blueprint';
import { TypeDefinition } from '../../../../../utils/type-definition';

@Injectable()
export class EditSectionBlueprintCategoryModalViewModel extends BaseViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private ngbModal: NgbModal,
  ) {
    super();
  }

  public categoryAllowedSectionTypesDropdowns$ = window.types.sectionTypes$.pipe(
    map(sectionTypes => {
      const productMenuTypes = sectionTypes?.filter(st => st?.isProductMenuType());
      const productMenuDropdown = new TypeDefinition(
        productMenuTypes?.map(st => st?.getSelectionValue())?.sort()?.join('-'),
        'Product Menu (Product, Title, Media Sections)'
      );
      const smartPlaylistTypes = sectionTypes?.filter(st => st?.isSmartPlaylistType());
      const smartPlaylistDropdown = new TypeDefinition(
        smartPlaylistTypes?.map(st => st?.getSelectionValue())?.sort()?.join('-'),
        'Smart Playlist Menu (Product Grouping Section)'
      );
      return [productMenuDropdown, smartPlaylistDropdown];
    })
  );

  private _canSubmit = new BehaviorSubject<boolean>(false);
  public canSubmit$ = this._canSubmit as Observable<boolean>;
  connectToCanSubmit = (canSubmit: boolean) => this._canSubmit.next(canSubmit);

  private _hasErrors = new BehaviorSubject<boolean>(false);
  public hasErrors$ = this._hasErrors as Observable<boolean>;
  connectToHasErrors = (hasErrors: boolean) => this._hasErrors.next(hasErrors);

  private _category = new BehaviorSubject<SectionBlueprintCategory>(SectionBlueprintCategory.newCategory());
  public category$ = this._category as Observable<SectionBlueprintCategory>;
  connectToCategory = (category: SectionBlueprintCategory) => this._category.next(category);

  public isEditing$ = this.category$.pipe(map(category => !!category?.id));
  public headerText$ = this.isEditing$.pipe(
    map(isEditing => isEditing ? 'Edit Section Blueprint Category' : 'Create Section Blueprint Category')
  );

  public associatedBlueprints$: Observable<SectionBlueprint[]> = combineLatest([
    this.category$,
    this.menuDomainModel.sectionBlueprints$
  ]).pipe(
    map(([
      category,
      sectionBlueprints
    ]) => {
      return !!category?.id ? sectionBlueprints?.filter(blueprint => blueprint?.categoryId === category?.id) : [];
    })
  );

  public showAssociatedBlueprints$ = combineLatest([
    this.isEditing$,
    this.associatedBlueprints$
  ]).pipe(
    map(([isEditing, associatedBlueprints]) => isEditing && associatedBlueprints?.length > 0)
  );

  public canClickSaveButton$ = combineLatest([
    this.isEditing$,
    this.hasErrors$,
    this.canSubmit$
  ]).pipe(
    map(([
      isEditing,
      hasErrors,
      canSubmit
    ]) => isEditing ? !hasErrors : canSubmit)
  );

  save() {
    this.isEditing$.once(isEditing => {
      if (isEditing) {
        this.updateSectionBlueprintCategory();
      } else {
        this.createSectionBlueprintCategory();
      }
    });
  }

  createSectionBlueprintCategory() {
    const lm = 'Creating New Section Blueprint Category';
    this.category$.pipe(
      switchMap(category => {
        this._loadingOpts.addRequest(lm);
        return this.menuDomainModel.createSectionBlueprintCategory(category);
      }),
      take(1)
    ).subscribe({
      next: (category) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('New Category created', 'Success');
        this.activeModal.close(category);
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  updateSectionBlueprintCategory() {
    const lm = 'Updating Section Blueprint Category';
    this.category$.pipe(
      switchMap(category => {
        this._loadingOpts.addRequest(lm);
        return this.menuDomainModel.updateSectionBlueprintCategory(category);
      }),
      take(1)
    ).subscribe(category => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Category Updated Successfully', 'Success');
      this.activeModal.close(category);
    }, (error: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
    });
  }

  showDeleteBlueprintCategoryModal() {
    // Note - unable to use modalService.openGenericConfirmationModal in this scenario because
    // it causes a circular dependency
    const opts = new ConfirmationOptions();
    opts.title = 'Delete Section Blueprint Category';
    opts.bodyText = `Are you sure you want to delete this blueprint category? All section blueprints associated ` +
      `with this category will become uncategorized.\n\nThis action cannot be undone.`;
    opts.cancelText = 'Cancel';
    opts.continueText = 'Continue';
    const modalRef = this.ngbModal.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) { this.deleteSectionBlueprintCategory(); }
    });
  }

  deleteSectionBlueprintCategory() {
    const lm = 'Deleting Section Blueprint Category';
    this._loadingOpts.addRequest(lm);
    this.category$.pipe(
      switchMap(category => this.menuDomainModel.deleteSectionBlueprintCategory(category)),
      take(1)
    ).subscribe({
      next: () => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Category Deleted Successfully', 'Success');
        this.activeModal.close();
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

}
