import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchWithLookAheadComponent } from '../../components/search-with-look-ahead/search-with-look-ahead.component';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { PrivateCompanyThemeModalViewModel } from './private-company-theme-modal-view-model';

@Component({
  selector: 'app-private-company-theme-modal',
  templateUrl: './private-company-theme-modal.component.html',
  styleUrls: ['./private-company-theme-modal.component.scss'],
  providers: [PrivateCompanyThemeModalViewModel]
})
export class PrivateCompanyThemeModalComponent extends BaseModalComponent {

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: PrivateCompanyThemeModalViewModel
  ) {
    super(activeModal);
  }

  @ViewChild('companySearch') companySearch: SearchWithLookAheadComponent;

  setSelectedCompanyIds(cIds: number[]) {
    this.viewModel.connectToSelectedCompanyIds(cIds);
  }

  setCompaniesUsingTheme(cIds: number[]) {
    this.viewModel.connectToCompaniesUsingTheme(cIds);
  }

  clearFiltersAndSearch() {
    this.viewModel.clearFilters();
    this.companySearch.clear();
  }

}
