import { TypeDefinition } from './type-definition';

export enum CardType {
  Full = 'FullBackgroundImage',
  Half = 'HalfBackgroundImage',
  Embedded = 'EmbeddedImage',
  HeaderImage = 'HeaderImage',
  HeaderImageCarousel = 'HeaderImageCarousel',
  ProductDealCarousel = 'ProductDealCarousel'
}

export class CardTypeDefinition extends TypeDefinition {

  override value: CardType;

  override getSelectionValue(): CardType {
    return this.value;
  }

  isCarouselCardType() {
    return this.value === CardType.HeaderImageCarousel ||
      this.value === CardType.ProductDealCarousel;
  }

  public getCardImageUrl(): string {
    switch (this.value) {
      case CardType.Half:                return 'assets/img/preview/Half-Image-Card.svg';
      case CardType.Embedded:            return 'assets/img/preview/Embedded-Image-Card.svg';
      case CardType.HeaderImage:         return 'assets/img/preview/Header-Image-Card.svg';
      case CardType.HeaderImageCarousel: return 'assets/img/preview/Header-Image-Carousel.svg';
      case CardType.ProductDealCarousel: return 'assets/img/preview/Product-Deal-Carousel.svg';
      case CardType.Full:                return 'assets/img/preview/Full-Image-Card.svg';
    }
    return null;
  }

}
