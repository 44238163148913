<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title-container">
        <div class="modal-title">Populate Sandbox Data</div>
      </div>
    </div>
    <hr />
  </div>

  <div class="sticky-body-container">
    <div class="bs-bold f14px">Sandbox Data to populate</div>
    <div class="bs-regular f12px populate-sandbox-description">
      Choose what information you would like to populate in the account. When populating new data the existing will be
      deleted.
    </div>
    <div class="mt-5">
      <lib-reactive-form-group
        #sandboxDataRequest
        [bindTo]="viewModel.populateSandboxRequest$ | async"
        (formSubmitted$)="viewModel.submitPopulateSandboxDataRequest()">
        <div class="populate-sandbox-data-checkbox">
          <div class="bs-medium f14px">Products</div>
          <lib-reactive-form-checkbox [inputName]="'populateProducts'" [bindingProperty]="'populateSandboxProducts'">
          </lib-reactive-form-checkbox>
        </div>
        <hr />
        <div class="populate-sandbox-data-checkbox">
          <div class="bs-medium f14px">Menus</div>
          <lib-reactive-form-checkbox [inputName]="'populateMenus'" [bindingProperty]="'populateSandboxMenus'">
          </lib-reactive-form-checkbox>
        </div>
        <hr />
        <div class="populate-sandbox-data-checkbox">
          <div class="bs-medium f14px">Displays</div>
          <lib-reactive-form-checkbox [inputName]="'populateDisplays'" [bindingProperty]="'populateSandboxDisplays'">
          </lib-reactive-form-checkbox>
        </div>
        <hr />
        <div class="bs-medium f14px pb-16px">
          Styling <span class="bs-medium-italic f12px styling-optional-text">(optional)</span>
        </div>

        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-color-square
            [inputName]="'menuTitleTextColor'"
            [label]="'Menu Title Text Color'"
            [bindingProperty]="'menuStyling.menuTitleTextColor'"></lib-reactive-form-color-square>

          <lib-reactive-form-color-square
            [inputName]="'menuBodyTextColor'"
            [label]="'Menu Body Text Color'"
            [bindingProperty]="'menuStyling.menuBodyTextColor'"></lib-reactive-form-color-square>

          <lib-reactive-form-color-square
            [inputName]="'sectionHeaderBackgroundColor'"
            [label]="'Section Header Background Color'"
            [bindingProperty]="'menuStyling.sectionHeaderBackgroundColor'"></lib-reactive-form-color-square>

          <lib-reactive-form-color-square
            [inputName]="'sectionHeaderTextColor'"
            [label]="'Section Header Text Color'"
            [bindingProperty]="'menuStyling.sectionHeaderTextColor'"></lib-reactive-form-color-square>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <hr />
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <hr />
    <lib-button-neutral (buttonClicked)="cancel()" class="me-2"> Cancel </lib-button-neutral>
    <lib-button-primary class="ms-2" (buttonClicked)="sandboxDataRequest.submitForm()"> Done </lib-button-primary>
  </div>
</div>
