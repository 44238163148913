import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';
import { NewSyncJobViewModel } from '../new-sync-job-view-model';
import { SortUtils } from '../../../../../../../../../utils/sort-utils';
import { Location } from '../../../../../../../../../models/company/dto/location';

@Component({
  selector: 'app-select-sync-locations',
  templateUrl: './select-sync-locations.component.html',
  styleUrls: ['./select-sync-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSyncLocationsComponent  extends BaseComponent {

  constructor(
    public viewModel: NewSyncJobViewModel
  ) {
    super();
  }

  incompleteLocationsLast = SortUtils.sortSpecifiedStringKeyLast(Location.incompleteLocationGroupName);

}
