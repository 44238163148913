<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="sticky-header-container">
  <div class="modal-header no-bottom-border">
    <div class="modal-title">Add to Display Group</div>
  </div>
</div>

<div class="modal-body">
  <div class="mb-3">
    <app-search-with-look-ahead
      [disablePopper]="true"
      [placeHolder]="'Search by display group name'"
      [nLookAheadItems]="null"
      [lookAheadItems]="viewModel.searchableGdags$ | async"
      [searchTheseProperties]="['displayName', 'groupName']"
      (searchText)="viewModel.searchText($event)"
      (lookAheadHits)="viewModel.searchHits($event)">
    </app-search-with-look-ahead>
  </div>
  <div class="results-container">
    <ng-container *ngIf="(viewModel.displayAttributesToShow$ | async)?.length > 0; else empty_state">
      <ng-container *ngFor="let g of viewModel.displayAttributesToShow$ | async">
        <div class="gdag-row" (click)="viewModel.groupClicked(g)">
          <div class="text-box mt-2">
            <div class="bs-medium f12px">
              {{ g.groupName }}
            </div>
            <div [style.color]="'#9FA2AD'" class="bs-regular f12px mb-2">
              {{ g.displayName }}
            </div>
          </div>
          <div class="icon-container">
            <img alt="right-chevron" class="row-icon" [src]="'assets/icons/dark/outline/chevron-right.svg'" />
          </div>
        </div>
        <hr class="no-margin" />
      </ng-container>
    </ng-container>
  </div>
  <ng-template #empty_state>
    Cannot find any Display Attribute Groups. Trying changing your search query, or click the button below to create
    one.
  </ng-template>
  <div class="ms-2">
    {{ viewModel.displayedGdagCount$ | async }} of {{ viewModel.totalGdagCount$ | async }} Display Groups
  </div>
  <div class="modal-footer-flex-end pt-3">
    <button (click)="viewModel.createNewGroup()" class="bs-button text-button new-button">
      Create New Display Group
    </button>
    <button (click)="close(null)" class="bs-button neutral-button">Cancel</button>
  </div>
</div>
