import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';

@Component({
  selector: 'app-column-style-options-form',
  templateUrl: './column-style-options-form.component.html',
  styleUrls: ['./column-style-options-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnStyleOptionsFormComponent extends BaseComponent implements OnInit, OnChanges {

  constructor(
    public columnOptionsViewModel: ColumnOptionsModalViewModel
  ) {
    super();
  }

  @Input() sectionColumnConfig: SectionColumnConfig;
  @Input() formMergeKey: string = '';
  @Input() columnOptionName: string = '';
  @Input() enabled: boolean;
  @Input() hideTextOptions: boolean = false;
  @Input() hideTextColor: boolean = false;

  public defaultFontStyleOptions$ = window.types.fontStyles$;
  public expanded: boolean = false;

  override ngOnInit(): void {
    super.ngOnInit();
    this.expanded = this.enabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enabled && !this.enabled) {
      this.expanded = false;
    }
  }

  parseIntAsOpacityPercentage = (val: number) => val / 100;

}
