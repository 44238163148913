import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Asset } from '../../../../models/image/dto/asset';
import { AssetSize } from '../../../../models/enum/dto/asset-size.enum';
import { ReplaySubject } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BaseComponent } from '../../../../models/base/base-component';
import { MediaUtils } from '../../../../utils/media-utils';
import { DateUtils } from '../../../../utils/date-utils';
import { AssetPreviewOptions } from '../../../../models/shared/stylesheet/asset-preview-options';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss']
})
export class AssetPreviewComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() asset: Asset;
  @Input() options: AssetPreviewOptions = new AssetPreviewOptions();

  @Output() primaryButtonPressed = new EventEmitter();
  @Output() secondaryButtonPressed = new EventEmitter();
  @Output() tertiaryButtonPressed = new EventEmitter();
  @Output() refreshButtonPressed = new EventEmitter<string>();

  public assetSrc: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);

  constructor() {
    super();
  }

  override ngOnInit(): void {
    this.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupBindings();
  }

  override setupBindings() {
    if (this.asset) {
      const size = MediaUtils.isVideo(this.asset?.mediaType) ? AssetSize.Original : this.options.previewSize;
      const imgSrcSub = this.assetSrc.bind(this.asset?.getAssetUrl(size)?.srcUrl);
      if (imgSrcSub) {
        this.pushSub(imgSrcSub);
      }
    }
  }

  isImage(): boolean {
    return MediaUtils.isImage(this.asset?.mediaType);
  }

  getUploadDate(): string {
    return DateUtils.formatUnixToDateTime(this.asset?.timestamp);
  }

}
