import { TypeDefinition } from './type-definition';

export enum PrintCardProperty {
  Name = 'Name',
  Brand = 'Brand',
  Manufacturer = 'Manufacturer',
  SKU = 'SKU',
  Barcode = 'Barcode',
  Description = 'Description',
  StrainType = 'StrainType',
  UnitSize = 'UnitSize',
  PackageQuantity = 'PackagedQuantity',
  ProductTypeVariantType = 'ProductTypeVariantType',
  Strain = 'Strain',
  IsMedical = 'IsMedical',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
  Label = 'Label',
  Badges = 'Badges',
  THC = 'THC',
  CBD = 'CBD',
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
  TAC = 'TAC',
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
  AlphaBisabolol = 'AlphaBisabolol',
  AlphaPinene = 'AlphaPinene',
  BetaCaryophyllene = 'BetaCaryophyllene',
  BetaEudesmol = 'BetaEudesmol',
  BetaMyrcene = 'BetaMyrcene',
  BetaPinene = 'BetaPinene',
  Bisabolol = 'Bisabolol',
  Borneol = 'Borneol',
  Camphene = 'Camphene',
  CaryophylleneOxide = 'CaryophylleneOxide',
  Delta3Carene = 'Delta3Carene',
  Eucalyptol = 'Eucalyptol',
  Geraniol = 'Geraniol',
  Guaiol = 'Guaiol',
  Humulene = 'Humulene',
  Linalool = 'Linalool',
  Limonene = 'Limonene',
  Ocimene = 'Ocimene',
  ThreeCarene = 'ThreeCarene',
  Terpinene = 'Terpinene',
  Terpinolene = 'Terpinolene',
  TransNerolidol = 'TransNerolidol',
  Valencene = 'Valencene',
}

export class PrintCardPropertyDefinition extends TypeDefinition {

  override value: PrintCardProperty;

  public static getProductPropertyList(): string[] {
    return [
      PrintCardProperty.Name,
      PrintCardProperty.Brand,
      PrintCardProperty.Manufacturer,
      PrintCardProperty.SKU,
      PrintCardProperty.Barcode,
      PrintCardProperty.Description,
      PrintCardProperty.StrainType,
      PrintCardProperty.UnitSize,
      PrintCardProperty.PackageQuantity,
      PrintCardProperty.ProductTypeVariantType,
      PrintCardProperty.Strain,
      PrintCardProperty.IsMedical,
      PrintCardProperty.Price,
      PrintCardProperty.SecondaryPrice,
      PrintCardProperty.Label,
      PrintCardProperty.Badges,
    ];
  }

  public static getSecondaryCannabinoidPropertyList(): string[] {
    return [
      PrintCardProperty.CBDA,
      PrintCardProperty.CBG,
      PrintCardProperty.CBGA,
      PrintCardProperty.CBL,
      PrintCardProperty.CBLA,
      PrintCardProperty.CBN,
      PrintCardProperty.CBNA,
      PrintCardProperty.CBT,
      PrintCardProperty.CBC,
      PrintCardProperty.CBCA,
      PrintCardProperty.CBCV,
      PrintCardProperty.CBDV,
      PrintCardProperty.THC8,
      PrintCardProperty.THC9,
      PrintCardProperty.THCA,
      PrintCardProperty.THCV,
      PrintCardProperty.TAC,
    ];
  }

  public static getIndividualTerpenePropertyList(): string[] {
    return [
      PrintCardProperty.AlphaBisabolol,
      PrintCardProperty.AlphaPinene,
      PrintCardProperty.BetaCaryophyllene,
      PrintCardProperty.BetaEudesmol,
      PrintCardProperty.BetaMyrcene,
      PrintCardProperty.BetaPinene,
      PrintCardProperty.Bisabolol,
      PrintCardProperty.Borneol,
      PrintCardProperty.Camphene,
      PrintCardProperty.CaryophylleneOxide,
      PrintCardProperty.Delta3Carene,
      PrintCardProperty.Eucalyptol,
      PrintCardProperty.Geraniol,
      PrintCardProperty.Guaiol,
      PrintCardProperty.Humulene,
      PrintCardProperty.Linalool,
      PrintCardProperty.Limonene,
      PrintCardProperty.Ocimene,
      PrintCardProperty.ThreeCarene,
      PrintCardProperty.Terpinene,
      PrintCardProperty.Terpinolene,
      PrintCardProperty.TransNerolidol,
      PrintCardProperty.Valencene,
    ];
  }

  public isProductProperty(): boolean {
    const productProperties = PrintCardPropertyDefinition.getProductPropertyList();
    return productProperties.includes(this.value);
  }

  public isPrimaryCannabinoidProperty(): boolean {
    return this.value === PrintCardProperty.CBD
      || this.value === PrintCardProperty.THC;
  }

  public isSecondaryCannabinoidProperty(): boolean {
    const secondaryCannabinoidProperties = PrintCardPropertyDefinition.getSecondaryCannabinoidPropertyList();
    return secondaryCannabinoidProperties.includes(this.value);
  }

  public isCannabinoidProperty(): boolean {
    return this.isPrimaryCannabinoidProperty() || this.isSecondaryCannabinoidProperty();
  }

  public isCollectiveTerpeneProperty(): boolean {
    return this.value === PrintCardProperty.TopTerpene || this.value === PrintCardProperty.TotalTerpene;
  }

  public isIndividualTerpeneProperty(): boolean {
    const terpeneProperties = PrintCardPropertyDefinition.getIndividualTerpenePropertyList();
    return terpeneProperties.contains(this.value);
  }

  public isTerpeneProperty(): boolean {
    return this.isCollectiveTerpeneProperty() || this.isIndividualTerpeneProperty();
  }

}
