import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './views/default/default-layout/default-layout.component';
import { AuthGuard } from './services/guards/auth.guard';
import { PermissionGuard } from './services/guards/permission.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'metrics',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'BudSense'
    },
    children: [
      {
        path: 'metrics',
        loadChildren: () => import('./views/metrics/metrics.module').then(m => m.MetricsModule),
        canActivate: [AuthGuard, PermissionGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'create',
        loadChildren: () => import('./views/create/create.module').then(m => m.CreateModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'themes',
        loadChildren: () => import('./views/themes/themes.module').then(m => m.ThemesModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'defaults',
        loadChildren: () => import('./views/defaults/defaults.module').then(m => m.DefaultsModule),
        canActivate: [AuthGuard, PermissionGuard],
      },

      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
