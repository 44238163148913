import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartFilterSelectionModalViewModel } from './smart-filter-selection-modal-view-model';
import { takeUntil } from 'rxjs/operators';
import { BaseModal } from '../../../../models/base/base-modal';

@Component({
  selector: 'app-smart-filter-selection-modal',
  templateUrl: './smart-filter-selection-modal.component.html',
  styleUrls: ['./smart-filter-selection-modal.component.scss'],
  providers: [SmartFilterSelectionModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterSelectionModalComponent extends BaseModal {

  constructor(
    public viewModel: SmartFilterSelectionModalViewModel,
    activeModel: NgbActiveModal,
  ) {
    super(activeModel);
  }

  override setupBindings() {
    this.viewModel.dismissModalSubject.pipe(takeUntil(this.onDestroy)).subscribe(ids => this.close(ids));
  }

  alreadyAddedSmartFilterIds(ids: string[]) {
    this.viewModel.previouslyAddedSmartFilterIds(ids || []);
  }

}
