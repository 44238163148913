import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';

@Component({
  selector: 'app-variant-usages-section',
  templateUrl: './variant-usages-section.component.html',
  styleUrls: ['./variant-usages-section.component.scss']
})
export class VariantUsagesSectionComponent extends BaseComponent {

  @Input() sectionTitle: string;
  @Input() usageCountMap: Map<string, number>;

  constructor() {
    super();
  }

}
