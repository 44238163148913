import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocationInfoTextViewModel } from './location-info-text-view-model.service';
import { Location } from '../../../../../../../../../../../../models/company/dto/location';
import { BaseComponent } from '../../../../../../../../../../../../models/base/base-component';

@Component({
  selector: 'app-location-info-text',
  templateUrl: './location-info-text.component.html',
  styleUrls: ['./location-info-text.component.scss'],
  providers: [LocationInfoTextViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationInfoTextComponent extends BaseComponent {

  constructor(public viewModel: LocationInfoTextViewModel) {
    super();
  }

  @Input() locationIds: number[];
  @Input() location: Location;

}
