<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Create Asset Group</div>
    </div>
  </div>

  <div class="sticky-body-container create-asset-group-form-container">
    <lib-reactive-form-group
      #createAssetGroupForm
      [bindTo]="viewModel?.createAssetGroupRequest"
      (canSubmit$)="viewModel?.canSubmit$.next($event)"
      (formSubmitted$)="viewModel?.createAssetGroup()">
      <lib-reactive-form-text
        [inputName]="'assetGroupName'"
        [label]="'Asset Group Name'"
        [placeholder]="'Enter Asset Group Name'"
        [bindingProperty]="'groupName'"
        [required]="true"></lib-reactive-form-text>

      <ng-container *ngIf="(viewModel?.unassignedVariants$ | async)?.length > 0">
        <lib-reactive-form-label
          [label]="'Select Unassigned UBVs'"
          [showRequiredAstrix]="true"></lib-reactive-form-label>

        <lib-reactive-form-checkboxes
          [customValueParser]="viewModel?.customVariantParser"
          [inputName]="'unassignedVariants'"
          [label]="'Select Unassigned UBVs'"
          [bindingProperty]="'universalVariantBarcodes'"
          [checkAllText]="'Select All Unassigned UBVs'"
          [requireMinimumSelection]="1">
          <lib-reactive-form-column-layout nColumns="1" rowGap="0.25rem">
            <ng-container *ngFor="let v of viewModel?.unassignedVariants$ | async">
              <lib-reactive-form-checkbox
                [inputName]="'UBV-' + v?.barcode"
                [label]="v.name + ' (' + v?.packagedQuantity + ' x ' + v?.unitSize + v?.unitOfMeasure + ')'"
                [selectedValue]="v?.barcode"
                [notSelectedValue]="null">
              </lib-reactive-form-checkbox>
            </ng-container>
          </lib-reactive-form-column-layout>
        </lib-reactive-form-checkboxes>
      </ng-container>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      class="bs-button preferred-button"
      (click)="createAssetGroupForm.submitForm()"
      [disabled]="!(viewModel?.canSubmit$ | async)"
      [ngClass]="{ disabled: !(viewModel?.canSubmit$ | async) }">
      Create
    </button>
  </div>
</div>
