import { Injectable } from '@angular/core';
import { HydratedAdminUser } from '../../../models/account/dto/hydrated-admin-user';
import { UpdateUserInfoRequest } from '../../../models/account/requests/update-user-info-request';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { BsError } from '../../../models/shared/bs-error';
import { ToastService } from '../../../services/toast-service';
import { ConfirmCodeRequest } from '../../../models/account/requests/confirm-code-request';
import { ConfirmationFlow } from '../../../models/account/enum/confirmation-flow.enum';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfirmationCodeValidatorDirective } from '@mobilefirstdev/reactive-form';

@Injectable({ providedIn: 'root' })
export class AccountViewModel extends BaseViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  public user: HydratedAdminUser;
  public user$: Observable<HydratedAdminUser> = this.userDomainModel.user$;
  public userEmail$ = this.userDomainModel.userEmail$;
  public accountInfoReq$: Observable<UpdateUserInfoRequest> = this.user$.pipe(
    tap(user => this.user = user),
    map(user => {
      const accountInfoReq = new UpdateUserInfoRequest();
      if (!!user && user.firstName !== '' && !!user.firstName) {
        accountInfoReq.firstName = user.firstName;
      }
      if (!!user && user.lastName !== '' && !!user.lastName) {
        accountInfoReq.lastName = user.lastName;
      }
      if (!!user && user.email !== '' && !!user.email) {
        accountInfoReq.email = user.email;
      }
      return accountInfoReq;
    }),
  );

  public userIsCompanyAdmin$ = this.user$.pipe(map(user => user?.isCompanyAdmin));
  public userFullName$ = this.user$.pipe(map(user => user?.getFullName()));
  public userProfilePicture$ = this.user$.pipe(map(user => user?.profilePicture));
  public userEmailConfirmed$ = this.user$.pipe(map(user => user?.emailConfirmed));

  public unsavedChanges: boolean = false;
  public emailConfirmationCodeCustomValidator = [new ConfirmationCodeValidatorDirective()];
  public confirmationCodeValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Form
  private _emailConfirmationReq: BehaviorSubject<ConfirmCodeRequest>
    = new BehaviorSubject<ConfirmCodeRequest>(new ConfirmCodeRequest());
  public emailConfirmationReq$ = this._emailConfirmationReq as Observable<ConfirmCodeRequest>;

  resendCode() {
    const loadingMess = 'Resending Confirmation Code';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.resendEmailConfirmation(loadingMess);
    }
  }

  connectToEmailVerificationCode = (code: string) => {
    const emailVerificationWithCode = new ConfirmCodeRequest();
    emailVerificationWithCode.confirmationCode = code;
    this._emailConfirmationReq.next(emailVerificationWithCode);
  };

  emailConfirmationSubmitted(req: ConfirmCodeRequest): void {
    this.userDomainModel.user$.once(user => {
      req.accessToken = user?.session?.accessToken;
      req.userId = user?.userId;
      req.flow = ConfirmationFlow.ConfirmationFlow_Email;
      const loadingMess = 'Confirming Email';
      if (!this._loadingOpts.containsRequest(loadingMess)) {
        this._loadingOpts.addRequest(loadingMess);
        this.confirmEmail(req, loadingMess);
      }
    });
  }

  accountInformationSubmitted(req: UpdateUserInfoRequest) {
    const loadingMess = 'Updating Profile';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.updateUser(req, loadingMess);
    }
  }

  public canDeactivate(): boolean | Promise<any> {
    return !this.unsavedChanges;
  }

  private updateUser(update: UpdateUserInfoRequest, loadingMess: string) {
    const userCopy = window?.injector?.Deserialize?.instanceOf(HydratedAdminUser, this.user);
    userCopy.firstName = update.firstName;
    userCopy.lastName = update.lastName;
    // check for a change in email
    userCopy.email = update.email;
    this.userDomainModel.updateUser(userCopy).subscribe({
      next: (_) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishSuccessMessage('Completed', 'Profile Updated');
        this._emailConfirmationReq.next(new ConfirmCodeRequest());
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(err);
        throwError(() => err);
      }
    });
  }

  private confirmEmail(req: ConfirmCodeRequest, loadingMess: string) {
    this.userDomainModel.confirmEmail(req).subscribe({
      next: (_) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishSuccessMessage('Your email has been confirmed.', 'Email Confirmation');
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(err);
        throwError(() => err);
      }
    });
  }

  private resendEmailConfirmation(loadingMess: string): void {
    this.userEmail$.pipe(
      take(1),
      switchMap((email) => this.userDomainModel.resendEmailConfirmationCode(email))
    ).subscribe({
      next: (_) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishSuccessMessage('Check your email.', 'Code Resent');
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });

  }

}
