import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';
import { NewSyncJobViewModel } from '../new-sync-job-view-model';

@Component({
  selector: 'app-data-to-sync-from-pos',
  templateUrl: './data-to-sync-from-pos.component.html',
  styleUrls: ['./data-to-sync-from-pos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataToSyncFromPOSComponent extends BaseComponent {

  constructor(
    public viewModel: NewSyncJobViewModel
  ) {
    super();
  }

}
