import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Asset } from '../../../../models/image/dto/asset';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { tap } from 'rxjs/operators';

@Injectable()
export class AssetPreviewModalViewModel extends BaseViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
    super();
  }

  public displayGroupId: string;
  public libraryTypeName: string;
  public asset: Asset;
  public canDelete: boolean = false;
  public isDisabled: boolean = false;
  public isBrandAssetGroup: boolean = false;
  public imgStyleOverrides = {
    position: 'relative',
    display: 'block',
    'margin-left': 'auto',
    'margin-right': 'auto',
    width: '35rem',
    height: '35rem'
  };

  public deleteCurrentAsset() {
    const op$ = this.isBrandAssetGroup
      ? this.productDomainModel.deleteBrandGroupAsset(this.displayGroupId, this.asset)
      : this.productDomainModel.deleteAssetGroupAsset(this.displayGroupId, this.asset);
    const lm = 'Deleting Asset';
    this._loadingOpts.addRequest(lm);
    return op$.pipe(
      tap(() => this._loadingOpts.removeRequest(lm)),
    );
  }

}
