<div class="error-banner" fxLayout="row">
  <img
    class="error-icon"
    fxFlexAlign="center"
    [src]="'assets/icons/light/solid/x-circle.svg'"
    alt="exclamation-circle.svg" />
  <div class="error-text" fxFlex>
    <ng-container *ngIf="!!message; else content">{{ message }}</ng-container>
    <ng-template #content><ng-content></ng-content></ng-template>
  </div>
</div>
