import { TypeDefinition } from './type-definition';

export class AssetLibraryType extends TypeDefinition {

  static readonly Package = 'Package';
  static readonly Product = 'Product';
  static readonly PrimaryBrand = 'PrimaryBrand';
  static readonly AlternateBrand = 'AlternateBrand';
  static readonly Brand = 'Brand';
  static readonly Marketing = 'Marketing';
  private static readonly BitBud = 'BitBud';
  private static readonly Custom1 = 'Custom1';
  private static readonly Custom2 = 'Custom2';
  private static readonly Custom3 = 'Custom3';

  constructor(value: string = null, name: string = null) {
    // Constructor should only be used when referencing 'dummy' instance
    super(value, name);
    this.value = value;
    this.name = name;
  }

}
