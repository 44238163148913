import 'src/app/utils/array.extensions';
import 'src/app/utils/behavior-subject.extensions';
import 'src/app/utils/map.extension';
import 'src/app/utils/observable.extensions';
import 'src/app/utils/replay-subject.extensions';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './views/default/default-layout/default-layout.component';
import { AccountModule } from './views/account/account.module';
import { SidebarNavComponent } from './views/default/sidebar-nav/sidebar-nav.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthInterceptorInterceptor } from './services/interceptors/auth-interceptor.interceptor';
import { SharedModule } from './views/shared/shared.module';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './services/strategy/cache-route-reuse.strategy';
import { SidebarSubItemListComponent } from './views/default/sidebar-nav/sidebar-sub-item-list/sidebar-sub-item-list.component';
import { NavItemComponent } from './views/default/sidebar-nav/nav-item/nav-item.component';
import { NavSubItemComponent } from './views/default/sidebar-nav/nav-sub-item/nav-sub-item.component';
import { SmartFiltersModule } from './views/smart-filters/smart-filters.module';
import { SignOutNavItemComponent } from './views/default/sidebar-nav/nav-item/sign-out-nav-item/sign-out-nav-item.component';
import { IsDefaultNavItemPipe } from './views/default/sidebar-nav/pipe/is-base-nav-item.pipe';
import { IsSignOutNavItemPipe } from './views/default/sidebar-nav/pipe/is-sign-out-nav-item.pipe';
import { UniversalVariantsSubNavItemComponent } from './views/default/sidebar-nav/nav-sub-item/universal-variants-sub-nav-item/universal-variants-sub-nav-item.component';
import { IsDefaultNavSubItemPipe } from './views/default/sidebar-nav/nav-sub-item/pipes/is-default-nav-sub-item.pipe';
import { IsUniversalVariantsSubNavItemPipe } from './views/default/sidebar-nav/nav-sub-item/pipes/is-universal-variants-sub-nav-item.pipe';
import { DisplayAttributesSubNavItemComponent } from './views/default/sidebar-nav/nav-sub-item/display-attributes-sub-nav-item/display-attributes-sub-nav-item.component';
import { IsDisplayAttributeSubNavItemPipe } from './views/default/sidebar-nav/nav-sub-item/pipes/is-display-attribute-sub-nav-item.pipe';
import { ApplicationSourceInterceptor } from './services/interceptors/application-source-interceptor';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgChartsModule } from 'ng2-charts';
import { GoogleMapsModule } from '@angular/google-maps';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    SidebarNavComponent,
    SidebarSubItemListComponent,
    NavItemComponent,
    NavSubItemComponent,
    SignOutNavItemComponent,
    IsDefaultNavItemPipe,
    IsDefaultNavSubItemPipe,
    IsSignOutNavItemPipe,
    IsDisplayAttributeSubNavItemPipe,
    IsUniversalVariantsSubNavItemPipe,
    UniversalVariantsSubNavItemComponent,
    DisplayAttributesSubNavItemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    AppRoutingModule,
    AccountModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    NgxPopperjsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'bs-toast ngx-toastr'
    }),
    SmartFiltersModule,
    NgChartsModule,
    SharedModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationSourceInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
