<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">
        {{ (viewModel?.isPotentialDuplicate$ | async) ? 'Potential Duplicates' : 'Create Brand' }}
        <img
          *ngIf="viewModel?.isPotentialDuplicate$ | async"
          src="assets/icons/red/outline/exclamation-circle.svg"
          alt="" />
      </div>
    </div>
  </div>

  <div class="sticky-body-container">
    <ng-container *ngIf="viewModel?.isPotentialDuplicate$ | async">
      <div class="mb-2 bs-regular f14px">
        It looks like there are some existing brands that may be potential duplicates of the new brand:
        <i>{{ (viewModel.newBrand$ | async)?.name }}</i>
      </div>
      <div *ngFor="let brand of viewModel?.duplicateBrands$ | async" class="py-3 bs-medium f12px duplicate">
        {{ brand?.groupName }}
      </div>
      <div class="pt-4 bs-regular f14px">Do you want to continue creating this new brand?</div>
    </ng-container>
    <lib-reactive-form-group
      #createBrandForm
      [bindTo]="viewModel?.newBrand$ | async"
      (canSubmit$)="viewModel?.connectToCanSubmit($event)"
      (formSubmitted$)="viewModel.checkIfBrandIsPotentialDuplicate($event)">
      <lib-reactive-form-text
        *ngIf="!(viewModel?.isPotentialDuplicate$ | async)"
        [inputName]="'brandName'"
        [label]="'Brand Name'"
        [placeholder]="'Enter Brand Name'"
        [bindingProperty]="'groupName'"
        [required]="true"></lib-reactive-form-text>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container create-brand-footer">
    <div>
      <button
        *ngIf="viewModel?.isPotentialDuplicate$ | async"
        class="bs-button text-button back-to-create-brand-button"
        (click)="viewModel?.goBackToCreate()">
        <img src="assets/icons/dark/outline/chevron-left.svg" alt="" />
        Back
      </button>
    </div>

    <div class="create-brand-action-button-container">
      <button (click)="cancel()" class="bs-button neutral-button">Cancel</button>
      <button
        *ngIf="!(viewModel?.isPotentialDuplicate$ | async)"
        class="bs-button preferred-button"
        (click)="createBrandForm.submitForm()"
        [disabled]="!(viewModel?.canSubmit$ | async)"
        [ngClass]="{ disabled: !(viewModel?.canSubmit$ | async) }">
        Create
      </button>
      <button
        *ngIf="viewModel?.isPotentialDuplicate$ | async"
        class="bs-button preferred-button"
        (click)="viewModel?.createBrand()">
        Create Duplicate
      </button>
    </div>
  </div>
</div>
