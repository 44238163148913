import { Pipe, PipeTransform } from '@angular/core';
import type { Theme } from '../../../../models/menu/dto/theme';

@Pipe({
  name: 'isPrivateTheme'
})
export class IsPrivateThemePipe implements PipeTransform {

  transform(theme: Theme): boolean {
    return theme?.isPrivateTheme() || false;
  }

}
