<div class="mt-4">
  <div class="my-3" *ngIf="viewModel?.showDisabledInGridModeMessage$ | async">
    <app-message-banner [message]="viewModel?.disabledInGridModeMessage$ | async"> </app-message-banner>
  </div>

  <app-column-options-form
    [formColumnConfig]="viewModel.formColumnConfig$ | push"
    [columnSectionKey]="viewModel.container.columnSectionKey$ | push"
    [formMergeKey]="viewModel.container.mergeKey"
    [sectionLayoutType]="viewModel.container.layoutType$ | push"
    (columnChanges)="viewModel.container.connectToColumnChanges($event)"></app-column-options-form>
</div>
