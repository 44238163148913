import { DefaultStackedSizeType } from './default-stacked-size-type';

export enum DefaultPrintCardSize {
  DefaultSize_AddressCard = 'DefaultSize_AddressCard',
  DefaultSize_BusinessCard = 'DefaultSize_BusinessCard',
  DefaultSize_IndexCard = 'DefaultSize_IndexCard',
  DefaultSize_PostCard = 'DefaultSize_PostCard',
  DefaultSize_Custom2x2 = 'DefaultSize_Custom2x2',
  DefaultSize_Custom5x5 = 'DefaultSize_Custom5x5',
}

export class DefaultPrintCardSizeType extends DefaultStackedSizeType {

  override value: DefaultPrintCardSize;

}
