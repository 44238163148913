import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { NavItem } from '../models/nav-item';
import { Router } from '@angular/router';
import { NavItemViewModel } from './nav-item-view-model';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Animatable } from '../../../../models/protocols/animatable';
import { BehaviorSubject } from 'rxjs';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  providers: [NavItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent extends BaseComponent implements OnChanges, Animatable  {

  @Input() navItem: NavItem;
  @Input() currentNavUrl: string;
  @Input() loadingOpts: LoadingOptions;
  protected _animating = new BehaviorSubject<boolean>(false);
  public animating$ = this._animating.asObservable();

  constructor(
    public viewModel: NavItemViewModel,
    protected router: Router,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToNavItem(this.navItem);
    this.viewModel.connectToCurrentNavUrl(this.currentNavUrl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navItem) { this.viewModel.connectToNavItem(this.navItem); }
    if (changes.currentNavUrl) { this.viewModel.connectToCurrentNavUrl(this.currentNavUrl); }
    if (changes.loadingOpts) { this.viewModel.connectToLoadingOpts(this.loadingOpts); }
  }

  navItemSelected() {
    const savedSubNav = this.navItem?.getSavedSubNavItem();
    const extras = { fragment: this.navItem?.savedFragment };
    if (exists(savedSubNav)) {
      this.router.navigate([`${this.navItem?.routerPath}/${savedSubNav?.relativeRouterPath}`], extras).then(() => {});
    } else {
      this.router.navigate([`${this.navItem?.routerPath}`], extras).then(() => {});
    }
  }

  animate(duration: number) {
    this._animating.next(true);
    setTimeout(function() { this._animating.next(false); }.bind(this), duration);
  }

}
