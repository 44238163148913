import { Injectable } from '@angular/core';
import { CompanyDomainModel } from './company-domain-model';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { DistinctUtils } from '../utils/distinct-utils';
import { SyncDataJob } from '../models/automation/sync-data-job';
import { ProductAPI } from '../api/product-api';
import { SyncJobStatus } from '../utils/sync-job-status-type';
import { DateUtils } from '../utils/date-utils';

@Injectable({providedIn: 'root'})
export class SyncDomainModel extends BaseDomainModel {

  constructor(private companyDomainModel: CompanyDomainModel, private productAPI: ProductAPI) {
    super();
  }

  // Sync Job polling
  public readonly POLLING_TIME = 10 * 1000; // 10 seconds
  public pollingTimer$ = interval(this.POLLING_TIME);
  private _lastPolledTime = new BehaviorSubject<number>(0);
  public lastPolledTime$ = this._lastPolledTime.asObservable();

  public companyId$ = this.companyDomainModel.selectedCompanyId$;

  private _companySyncJobs = new BehaviorSubject<SyncDataJob[]>(null);
  public companySyncJobs$ = combineLatest([
    this._companySyncJobs,
    this.companyDomainModel.selectedCompany$,
  ]).pipe(
    map(([jobs, currentCompany]) => {
      jobs?.forEach(job => job?.autoAddPromotionsToSyncTypesIfNeeded(currentCompany.provider));
      return jobs;
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({bufferSize: 1, refCount: true}),
    debounceTime(100) // delay pipe to not spam table. Spamming the table breaks it
  );

  private getCompanySyncJobs = this.companyId$
    .notNull()
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: (companyId) => this.fetchCompanySyncJobs(companyId)
    });

  private fetchCompanySyncJobs(companyId: number) {
    this.productAPI.GetSyncJobs(companyId).subscribe(syncJobs => this._companySyncJobs.next(syncJobs));
  }

  public pollingInputJobs$ = this.companySyncJobs$.pipe(
    map(jobs => {
      return jobs?.filter(j => {
        // Continue to poll for jobs that are queued or processing
        const queued = SyncJobStatus[j.jobStatus] === SyncJobStatus.SyncJobStatus_Queued;
        const processing = SyncJobStatus[j.jobStatus] === SyncJobStatus.SyncJobStatus_Processing;
        const tenMinutesIntoTheFuture = j.dateCreated + DateUtils.secondsInOneMinute() * 10;
        const continuePolling = tenMinutesIntoTheFuture > DateUtils.currentTimestampInSeconds();
        return (queued || processing) && continuePolling;
      });
    })
  );

  private poller = combineLatest([
    this.companyId$,
    this.pollingInputJobs$,
    this.pollingTimer$,
    this.lastPolledTime$
  ]).pipe(
    concatMap(([companyId, jobs, _, lastPolledTime]) => {
      if (jobs.length > 0 && (Date.now() - lastPolledTime > this.POLLING_TIME)) {
        this._lastPolledTime.next(Date.now());
        return this.getUpdatedSyncJobStatus(jobs, companyId);
      } else {
        return of([]);
      }
    }),
    takeUntil(this.onDestroy)
  ).subscribe();

  private getUpdatedSyncJobStatus(jobs: SyncDataJob[], companyId: number): Observable<SyncDataJob[]> {
    return this.productAPI.GetSyncJobs(companyId, jobs.map(j => j.id).unique()).pipe(
      tap(updatedJobs => {
        updatedJobs.forEach(j => {
          if (j.jobStatus === SyncJobStatus.SyncJobStatus_Success) {
            this.companyDomainModel.refreshCompany(j.companyId);
          }
          this.updateCurrentSyncJobs(j);
        });
      })
    );
  }

  private updateCurrentSyncJobs(job: SyncDataJob) {
    this._companySyncJobs.once(jobs => {
      const updatedJobs = jobs.shallowCopy();
      const existingIndex = updatedJobs.findIndex(j => j.id === job.id);
      if (existingIndex > -1) {
        updatedJobs.splice(existingIndex, 1);
      }
      updatedJobs.push(job);
      this._companySyncJobs.next(updatedJobs);
    });
  }

  public createSyncJob(job: SyncDataJob): Observable<SyncDataJob> {
    return this.productAPI.CreateSyncJob(job).pipe(
      tap(newJob => this.updateCurrentSyncJobs(newJob))
    );
  }

}
