import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModelComponent } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';

@Injectable()
export class TerpeneAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModelComponent {

  public terpeneDropdowns$ = window.types.terpenes$;
  public terpenes$ = window.types.terpenes$.pipe(
    map(terpeneTypes => terpeneTypes?.map(t => {
      return {
        key: t?.getSelectionValue()?.toString()?.stripWhiteSpace(),
        text: t?.getSelectionValue()
      };
    }))
  );

}
