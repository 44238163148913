import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { SortUtils } from '../../../utils/sort-utils';
import { ColorUtils } from '../../../utils/color-utils';
import { DisplayLabelInterface } from './display-label-interface';

@Injectable()
export class LabelViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _displayLabelInterface = new BehaviorSubject<DisplayLabelInterface>(null);
  public readonly displayLabelInterface$ = this._displayLabelInterface as Observable<DisplayLabelInterface>;
  connectToDisplayLabelInterface = (i: DisplayLabelInterface) => this._displayLabelInterface.next(i);

  public displayableInterface$ = this.displayLabelInterface$.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ************************************ Primary Label ************************************ */

  public readonly label$ = this.displayableInterface$.pipe(
    switchMap((displayLabelInterface) => {
      if (!!displayLabelInterface) {
        return of(displayLabelInterface?.getLabelsForLabelComponent());
      }
      return of(null);
    }),
    map(labels => labels?.sort(SortUtils.sortLabelsByPriority)?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly originalLabelText$ = combineLatest([
    this.displayableInterface$,
    this.label$,
  ]).pipe(
    map(([displayInterface, label]) => {
      if (!!displayInterface) {
        const labels = displayInterface?.getLabelsForLabelComponent();
        if (labels?.length > 1) {
          return `${label?.text} (+${labels?.length - 1})`;
        }
      }
      return label?.text ?? '';
    })
  );

  public readonly labelText$ = combineLatest([
    this.displayableInterface$,
    this.label$,
  ]).pipe(
    switchMap(([displayInterface, label]) => {
      // can add override labelText in here as needed
      return this.originalLabelText$;
    }),
    distinctUntilChanged()
  );

  public readonly labelBackgroundColor$ = this.label$.pipe(
    map(label => label?.color ?? ColorUtils.LABEL_DEFAULT_TEXT_DARK_COLOR)
  );

  public readonly labelTextColor$ = combineLatest([
    this.label$,
    this.labelBackgroundColor$
  ]).pipe(
    map(([labelKey, labelBackgroundColor]) => {
      const offWhite = ColorUtils.LABEL_DEFAULT_TEXT_LIGHT_COLOR;
      const black = ColorUtils.LABEL_DEFAULT_TEXT_DARK_COLOR;
      const labelTextColor = labelKey?.textColor ?? offWhite;
      switch (true) {
        case !!labelTextColor:
          return labelTextColor;
        case !!labelBackgroundColor:
          return ColorUtils.isDarkColor(labelBackgroundColor) ? offWhite : black;
        default:
          return offWhite;
      }
    })
  );

  public readonly removable$ = this.displayableInterface$.pipe(
    map((displayableInterface) => {
      const displayableInterfaceClearable = displayableInterface?.getClearableForLabelComponent();
      return displayableInterfaceClearable || false;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
