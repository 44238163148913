<div class="asset-preview-container">
  <div class="asset-preview">
    <app-asset
      #appAsset
      [asset]="asset"
      [scaleFit]="true"
      [showControls]="true"
      [style.width.px]="appAsset?.el?.nativeElement?.clientHeight * (appAsset.ratio | async)"
      [size]="options.previewSize">
    </app-asset>
  </div>

  <div class="asset-info">
    <div class="asset-details">
      <div class="asset-name ellipsis-nowrap-text">
        {{ asset?.getFileNameWithoutExtension() }}
      </div>
      <div class="asset-detail">
        <span>Date Uploaded:</span>
        {{ getUploadDate() }}
      </div>
      <div class="asset-detail">
        <span>Asset Type:</span>
        {{ asset?.mediaType }}
      </div>
    </div>
    <div class="asset-preview-buttons">
      <lib-button-neutral
        class="mx-2"
        [throttleInMilliSec]="1000"
        (buttonClicked)="refreshButtonPressed.emit(this.asset?.fileName)"
        [iconSrc]="'assets/icons/dark/solid/refresh.svg'">
      </lib-button-neutral>

      <lib-button-neutral
        class="mx-2"
        *ngIf="!!options?.tertiaryButtonText && options?.tertiaryButtonText !== ''"
        (buttonClicked)="tertiaryButtonPressed.emit()">
        {{ options?.tertiaryButtonText }}
      </lib-button-neutral>

      <lib-button-neutral
        class="mx-2"
        *ngIf="!!options?.secondaryButtonText && options?.secondaryButtonText !== ''"
        (buttonClicked)="secondaryButtonPressed.emit()">
        {{ options?.secondaryButtonText }}
      </lib-button-neutral>

      <!--   Lay out primary button in both default and destructive states   -->

      <lib-button-destructive
        class="mx-2"
        *ngIf="!!options?.primaryButtonText && options?.primaryButtonText !== '' && options?.primaryButtonDestructive"
        (buttonClicked)="primaryButtonPressed.emit()">
        {{ options?.primaryButtonText }}
      </lib-button-destructive>

      <lib-button-neutral
        class="mx-2"
        *ngIf="!!options?.primaryButtonText && options?.primaryButtonText !== '' && !options?.primaryButtonDestructive"
        (buttonClicked)="primaryButtonPressed.emit()">
        {{ options?.primaryButtonText }}
      </lib-button-neutral>
    </div>
  </div>
</div>
