import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { map, switchMap } from 'rxjs/operators';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { SectionColumnConfigDefaultState, SectionColumnConfigDefaultStateType } from '../../../../../../../utils/section-column-config-default-state-type';
import { InformationItem } from '../../../../../../../models/shared/information-item';
import { SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigIndividualTerpeneKey, SectionColumnConfigKey, SectionColumnConfigPrimaryCannabinoidKey, SectionColumnConfigProductInfoKey, SectionColumnConfigSecondaryCannabinoidKey } from '../../../../../../../models/enum/dto/section-column-config-key';

type DefaultMapStates = [SectionColumnConfigKey, Observable<SectionColumnConfigDefaultStateType[]>];

@Injectable()
export class ColumnGeneralOptionsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private noAutoSectionDefaultStateOptions$ = window.types.noAutoSectionColumnConfigDefaultStates$;
  private autoSectionDefaultStateOptions$ = window.types.autoSectionColumnConfigDefaultStates$;
  private disableSectionDefaultStateOptions$ = window.types.disabledSectionColumnConfigDefaultStates$;

  private selectableDefaultStateMap = new Map([
    ...Object.values(SectionColumnConfigProductInfoKey).map(key => {
      switch (key) {
        case SectionColumnConfigProductInfoKey.Asset:
        case SectionColumnConfigProductInfoKey.StrainType:
        case SectionColumnConfigProductInfoKey.Price:
        case SectionColumnConfigProductInfoKey.SecondaryPrice:
          return [key, this.noAutoSectionDefaultStateOptions$];
        default:
          return [key, this.autoSectionDefaultStateOptions$];
      }
    }),
    ...Object.values(SectionColumnConfigPrimaryCannabinoidKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigSecondaryCannabinoidKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigCollectiveTerpeneKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigIndividualTerpeneKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
  ] as DefaultMapStates[]);

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => this._formColumnConfig.next(formColumnConfig);

  private _columnSectionKey = new BehaviorSubject<SectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey as Observable<SectionColumnConfigKey>;
  connectToColumnSectionKey = (columnSectionKey: SectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  public columnSectionKeyIsCannabinoidOrTerpene$ = this.columnSectionKey$.pipe(
    map(key => {
      const cannabinoidAndTerpeneKeys = [
        ...(Object.values(SectionColumnConfigPrimaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(SectionColumnConfigSecondaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(SectionColumnConfigIndividualTerpeneKey)?.map(key => key?.toString()) || [])
      ];
      return cannabinoidAndTerpeneKeys.includes(key);
})
  );

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  private _inputDisabled = new BehaviorSubject<boolean>(false);
  public inputDisabled$ = this._inputDisabled as Observable<boolean>;
  connectToInputDisabled = (inputDisabled: boolean) => this._inputDisabled.next(inputDisabled);

  public columnConfigDefaultState$ = this.formColumnConfig$.pipe(
    map(columnConfig => columnConfig?.defaultState)
  );

  public sectionDefaultStateOptions$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$
  ]).pipe(
    switchMap(([sectionLayoutType, columnSectionKey]) => {
      if (ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, sectionLayoutType)) {
        return this.disableSectionDefaultStateOptions$;
      }
      return this.selectableDefaultStateMap?.get(columnSectionKey);
    }),
    map(defaultStateOptions => {
      const unknownDefaultStateOpt = new SectionColumnConfigDefaultStateType(
        SectionColumnConfigDefaultState.Unknown,
        'Select Default State'
      );
      return [...defaultStateOptions, unknownDefaultStateOpt];
    })
  );

  public tooltip$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([columnSectionKey, columnSectionKeyIsCannabinoidOrTerpene]) => {
      if (columnSectionKeyIsCannabinoidOrTerpene) {
        return 'Auto - only if 1 or more variants in section have cannabinoids and with a value of at least 1';
      }
      switch (columnSectionKey) {
        case SectionColumnConfigProductInfoKey.Asset:
          return 'Show product images in menu';
        case SectionColumnConfigProductInfoKey.Badges:
          return 'Auto - only appears when 1 or more variants in section have a badge associated';
        case SectionColumnConfigProductInfoKey.Brand:
          return 'Auto - only appears when 1 or more variants in section have a brand associated';
        case SectionColumnConfigProductInfoKey.StrainType:
          return 'Show product strain type in menu';
        case SectionColumnConfigProductInfoKey.Quantity:
          return 'Auto - only if 1 or more variants in section have QTY > 1. Will be disabled if menu is set to grid';
        case SectionColumnConfigProductInfoKey.Size:
          return 'Auto - only if 2 or more sizes exist on section variants';
        case SectionColumnConfigProductInfoKey.Price:
          return 'Show Price in menu';
        case SectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'This column allows you to display a comparative price or display the price per unit of measure. ' +
            'Will be disabled if menu is set to grid.';
        default:
          return '';
      }
    })
  );

  public defaultStateInputDisabled$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$,
    this.formColumnConfig$
  ]).pipe(
    map(([layoutType, columnSectionKey, formColumnConfig]) => {
      return (layoutType?.isGrid() && columnSectionKey === SectionColumnConfigProductInfoKey.Price) ||
        formColumnConfig?.defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public hideColumnName$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigProductInfoKey.Asset;
    })
  );

  public showColumnDataOptions$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnKeyIsCannabinoidOrTerpene]) => {
      return columnKeyIsCannabinoidOrTerpene
        || key === SectionColumnConfigProductInfoKey.Asset
        || key === SectionColumnConfigProductInfoKey.SecondaryPrice;
    })
  );

  public columnDataOptionsLabel$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnSectionKeyIsCannabinoidOrTerpene]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit';
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Use Value';
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return 'Data Layout';
        case columnSectionKeyIsCannabinoidOrTerpene:
          return 'Display Format';
      }
    })
  );

  public columnDataOptionsToolTipModalTitle$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnSectionKeyIsCannabinoidOrTerpene]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit Information';
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Secondary Price Information';
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return 'Strain Type Layout';
        case columnSectionKeyIsCannabinoidOrTerpene:
          return 'Display Format';
      }
    })
  );

  private dataValueDropdowns$ = combineLatest([
    window.types.assetColumnConfigDataValues$,
    window.types.secondaryPriceColumnConfigDataValues$,
    window.types.strainTypeColumnConfigDataValues$,
    window.types.displayFormatTypeColumnConfigDataValues$,
  ]);

  public columnDataOptionsDropdowns$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$,
    this.dataValueDropdowns$
  ]).pipe(
    map(([
      key,
      columnSectionKeyIsCannabinoidOrTerpene,
      [
        assetDropdowns,
        secondaryPriceDropdowns,
        strainTypeDropdowns,
        displayFormatDropdowns
      ]
    ]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return assetDropdowns;
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return secondaryPriceDropdowns;
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return strainTypeDropdowns;
        case columnSectionKeyIsCannabinoidOrTerpene:
          return displayFormatDropdowns;
      }
    })
  );

  public columnDataOptionsToolTipModalItems$ = this.columnDataOptionsDropdowns$.pipe(
    map(dropdowns => {
      return dropdowns?.map(m => new InformationItem(m?.name, m?.getSelectionInformationDescription()));
    })
  );

  public widthRequired$ = this.inputDisabled$.pipe(
    map(inputDisabled => !inputDisabled)
  );

}
