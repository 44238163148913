import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { PopulateSandboxRequest } from '../../../../../models/account/requests/populate-sandbox-request';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { take } from 'rxjs/operators';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';

@Injectable()
export class PopulateSandboxDataModalViewModel extends BaseModalViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _populateSandboxRequest = new BehaviorSubject<PopulateSandboxRequest>(null);
  public populateSandboxRequest$ = this._populateSandboxRequest as Observable<PopulateSandboxRequest>;
  createPopulateSandboxRequest = (companyId: number) => {
    this._populateSandboxRequest.next(new PopulateSandboxRequest(companyId));
  };

  submitPopulateSandboxDataRequest() {
    const lm = 'Requesting Population of Sandbox Data';
    this.populateSandboxRequest$.pipe(
      switchMap(req => {
        this._loadingOpts.addRequest(lm);
        return this.companyDomainModel.populateSandboxData(req);
      }),
      take(1)
    ).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Sandbox Data Populated Successfully', 'Success');
        this.activeModal.close();
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

}
