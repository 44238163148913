import { Asset } from '../../../../models/image/dto/asset';

export class AssetLibraryEntry {

  public asset: Asset;
  public assetLibraryType: string;
  public enabled: boolean = true;
  public uniqueAssetLibraryInputName: string;
  public uniqueAssetEnableInputName: string;
  public backgroundColor: string;

  constructor(asset: Asset) {
    this.asset = asset;
    this.uniqueAssetLibraryInputName = 'assetLibraryType-' + asset.id + '-' + asset.md5Hash;
    this.uniqueAssetEnableInputName = 'assetEntryEnabled-' + asset.id + '-"' + asset.md5Hash;
  }

}
