import { Deserializable } from '../../protocols/deserializable';

export class ThemeFeatures implements Deserializable {

  // Menu Defaults
  public featuredProducts: boolean = false;
  public titleTextColor: boolean = false;
  public bodyTextColor: boolean = false;
  public badgeCount: number = 0;
  // Menu Background
  public backgroundMedia: boolean = false;
  public backgroundOpacity: boolean = false;
  public blurredBackground: boolean = false;
  public bodyBackgroundColor: boolean = false;
  // Product Section
  public sectionImage: boolean = false;
  public sectionSecondaryImage: boolean = false;
  public sectionProductMaxCount: number = 0;
  public sectionHeaderBackgroundColor: boolean = false;
  public sectionBodyBackgroundColor: boolean = false;
  public sectionHeaderTextColor: boolean = false;
  public sectionBodyTextColor: boolean = false;
  // Title Section
  public titleSectionBackgroundImage: boolean = false;
  public titleSectionSubtitle: boolean = false;

  constructor(
    sectionImage: boolean = false,
    sectionSecondaryImage: boolean = false,
    featuredProducts: boolean = false,
    backgroundMedia: boolean = false,
    backgroundOpacity: boolean = false,
    titleTextColor: boolean = false,
    bodyTextColor: boolean = false,
    bodyBackgroundColor: boolean = false,
    blurredBackground: boolean = false,
    sectionHeaderBackgroundColor: boolean = false,
    sectionBodyBackgroundColor: boolean = false,
    sectionHeaderTextColor: boolean = false,
    sectionBodyTextColor: boolean = false,
    badgeCount: number = 0,
    sectionProductMaxCount: number = 0,
    titleSectionBackgroundImage: boolean = false,
    titleSectionSubtitle: boolean = false
  ) {
    this.sectionImage = sectionImage;
    this.sectionSecondaryImage = sectionSecondaryImage;
    this.featuredProducts = featuredProducts;
    this.backgroundMedia = backgroundMedia;
    this.backgroundOpacity = backgroundOpacity;
    this.titleTextColor = titleTextColor;
    this.bodyTextColor = bodyTextColor;
    this.bodyBackgroundColor = bodyBackgroundColor;
    this.blurredBackground = blurredBackground;
    this.sectionHeaderBackgroundColor = sectionHeaderBackgroundColor;
    this.sectionBodyBackgroundColor = sectionBodyBackgroundColor;
    this.sectionHeaderTextColor = sectionHeaderTextColor;
    this.sectionBodyTextColor = sectionBodyTextColor;
    this.badgeCount = badgeCount;
    this.sectionProductMaxCount = sectionProductMaxCount;
    this.titleSectionBackgroundImage = titleSectionBackgroundImage;
    this.titleSectionSubtitle = titleSectionSubtitle;
  }

  onDeserialize() {
    this.sectionImage = this.sectionImage ?? false;
    this.sectionSecondaryImage = this.sectionSecondaryImage ?? false;
    this.featuredProducts = this.featuredProducts ?? false;
    this.backgroundMedia = this.backgroundMedia ?? false;
    this.backgroundOpacity = this.backgroundOpacity ?? false;
    this.titleTextColor = this.titleTextColor ?? false;
    this.bodyTextColor = this.bodyTextColor ?? false;
    this.bodyBackgroundColor = this.bodyBackgroundColor ?? false;
    this.blurredBackground = this.blurredBackground ?? false;
    this.sectionHeaderBackgroundColor = this.sectionHeaderBackgroundColor ?? false;
    this.sectionBodyBackgroundColor = this.sectionBodyBackgroundColor ?? false;
    this.sectionHeaderTextColor = this.sectionHeaderTextColor ?? false;
    this.sectionBodyTextColor = this.sectionBodyTextColor ?? false;
    this.badgeCount = this.badgeCount ?? 0;
    this.sectionProductMaxCount = this.sectionProductMaxCount ?? 0;
    this.titleSectionBackgroundImage = this.titleSectionBackgroundImage ?? false;
    this.titleSectionSubtitle = this.titleSectionSubtitle ?? false;
  }

}
