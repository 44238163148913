import { Component } from '@angular/core';
import { FoundationBaseModal } from '@mobilefirstdev/base-angular';
import { ClientTypeUtils } from '../../utils/client-type-utils';

@Component({ template: '' })
export abstract class BaseModalComponent extends FoundationBaseModal {

  Types = ClientTypeUtils;

  override cancel() {
    super.cancel();
  }

}
