import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalViewModel } from './create-display-attribute-group-details-or-update-variants-modal-view-model.service';

@Component({
  selector: 'app-create-display-attribute-group-details-modal',
  templateUrl: './create-display-attribute-group-details-or-update-variants-modal.component.html',
  styleUrls: ['./create-display-attribute-group-details-or-update-variants-modal.component.scss'],
  providers: [CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: CreateDisplayAttributeGroupDetailsOrUpdateVariantsModalViewModel,
    activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

}
