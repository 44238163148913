<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<div class="usages-component">
  <div>
    <app-variant-usages-section
      [sectionTitle]="'Universal Variant Name'"
      [usageCountMap]="viewModel.nameCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Brand'"
      [usageCountMap]="viewModel.brandCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Manufacturer'"
      [usageCountMap]="viewModel.manufacturerCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Product Type'"
      [usageCountMap]="viewModel.productTypeCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Variant Type'"
      [usageCountMap]="viewModel.variantTypeCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Classification'"
      [usageCountMap]="viewModel.strainTypeCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Strain'"
      [usageCountMap]="viewModel.strainCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Unit Size'"
      [usageCountMap]="viewModel.unitSizeCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Unit of Measure'"
      [usageCountMap]="viewModel.unitOfMeasureCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'Packaged Quantity'"
      [usageCountMap]="viewModel.packagedQuantityCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'THC'"
      [usageCountMap]="viewModel.THCCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'THC Range'"
      [usageCountMap]="viewModel.THCRangeCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'CBD'"
      [usageCountMap]="viewModel.CBDCountMap$ | async"></app-variant-usages-section>
  </div>

  <div>
    <app-variant-usages-section
      [sectionTitle]="'CBD Range'"
      [usageCountMap]="viewModel.CBDRangeCountMap$ | async"></app-variant-usages-section>
  </div>
</div>
