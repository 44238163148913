import { Component } from '@angular/core';
import { AddVariantToDisplayGroupViewModel } from './add-variant-to-display-group-view-model';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../../../../models/product/dto/variant';

@Component({
  selector: 'app-add-variant-to-display-group-modal',
  templateUrl: './add-variant-to-display-group-modal.component.html',
  styleUrls: ['./add-variant-to-display-group-modal.component.scss'],
  providers: [AddVariantToDisplayGroupViewModel]
})
export class AddVariantToDisplayGroupModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: AddVariantToDisplayGroupViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setUBVToAdd(ubvToAdd: Variant) {
    this.viewModel.connectToUbvToAdd(ubvToAdd);
  }

  setIdsToAdd(idsToAdd: string[]) {
    this.viewModel.connectToIdsToAdd(idsToAdd);
  }

}
