<div
  class="card-deck"
  [class.horizontal-scroller]="horizontalScroller"
  [ngStyle]="{ 'max-height': getMaxHeightRem() }"
  [style.row-gap]="rowGap"
  [style.column-gap]="columnGap">
  <ng-container *ngFor="let card of cards; let cardIndex = index">
    <!--  Default Card  -->
    <app-card *ngIf="card.cardStyle === cardStyles.Default" [card]="card" (click)="handleCardPress(card)"> </app-card>
    <!--  Incomplete Product Card  -->
    <app-incomplete-product-card
      (click)="handleCardPress(card)"
      *ngIf="card.cardStyle === cardStyles.IncompleteProduct"
      [card]="card">
    </app-incomplete-product-card>
    <!--  Unconfirmed Product Card  -->
    <app-unconfirmed-product-card
      (click)="handleCardPress(card)"
      *ngIf="card.cardStyle === cardStyles.UnconfirmedProduct"
      [card]="card">
    </app-unconfirmed-product-card>
    <app-quick-action-card
      *ngIf="card.cardStyle === cardStyles.QuickAction"
      [ngClass]="{ 'quick-action-card-size': true }"
      [card]="card"
      (click)="handleCardPress(card)">
    </app-quick-action-card>
    <app-theme-preview-card
      (cardButtonClicked)="cardButtonPressed.emit(card)"
      (click)="handleCardPress(card)"
      *ngIf="card.cardStyle === cardStyles.ThemePreview"
      [card]="card"
      [id]="card.id">
    </app-theme-preview-card>
    <app-theme-carousel-image *ngIf="card.cardStyle === cardStyles.ThemeCarouselImage" [card]="card" [id]="card.id">
    </app-theme-carousel-image>
    <app-asset-preview-card
      *ngIf="card.cardStyle === cardStyles.AssetPreview"
      (cardPressed)="cardPressed.emit($event)"
      [card]="card"
      [id]="card.id">
    </app-asset-preview-card>
    <app-edit-asset-preview-card
      *ngIf="card.cardStyle === cardStyles.EditAssetPreview"
      (cardPressed)="cardPressed.emit($event)"
      [card]="card"
      [id]="card.id">
    </app-edit-asset-preview-card>
    <app-smart-filter-category-preview-card
      *ngIf="card.cardStyle === cardStyles.SmartFilterCategoryPreview"
      (cardPressed)="cardPressed.emit($event)"
      [card]="card"
      [id]="card.id">
    </app-smart-filter-category-preview-card>
    <app-section-blueprint-category-preview-card
      *ngIf="card.cardStyle === cardStyles.SectionBlueprintCategoryPreview"
      (cardPressed)="cardPressed.emit($event)"
      [card]="card"
      [id]="card.id">
    </app-section-blueprint-category-preview-card>
    <app-unassigned-asset-group-card
      *ngIf="card.cardStyle === cardStyles.UnassignedAssetGroups"
      (cardPressed)="cardPressed.emit($event)"
      [card]="card"
      [id]="card.id"></app-unassigned-asset-group-card>
  </ng-container>
</div>
