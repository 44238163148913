import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { LabelComponent } from '../label/label-component';
import { FlagLabelViewModel } from './flag-label-view-model';
import { ResizeObserver } from '@juggle/resize-observer';

@Component({
  selector: 'app-flag-label',
  templateUrl: './flag-label.component.html',
  styleUrls: [
    '../label/label.scss',
    './flag-label.component.scss'
  ],
  providers: [FlagLabelViewModel]
})
export class FlagLabelComponent extends LabelComponent implements AfterViewInit {

  constructor(
    public override viewModel: FlagLabelViewModel
  ) {
    super(viewModel);
  }

  @ViewChild('flagBody') flagBody: ElementRef;
  private resizeObserver: ResizeObserver;

  override setupBindings() {
    this.observeFlagBody();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.connectToFlagHeight();
  }

  private observeFlagBody() {
    this.resizeObserver?.disconnect();
    if (!!this.flagBody?.nativeElement) {
      this.resizeObserver = new ResizeObserver(_ => this.connectToFlagHeight());
      this.resizeObserver.observe(this.flagBody?.nativeElement);
    }
  }

  private connectToFlagHeight(): void {
    if (!!this.flagBody?.nativeElement) {
      const computedStyle = window.getComputedStyle(this.flagBody?.nativeElement);
      const height = parseFloat(computedStyle.getPropertyValue('height')) || 0;
      this.viewModel.connectToFlagHeight(height);
    }
  }

  override destroy() {
    super.destroy();
    this.resizeObserver?.disconnect();
  }

}
