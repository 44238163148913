import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetGroup } from '../../../../models/product/dto/asset-group';
import { map, switchMap } from 'rxjs/operators';
import { AssetLibraryEntry } from './asset-library-entry';
import { BsError } from '../../../../models/shared/bs-error';
import { AssetGroupUtils } from '../../../../utils/asset-group-utils';
import { AssetLibraryType } from '../../../../utils/asset-library-type';
import { exists } from '../../../../functions/exists';

@Injectable()
export class UpdateAssetLibraryTypesViewModel extends BaseModalViewModel {

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public assetLibraryTypeDropdowns$ = window?.types.assetLibraryAssetGroupTypes$;
  public brandAssetLibraryTypeDropdowns$ = window?.types.assetLibraryBrandAssetGroupTypes$;

  private _assetGroup: BehaviorSubject<AssetGroup> = new BehaviorSubject<AssetGroup>(null);
  public assetGroup$ = this._assetGroup as Observable<AssetGroup>;
  public assetGroupName$ = this.assetGroup$.pipe(map(ag => ag?.groupName || 'Shared Group'));
  public assetLibraryEntries$ = this.assetGroup$.pipe(map(ag => {
    const libraryEntries: AssetLibraryEntry[] = [];
    let assetsForGroup = ag?.assets;
    if (ag?.id === ag?.displayAttributeGroupId) {
      assetsForGroup = ag?.assets?.filter(a => AssetGroupUtils.findVariantIndexForAsset(a, ag?.variants) <= -1);
    }

    ag?.assetTypeMap.forEach((assetIdHashes, assetTypeKey) => {
      assetIdHashes.forEach(assetIdHash => {
        const asset = AssetGroupUtils.findAssetWithIdHash(assetIdHash, assetsForGroup);
        if (exists(asset)) {
          const entry = new AssetLibraryEntry(asset);
          entry.assetLibraryType = assetTypeKey;
          entry.backgroundColor = assetTypeKey === AssetLibraryType.AlternateBrand ? '#222222' : 'transparent';
          libraryEntries.push(entry);
        }
      });
    });
    assetsForGroup?.forEach(asset => {
      const assetIndex = libraryEntries.findIndex(entry => {
        return entry.asset.id === asset.id && entry.asset.md5Hash === asset.md5Hash;
      });
      if (assetIndex <= -1) {
        const entry = new AssetLibraryEntry(asset);
        entry.enabled = !AssetGroupUtils.isAssetDisabled(asset, ag?.disabledAssets);
        libraryEntries.push(entry);
      }
    });
    return libraryEntries;
  }));

  public libraryTypesDropdowns$ = this.assetGroup$.pipe(
    switchMap(ag => (ag?.isBrand ? this.brandAssetLibraryTypeDropdowns$ : this.assetLibraryTypeDropdowns$))
  );

  public canSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  connectToAssetGroup = (as: AssetGroup) => this._assetGroup.next(as);

  handleChangeOfAssetLibraryType(selectedLibraryType: string, assetLE: AssetLibraryEntry) {
    assetLE.backgroundColor = selectedLibraryType === AssetLibraryType.AlternateBrand ? '#222222' : 'transparent';
  }

  toggleAssetEnable(assetLE: AssetLibraryEntry) {
    assetLE.enabled = !assetLE.enabled;
  }

  updateAssetLibraryTypes(assetLibraryEntries: AssetLibraryEntry[]) {
    const lm = 'Updating Asset Library Types';
    this._loadingOpts.addRequest(lm);
    const newAssetLibraryMap = new Map<string, string[]>();
    const newDisabledAssets: string[] = [];
    assetLibraryEntries.forEach(ales => {
      if (exists(ales.assetLibraryType) && ales.enabled) {
        let assetLibraryMap = newAssetLibraryMap.get(ales.assetLibraryType);
        if (!assetLibraryMap) { assetLibraryMap = []; }
        assetLibraryMap.push(AssetGroupUtils.createAssetUniqueId(ales.asset));
        newAssetLibraryMap.set(ales.assetLibraryType, assetLibraryMap);
      } else if (!ales.enabled) {
        newDisabledAssets.push(AssetGroupUtils.createAssetUniqueId(ales.asset));
      }
    });

    const updatedAssetGroupCopy = window.injector.Deserialize.instanceOf(AssetGroup, this._assetGroup.getValue());
    updatedAssetGroupCopy.assetTypeMap = newAssetLibraryMap;
    updatedAssetGroupCopy.disabledAssets = newDisabledAssets;

    this.productDomainModel.updateAssetGroup(updatedAssetGroupCopy).subscribe(ags => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Asset Library Types for Asset Group successfully updated', 'Success');
      this.activeModal.close(ags);
    }, (err: BsError) => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(err);
    });
  }

}
