import { HasId } from '../../protocols/has-id';
import { Selectable } from '../../protocols/selectable';
import { Deserializable } from '../../protocols/deserializable';
import { SectionType } from '../../../utils/section-type-definition';

export class SectionBlueprintCategory implements HasId, Selectable, Deserializable {

  public companyId: number;
  public id: string;
  public name: string;
  public allowedSectionTypes: SectionType[];

  // internal use only
  public allowedSectionTypesString: string;

  static newCategory(): SectionBlueprintCategory {
    const category = new SectionBlueprintCategory();
    category.companyId = -1;
    category.name = '';
    category.allowedSectionTypes = [];
    return category;
  }

  onDeserialize() {
    this.allowedSectionTypes = Array.from(this.allowedSectionTypes || []);
    this.allowedSectionTypesString = this.allowedSectionTypes?.sort()?.join('-');
  }

  onSerialize() {
    const dto: SectionBlueprintCategory = Object.create(SectionBlueprintCategory.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.name = this.name;
    dto.allowedSectionTypes = this.allowedSectionTypesString?.split('-') as SectionType[] || [];
    return dto;
  }

  getId(): string {
    return this.id;
  }

  getSelectionTitle(): string {
    return this.name.toUpperCase();
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

}
