import { TypeDefinition } from './type-definition';

export enum MenuSubtype {
  NONE = 'NONE',
  Product = 'PRODUCT_MENU',
  Spotlight = 'SPOTLIGHT_MENU',
  Playlist = 'LOOPING_CONTENT_MENU',
  SmartPlaylist = 'PRODUCT_LINKED_LOOPING_CONTENT_MENU',
  FeaturedProduct = 'FEATURED_PRODUCT_MENU',
  FeaturedProductCombo = 'FEATURED_PRODUCT_COMBO_MENU',
  FeaturedCategoryMenu = 'FEATURED_CATEGORY_MENU',
  PrintCardMenu = 'PRINT_CARD_MENU',
  PrintLabelMenu = 'PRINT_INVENTORY_LABEL_MENU',
  PrintReportOrderReview = 'PRINT_REPORT_ORDER_REVIEW',
  UrlPlaylistMenu = 'URL_PLAYLIST_MENU',
  ShelfTalkerMenu = 'SHELF_TALKER_MENU_BRANDS'
}

export class MenuSubtypeDefinition extends TypeDefinition {

  override value: MenuSubtype;

  static createSeeAllSubType(): MenuSubtypeDefinition {
    return new MenuSubtypeDefinition(MenuSubtype.NONE, 'See All');
  }

}
