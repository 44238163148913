<div class="bs-breadcrumb-container" [style.margin]="margin">
  <ul class="bs-breadcrumb-list" [style.margin]="unorderedListMargin">
    <li
      (click)="breadcrumbClicked(breadcrumb)"
      *ngFor="let breadcrumb of breadcrumbs; let i = index"
      [ngClass]="{ active: breadcrumb.active, disabled: breadcrumb.disabled }"
      class="bs-breadcrumb-item">
      {{ breadcrumb.title }}
      <div *ngIf="i < breadcrumbs.length - 1" class="breadcrumb-divider">
        <img [src]="'assets/icons/dark/outline/chevron-right.svg'" alt="" />
      </div>
    </li>
  </ul>
</div>
