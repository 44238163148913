import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { SectionBlueprint } from '../models/menu/dto/section-blueprint';
import { SectionBlueprintCategory } from '../models/menu/dto/section-blueprint-category';

@Injectable({
  providedIn: 'root'
})
export class TemplateAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Template';

  public CreateSectionBlueprint(sectionBlueprint: SectionBlueprint): Observable<SectionBlueprint> {
    const url = Endpoints.CreateSectionBlueprint();
    return this.apiClient.postObj(SectionBlueprint, url, sectionBlueprint).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSectionBlueprint', err));
        return throwError(err);
      })
    );
  }

  public CreateSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<SectionBlueprintCategory> {
    const url = Endpoints.CreateSectionBlueprintCategory();
    return this.apiClient.postObj(SectionBlueprintCategory, url, category).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSectionBlueprintCategory', err));
        return throwError(err);
      })
    );
  }

  public UpdateSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<SectionBlueprintCategory> {
    const url = Endpoints.UpdateSectionBlueprintCategory();
    return this.apiClient.postObj(SectionBlueprintCategory, url, category).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSectionBlueprintCategory', err));
        return throwError(err);
      })
    );
  }

  public DeleteSectionBlueprintCategory(category: SectionBlueprintCategory): Observable<string> {
    const url = Endpoints.DeleteSectionBlueprintCategory();
    return this.apiClient.deleteStr(url, category).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteSectionBlueprint', err));
        return throwError(err);
      })
    );
  }

  public GetSectionBlueprints(ids?: string[]): Observable<SectionBlueprint[]> {
    const url = Endpoints.GetSectionBlueprints(ids?.join(','));
    const companyId = -1;
    return this.apiClient.getArr<SectionBlueprint>(
      SectionBlueprint,
      url,
      { CompanyId: `${companyId}` }
    ).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSectionBlueprints', err));
        return throwError(err);
      })
    );
  }

  public GetSectionBlueprintCategories(): Observable<SectionBlueprintCategory[]> {
    const url = Endpoints.GetSectionBlueprintCategories();
    const companyId = -1;
    return this.apiClient.getArr<SectionBlueprintCategory>(
      SectionBlueprintCategory,
      url,
      { CompanyId: `${companyId}`}
    ).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSectionBlueprintCategories', err));
        return throwError(err);
      })
    );
  }

  public UpdateSectionBlueprint(blueprint: SectionBlueprint): Observable<SectionBlueprint> {
    const url = Endpoints.UpdateSectionBlueprint();
    return this.apiClient.postObj(SectionBlueprint, url, blueprint).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateSectionBlueprint', err));
        return throwError(err);
      })
    );
  }

  public DeleteSectionBlueprint(blueprint: SectionBlueprint): Observable<string> {
    const url = Endpoints.DeleteSectionBlueprint();
    return this.apiClient.deleteStr(url, blueprint).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteSectionBlueprint', err));
        return throwError(err);
      })
    );
  }

}
