/**
 * Do not delete this enum. The enum is used to BUILD properties on display-attributes and smart-filters.
 * Order matters here. It is used to sort the cannabinoids in the UI.
 * This list is accessed via Object.values(SecondaryCannabinoids).
 */
export enum SecondaryCannabinoid {
  CBDA = 'CBDA',
  CBG = 'CBG',
  CBGA = 'CBGA',
  CBL = 'CBL',
  CBLA = 'CBLA',
  CBN = 'CBN',
  CBNA = 'CBNA',
  CBT = 'CBT',
  CBC = 'CBC',
  CBCA = 'CBCA',
  CBCV = 'CBCV',
  CBDV = 'CBDV',
  THC8 = 'THC8',
  THC9 = 'THC9',
  THCA = 'THCA',
  THCV = 'THCV',
}
