import { Deserializable } from '../../protocols/deserializable';
import { Variant } from './variant';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { AssetGroup } from './asset-group';
import { Pagable } from '../../protocols/pagable';
import { DisplayAttributeGroupDetails } from './display-attribute-group-details';
import { SortUtils } from '../../../utils/sort-utils';

export class DisplayAttributeGroup implements Deserializable, LookAheadItem, Pagable, UniquelyIdentifiable {

  public id: string;
  public groupName: string = '';
  public displayName: string = '';
  public dateCreated: number;
  public lastModified: number;
  public variants: Variant[];
  public assetGroups: AssetGroup[];
  public groupDetails: DisplayAttributeGroupDetails[];

  onDeserialize() {
    this.variants = window.injector.Deserialize.arrayOf(Variant, this.variants);
    this.assetGroups = window.injector.Deserialize.arrayOf(AssetGroup, this.assetGroups);
    this.groupDetails = window.injector.Deserialize
      .arrayOf(DisplayAttributeGroupDetails, this.groupDetails)
      ?.sort(SortUtils.sortDisplayAttributeGroupDetails);
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  getStartKey(): string {
    return this.id;
  }

  getVariantsForDisplayAttributeGroupDetailsFromBarcodes(barcodes: string[]): Variant[] {
    return this.variants?.filter(variant => barcodes?.contains(variant?.barcode)) || [];
  }

  getVariantsForDisplayAttributeGroupDetails(details: DisplayAttributeGroupDetails): Variant[] {
    return this.variants?.filter(variant => details?.variantBarcodes?.contains(variant?.barcode)) || [];
  }

  getDefaultSharedDetails(): DisplayAttributeGroupDetails {
    return this.groupDetails?.find(details => details?.isDefaultSharedGroupForDisplayAttribute());
  }

  updateAssetGroups(updatedAssetGroups: AssetGroup[]) {
    const currentAssetGroupIds = this.assetGroups?.map(ag => ag?.id) || [];
    const updatedAssetGroupIds = updatedAssetGroups?.map(ag => ag?.id) || [];
    const updatedCurrentAssetGroupIds = currentAssetGroupIds.intersection(updatedAssetGroupIds);
    const newAssetGroupIds = updatedAssetGroupIds?.filter(id => !updatedCurrentAssetGroupIds.contains(id));
    const newAssetGroups = updatedAssetGroups?.filter(ag => newAssetGroupIds?.contains(ag?.id));
    const oldAssetGroups = this.assetGroups?.shallowCopy() ?? [];
    const hasNewAssetGroups = newAssetGroupIds?.length > 0;
    if (hasNewAssetGroups) oldAssetGroups?.push(...newAssetGroups);

    const containsUpdates = updatedCurrentAssetGroupIds?.length > 0;
    if (containsUpdates || hasNewAssetGroups) {
      this.assetGroups = oldAssetGroups.deepCopy()?.map(assetGroup => {
        const updatedAG = updatedAssetGroups?.find(ag => ag?.id === assetGroup?.id);
        if (!!updatedAG) {
          return window.injector.Deserialize.instanceOf(AssetGroup, updatedAG);
        } else {
          return assetGroup;
        }
      });
    }
  }

  updateDisplayAttrGroupDetails(updatedGroupDetails: DisplayAttributeGroupDetails): void {
    const updated = this.groupDetails?.shallowCopy() || [];
    const updatedGroupDetailIndex = updated?.findIndex(gd => gd?.id === updatedGroupDetails?.id);
    updatedGroupDetailIndex >= 0
      ? updated[updatedGroupDetailIndex] = updatedGroupDetails
      : updated.push(updatedGroupDetails);
    this.groupDetails = updated;
  }

  removeDisplayAttrGroupDetails(remove: DisplayAttributeGroupDetails): void {
    const updated = this.groupDetails?.shallowCopy() || [];
    const removeAt = updated?.findIndex(gd => gd?.id === remove?.id);
    if (removeAt >= 0) updated?.splice(removeAt, 1);
    this.groupDetails = updated;
  }

  hasCompletedAdditionalProperties(): boolean {
    return this.groupDetails?.some(details => details?.hasAdditionalProperties());
  }

  isAssetGroupSnoozed(): boolean {
    return this.assetGroups?.find(it => it?.isDefaultSharedAssetGroup())?.isBeingSnoozed() || false;
  }

  getUniqueIdentifier(): string {
    const uniqueVariants = this.variants?.map(v => v.getUniqueIdentifier()).sort().join(',');
    const uniqueAssetGroups = this.assetGroups?.map(ag => ag.getUniqueIdentifier()).sort().join(',');
    const uniqueGroupDetails = this.groupDetails?.map(gd => gd?.getUniqueIdentifier())?.sort()?.join(',');
    return `
      -${this.id}
      -${this.groupName}
      -${this.displayName}
      -${uniqueVariants}
      -${uniqueAssetGroups}
      -${uniqueGroupDetails}
    `;
  }

}
