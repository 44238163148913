import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listIncludes'
})
export class ListIncludesPipe implements PipeTransform {

  transform(value: any[], item: any): boolean {
    return value?.includes?.(item);
  }

}
