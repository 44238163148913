import { Orientation } from './orientation-type';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ModalUtils {

  static defaultModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static displayModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static reorderModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static editProductModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static viewProductModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static confirmationModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static informationModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static createMarketingModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static newMenuModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static themeDetailsModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static newMenuSectionModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static menuOptionsModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static duplicateMenuModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static sectionVariantBadgeModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static editMenuStyleModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static setupDeviceModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static addNewBadgeModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static addEditSmartFiltersModalOptions(): NgbModalOptions {
    return ModalUtils.extraLargeStatic();
  }

  static selectSmartFiltersModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static createNewGDAGModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static addToGDAGModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static assetPreviewModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static createAssetGroupsModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static assignVariantToAssetGroupModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static addAssetModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static updateAssetLibraryTypesModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static createBrandsModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static filterByBrandModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static privateCompanyThemeModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static filterByCategoryModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static changeMediaModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  static createSectionBlueprintModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static createSectionBlueprintCategoryModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static smartSyncLocationSelectModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  static printCardPropertyModalOptions(): NgbModalOptions {
    return ModalUtils.extraLargeStatic();
  }

  static large(): NgbModalOptions {
    return {
      size: 'lg',
      centered: true,
    };
  }

  private static mediumStatic(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
    };
  }

  private static largeStatic(): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    };
  }

  private static extraLargeStatic(): NgbModalOptions {
    return {
      size: 'bs-xl',
      backdrop: 'static',
      centered: true,
    };
  }

  static liveViewOptions(o: Orientation): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: (o === Orientation.Landscape) ? 'live-view-modal-landscape' : 'live-view-modal-portrait'
    };
  }

  static productPickerModalOptions(): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'product-picker-modal'
    };
  }

  static editVariantModalOptions(): NgbModalOptions {
    return {
      size:'lg',
      backdrop: 'static',
      centered: true,
      windowClass: 'edit-variant-modal'
    };
  }

  static columnOptionsModalOptions(): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
  }

}
