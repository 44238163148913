import { Injectable } from '@angular/core';
import { DisplayAttributeGroup } from '../../../../models/product/dto/display-attribute-group';
import { ToastService } from '../../../../services/toast-service';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsError } from '../../../../models/shared/bs-error';
import { BaseModalViewModel } from 'src/app/models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { Checkbox } from '../../../../models/shared/stylesheet/checkbox';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { StringUtils } from '../../../../utils/string-utils';

@Injectable()
export class CreateDisplayAttributeGroupViewModel extends BaseModalViewModel {

  public openDisplayGroup: Checkbox = new Checkbox('Open after create');
  private _barcodesToAdd: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public barcodesToAdd$ = this._barcodesToAdd as Observable<string[]>;
  public variantsToAdd$ = combineLatest([
    this.productDomainModel.unassignedVariants$,
    this.barcodesToAdd$
  ]).pipe(
    map(([variants, variantBarcodes]) => variants?.filter(v => variantBarcodes?.contains(v?.barcode)) || [])
  );
  public newDisplayAttributeGroup$ = this.variantsToAdd$.pipe(
    map(variantsToAdd => {
      const displayAttributeGroup = new DisplayAttributeGroup();
      displayAttributeGroup.variants = variantsToAdd;
      return displayAttributeGroup;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  programmaticallyChangeVariantBrand$ = this.variantsToAdd$.pipe(
    map(variantsToAdd => {
      const variantBrandList = variantsToAdd?.map(variant => variant?.brand);
      const filteredBrandList = variantBrandList?.filterFalsies()?.filter(variant => variant !== '-');
      const hasBrands = filteredBrandList.length > 0;
      if ((variantsToAdd?.length > 0) && hasBrands) {
        return `${StringUtils.getStringMode(variantBrandList)} - `;
      } else {
        return undefined;
      }
    })
  );

  constructor(
    private toastService: ToastService,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  createGroup() {
    const lm = 'Creating new Display Attribute Group';
    this._loadingOpts.addRequest(lm);
    this.newDisplayAttributeGroup$.pipe(
      take(1),
      switchMap(newDisplayAttributeGroup => {
        return this.productDomainModel.createDisplayAttributeGroup(newDisplayAttributeGroup);
      })
    ).subscribe(g => {
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishSuccessMessage('Your group was created', 'Success');
      this.activeModal.close(true); // Pass true in order to clear selection
      if (this.openDisplayGroup.checked) {
        this.router.navigate(['products/displayattributes/edit', g.id]).then();
      }
    }, (err: BsError) => {
      this.activeModal.close(false);
      this._loadingOpts.removeRequest(lm);
      this.toastService.publishError(err);
    });
  }

  connectToBarcodesToAdd = (barcodesToAdd: string[]) => this._barcodesToAdd.next(barcodesToAdd);

}
