<lib-reactive-form-group #SyncDataForm>
  <lib-reactive-form-text
    [inputName]="'name'"
    [label]="'Sync Job Name'"
    [placeholder]="viewModel.currentDate$ | async"
    [inlineLabel]="false"
    (valueChanged)="viewModel.connectToName($event)">
  </lib-reactive-form-text>
</lib-reactive-form-group>

<div fxLayout="column" [style.gap.rem]="1">
  <div class="new-sync-subtitle">Data to Sync From POS</div>
  <div class="new-sync-description">Choose what information you would like to sync from the POS.</div>
  <hr class="no-margin" />
</div>

<div>
  <app-selectable-sync-type
    *ngFor="let syncType of viewModel.manualSyncTypeList$ | async"
    [selection]="syncType"
    [selectedIds]="viewModel.selectedSyncTypeIds$ | async"
    [provider]="viewModel.inventoryProvider$ | async"
    (add)="viewModel.addSyncTypeIdToJob($event)"
    (remove)="viewModel.removeSyncTypeIdFromJob($event)">
  </app-selectable-sync-type>
</div>
