import { ApiClient } from './api-client';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { VariantBadge } from '../models/product/dto/variant-badge';
import { catchError } from 'rxjs/operators';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import { VariantLookupLog } from '../models/product/dto/variant-lookup-log';
import { Variant } from '../models/product/dto/variant';
import { DisplayAttributeGroup } from '../models/product/dto/display-attribute-group';
import { AssetGroup } from '../models/product/dto/asset-group';
import { CreateAssetGroupRequest } from '../models/product/requests/create-asset-group-request';
import { ApiPagination } from '../models/shared/api-pagination';
import { ProductType } from '../utils/product-type-definition';
import { SyncDataJob } from '../models/automation/sync-data-job';
import { DisplayAttributeGroupDetails } from '../models/product/dto/display-attribute-group-details';

export const BRAND_ALL_PAGINATION_COUNT = 500;
export const DISPLAY_GROUP_BULK_PAGINATION_COUNT = 500;
export const DISPLAY_GROUP_ALL_PAGINATION_COUNT = 5000;
export const UNIVERSAL_VARIANT_PAGINATION_COUNT = 5000;

@Injectable({ providedIn: 'root' })
export class ProductAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Product';

  // Product

  public DeleteCuratedBadge(badgeIds: string[]): Observable<string> {
    const url = Endpoints.DeleteCuratedBadges(badgeIds.join());
    return this.apiClient.deleteStr(url, badgeIds.join(), null, 'text').pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteCuratedBadge', err));
        return throwError(() => err);
      })
    );
  }

  public SyncDisplayNames(id: string): Observable<CompanyConfiguration> {
    const url = Endpoints.SyncDisplayNames();
    return this.apiClient.getObj<CompanyConfiguration>(CompanyConfiguration, url, {CompanyId: id}).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SyncDisplayNames', err));
        return throwError(() => err);
      })
    );
  }

  public CreateSyncJob(job: SyncDataJob): Observable<SyncDataJob> {
    const url = Endpoints.CreateSyncJob();
    return this.apiClient.postObj(SyncDataJob, url, job).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSyncJob', err));
        return throwError(() => err);
      })
    );
  }

  public GetSyncJobs(companyId: number, jobIds: string[] = null): Observable<SyncDataJob[]> {
    const url = Endpoints.GetSyncJobs(jobIds);
    return this.apiClient.getArr<SyncDataJob>(SyncDataJob, url, {CompanyId: companyId}).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSyncJobs', err));
        return throwError(() => err);
      })
    );
  }

  public GetAdminCuratedBadges(): Observable<HydratedVariantBadge[]> {
    const url = Endpoints.GetAdminCuratedBadges();
    return this.apiClient.getArr<HydratedVariantBadge>(HydratedVariantBadge, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAdminCuratedBadges', err));
        return throwError(() => err);
      })
    );
  }

  public GetAdminCuratedBadge(id: string): Observable<HydratedVariantBadge[]> {
    const url = Endpoints.GetAdminCuratedBadges(id);
    return this.apiClient.getArr<HydratedVariantBadge>(HydratedVariantBadge, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAdminCuratedBadge', err));
        return throwError(() => err);
      })
    );
  }

  public CreateAdminCuratedBadge(badge: VariantBadge): Observable<VariantBadge> {
    const url = Endpoints.CreateCuratedBadge();
    return this.apiClient.postObj(VariantBadge, url, badge).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'WriteVariantBadge', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateCuratedBadges(badges: VariantBadge[]): Observable<HydratedVariantBadge[]> {
    const url = Endpoints.UpdateCuratedBadges();
    return this.apiClient.postArr(HydratedVariantBadge, url, badges).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCuratedBadges', err));
        return throwError(() => err);
      })
    );
  }

  public GetVariantLookupLogs(): Observable<VariantLookupLog[]> {
    const url = Endpoints.GetVariantLookupLogs();
    return this.apiClient.getArr<VariantLookupLog>(VariantLookupLog, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetVariantLookupLogs', err));
        return throwError(() => err);
      })
    );
  }

  public GetVariantsFromBarcode(barcode: string): Observable<Variant[]> {
    const url = Endpoints.GetVariantsFromBarcode(barcode);
    return this.apiClient.getArr<Variant>(Variant, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetVariantsFromBarcode', err));
        return throwError(() => err);
      })
    );
  }

  public GetUniversalVariants(ignoredProductTypes: ProductType[]): Observable<Variant[]> {
    const url = Endpoints.GetUniversalVariants(ignoredProductTypes);
    const pagination = new ApiPagination(UNIVERSAL_VARIANT_PAGINATION_COUNT);
    return this.apiClient.recursiveGet<Variant>(Variant, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getUniversalVariants', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateUniversalVariant(variant: Variant): Observable<Variant> {
    const url = Endpoints.UpdateUniversalVariant();
    return this.apiClient.postObj(Variant, url, variant).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateUniversalVariant', err));
        return throwError(() => err);
      })
    );
  }

  /**
   * If ids are provided, then a list of hydrated attribute groups are returned. You should never be
   * asking for all the hydrated attribute groups at once, because that would be a huge amount of data.
   * Instead, call this method without ids to get all the attribute groups as non-hydrated objects, then call
   * this api again with the ids that you need to get the hydrated version for.
   *
   * @param ids options, if provided, will return hydrated attribute groups for the ids provided,
   * else will return all attribute groups as non-hydrated objects.
   */
  public GetDisplayAttributeGroups(ids?: string): Observable<DisplayAttributeGroup[]> {
    const url = Endpoints.GetDisplayAttributeGroups(ids);
    const pageCount = ids?.length > 0 ? DISPLAY_GROUP_BULK_PAGINATION_COUNT : DISPLAY_GROUP_ALL_PAGINATION_COUNT;
    const pagination = new ApiPagination(pageCount);
    return this.apiClient.recursiveGet<DisplayAttributeGroup>(DisplayAttributeGroup, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplayAttributeGroups', err));
        return throwError(() => err);
      })
    );
  }

  public CreateDisplayAttributeGroup(gdag: DisplayAttributeGroup): Observable<DisplayAttributeGroup> {
    const url = Endpoints.CreateDisplayAttributeGroup();
    return this.apiClient.postObj(DisplayAttributeGroup, url, gdag).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateDisplayAttributeGroup', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateDisplayAttributeGroup(gdag: DisplayAttributeGroup): Observable<DisplayAttributeGroup> {
    const url = Endpoints.UpdateDisplayAttributeGroup();
    return this.apiClient.postObj(DisplayAttributeGroup, url, gdag).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDisplayAttributeGroup', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteDisplayAttributeGroup(gdag: DisplayAttributeGroup): Observable<string> {
    const url = Endpoints.DeleteDisplayAttributeGroup();
    return this.apiClient.deleteStr(url, gdag).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteDisplayAttributeGroup', err));
        return throwError(() => err);
      })
    );
  }

  public CreateDisplayAttributeGroupDetails(
    req: DisplayAttributeGroupDetails
  ): Observable<DisplayAttributeGroupDetails> {
    const url = Endpoints.CreateDisplayGroupDetails();
    return this.apiClient.postObj(DisplayAttributeGroupDetails, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createDisplayGroupDetails', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteDisplayAttributeGroupDetails(req: DisplayAttributeGroupDetails): Observable<string> {
    const url = Endpoints.DeleteDisplayGroupDetails();
    return this.apiClient.deleteStr(url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteDisplayGroupDetails', err));
        return throwError(() => err);
      })
    );
  }

  public GetAssetGroups(displayGroupIds: string, brandDisplayGroupIds: string): Observable<AssetGroup[]> {
    const url = Endpoints.GetAssetGroups(displayGroupIds, brandDisplayGroupIds);
    return this.apiClient.getArr<AssetGroup>(AssetGroup, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAssetGroups', err));
        return throwError(() => err);
      })
    );
  }

  public GetBrandAssetGroups(): Observable<AssetGroup[]> {
    const url = Endpoints.GetBrandAssetGroups();
    const pagination = new ApiPagination(BRAND_ALL_PAGINATION_COUNT);
    return this.apiClient.recursiveGet<AssetGroup>(AssetGroup, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBrandAssetGroups', err));
        return throwError(() => err);
      })
    );
  }

  public CreateAssetGroup(req: CreateAssetGroupRequest): Observable<AssetGroup[]> {
    const url = Endpoints.CreateAssetGroup();
    return this.apiClient.postObjGetArray(AssetGroup, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateAssetGroup', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateAssetGroup(ag: AssetGroup): Observable<AssetGroup[]> {
    const url = Endpoints.UpdateAssetGroup();
    return this.apiClient.postObjGetArray(AssetGroup, url, ag).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateAssetGroup', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteAssetGroup(ag: AssetGroup): Observable<string> {
    const url = Endpoints.DeleteAssetGroup();
    return this.apiClient.deleteStr(url, ag).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteAssetGroup', err));
        return throwError(() => err);
      })
    );
  }

}
