import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Location } from '../../../../../models/company/dto/location';

@Component({
  selector: 'app-edit-location-use-purpose',
  templateUrl: './edit-location-use-purpose.component.html',
  styleUrls: ['./edit-location-use-purpose.component.scss'],
})
export class EditLocationUsePurposeComponent {

  public location: Location;
  public canSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public usePurposeDropdowns$ = window.types.usePurposes$;

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    this.activeModal.close(this.location);
  }

}
