import { BudsenseFile } from '../models/shared/budsense-file';
import { ColorUtils } from './color-utils';
import { MediaType } from '../models/enum/dto/media-type.enum';

export class FileUtils {

  static getPngImageFromHex(hex: string, canvas: HTMLCanvasElement): BudsenseFile {
    hex = ColorUtils.validateHexColor(hex);
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;
    ctx.fillStyle = hex;
    ctx.fillRect(0, 0, 500, 500);
    const encodedData = 'data:image/png;base64,' + canvas.toDataURL('image/png', '').substring(22);
    // Init File for color
    const file = new BudsenseFile();
    file.name = `${hex}.png`.toLowerCase();
    file.type = MediaType.PNG;
    file.url = encodedData;
    return file;
  }

  static formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
